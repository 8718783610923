import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('ValuationCategory', {params});
    }, 
    show(id) {
        return Service().get('ValuationCategory/show/'+id);
    },    
    create(params) {
        return Service().post('ValuationCategory/create', params);
    },
    update(id, params) {
        return Service().post('ValuationCategory/update/'+id, params);
    },
    delete(id) {
        return Service().post('ValuationCategory/delete/'+id);
    }
}