import Service from '@/helpers/service'

export default {
	endUserSearchMaterial(page, params) {
		return Service().get('end-user-search-material?page=' + page, {params});
	},
	endUserSearchByCharacteristic(page, params) {
		return Service().get('end-user-search-by-characteristic?page=' + page, {params});
	},
    partCharValueEndUser(params) {
        return Service().get('part-char-value-end-user', {params});
    },
    getMaterialPlant(params) {
        return Service().get('material-plant', {params});
    },
    getMaterialShp(params) {
        return Service().get('end-user-shp-by-material-number', {params});
    },
}
