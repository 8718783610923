import Service from '@/helpers/service'

export default {
	approval(urlApproval, key, param) {
		//approve, reject, reapprove, reject-item, revise-item, processs
        return Service().post('/mcr-approval-page/'+ urlApproval +'/'+key, param); 
	},
	multiApproval(type, param) {
		// multi-revise, multi-reject, multi-approve
        return Service().post('/mcr-approval-page/multi-approval/'+type, param); 
	},
	appTracking(mcr_code){
		return Service().get('/emcr/log_approval/'+ mcr_code); 
	},
	process(params, id) {
        return Service().post('mcr-approval-page/process/'+id, params, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
        });
    },
    statusWorkflow(mcr_code){
		return Service().get('statusWorkflow/'+ mcr_code); 
	},
	receiver(mcr_code){
		return Service().get('receiver/'+ mcr_code); 
	},
}
