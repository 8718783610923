<template>
    <div v-if="requestCalls > 0">
        <skeleton-loading></skeleton-loading>
    </div>
    <div v-else>
        <label class="form-control-label">{{ tt('description') }} <span class="text-danger">*</span></label>
        <base-input :disabled="form.disabled" :name="tt('description')" :placeholder="tt('description')" v-model="inputDraftListB1.description" @input="inputDraftListB1.description = inputDraftListB1.description.toUpperCase()" rules="required"></base-input>

        <div id="accordionPrm">
            <div class="card z-depth-0 bordered">
                <div class="card-header bg-secondary border" id="headingOneBds">
                    <div class="row">
                      <div class="col-md-10">
                        <h5 class="mb-0">
                          <a
                            href="javascript:void(0)"
                            @click="toggleAccordion(accordion.prm.show, 'prm')"
                            >{{ tt("parameter") }}
                            <span class="text-danger">*</span></a
                          >
                        </h5>
                      </div>
                      <div class="col-md-2 text-right">
                          <base-button v-if="formType != 'detail'" size="sm" type="default" @click="addParamsB1">{{ tt('add_new') }}</base-button>
                      </div>
                    </div>
                </div>
                <div id="collapsePrm" :class="'collapse ' + accordion.prm.show">
                    <table class="table align-items-center table-flush table-bordered table-condensed table-sm">
                        <thead class="card-header bg-primary">
                            <tr>
                                <th class="text-white">{{tt('parameter')}} <span class="text-danger">*</span></th>
                                <th class="text-white">{{tt('category')}} <span class="text-danger">*</span></th>
                                <th class="text-white">{{tt('value')}} <span class="text-danger">*</span></th>
                                <th class="text-white">{{tt('description')}} <span class="text-danger">*</span></th>
                                <th class="text-white">{{tt('attachment')}}</th>
                                <th class="text-white">#</th>
                            </tr>
                        </thead>
                        <tbody>
                            <!-- PREVIEW -->
                            <tr v-for="(pp, key) in prevParamsB1.parameter" v-bind:key="pp">
                                <td>
                                    <select :disabled="form.disabled" class="form-control form-control-sm" v-model="prevParamsB1.parameter[key]">
                                        <option value="">Choose Parameter</option>
                                        <option v-for="p in parameter" v-bind:key="p">{{p}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select :disabled="form.disabled" class="form-control form-control-sm" v-model="prevParamsB1.category[key]">
                                        <option value="">Choose Category</option>
                                        <option :value="c" v-for="c in category" v-bind:key="c">{{c}}</option>
                                    </select>
                                </td>
                                <td>
                                    <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('value')" :placeholder="tt('value')" v-model="prevParamsB1.value[key]" rules="required">
                                </td>
                                <td>
                                    <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('description')" :placeholder="tt('description')" v-model="prevParamsB1.description[key]" rules="required">
                                </td>
                                <td v-if="formType != 'add'">
                                    <span style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'smartcat_attachment/'+prevParamsB1.preview[key])">
                                    {{prevParamsB1.preview[key]}}
                                </span>
                                </td>
                                <td v-if="formType != 'detail'">
                                    <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="removePrevAd(prevParamsB1.id[key])"></i>
                                </td>
                            </tr>

                            <!-- INPUT -->
                            <tr v-for="(pp, key) in paramsB1.parameter" v-bind:key="pp">
                                <td>
                                    <select :disabled="form.disabled" class="form-control form-control-sm" v-model="paramsB1.parameter[key]">
                                        <option value="">Choose Parameter</option>
                                        <option v-for="p in parameter" v-bind:key="p">{{p}}</option>
                                    </select>
                                </td>
                                <td>
                                    <select :disabled="form.disabled" class="form-control form-control-sm" v-model="paramsB1.category[key]">
                                        <option value="">Choose Category</option>
                                        <option :value="c" v-for="c in category" v-bind:key="c">{{c}}</option>
                                    </select>
                                </td>
                                <td>
                                    <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('value')" :placeholder="tt('value')" v-model="paramsB1.value[key]"  @input="paramsB1.value[key] = paramsB1.value[key].toUpperCase()" rules="required">
                                </td>
                                <td>
                                    <input :disabled="form.disabled" class="form-control form-control-sm" :name="tt('description')" :placeholder="tt('description')" v-model="paramsB1.description[key]" @input="paramsB1.description[key] = paramsB1.description[key].toUpperCase()" rules="required">
                                </td>
                                <td>
                                    <file-input
                                        :disabled="form.disabled"
                                        @change="changeFileAttachmentName"
                                        :initialLabel="(pp.attachment) ? pp.attachment : maxValidation.text"
                                        :ref="'file' + key"
                                        accept="application/pdf"
                                        :max-file="maxValidation.size"
                                    ></file-input>
                                </td>
                                <td v-if="formType != 'detail'">
                                    <i class="fa fa-trash text-danger" style="cursor: pointer;" @click="removeParamsB1(key)" v-if="formType == 'edit' || key != 0"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <label class="form-control-label">{{ tt('reason') }} <span class="text-danger">*</span></label>
        <base-input :name="tt('reason')" rules="required">
            <textarea :disabled="form.disabled" class="form-control" v-model="inputDraftListB1.reason" @input="inputDraftListB1.reason = inputDraftListB1.reason.toUpperCase()" rows="5"></textarea>
        </base-input>
        <label class="form-control-label">{{ tt('note') }}</label>
        <base-input :name="tt('note')">
            <textarea :disabled="form.disabled" class="form-control" v-model="inputDraftListB1.note" @input="inputDraftListB1.note = inputDraftListB1.note.toUpperCase()" rows="5"></textarea>
        </base-input>

        <div class="text-right mb-3">
            <base-button
              type="primary"
              @click="save()"
              :disabled="form.btnSave.onLoading">
              <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
              <span v-else>{{ tt("save") }}</span>
            </base-button>
        </div>

        <!-- PREVIEW ATTACHMENT -->
        <preview-attachment :previewAttachmentModalProp='previewAttachmentModal' :attachmentFileProp='attachmentFile' :attachmentTypeProp='attachmentFileType' @changeModal="changeModalPrev"/>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import baseApiUrl from '@/configs/config';

    export default {        
        data() {
            return { 
                requestCalls: 0,
                form: {
                    disabled: false,
                    btnSave: {
                        onLoading: false
                    }
                },
                accordion: {
                    prm: {
                        show: 'show'
                    },
                },
                inputDraftListB1: {
                    id: '',
                    description: '',
                    reason: '',
                    note: ''
                },
                prevParamsB1: {
                    id: [],
                    parameter: [],
                    category: [],
                    value: [],
                    description: [],
                    preview: [],
                },
                paramsB1: {
                    parameter: [],
                    category: [],
                    value: [],
                    description: [],
                    attachment: [],
                },
                parameter: {},
                category: {},
                apiUrl: baseApiUrl.storageUrl,
                previewAttachmentModal: false,
                attachmentFile: '',
                attachmentFileType: '',

                mcr_code: this.$route.params.mcr_code,
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
                maxValidation:{
                    size:null,
                    text:null
                }

            }
        },
        computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
        },
        mounted() {
            this.maximumValidation()
            this.getDetailMcrItem();
            this.getParameterMcr();
            this.checkFormType();
        },
        methods: {
            maximumValidation(){
                this.maximumSizeAlert().then((res) =>{
                    this.maxValidation.size = res.size
                    this.maxValidation.text = res.text
                })
            },
            checkFormType() {
                if(this.formType == 'edit'){
					this.form.disabled = false;
				} else if (this.formType == 'detail'){
					this.form.disabled = true;
				} else if (this.formType == 'approval' && this.authUserPermission['mcr-approval-cataloguer-form-edit']){
					this.form.disabled = false;
				} else if (this.formType == 'sapSyncTaskEdit'){
					this.form.disabled = false;
				} else {
					this.form.disabled = true;
				}
            },
            getDetailMcrItem() {
                this.requestCalls++;
                let context = this; 
                
                context.prevParamsB1.parameter       = [];
                context.prevParamsB1.category        = [];
                context.prevParamsB1.value           = [];
                context.prevParamsB1.description     = [];
                context.prevParamsB1.preview         = [];

                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {
                    context.inputDraftListB1.id          = response.data.data[0].id;
                    context.inputDraftListB1.description = response.data.data[0].specification;
                    context.inputDraftListB1.reason      = response.data.data[0].reason;
                    context.inputDraftListB1.note        = response.data.data[0].note;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inputDraftListB1 = [];
                    }
                }).call()
                
                Api(context, draftList.getMcrItemParameter(this.mcrItemCode)).onSuccess(function(response) {
                    for (var i=0; i<response.data.data.length; i++) {
                        context.prevParamsB1.id[i]          = response.data.data[i].id;
                        context.prevParamsB1.parameter[i]   = response.data.data[i].parameter;
                        context.prevParamsB1.category[i]    = response.data.data[i].category;
                        context.prevParamsB1.value[i]       = response.data.data[i].value;
                        context.prevParamsB1.description[i] = response.data.data[i].description;
                        context.prevParamsB1.preview[i]     = response.data.data[i].attachment;
                    }                        
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.prevParamsB1.parameter   = [];
                        context.prevParamsB1.category    = [];
                        context.prevParamsB1.value       = [];
                        context.prevParamsB1.description = [];
                        context.prevParamsB1.preview     = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getParameterMcr() {
                this.requestCalls++;
                let context = this;

                Api(context, draftList.getParameterMcr(this.mcrItemCode))
                .onSuccess(function(response) {
                  context.parameter = response.data.data.parameter;
                  context.category = response.data.data.category;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.input = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            addParamsB1() {
                this.paramsB1.parameter.push('');
                this.paramsB1.category.push('');
                this.paramsB1.value.push('');
                this.paramsB1.description.push('');
                this.paramsB1.attachment.push('');
            },
            removeParamsB1(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.paramsB1.parameter.splice(key,1)
                        this.paramsB1.category.splice(key,1)
                        this.paramsB1.value.splice(key,1)
                        this.paramsB1.description.splice(key,1)
                        this.paramsB1.attachment.splice(key,1)
                    }
                })
            },
            save() {
                let context = this;
                let formData = new FormData();
                formData.append("note", (context.inputDraftListB1.note == null || context.inputDraftListB1.note.length == 0 ? '' : context.inputDraftListB1.note));

                if (context.inputDraftListB1.description != '' && context.inputDraftListB1.reason != '') 
                {
                    formData.append("mcr_code", context.mcr_code);
                    formData.append("specification", context.inputDraftListB1.description);
                    formData.append("reason", context.inputDraftListB1.reason);

                    // ------------------ PREV PARAMETER ------------------
                    for (var i=0; i<context.prevParamsB1.parameter.length; i++) 
                    {
                        if (context.prevParamsB1.parameter[i] != "" && context.prevParamsB1.category[i] != "" && context.prevParamsB1.value[i] != "" && context.prevParamsB1.description[i] != "") 
                        {
                            formData.append("prev_parameter[" + i + "]", context.prevParamsB1.parameter[i]);
                            formData.append("prev_category[" + i + "]", context.prevParamsB1.category[i]);
                            formData.append("prev_value[" + i + "]", context.prevParamsB1.value[i]);
                            formData.append("prev_description[" + i + "]", context.prevParamsB1.description[i]);
                            if (context.prevParamsB1.preview[i] != null) {
                                formData.append("prev_attachment[" + i + "]", context.prevParamsB1.preview[i]);
                            }else{
                                formData.append("prev_attachment[" + i + "]", '');
                            }
                        } else {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("parameter") }),                  
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            return false;
                        }
                    }

                    // ------------------ PARAMETER ------------------
                    for (var i=0; i<context.paramsB1.parameter.length; i++) 
                    {
                        if (context.paramsB1.parameter[i] != "" && context.paramsB1.category[i] != "" && context.paramsB1.value[i] != "" && context.paramsB1.description[i] != "") 
                        {
                            formData.append("parameter[" + i + "]", context.paramsB1.parameter[i]);
                            formData.append("category[" + i + "]", context.paramsB1.category[i]);
                            formData.append("value[" + i + "]", context.paramsB1.value[i]);
                            formData.append("description[" + i + "]", context.paramsB1.description[i]);
                            if (context.paramsB1.attachment[i] != null) {
                                formData.append("attachment[" + i + "]", context.paramsB1.attachment[i]);
                            }else{
                                formData.append("attachment[" + i + "]", '');
                            }
                        } else {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("parameter") }),                  
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            return false;
                        }
                    }

                    // STATUS PROCESS
                    formData.append('status_process', 'user_process')
                    
                    context.form.btnSave.onLoading = true;

                    Api(context, draftList.updateMcrItemNoFormData(formData, context.inputDraftListB1.id)).onSuccess(function(response) {
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$emit('saveB1')
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                        }

                        context.$notify({
                            message: 'Error 500',                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        context.form.btnSave.onLoading = false;
                    })
                    .call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("create_&_edit_master_cataloguing") }),                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                    return false; 
                }                
            },
            toggleAccordion(show, type) {
              if (type == 'prm') {
                    if (show == '') {
                        this.accordion.prm.show = 'show'
                    } else {
                        this.accordion.prm.show = ''
                    }
                }
            },
            changeFileAttachmentName(files) {
                if(files.length === 0){
                    return;
                }


                if (files[0].size > this.maxValidation.size) {
                    this.paramsB1.attachment == [];
                    this.$notify({
                        message :this.maxValidation.text,
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }else {
                    this.paramsB1.attachment[
                        this.paramsB1.attachment.length - 1
                    ] = files[0];
                }
            },
            removePrevAd(id){
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemParameter(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
            },
            previewAttachment(attachment) {
                this.previewAttachmentModal = '';
                this.attachmentFile         = '';
                this.attachmentFileType     = '';
                this.previewAttachmentModal = true;
                this.attachmentFile         = attachment;
                if (attachment.substring(attachment.length-3) == 'peg' || attachment.substring(attachment.length-3) == 'PEG') {
                    this.attachmentFileType = attachment.substring(attachment.length-4);
                }else{
                    this.attachmentFileType = attachment.substring(attachment.length-3);
                }
            },
            changeModalPrev(value) {
                this.previewAttachmentModal = value;
            }
        }   
    };
</script>