<template>
    <div>
        <label class="form-control-label">Level <span class="text-danger">*</span></label>
        <base-input :name="tt('client_level')" rules="required">
            <el-select v-model="client_plant_level" :placeholder="tt('choose_client_level_or_plant_level')" :disabled="formType == 'detail' || formType == 'approval'" @change="changeLevel">
                <el-option value="Specification" label="Specification" key="Specification"></el-option>
                <el-option value="RopMinMax" label="ROP & Min-Max" key="RopMinMax"></el-option>
                <el-option value="BoM" label="Bill of Material" key="BoM" v-if="authUserRole['Staff Reliability']"></el-option>
                <el-option value="SHP" label="Stock Holding Policy" key="Stock Holding Policy"></el-option>
            </el-select>
        </base-input>
        
        <!-- hanya jika client level SHP -->
        <div class="row" v-if="client_plant_level == 'SHP'">
			<div class="col-md-12">
				<label class="form-control-label">{{ tt("equipment") }} <span class="text-danger">*</span></label>
				<base-input :name="tt('equipment')">
					<el-select style="width: 100%;" size="small" v-model="shp_equipment" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipment" :loading="inputTo.loading" clearable @change="changeEquipment" :disabled="(formType == 'approval' || formType == 'detail' || mcrType == 'A1S' || mcrType == 'B2S') ? true : false">
                        <!-- @focus="changeLog('equipment')" -->
						<el-option v-for="ec in equipments" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
					</el-select>
				</base-input>
			</div>
			<div class="col-md-12" v-if="mcrType == 'A2' || mcrType == 'B3'">
				<label class="form-control-label">{{ tt("material_number") }} <span class="text-danger">*</span></label>
				<base-input :name="tt('material_number')">
					<el-select style="width: 100%;" size="small" v-model="shp_material_number" filterable reserve-keyword :placeholder="tt('material_number')" :loading="inputTo.loading" @change="changeMaterialNumber" :disabled="(formType == 'approval' || formType == 'detail' || mcrType == 'A1S' || mcrType == 'B2S') ? true : false" >
                        <!-- @focus="changeLog('material_number')" -->
						<el-option v-for="mn in materialNumbers" :key="mn.material_number" :label="mn.material_number + ' - ' + mn.short_description" :value="mn.material_number"></el-option>
					</el-select>
				</base-input>
			</div>
		</div>

        <McrFormA2a v-if="client_plant_level == 'Specification' || client_plant_level == 'BoM'" @saveA2a="processA2" ref="formA2a" :test="client_plant_level"></McrFormA2a>
        <McrFormA2b v-if="client_plant_level == 'RopMinMax'" @saveA2b="processA2"></McrFormA2b>
        <!-- <McrFormShp v-if="client_plant_level == 'SHP'" @saveShp="processA2"></McrFormShp> -->
        <!-- <McrFormShp ref="shp" v-if="client_plant_level == 'SHP'" @saveShp="processA2" @containedValuesShp="actContainedValuesShp"></McrFormShp> -->
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import McrFormA2a from "./McrFormA2a.vue";
    import McrFormA2b from "./McrFormA2b.vue";
    import McrFormShp from "./McrFormShp.vue";
	import equipmentCode from "@/services/master/equipmentCode.service";
    import materialNumber from '@/services/master/materialNumber.service';

    export default {       
        components: {
            McrFormA2a,
            McrFormA2b,
            McrFormShp,
        } ,
        props:['saveA2a', 'saveA2b', 'saveShp'],
        data() {
            return {
                client_plant_level: '',
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
                mcrType: this.$route.params.mcr_type,
                valuesShpComeFromOutside: {},
                shp_equipment:'',
                shp_material_number:'',
                equipments: [],
                materialNumbers: [],
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                valuesOfShpForm: []
            }
        },
        computed: {
            ...mapState({
                authUserRole :state => state.auth.userRole
            }),
        },
        mounted() {
            this.getDetailMcrItem()
        },
        methods: {
            getDetailMcrItem() {
                let context = this; 
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.client_plant_level = response.data.data[0].client_plant_level;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.client_plant_level = '';
                    }
                }).call()
            },
            processA2(){
                this.$emit('processA2');
            },
            changeLevel(event) {
                this.$refs.formA2a.level = event
            },
            getEquipment(query) {
		    	if (query.length > 3) {
			  		clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, equipmentCode.getByCompany(null, {search: query})).onSuccess(function(response) {
	                        context.equipments =  response.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.equipments = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
	            }
		    },
            changeEquipment(value){
				this.shp_material_number = '';
				this.dependEquipment(value)
			},
			dependEquipment(eqp) {                                   
				let context = this;   
				Api(context, materialNumber.get({search: '',equipment:eqp})).onSuccess(function(response) {
					context.materialNumbers = response.data.data;                        
					context.inputTo.loading = false;                     
				}).onError(function(error) {
					context.materialNumbers = [];
					context.inputTo.loading = false;
				}).call();                        
            },
            changeMaterialNumber(event){
				this.requestCalls++;
				let context = this;
				context.getSourceType()
				context.getManrefType()

                context.$set(context.valuesOfShpForm, {
                    input: {
                        shp_mce_strategy: {
                            mce_strategy: [],
                            mce_interval: [],
                            mce_net_pf: [],
                            mce_tindakan: []
                        },
                        shp_failure_mode: [],
                    },
                    manufacturePartNumber: {
                        manufacture_code: [],
                        manufacture_code_view: [],
                        mpn: [],
                        source_type: [],
                        position: [],
                        manufacture_type: [],
                        manufacture_note: []
                    },
                })

				Api(context, endUserSearchMaterial.getMaterialShp({material_number: event, equipment_code: this.input.shp_equipment})).onSuccess(function(response) {

					context.input.shp_effect_spare_part_not_available  = ""
					context.input.shp_action_to_handle  = ""
					context.input.shp_lead_time_action_to_handle  = ""
					context.input.shp_consequences_criticality  = ""
					context.input.shp_estimated_loss_idr  = ""
					context.input.shp_estimated_loss_day  = ""
					context.input.shp_total_estimated_loss  = ""
					context.input.shp_estimated_lead_time  = ""
					context.input.shp_category_lead_time  = ""
					context.input.shp_abc_indicator  = ""
					context.input.shp_unit_price  = ""
					context.input.shp_quantity_on_hand  = '0'
					context.input.shp_value_unrestricted  = ""
					context.input.shp_original_stock_strategy  = ""
					context.input.shp_stock_strategy  = ""
					context.input.shp_change_mrp_type  = ""
					context.input.shp_resume_justification_stock_strategy  = ""
					context.input.shp_demand_rate  = ""
					context.input.shp_original_stock_level_type_calculation  = ""
					context.input.shp_stock_level_type_calculation  = ""
					context.input.shp_original_service_level  = ""
					context.input.shp_service_level  = ""
					context.input.shp_original_stock_level  = ""
					context.input.shp_stock_level  = ""
                    context.input.shp_mce_strategy.mce_strategy = []
					context.input.shp_mce_strategy.mce_interval = []
					context.input.shp_mce_strategy.mce_net_pf = []
					context.input.shp_mce_strategy.mce_tindakan = []
					context.input.shp_failure_mode  = []
                    context.manufacturePartNumber.manufacture_code = [];
			        context.manufacturePartNumber.manufacture_code_view = [];
			        context.manufacturePartNumber.mpn = [];
			        context.manufacturePartNumber.source_type = [];
			        context.manufacturePartNumber.position = [];
			        context.manufacturePartNumber.manufacture_type = [];
			        context.manufacturePartNumber.manufacture_note = [];
                    context.equipmentQuantityInstall.plant_code = [];
			        context.equipmentQuantityInstall.equipment_code = [];
			        context.equipmentQuantityInstall.qty_installed = [];
			        context.equipmentQuantityInstall.drawing_number = [];
			        context.equipmentQuantityInstall.del_flag = [];
			        context.equipmentQuantityInstall.status = [];
			        context.equipmentQuantityInstall.show = [];
			        context.equipmentQuantityInstall.attachment = [];
                    context.partShortDesc = '';
                    context.prcInc = '';

					if(!!response.data.data.shp){
						context.input.shp_effect_spare_part_not_available  = response.data.data.shp.shp_effect_spare_part_not_available != null ? response.data.data.shp.shp_effect_spare_part_not_available : ''
						context.input.shp_action_to_handle  = response.data.data.shp.shp_action_to_handle != null ? response.data.data.shp.shp_action_to_handle : ''
						context.input.shp_lead_time_action_to_handle  = response.data.data.shp.shp_lead_time_action_to_handle != null ? response.data.data.shp.shp_lead_time_action_to_handle : ''
						context.input.shp_consequences_criticality  = response.data.data.shp.shp_consequences_criticality != null ? response.data.data.shp.shp_consequences_criticality : ''
						context.input.shp_estimated_loss_idr  = response.data.data.shp.shp_estimated_loss_idr != null ? response.data.data.shp.shp_estimated_loss_idr : ''
						context.input.shp_estimated_loss_day  = response.data.data.shp.shp_estimated_loss_day != null ? response.data.data.shp.shp_estimated_loss_day : ''
						context.input.shp_total_estimated_loss  = response.data.data.shp.shp_total_estimated_loss != null ? response.data.data.shp.shp_total_estimated_loss : ''
						context.input.shp_estimated_lead_time  = response.data.data.shp.shp_estimated_lead_time != null ? response.data.data.shp.shp_estimated_lead_time : ''
						context.input.shp_category_lead_time  = response.data.data.shp.shp_category_lead_time != null ? response.data.data.shp.shp_category_lead_time : ''
						context.input.shp_abc_indicator  = response.data.data.shp.shp_abc_indicator != null ? response.data.data.shp.shp_abc_indicator : ''
						context.input.shp_unit_price  = response.data.data.shp.shp_unit_price != null ? response.data.data.shp.shp_unit_price : ''
						context.input.shp_quantity_on_hand  = response.data.data.shp.shp_quantity_on_hand != null ? response.data.data.shp.shp_quantity_on_hand : '0'
						context.input.shp_value_unrestricted  = response.data.data.shp.shp_value_unrestricted != null ? response.data.data.shp.shp_value_unrestricted : ''
						context.input.shp_original_stock_strategy  = response.data.data.shp.shp_stock_strategy != null ? response.data.data.shp.shp_stock_strategy : ''
						context.input.shp_stock_strategy  = ''
						context.input.shp_change_mrp_type  = response.data.data.shp.shp_change_mrp_type != null ? response.data.data.shp.shp_change_mrp_type : ''
						context.input.shp_resume_justification_stock_strategy  = response.data.data.shp.shp_resume_justification_stock_strategy != null ? response.data.data.shp.shp_resume_justification_stock_strategy : ''
						context.input.shp_demand_rate  = response.data.data.shp.shp_demand_rate != null ? response.data.data.shp.shp_demand_rate : ''
						context.input.shp_original_stock_level_type_calculation  = response.data.data.shp.shp_stock_level_type_calculation != null ? response.data.data.shp.shp_stock_level_type_calculation : ''
						context.input.shp_stock_level_type_calculation  = ''
						context.input.shp_original_service_level  = response.data.data.shp.shp_service_level != null ? response.data.data.shp.shp_service_level : ''
						context.input.shp_service_level  = ''
						context.input.shp_original_stock_level  = response.data.data.shp.shp_stock_level != null ? response.data.data.shp.shp_stock_level : ''
						context.input.shp_stock_level  = ''
						context.input.shp_original_min  = response.data.data.shp.shp_min != null ? response.data.data.shp.shp_min : ''
						context.input.shp_min  = ''
						context.input.shp_original_max  = response.data.data.shp.shp_max != null ? response.data.data.shp.shp_max : ''
						context.input.shp_max  = ''
						context.input.shp_date_during_analysis  = response.data.data.shp.shp_date_during_analysis != null ? response.data.data.shp.shp_date_during_analysis : ''
						context.input.shp_owner_estimate  = response.data.data.shp.shp_owner_estimate != null ? parseInt(response.data.data.shp.shp_owner_estimate) : 0
					}

					context.input.shp_mce_strategy.mce_strategy = []
					context.input.shp_mce_strategy.mce_interval = []
					context.input.shp_mce_strategy.mce_net_pf = []
					context.input.shp_mce_strategy.mce_tindakan = []
					context.input.shp_failure_mode  = []

					if(!!response.data.data.shp && response.data.data.shp.shp_mce_strategy != '' ){
						let mceStrategies = JSON.parse(response.data.data.shp.shp_mce_strategy);
						let mceIntervals = JSON.parse(response.data.data.shp.shp_interval);
						let mceNetPf = JSON.parse(response.data.data.shp.shp_net_pf);
						let mceTindakan = JSON.parse(response.data.data.shp.shp_tindakan);
						for(var i = 0; i < mceStrategies.length;i++){
							context.input.shp_mce_strategy.mce_strategy.push(mceStrategies[i])
							context.input.shp_mce_strategy.mce_interval.push(mceIntervals[i])
							context.input.shp_mce_strategy.mce_net_pf.push(mceNetPf[i])
							context.input.shp_mce_strategy.mce_tindakan.push(mceTindakan[i])
						}
					}

					if(!!response.data.data.shp && response.data.data.shp.shp_failure_mode != ''){
						let failureModes = JSON.parse(response.data.data.shp.shp_failure_mode);

						for(var i = 0; i < failureModes.length;i++){
							context.input.shp_failure_mode.push(failureModes[i])
						}
					}

					context.manufacturePartNumber.manufacture_code = [];
			        context.manufacturePartNumber.manufacture_code_view = [];
			        context.manufacturePartNumber.mpn = [];
			        context.manufacturePartNumber.source_type = [];
			        context.manufacturePartNumber.position = [];
			        context.manufacturePartNumber.manufacture_type = [];
			        context.manufacturePartNumber.manufacture_note = [];

					for (var i = 0; i < response.data.data.manufacture.length; i++) {
						context.manufacture[i] = response.data.data.manufacture[i];
						context.manufacturePartNumber.manufacture_code[i] = response.data.data.manufacture[i].manufacture_code;
						context.manufacturePartNumber.manufacture_code_view[i] = response.data.data.manufacture[i].manufacture_code +' - '+ response.data.data.manufacture[i].manufacture_name;
						context.manufacturePartNumber.mpn[i] = response.data.data.manufacture[i].manufacture_ref;
						context.manufacturePartNumber.source_type[i] = response.data.data.manufacture[i].source_type_code;
						context.manufacturePartNumber.position[i] = response.data.data.manufacture[i].position;
						context.manufacturePartNumber.manufacture_type[i] = response.data.data.manufacture[i].type;
						context.manufacturePartNumber.manufacture_note[i] = response.data.data.manufacture[i].note;
					}

					
					context.equipmentQuantityInstall.plant_code = [];
			        context.equipmentQuantityInstall.equipment_code = [];
			        context.equipmentQuantityInstall.qty_installed = [];
			        context.equipmentQuantityInstall.drawing_number = [];
			        context.equipmentQuantityInstall.del_flag = [];
			        context.equipmentQuantityInstall.status = [];
			        context.equipmentQuantityInstall.show = [];
			        context.equipmentQuantityInstall.attachment = [];

					for (var i = 0; i < response.data.data.equipment.length; i++) {
						context.equipmentQuantityInstall.plant_code[i] = response.data.data.equipment[i].plant_code;
						context.equipmentQuantityInstall.equipment_code[i] = response.data.data.equipment[i].equipment_code;
						context.equipmentQuantityInstall.qty_installed[i] = response.data.data.equipment[i].qty_installed;
						context.equipmentQuantityInstall.drawing_number[i] = response.data.data.equipment[i].drawing_number;
						context.equipmentQuantityInstall.del_flag[i] = response.data.data.equipment[i].del_flag;
						context.equipmentQuantityInstall.status[i] = response.data.data.equipment[i].status;
						context.equipmentQuantityInstall.show[i] = response.data.data.equipment[i].show;
						context.equipmentQuantityInstall.attachment[i] = response.data.data.equipment[i].attachment;
					}


					context.partShortDesc = '';
					context.partShortDesc =response.data.data.short_desc ? response.data.data.short_desc.short_description : '';

					context.prcInc = '';
					context.prcInc = response.data.data.inc ? response.data.data.inc : '';

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
						context.equipmentQuantityInstall.plant_code = [];
						context.equipmentQuantityInstall.equipment_code = [];
						context.equipmentQuantityInstall.qty_installed = [];
						context.equipmentQuantityInstall.drawing_number = [];
						context.equipmentQuantityInstall.del_flag = [];
						context.equipmentQuantityInstall.status = [];
						context.equipmentQuantityInstall.show = [];
						context.equipmentQuantityInstall.attachment = [];

						context.manufacturePartNumber.manufacture_code = [];
						context.manufacturePartNumber.manufacture_code_view = [];
						context.manufacturePartNumber.mpn = [];
						context.manufacturePartNumber.source_type = [];
						context.manufacturePartNumber.position = [];
						context.manufacturePartNumber.manufacture_type = [];
						context.manufacturePartNumber.manufacture_note = [];

						context.partShortDesc = '';
						context.prcInc = '';

						context.input.shp_failure_mode = [] 
						context.input.shp_mce_strategy.mce_strategy = []
						context.input.shp_mce_strategy.mce_interval  = []
						context.input.shp_mce_strategy.mce_net_pf  = []
						context.input.shp_mce_strategy.mce_tindakan  = []
						context.input.shp_effect_spare_part_not_available = ''
						context.input.shp_action_to_handle = ''
						context.input.shp_lead_time_action_to_handle = ''
						context.input.shp_consequences_criticality = ''
						context.input.shp_estimated_loss_idr = ''
						context.input.shp_estimated_loss_day = ''
						context.input.shp_total_estimated_loss = ''
						context.input.shp_estimated_lead_time = ''
						context.input.shp_category_lead_time = ''
						context.input.shp_abc_indicator = ''
						context.input.shp_unit_price = ''
						context.input.shp_quantity_on_hand = '0'
						context.input.shp_value_unrestricted = ''
						context.input.shp_original_stock_strategy = ''
						context.input.shp_stock_strategy = ''
						context.input.shp_change_mrp_type = ''
						context.input.shp_resume_justification_stock_strategy = ''
						context.input.shp_demand_rate = ''
                    }
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
			},
        }
    };
</script>