import { render, staticRenderFns } from "./McrFormA1.vue?vue&type=template&id=1169a047"
import script from "./McrFormA1.vue?vue&type=script&lang=js"
export * from "./McrFormA1.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports