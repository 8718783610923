<template>
    <div>
        <base-button class="float-right mb-2" size="sm" @click="filter.show = true" :disabled="table.onLoad">{{ tt('generate') }}<span v-if="table.onLoad"><i class="fas fa-spinner fa-spin"></i></span></base-button>
        <a :href="apiUrl+'export/abc-evaluation/' + mcrItemCode +'?token='+token" class="btn btn-sm btn-default float-right mr-4" target="_blank">{{ tt('export_as_excel') }}</a>
        <h5  class="text-left fw-bold">Periode: {{ periode }}</h5>

        <div class="table-responsive header-fixed-multiple customTableInputB4 mb-5" style="height: 500px" v-if="!generateLoad">
            <table class="table table-bordered">
                <thead class="text-center thead-light">
                    <tr>
                        <th rowspan="2">{{ tt('material_number')}}</th>
                        <th rowspan="2">{{ tt('plant')}}</th>
                        <th rowspan="2">{{ tt('mrp_type') }}</th>
                        <th rowspan="2" width="400px">{{ tt('description')}}</th>
                        <th rowspan="2">{{ tt('uom')}}</th>
                        <!-- <th rowspan="2">{{ tt('current_stock')}}</th>
                        <th rowspan="2">{{ tt('last_inventory_value')}}</th> -->
                        <th :colspan="years.length">{{ tt('gi') }}</th>
                        <th rowspan="2">{{ tt('total_gi') }}</th>
                        <th rowspan="2">{{ tt('old')}}</th>
                        <th rowspan="2" style="width:100px">{{ tt('new') }}</th>
                    </tr>
                    <tr>
                        <th v-for="(year, $index) in years" :key="$index">{{ year }}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(row, $index) in table.data" :key="$index">
                        <td>{{ row.material_number }}</td>
                        <td>{{ row.plant_code }}</td>
                        <td>{{ row.mrp_type_code }} - {{ row.mrp_type_desc }}</td>    
                        <td>{{ row.short_description}}</td>
                        <td>{{ row.uom_code}}</td>
                        <!-- <td>10</td>
                        <td>10</td> -->
                        <template>
                            <td  class="text-center" v-for="(gi, $indexGI) in JSON.parse(row.value_gi)" :key="$indexGI">{{ gi.count }}</td>
                        </template>
                        <td class="text-center" >{{ row.total_gi }}</td>   
                        <td class="text-center" :style="{backgroundColor: row.new_evaluation != row.old_evaluation ? '#7A86B6' : 'white',color:  row.new_evaluation != row.old_evaluation ? 'white' : 'black'}">{{ row.old_evaluation }}</td>    
                        <td>
                            <select type="text" style="border:none;width:100%;" @change="update(row.new_evaluation, row.material_number,row.mcr_item_code,row.plant_code,row.mrp_type_code,$index)" v-model="row.new_evaluation" :style="{backgroundColor:  row.new_evaluation != row.old_evaluation ? '#7A86B6' : 'white',color:  row.new_evaluation != row.old_evaluation ? 'white' : 'black'}">
                                <option label="A - Fast Moving" value="A"></option>
                                <option label="B - Low Moving" value="B"></option>
                                <option label="C - Non Moving" value="C"></option>
                                <option label="D - Insurance" value="D"></option>
                                <option label="E - New Item" value="E"></option>
                                <option label="X - Obsolete" value="X"></option>
                            </select>
                        </td>    
                    </tr>
                </tbody>
            </table>
            <infinite-loading @infinite="infiniteHandler"></infinite-loading>
        </div>
        <page-loading v-else></page-loading>

        <modal :show.sync="filter.show">
            <template slot="header">
                <h5 class="modal-title">{{ tt('generate')}}</h5>
            </template>
            <div>
                <label class="form-control-label">{{ tt('plant') }}</label>
                 <base-input>
                    <el-select v-model="filter.plant_code" :placeholder="tt('choose_plant')" clearable filterable multiple>
                        <el-option v-for="pl in plants" :value="pl.plant_code" :label="pl.plant_code + ' - ' + pl.description" :key="pl.plant_code"></el-option>
                    </el-select>
                 </base-input>

                <label class="form-control-label">{{ tt('mrp') }}</label>
                <base-input>
                    <el-select v-model="filter.mrp_type_code" :placeholder="tt('choose_mrp_type')" clearable filterable multiple>
                        <el-option v-for="mt in mrpTypes" :value="mt.mrp_type_code" :label="mt.mrp_type_code +' - '+ mt.mrp_type_desc" :key="mt.mrp_type_code"></el-option>
                    </el-select>
                </base-input>

                 <label class="form-control-label">{{ tt('abc_indicator') }}</label>
                 <base-input>
                    <el-select v-model="filter.abc_indicator" :placeholder="tt('choose_abc_indicator')" clearable filterable multiple>
                        <el-option v-for="ai in abcIndicators" :value="ai.abc_indicator_code" :label="ai.abc_indicator_code +' - '+ ai.abc_indicator_desc" :key="ai.abc_indicator_code"></el-option>
                    </el-select>
                 </base-input>
            </div>
            <template slot="footer">
                <base-button type="secondary" @click="filter.show = false">{{ tt('close') }}</base-button>
                <base-button type="primary" @click="generate()" :disabled="disableGenerate">
                    {{ tt('generate') }}
                </base-button>
            </template>
        </modal>
    </div>
</template>

<script>
import {mapState} from 'vuex'
  import Api from '@/helpers/api';
  import abcEvaluationService from '@/services/abcEvaluation/abcEvaluation.service';
  import draftList from "@/services/material/draftList.service";
  import baseApiUrl from '@/configs/config';
  import abcIndicator from '@/services/master/abcIndicator.service';
  import mrpType from '@/services/master/mrpTypes.service';
  import plant from '@/services/master/plantCode.service';

    export default {
        data() {
            return {
                table:{
                    data:[],
                    onLoad:false,
                    page:2,
                    loadTimeout:null,
                    last_page:0,
                },
                years:['Y1','Y2','Y3','Y4','Y5'],
                mcrItemCode: this.$route.params.mcr_item_code,
                periode:'',
                generateLoad:false,
                apiUrl: baseApiUrl.apiUrl,
                token: localStorage.token,
                plants:[],
                mrpTypes:[],
                abcIndicators:[],
                filter:{
                    show:false,
                    abc_indicator:[],
                    mrp_type_code:[],
                    plant_code:[],
                }
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
            disableGenerate(){
                if(this.filter.abc_indicator.length > 0 || this.filter.mrp_type_code.length > 0 || this.filter.plant_code.length > 0){
                    return false;
                }else{
                    return true;
                }
            }
        },
        created(){
            this.get();
            this.getDetailMcrItem();
            this.getPlant();
            this.getMrpTypes();
            this.getAbcIndicator();
        },
        methods: {
            getDetailMcrItem(){
                let context = this;
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode))
		        .onSuccess(function(response) {
		            context.periode = response.data.data[0].periode_generate_abc_evaluation;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
					context.periode = ''
				  }
		        })
		        .call();
            },
            get() {
                let context = this; 

                this.table.data = [];
                Api(context, abcEvaluationService.get(1, { mcr_item_code: context.mcrItemCode})).onSuccess((response) =>{
                    context.table.data         = response.data.data.data
                    context.table.last_page    = response.data.data.last_page
                    context.table.total        = response.data.data.total
                    context.table.current_page = response.data.data.current_page
                    context.table.per_page     = response.data.data.per_page
                }).onError((e) => {
                    context.table.data = []
                }).call()
            },
            infiniteHandler($state){
                let context = this;

                setTimeout(() =>{
                    Api(context, abcEvaluationService.get(context.table.page, {mcr_item_code: context.mcrItemCode})).onSuccess((response) =>{
                        context.table.data.push(...response.data.data.data)
                        context.table.last_page    = response.data.data.last_page
                        context.table.current_page = response.data.data.current_page
                        context.table.per_page     = response.data.data.per_page
                        context.table.total        = response.data.data.total
                        $state.loaded()
                    }).onError((e) => {
                        $state.complete()
                     }).onFinish(() => {
                         context.table.page += 1
                     }).call()
                },300)
            },
            update(value, material_number, mcr_item_code, plant_code, mrp_type_code, key){
                let data = {
                    new_evaluation : value,
                    material_number: material_number,
                    mcr_item_code: mcr_item_code,
                    plant_code: plant_code,
                    mrp_type_code: mrp_type_code
                }
                let context = this; 
                Api(context, abcEvaluationService.update(data)).onSuccess((response) =>{
                    context.table.data[key].updated_by = response.data.data
                }).onError((e) => {
                    context.table.data[key].updated_by = null
                }).call()
            },
            generate(){
                this.confirmDialog(this.tt('confirm_replace')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.table.onLoad = true;
                        this.generateLoad = true;
                        this.filter.show = false;

                        let formData = new FormData();

                        formData.append('mcr_item_code', this.mcrItemCode)
                        
                        for(var i = 0; i < this.filter.abc_indicator.length;i++){
                            formData.append('abc_indicator['+i+']', this.filter.abc_indicator[i])
                        }

                        for(var i = 0; i < this.filter.plant_code.length;i++){
                            formData.append('plant_code['+i+']', this.filter.plant_code[i])
                        }

                        for(var i = 0; i < this.filter.mrp_type_code.length;i++){
                            formData.append('mrp_type_code['+i+']', this.filter.mrp_type_code[i])
                        }

                        Api(context, abcEvaluationService.generate(formData)).onSuccess((response) =>{
                            context.$notify({
                                message: response.data.message,                  
                                type: 'success',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            context.get()
                            context.table.page = 2;
                            context.getDetailMcrItem()
                        }).onError((e) => {
                            context.$notify({
                                message: e.response.data.message,                  
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                        }).onFinish(() => {
                            context.table.onLoad = false;
                            context.generateLoad = false;
                            context.$parent.$parent.$parent.$refs.statusProcess.get()
                        }).call()
                    }
                })
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicators = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicators = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpType.get(null,{per_page: 'none', query: 'form-b'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getPlant() {        
                let context = this;   
                    
                Api(context, plant.companyPlant(null, {per_page: 'none'})).onSuccess(function(response) {
                    context.plants = response.data.data;    
                    context.loading = false;                                           
                }).onError(function(error) {
                    context.plants = [];
                    context.loading = false;
                }).call();             
            },
        },
    };
</script>