<template>
	<div>
		<skeleton-loading v-if="requestCalls > 0"></skeleton-loading>

		<div v-else>
			<div class="text-right mb-3" v-if="mcrType == 'B2' || mcrType == 'B2S'">
		        <base-button size="sm" type="default" @click="modalCopyAllValue = true">
					<i class="far fa-copy text-white"></i>
					{{ tt('copy_value') }}
				</base-button>
		    </div>
			
			<div v-if="(mcrType == 'B3' || mcrType == 'B4' || mcrType == 'A2' || mcrType == 'A3')">
				<label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
				<base-input :name="tt('material_number')" rules="required">
					<el-select v-model="input.material_number" :placeholder="tt('choose_material_number')" :remote-method="querySearchMaterialNumber" :loading="selectSearch.matnumLoading" filterable remote reserve-keyword :disabled="formType == 'detail' || formType == 'approval'" @change="changeMaterialNumber('changeMaterial')">
						<el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
					</el-select>
				</base-input>
			</div>

			<!-- BASIC DATA SPECIFICATION -->

			<div id="accordionBds" v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          	<a href="javascript:void(0)" @click="toggleAccordion(accordion.bds.show, 'bds')">
				          		<b>{{ tt("basic_data_&_specification") }}</b>
				            	<span class="text-danger"> *</span>
				        	</a>
				        </h5>
				      </div>
				    </div>
				  </div>
				  <div id="collapseBds" :class="'collapse ' + accordion.bds.show">
				    <div class="card-body border">
				    	<div class="row">
							<div class="col-md-9">
								<label class="form-control-label">{{ tt('item_name') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('item_name')" rules="required">
								    <el-select v-model="input.prc_inc" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchItemName" :loading="selectSearch.sitLoading" 
									filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @change="changeItemName(), changePrcItemTypeCode(), showCharValue()" @focus="changeLog('item_name')">
								        <el-option v-for="si in standardItemName" :key="si.inc" :label="si.inc +' - '+ si.item_name" :value="si.inc"></el-option>
								    </el-select>
								</base-input>
							</div>

							<div class="col-md-3">
								<label class="form-control-label">{{ tt('item_type') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('item_type')" rules="required" v-model="input.prc_item_type_code" :placeholder="tt('choose_item_type')" disabled @focus="changeLog('item_type')">
					            </base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('group_class_or_material_group') }} <span class="text-danger">*</span></label>
								<!-- <base-input :name="tt('group_class')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_nsc" :placeholder="tt('group_class_or_material_group')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @change="changeGroupClass()" @focus="changeLog('group_class')">
								    <el-option class="select-danger" :value="mg.code" :label="mg.code +' - '+ mg.description" :key="mg.code" v-for="mg in materialGroup"></el-option>
								  </el-select>
								</base-input> -->
								<base-input :name="tt('group_class')" rules="required">
									<el-autocomplete 
										class="inline-input col-md-12 p-0"
										popper-class="my-autocomplete"
										v-model="prcNscDisplayVal"
										:placeholder="tt('group_class_or_material_group')"
										:fetch-suggestions="getValMatGroup"
										:disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"
										size="large"
										@focus="changeLog('group_class')"
										@select="handleSelect">
										<template slot-scope="{ item }">
											<div class="value">{{ item.code }} - {{ item.description }}</div>
										</template>
									</el-autocomplete>
								</base-input>

							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('scope_of_supply') }}</label>
								<base-input :name="tt('scope_of_supply')">
								  <el-select class="select-danger" v-model="input.prc_sos_header" :placeholder="tt('scope_of_supply')" @focus="changeLog('scope_of_supply')" disabled>
								    <el-option class="select-danger" :value="sos.sos_header" :label="sos.sos_header +' - '+ sos.sos_description" :key="sos.sos_header" v-for="sos in  scopeOfSupply"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('uom') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('uom')" rules="required">
									<div class="el-input el-input-group el-input-group--append">
										<el-select v-model="input.prc_uom_code" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchUom" :loading="selectSearch.uomLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('uom')">
											<el-option :value="uom.code" :label="uom.code+' - '+uom.description" :key="uom.code" v-for="uom in  unitOfMeasure"></el-option>
										</el-select>
										<div class="el-input-group__append">
											<el-button class="bg-dark text-white" @click="modalUomConversion = true" :disabled="input.prc_uom_code == '' || form.disabled || mcrType == 'B4' || mcrType == 'A3'">AU</el-button>
										</div>
									</div>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('material_type') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('material_type')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_mtype_code" :placeholder="tt('minimum_type_char', {total: 1})" :loading="selectSearch.mtLoading" filterable :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('material_type')">
								    <el-option class="select-danger" :value="mt.code" :label="mt.code+' - '+mt.description" :key="mt.code" v-for="mt in materialType"></el-option>
								  </el-select>
								</base-input>
							</div>

							<div class="col-md-6">
								<label class="form-control-label">{{ tt('fabric_or_non_fabrication') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('fabric_or_non_fabrication')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('fab_non_fab')">
								    <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
								  </el-select>
								</base-input>
							</div>
				        
							<div class="col-md-6">
								<label class="form-control-label">{{ tt('bom_or_non_bom') }} <span class="text-danger">*</span></label>
								<base-input :name="tt('bom_or_non_bom')" rules="required">
								  <el-select class="select-danger" v-model="input.prc_bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('bom_non_bom')">
								    <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
								  </el-select>
								</base-input>
							</div>
				       
				        	<div class="col-md-6">
					            <label class="form-control-label">{{ tt('critical_non_critical') }} <span class="text-danger">*</span></label>
					            <base-input :name="tt('critical_non_critical')" rules="required">
					              <el-select class="select-danger" v-model="input.prc_critical_non_critical" :placeholder="tt('choose_critical_non_critical')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('critical_non_critical')">
					                <el-option class="select-danger" :value="cnc" :label="cnc" :key="cnc" v-for="cnc in  criticalNonCritical"></el-option>
					              </el-select>
					            </base-input>
					        </div>

							<div class="col-md-6">
					            <label class="form-control-label">{{ tt('old_material_number') }}</label>
					            <base-input :name="tt('old_material_number')" v-model="input.prc_old_material_number" :placeholder="tt('old_material_number')" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('old_material_number')">
					            </base-input>
					        </div>
				        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- CHARACTERISTIC & VALUE -->

			<div id="accordionCharValue" v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
					<div class="card-header bg-secondary border" id="headingOneBds">
						<div class="row">
							<div class="col-md-6">
								<h5 class="mb-0">
								<a
									href="javascript:void(0)"
									@click="toggleAccordion(accordion.charValue.show, 'charValue')">
									<b>{{ tt("characteristic_value") }}</b>
									<span class="text-danger"> *</span>
								</a>
								</h5>
							</div>
						</div>
					</div>
					<div id="collapseCharValue" :class="'collapse ' + accordion.charValue.show">
						<!-- <div class="card-body border"> -->
							<skeleton-loading v-if="requestCallsCharValue > 0"></skeleton-loading>

							<table class="table align-items-center table-bordered" v-else>
								<thead class="card-header bg-primary">
									<tr>
									<th class="text-white">{{tt('characteristic')}}</th>
									<th class="text-white">{{tt('value')}}</th>
									<th width="10" class="text-white">{{tt('view')}}</th>
									<th width="10" class="text-white text-center" v-if="['A1','A1S','B2','B2S'].includes(mcrType)">#</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(dinc, key) in prcMcrItemCharValue.cin_code" :key="key">
										<td>
											{{prcMcrItemCharValue.characteristic[key]}}
											<span class="text-danger" v-if="prcMcrItemCharValue.is_mandatory[key] === 'yes'"> *</span>
											<input type="hidden" v-model="prcMcrItemCharValue.cin_code[key]">
										</td>
										<td>
											<el-autocomplete 
											v-model="prcMcrItemCharValue.value[key]"
											:fetch-suggestions="getValue"
											@focus="selectedCinCode(prcMcrItemCharValue.cin_code[key])"
											size="small"
											:disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"
											@input="prcMcrItemCharValue.value[key] = prcMcrItemCharValue.value[key].toUpperCase()">
											</el-autocomplete>
										</td>
										<td><input type="checkbox" name="check" v-model="prcMcrItemCharValue.view[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"/></td>
										<td v-if="['A1','A1S','B2','B2S'].includes(mcrType)">
											<button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-if="prcMcrItemCharValue.multipleChar[key] == 2 && prcMcrItemCharValue.code[key] == ''">
												<i class="fa fa-plus-square" aria-hidden="true" style="font-size: 15px" @click="addMultipleChar(key, prcMcrItemCharValue.cin_code[key], prcMcrItemCharValue.characteristic[key], prcMcrItemCharValue.view[key])" v-if="!form.disabled"></i>
											</button>

											<button style=" background-color: Transparent;background-repeat:no-repeat;border: none;cursor:pointer;overflow: hidden;outline:none;" v-else-if="prcMcrItemCharValue.multipleChar[key] == '' || prcMcrItemCharValue.code[key] == 1">
												<i class="fa fa-minus-square text-danger" aria-hidden="true" style="font-size: 15px" @click="removeMultipleChar(key)" v-if="!form.disabled"></i>
											</button>
										</td>
									</tr>
								</tbody>
							</table>
						<!-- </div> -->
					</div>
				</div>
			</div>
	        
	        <div v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
		        <label class="form-control-label">
		        	{{ tt('po_text_memo') }} 
		        </label>
		        <base-input :name="tt('po_text_memo')">
		          <textarea v-model="input.prc_po_text_memo" class="form-control" rows="5" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('po_text_memo')" @input="customInputPoTextMemo(),fixCursor($event)"></textarea>
		        </base-input>
		    </div>

	        <!-- MANUFACTURER PART NUMBER -->

	        <div id="accordionManref" v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.manref.show, 'manref')">
				            <b>{{ tt("manufacture_refference") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addMpn" v-if="!form.disabled && (mcrType == 'A1' || mcrType == 'A1S' || (mcrType == 'A2' && clientPlantLevel == 'client') || mcrType == 'B2' || mcrType == 'B2S' || (mcrType == 'B3' && clientPlantLevel == 'Client'))">{{ tt('add_new') }}</base-button>
			              <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseManref" :class="'collapse ' + accordion.manref.show">
				    <div class="table-responsive">
				    	<table class="table align-items-center table-bordered">
				          <thead class="card-header bg-primary">
				            <tr>
				              <th class="text-white">{{tt('manufacturer_code')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('source_type')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('manufacture_ref')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('position')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('type')}}<span class="text-danger"> *</span></th>
				              <th class="text-white">{{tt('note')}}</th>
				              <th class="text-white">{{tt('shortdes')}}</th>
				              <th class="text-white">{{tt('status')}}</th>
				              <th class="text-white text-center">#</th>
				            </tr>
				          </thead>
				          <tbody>
				            <tr v-for="(mpn, key) in prcMcrItemMpn.manufacture_code"
				              v-bind:key="mpn.manufacture_code">
				              <td>
				                <el-select v-model="prcMcrItemMpn.manufacture_code[key]" :placeholder="tt('minimum_type_char', {total: 5})" :remote-method="querySearchMancode" :loading="selectSearch.mancodeLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('manufacture_code_line_'+(key+1))">
				                  <el-option :label="m.manufacture_name" :value="m.manufacture_code" v-for="m in masterManufacture" :key="m.id"></el-option>
				                </el-select>
				              </td>
				              <td>
				              	<el-select v-model="prcMcrItemMpn.source_type[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('source_type_line_'+(key+1))" @change="changeSourceType(key)">
				                  <el-option :label="st.code +' - '+ st.description" :value="st.code" v-for="st in sourceType" :key="st.id"></el-option>
				                </el-select>
				              </td>
				              <td>
				              	<input class="form-control" :placeholder="tt('manufacture_ref')" v-model="prcMcrItemMpn.mpn[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('manufacture_ref_line_'+(key+1))" @input="prcMcrItemMpn.mpn[key] = prcMcrItemMpn.mpn[key].toUpperCase()">
				              </td>
				              <td>
				              	<input class="form-control" :name="tt('position')" :placeholder="tt('position')"
				                  v-model="prcMcrItemMpn.position[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3' || prcMcrItemMpn.source_type[key] != 'DWG'" @focus="changeLog('position_line_'+(key+1))">
				              </td>
				              <td>
				                <el-select v-model="prcMcrItemMpn.manufacture_type[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('manufacture_type_line_'+(key+1))" style="width: 100px">
				                  <el-option :label="mt.code +' - '+ mt.description" :value="mt.code" v-for="mt in manrefType" :key="mt.id"></el-option>
				                </el-select>
				              </td>
				              <td>
				              	<input class="form-control" :name="tt('note')" :placeholder="tt('note')"
				                  v-model="prcMcrItemMpn.manufacture_note[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('manufacture_note_line_'+(key+1))" @input="prcMcrItemMpn.manufacture_note[key] = prcMcrItemMpn.manufacture_note[key].toUpperCase()" style="width: 100px">
				              </td>
							  <td class="text-center">
								<el-checkbox v-model="prcMcrItemMpn.shortdes[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3' || prcMcrItemMpnShortdes(key)"></el-checkbox>
							  </td>
				              <td>
				              	<label class="badge badge-warning" v-if="prcMcrItemMpn.status[key] == 'old'">{{ prcMcrItemMpn.status[key] }}</label>
				              	<label class="badge badge-success" v-if="prcMcrItemMpn.status[key] == 'new'">{{ prcMcrItemMpn.status[key] }}</label>
				              </td>
				              <td width="10">
								<!-- CREATE & CHANGE (NEW) -->
				              	<i class="fa fa-trash text-danger" @click="removeMpn(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B3' || mcrType == 'A2') && prcMcrItemMpn.status[key] == 'new')) && !form.disabled"></i>

								<span v-else></span>
				              </td>
				            </tr>
				          </tbody>
				        </table>
				    </div>
				  </div>
				</div>
			</div>

			<!-- EQUIPMENT -->
	        
	        <div id="accordionEquipment" v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.equipment.show, 'equipment')">
				            <b>{{ tt("equipment") }}</b>
				            <span class="text-danger" v-if="input.prc_item_type_code == 'OEM' || input.prc_bom_non_bom == 'BOM'"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addEquipment" v-if="(!form.disabled && ['B2','B2S','A1','A1S'].includes(mcrType)) || (clientPlantLevel == 'Client' && ['B3','A2'].includes(mcrType))">{{ tt('add_new') }}</base-button>
			              
			              <span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseEquipment" :class="'collapse ' + accordion.equipment.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('equipment_code')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('quantity_install')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('drawing_number')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('attachment')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white">{{tt('show')}}</th>
				                <th class="text-white">{{tt('delete')}}</th>
								<th class="text-white" v-if="mcrType == 'B2S' || mcrType == 'A1S'">SHP <span class="text-danger" v-if="['suku_cadang_penyangga','not_applicable'].includes(varMcrType)">*</span></th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(eqi, key) in prcMcrItemEquipment.equipment_code"
				                v-bind:key="eqi.equipment_code">
				                <td>
				                  <el-select v-model="prcMcrItemEquipment.plant_equipment_code[key]" :disabled="prcMcrItemEquipment.status[key] == 'old' || form.disabled" @focus="changeLog('plant_equipment_code_line_'+(key+1))" style="width: 100px" @change="checkEquipment(key)">
				                    <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemEquipment.equipment_code[key]" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchEquipment" :loading="selectSearch.equipmentLoading" filterable remote reserve-keyword :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @change="clearDrawing(key), checkEquipment(key), setSelectedEqpCodeIntoShpForm(prcMcrItemEquipment.equipment_code[key], key)" @focus="changeLog('equipment_code_line_'+(key+1))" style="width: 150px">
									<el-option :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code" v-for="ec in equipmentCode" :key="'equipment' + ec.id"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<input type="number" min="0" class="form-control" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="prcMcrItemEquipment.qty_installed[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('qty_installed_line_'+(key+1))">
				                </td>
				                <td>
									<el-select v-model="prcMcrItemEquipment.drawing_number[key]" :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="querySearchDrawingNumber" :loading="selectSearch.drawingNumLoading" filterable remote reserve-keyword @change="changeDrawingNumber(key)" @focus="storeFocusedEquipment(prcMcrItemEquipment.equipment_code[key]); changeLog('drawing_number_line_'+(key+1))" @click.native="drawingMaster = []" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'">
										<el-option :label="dm.drawing_number +' - '+ dm.drawing_name" :value="dm.drawing_number" v-for="dm in drawingMaster" :key="'equipment' + dm.id"></el-option>
									</el-select>
				                </td>
				                <td>
				                	<a :href="storageUrl+'drawing_master/'+drawingAttachment[key]" target="_blank">{{drawingAttachment[key]}}</a>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemEquipment.status[key] == 'old'">{{ prcMcrItemEquipment.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemEquipment.status[key] == 'new'">{{ prcMcrItemEquipment.status[key] }}</label>
				                </td>
								<td class="text-center">
									<el-checkbox v-model="prcMcrItemEquipment.show[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"></el-checkbox>
								</td>
								<td class="text-center">
									<el-checkbox v-model="prcMcrItemEquipment.del_flag[key]" :checked="prcMcrItemEquipment.del_flag[key] === 'X'" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'"></el-checkbox>
								</td>
								<td v-if="mcrType == 'B2S' || mcrType == 'A1S'">
									<!-- <i 
										class="ni ni-fat-add text-primary" 
										:style="{cursor :prcMcrItemEquipment.save_process[key] == 'unsave' || varMcrType === 'consumable' ? 'not-allowed' : 'pointer', 
										pointerEvents:prcMcrItemEquipment.save_process[key] == 'unsave' || varMcrType === 'consumable' ? 'none' : '', fontSize: '20px'}" 
										@click="showShp(prcMcrItemEquipment.equipment_code[key], prcMcrItemEquipment.code[key], input.prc_uom_code)"
									></i> -->

									<!-- <base-button
										size="sm"
										type="default"
										@click="testShow(prcMcrItemEquipment.equipment_code[key])"
										> test
									</base-button> -->

									<i 
										class="ni ni-fat-add text-primary" 
										:style="{cursor: prcMcrItemEquipment.equipment_code[key] == '' || varMcrType === 'consumable' ? 'not-allowed' : 'pointer', 
										pointerEvents: prcMcrItemEquipment.equipment_code[key] == '' || varMcrType === 'consumable' ? 'none' : '', fontSize: '20px'}" 
										@click="showShp(prcMcrItemEquipment.equipment_code[key])"
									></i>
									<i class="ni ni-check-bold text-success ml-2" v-if="prcMcrItemEquipment.esti_lead_time_shp_stored_in_db[key]" style="font-size: 17px;"></i>
								</td>
				                <td width="10">
									<!-- CREATE & CHANGE (NEW) -->
									<i class="fa fa-trash text-danger" @click="removeEquipment(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B3' || mcrType == 'A2') && prcMcrItemEquipment.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- PLANT -->

			<div id="accordionPlant" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.plant.show, 'plant')">
				            <b>{{ tt("plant") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addPlant" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

							<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapsePlant" :class="'collapse ' + accordion.plant.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white" width="10">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(pl, key) in prcMcrItemPlant.plant" v-bind:key="pl.plant">
				                <td>
				                  <el-select style="width: 100%" v-model="prcMcrItemPlant.plant[key]" :placeholder="tt('minimum_type_char', {total: 2})" :remote-method="querySearchPlant" :loading="selectSearch.plantLoading" filterable remote reserve-keyword :disabled="prcMcrItemPlant.status[key] == 'old' || form.disabled" @focus="changeLog('plant_line_'+(key+1))">
									<el-option :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code" v-for="pc in plantCode" :key="pc.id" :disabled="(pc.material_number != null || prcMcrItemPlant.plant.includes(pc.plant_code)) ? true : false"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemPlant.status[key] == 'old'">{{ prcMcrItemPlant.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemPlant.status[key] == 'new'">{{ prcMcrItemPlant.status[key] }}</label>
				                </td>
				                <td width="10">
									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removePlant(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemPlant.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- STORAGE LOCATION -->

			<div id="accordionSloc" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.sloc.show, 'sloc')">
				            <b>{{ tt("storage_location") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addSloc" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

						  	<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseSloc" :class="'collapse ' + accordion.sloc.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
	                        <table class="table align-items-center table-bordered">
	                            <thead class="card-header bg-primary">
	                                <tr>
	                                    <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
	                                    <th class="text-white">{{tt('storage_location')}}<span class="text-danger"> *</span></th>
	                                    <th class="text-white">{{tt('bin_location')}}</th>
	                                    <th class="text-white" width="10">{{tt('status')}}</th>
	                                    <th class="text-white text-center">#</th>
	                                </tr>
	                            </thead>
	                            <tbody>
	                                <tr v-for="(sloc, key) in prcMcrItemSloc.storage_location" :key="sloc.storage_location">
	                                    <td>
	                                        <el-select v-model="prcMcrItemSloc.plant_code[key]" :disabled="prcMcrItemSloc.status[key] == 'old' || form.disabled" @focus="changeLog('plant_sloc_line_'+(key+1))">
	                                            <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" :key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
	                                        </el-select>
	                                    </td>
	                                    <td>
											<el-select v-model="prcMcrItemSloc.storage_location[key]" :disabled="form.disabled || (prcMcrItemSloc.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('storage_location_line_'+(key+1))">
							                    <el-option :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code" v-for="lc in locationCode[prcMcrItemSloc.plant_code[key]]" :key="'sloc' + lc.id" :disabled="(countPlantSloc(prcMcrItemSloc.plant_code[key]) && prcMcrItemSloc.storage_location.includes(lc.location_code)) ? true : false"></el-option>
							                </el-select>
	                                    </td>
	                                    <td>
											<input class="form-control" :name="tt('bin_location')" :placeholder="tt('bin_location')" v-model="prcMcrItemSloc.bin_location[key]" rules="required" :disabled="form.disabled || (prcMcrItemSloc.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('bin_location_line_'+(key+1))" @input="prcMcrItemSloc.bin_location[key] = prcMcrItemSloc.bin_location[key].toUpperCase()">
	                                    </td>
	                                    <td>
	                                    	<label class="badge badge-warning" v-if="prcMcrItemSloc.status[key] == 'old'">{{ prcMcrItemSloc.status[key] }}</label>
							              	<label class="badge badge-success" v-if="prcMcrItemSloc.status[key] == 'new'">{{ prcMcrItemSloc.status[key] }}</label>
	                                    </td>
	                                    <td width="10">
											<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueSloc(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

											<!-- CREATE & EXTEND -->
											<i class="fa fa-trash text-danger" @click="removeSloc(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemSloc.status[key] == 'new')) && !form.disabled"></i>

											<span v-else></span>
	                                    </td>
	                                </tr>
	                            </tbody>
	                        </table>
	                    </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- PURCHASING -->

			<div id="accordionPurchasing" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.purchasing.show, 'purchasing')">
				            <b>{{ tt("purchasing") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addPurchasing" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

						  	<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapsePurchasing" :class="'collapse ' + accordion.purchasing.show">
				  	<!-- <div class="card-body" style="margin-top: -20px"> -->
						<div class="table-responsive">
							<table class="table align-items-center table-bordered">
								<thead class="card-header bg-primary">
									<tr>
										<th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
										<th class="text-white">{{tt('purchasing_group')}}<span class="text-danger"> *</span></th>
										<th class="text-white">{{tt('planned_delivery_days')}}</th>
										<th class="text-white">{{tt('status')}}</th>
										<th class="text-white text-center">#</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(pc, key) in prcMcrItemPurchasing.purchasing_group" v-bind:key="pc.code">
										<td>
											<el-select v-model="prcMcrItemPurchasing.plant_purchasing[key]" :disabled="prcMcrItemPurchasing.status[key] == 'old' || form.disabled" @focus="changeLog('plant_purchasing_line_'+(key+1))">
												<el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
											</el-select>
										</td>
										<td>
											<el-select v-model="prcMcrItemPurchasing.purchasing_group[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchPurGroup" :loading="selectSearch.purGroupLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemPurchasing.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('purchasing_group_line_'+(key+1))">
												<el-option :label="pg.purchasing_group_code +' - '+ pg.purchasing_group_description" :value="pg.purchasing_group_code" v-for="pg in purchasingGroup" :key="'purchasing' + pg.id"></el-option>
											</el-select>
										</td>
										<td>
											<input class="form-control" :name="tt('planned_delivery')" :placeholder="tt('planned_delivery')" v-model="prcMcrItemPurchasing.planned_delivery[key]" rules="required" type="number" min="0" :disabled="form.disabled || (prcMcrItemPurchasing.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('planned_delivery_line_'+(key+1))">
										</td>
										<td>
											<label class="badge badge-warning" v-if="prcMcrItemPurchasing.status[key] == 'old'">{{ prcMcrItemPurchasing.status[key] }}</label>
											<label class="badge badge-success" v-if="prcMcrItemPurchasing.status[key] == 'new'">{{ prcMcrItemPurchasing.status[key] }}</label>
										</td>
										<td width="10">
											<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValuePurchasing(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

											<!-- CREATE & EXTEND -->
											<i class="fa fa-trash text-danger" @click="removePurchasing(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemPurchasing.status[key] == 'new')) && !form.disabled"></i>

											<span v-else></span>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					<!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- MRP -->

			<div id="accordionMrp" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.mrp.show, 'mrp')">
				            <b>{{ tt("mrp") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addMrp" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

						  	<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseMrp" :class="'collapse ' + accordion.mrp.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('abc_indicator')}}</th>
				                <th class="text-white">{{tt('mrp_type')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('mrp_controller')}}</th>
				                <th class="text-white">{{tt('reorder_point')}}</th>
				                <th class="text-white">{{tt('lot_size')}}</th>
								<th class="text-white">{{tt('fixed_lot')}}</th>
				                <th class="text-white">{{tt('safety_stock')}}</th>
				                <th class="text-white">{{tt('min')}}</th>
				                <th class="text-white">{{tt('max')}}</th>
				                <th class="text-white">{{tt('procurement_type')}}</th>
				                <th class="text-white">{{tt('special_proc')}}</th>
				                <th class="text-white">{{tt('max_stock')}}</th>
				                <!-- <th class="text-white">{{tt('ord_costs')}}</th> -->
				                <th class="text-white">{{tt('avail_check')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('sloc_for_ep')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(mrp, key) in prcMcrItemMrp.abc_indicator" v-bind:key="mrp.abc_indicator">
				                <td>
				                  <el-select v-model="prcMcrItemMrp.plant_mrp[key]" :disabled="prcMcrItemMrp.status[key] == 'old' || form.disabled" @focus="changeLog('plant_mrp_line_'+(key+1))" style="width: 100px">
				                    <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemMrp.abc_indicator[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('abc_indicator_line_'+(key+1))">
				                    <el-option :label="ai.abc_indicator_code +' - '+ ai.abc_indicator_desc" :value="ai.abc_indicator_code" v-for="ai in abcIndicator" v-bind:key="ai.abc_indicator_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemMrp.mrp_type[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('mrp_type_line_'+(key+1))">
				                    <el-option :label="mt.mrp_type_code +' - '+ mt.mrp_type_desc" :value="mt.mrp_type_code" v-for="mt in mrpTypes" v-bind:key="mt.mrp_type_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
								  <el-select v-model="prcMcrItemMrp.mrp_controller[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('mrp_controller_line_'+(key+1))">
				                    <el-option :label="mc.mrp_controller_code +' - '+ mc.mrp_controller_desc" :value="mc.mrp_controller_code" v-for="mc in mrpController[prcMcrItemMrp.plant_mrp[key]]" v-bind:key="mc.mrp_controller_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('reorder_point')" :placeholder="tt('reorder_point')" v-model="prcMcrItemMrp.reorder_point[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" type="number" min="0" @focus="changeLog('reorder_point_line_'+(key+1))">
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemMrp.lot_size[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('lot_size_line_'+(key+1))">
									<el-option label="" value=""></el-option>
				                    <el-option :label="ls.lot_size_code +' - '+ ls.lot_size_desc" :value="ls.lot_size_code" v-for="ls in lotSize" v-bind:key="ls.lot_size_code"></el-option>
				                  </el-select>
				                </td>
								<td>
				                	<input class="form-control" :name="tt('fixed_lot')" :placeholder="tt('fixed_lot')" v-model="prcMcrItemMrp.fixed_lot[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" type="number" min="0" @focus="changeLog('fixed_lot_line_'+(key+1))">
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('safety_stock')" :placeholder="tt('safety_stock')" v-model="prcMcrItemMrp.safety_stock[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" style="width: 80px" type="number" min="0" @focus="changeLog('safety_stock_line_'+(key+1))">
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('min')" :placeholder="tt('min')" v-model="prcMcrItemMrp.min[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" style="width: 80px" type="number" min="0" @focus="changeLog('min_line_'+(key+1))">
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('max')" :placeholder="tt('max')" v-model="prcMcrItemMrp.max[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" style="width: 80px" type="number" min="0" @focus="changeLog('max_line_'+(key+1))">
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemMrp.procurement_type[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('procurement_type_line_'+(key+1))">
				                    <el-option :label="pt.proc_type_code +' - 	'+ pt.proc_type_desc" :value="pt.proc_type_code" v-for="pt in procurementType" v-bind:key="pt.proc_type_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
								  <el-select v-model="prcMcrItemMrp.special_proc[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('special_proc_line_'+(key+1))">
				                    <el-option :label="spt.spc_proc_type_code +' - '+ spt.spc_proc_type_desc" :value="spt.spc_proc_type_code" v-for="spt in specialProcurementType[prcMcrItemMrp.plant_mrp[key]]" v-bind:key="'mrp' + spt.id"></el-option>
					              </el-select>
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('max_stock')" :placeholder="tt('max_stock')" v-model="prcMcrItemMrp.max_stock[key]" rules="required" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" type="number" min="0" @focus="changeLog('max_stock_line_'+(key+1))">
				                </td>
				                <td>
				                	<el-select v-model="prcMcrItemMrp.avail_check[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('avail_check_line_'+(key+1))">
					                    <el-option value="">{{tt('choose_avail_check')}}</el-option>
					                    <el-option value="01">01 - Daily Requirements</el-option>
					                    <el-option value="02">02 - Individ.Requirements</el-option>
					                    <el-option value="CH">CH - Batches</el-option>
					                    <el-option value="DR">DR</el-option>
					                    <el-option value="KP">KP - No Check</el-option>
						            </el-select>
				                </td>
				                <td>
				                	<el-select v-model="prcMcrItemMrp.sloc_for_ep[key]" :disabled="form.disabled || (prcMcrItemMrp.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('sloc_for_ep_line_'+(key+1))">
					                    <el-option :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code" v-for="lc in locationCode[prcMcrItemMrp.plant_mrp[key]]" v-bind:key="'mrp' + lc.id" :disabled="(countPlantMrp(prcMcrItemMrp.plant_mrp[key]) && prcMcrItemMrp.sloc_for_ep.includes(lc.location_code)) ? true : false"></el-option>
					                </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemMrp.status[key] == 'old'">{{ prcMcrItemMrp.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemMrp.status[key] == 'new'">{{ prcMcrItemMrp.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueMRP(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeMrp(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemMrp.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- SALES -->

	        <div id="accordionSales" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.sales.show, 'sales')">
				            <b>{{ tt("sales") }}</b>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addSales" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

						  	<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseSales" :class="'collapse ' + accordion.sales.show">
				    <div class="card-body" style="margin-top: -20px">
						<div class="row mb-3">
							<div class="col-md-2">
								<label style="font-size: 12px">{{ tt('division') }}</label>
								<el-select class="select-danger" :placeholder="tt('division')" v-model="input.prc_division_code" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('division')">
									<el-option class="select-danger" :value="d.division_code" :label="d.division_code+' - '+d.division_desc" v-for="d in division" :key="'sales' + d.division_code"></el-option>
								</el-select>
							</div>
							<div class="col-md-2">
								<label style="font-size: 12px">{{ tt('gen_item_cat_grp') }}</label>
								<el-select class="select-danger" :placeholder="tt('gen_item_cat_grp')" v-model="input.prc_gen_item_cat_grp" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('gen_item_cat_grp')">
									<el-option class="select-danger" :value="icg.item_cat_group_code" :label="icg.item_cat_group_code+' - '+icg.item_cat_group_desc" v-for="icg in itemCategoryGroup" :key="'sales' + icg.item_cat_group_code"></el-option>
								</el-select>
							</div>
							<div class="col-md-2">
								<label style="font-size: 12px">{{ tt('trans_group') }}</label>
								<el-select class="select-danger" :placeholder="tt('trans_group')" v-model="input.prc_trans_grp" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('trans_grp')">
									<el-option class="select-danger" :value="tg.trans_group_code" :label="tg.trans_group_code+' - '+tg.trans_group_desc" v-for="tg in transportationGroup" :key="'sales' + tg.trans_group_code"></el-option>
								</el-select>
							</div>
						</div>

						<!-- SALES GENERAL LEVEL -->

						<div class="card-header border">
							<div class="row">
								<div class="col-md-6">
									<h5 class="mb-0">
										<b>{{ tt("general") }}</b>
									</h5>
								</div>
								<div class="col-md-6 text-right">
									<!-- CREATE & EXTEND -->
									<base-button size="sm" type="default" @click="addSalesGeneralLevel" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

									<span v-else></span>
								</div>
							</div>
						</div>
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('sales_org')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('distr_channel')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('sales_unit')}}<span class="text-danger"> *</span></th>
				                <!-- <th class="text-white">{{tt('tax_cat')}}<span class="text-danger"> *</span></th> -->
				                <th class="text-white">{{tt('tax_class')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('account_assign_grp')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('item_cat_grp')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(s, key) in prcMcrItemSales.sales_org" :key="s.sales_org">
				                <td>
				                  <el-select v-model="prcMcrItemSales.sales_org[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('sales_org_line_'+(key+1))">
				                    <el-option :label="so.sales_org_code + ' - ' + so.sales_org_description" :value="so.sales_org_code" v-for="so in salesOrganization" :key="'sales' + so.sales_org_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSales.distr_channel[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('distr_channel_line_'+(key+1))">
				                    <el-option :label="dc.distr_channel_code + ' - ' + dc.distr_channel_description" :value="dc.distr_channel_code" v-for="dc in distributionChannel" :key="'sales' + dc.distr_channel_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
									<el-select v-model="prcMcrItemSales.sales_unit[key]" :placeholder="tt('sales_unit')" :remote-method="querySearchUom" :loading="selectSearch.uomLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('sales_unit_line_'+(key+1))">
										<el-option :value="uom.code" :label="uom.code+' - '+uom.description" :key="uom.code" v-for="uom in unitOfMeasure"></el-option>
									</el-select>
					            </td>
				                <td>
				                  <el-select v-model="prcMcrItemSales.tax_class[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('tax_class_line_'+(key+1))">
				                    <el-option :label="tc.tax_class_code + ' - ' +tc.tax_class_desc" :value="tc.tax_class_code" v-for="tc in taxClassification" :key="'sales' + tc.id"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSales.account_assign_grp[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('account_assign_grp_line_'+(key+1))">
				                    <el-option :label="aag.acc_ass_group_code +' - '+ aag.acc_ass_group_description" :value="aag.acc_ass_group_code" v-for="aag in accountAssignmentGroup" :key="'sales' + aag.acc_ass_group_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSales.item_cat_grp[key]" :disabled="form.disabled || (prcMcrItemSales.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('item_cat_grp_line_'+(key+1))">
				                    <el-option :label="icg.item_cat_group_code +' - '+ icg.item_cat_group_desc" :value="icg.item_cat_group_code" v-for="icg in itemCategoryGroup" :key="'sales' + icg.item_cat_group_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemSales.status[key] == 'old'">{{ prcMcrItemSales.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemSales.status[key] == 'new'">{{ prcMcrItemSales.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueSalesGeneralLevel(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeSalesGeneralLevel(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemSales.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>

						<!-- SALES PLANT LEVEL -->

						<div class="card-header border mt-3">
							<div class="row">
								<div class="col-md-6">
									<h5 class="mb-0">
										<b>{{ tt("plant") }}</b>
									</h5>
								</div>
								<div class="col-md-6 text-right">
									<!-- CREATE & EXTEND -->
									<base-button size="sm" type="default" @click="addSalesPlantLevel" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

									<span v-else></span>
								</div>
							</div>
						</div>
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('loading_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('profit_center')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(s, key) in prcMcrItemSalesPlant.sls_plant_code" :key="s.sls_plant_code">
				                <td>
				                  <el-select v-model="prcMcrItemSalesPlant.sls_plant_code[key]" :disabled="prcMcrItemSalesPlant.status[key] == 'old' || form.disabled">
				                    <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" :key="pc"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSalesPlant.loading_group[key]" :disabled="form.disabled || (prcMcrItemSalesPlant.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('loading_group_line_'+(key+1))">
				                    <el-option :label="lg.loading_group_code +' - '+ lg.loading_group_desc" :value="lg.loading_group_code" v-for="lg in loadingGroup" :key="'sales' + lg.loading_group_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemSalesPlant.profit_center[key]" :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="querySearchProfitCenter" :loading="selectSearch.pcenterLoading" filterable remote reserve-keyword :disabled="form.disabled || (prcMcrItemSalesPlant.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('profit_center_line_'+(key+1))">
									<el-option :label="pc.profit_center_code +' - '+ pc.profit_center_description" :value="pc.profit_center_code" v-for="pc in profitCenter" :key="'sales' + pc.id"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemSalesPlant.status[key] == 'old'">{{ prcMcrItemSalesPlant.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemSalesPlant.status[key] == 'new'">{{ prcMcrItemSalesPlant.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueSalesPlantLevel(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeSalesPlantLevel(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemSalesPlant.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>
	        
			<!-- WAREHOUSE -->
			<div id="accordionWm" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.wManagement.show, 'warehouse_management')">
				            <b>{{ tt("warehouse_management") }}</b>
				          </a>
				        </h5>
				      </div>
				    </div>
				  </div>
				  <div id="collapseSales" :class="'collapse ' + accordion.wManagement.show" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				    <div class="card-body" style="margin-top: -20px">

						<!-- WM 1 -->

						<div class="card-header border">
							<div class="row">
								<div class="col-md-6">
									<h5 class="mb-0">
										<b>{{ tt("warehouse_management_1") }}</b>
									</h5>
								</div>
								<div class="col-md-6 text-right">
									<!-- CREATE & EXTEND -->
									<base-button size="sm" type="default" @click="addWarehouseManagement1" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

									<span v-else></span>
								</div>
							</div>
						</div>
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('warehouse_number')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('stock_removal')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('stock_replacement')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('storage_section_indicator')}}<span class="text-danger"> *</span></th>
								<th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(s, key) in warehouseManagement1.warehouse_number" v-bind:key="s.warehouse_number">
				                <td>
				                  <el-select placeholder="Choose Warehouse Number" v-model="warehouseManagement1.warehouse_number[key]" :disabled="form.disabled || (warehouseManagement1.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" filterable remote reserve-keyword :loading="selectSearch.warehouseNumber" :remote-method="getWarehouse" @change="changeWarehouseNumber1(key, warehouseManagement1.warehouse_number[key])" @focus="changeLog('warehouse_number_1_line_'+(key+1))">
				                    <el-option :value="wn.warehouse_number" v-for="wn in warehouseNumbers" :key="wn.id" :label="wn.warehouse_number + ' - ' + wn.warehouse_description"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="warehouseManagement1.stock_removal[key]" :disabled="form.disabled || (warehouseManagement1.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('stock_removal_line_'+(key+1))">
				                    <el-option :value="sr.storage_type_indicator" v-for="sr in stockRemovals[key]" :key="sr.id" :label="sr.storage_type_indicator + ' - '+ sr.stype_description"></el-option>
				                  </el-select>
				                </td>
				                <td>
									<el-select class="select-danger" v-model="warehouseManagement1.stock_replacement[key]" :placeholder="tt('stock_replacement')" reserve-keyword :disabled="form.disabled || (warehouseManagement1.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('stock_replacement_line_'+(key+1))">
										<el-option class="select-danger" :value="sr.storage_type_indicator" :label="sr.storage_type_indicator + ' - '+  sr.stype_description" :key="sr.id" v-for="sr in stockReplacements[key]"></el-option>
									</el-select>
					            </td>
				                <td>
				                  <el-select v-model="warehouseManagement1.storage_section_indicator[key]" :disabled="form.disabled || (warehouseManagement1.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('storage_section_indicator_line_'+(key+1))">
				                    <el-option :value="sli.storage_section_indicator" v-for="sli in storageSections[key]" v-bind:key="sli.id" :label="sli.storage_section_indicator + ' - ' + sli.description "></el-option>
				                  </el-select>
				                </td>
								<td>
				                	<label class="badge badge-warning" v-if="warehouseManagement1.status[key] == 'old'">{{ warehouseManagement1.status[key] }}</label>
					              	<label class="badge badge-success" v-if="warehouseManagement1.status[key] == 'new'">{{ warehouseManagement1.status[key] }}</label>
				                </td>
				                <td width="10">
									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeWarehouseManagement1(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && warehouseManagement1.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>

						<!-- WM 2 -->

						<div class="card-header border mt-3">
							<div class="row">
								<div class="col-md-6">
									<h5 class="mb-0">
										<b>{{ tt("warehouse_management_2") }}</b>
									</h5>
								</div>
								<div class="col-md-6 text-right">
									<!-- CREATE & EXTEND -->
									<base-button size="sm" type="default" @click="addWarehouseManagement2" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

									<span v-else></span>
								</div>
							</div>
						</div>
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('warehouse_number')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('storage_type')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('storage_bin')}}<span class="text-danger"> *</span></th>
								<th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(s, key) in warehouseManagement2.warehouse_number" v-bind:key="s.warehouse_number">
				                <td>
				                  <el-select v-model="warehouseManagement2.warehouse_number[key]" @change="changeWarehouseNumber2(key, warehouseManagement2.warehouse_number[key])" @focus="changeLog('warehouse_number_2_line'+(key+1))" :disabled="form.disabled || (warehouseManagement2.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <el-option :value="wn" v-for="wn in warehouseNumberWm1" :key="wn" :label="wn"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="warehouseManagement2.storage_type_indicator[key]" @focus="changeLog('storage_type_indicator_line'+(key+1))" @change="changeStorageType(key,warehouseManagement2.warehouse_number[key],warehouseManagement2.storage_type_indicator[key])" :disabled="form.disabled || (warehouseManagement2.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
				                    <el-option :value="st.storage_type_indicator" v-for="st in storageTypes[key]" v-bind:key="st.id" :label="st.storage_type_indicator + ' - ' + st.stype_description"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="warehouseManagement2.storage_bin[key]" :placeholder="tt('minimum_type_char', {total: 1})" reserve-keyword @focus="changeLog('storage_bin_line'+(key+1))" :disabled="form.disabled || (warehouseManagement2.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))">
									<el-option :label="sb.storage_bin" :value="sb.storage_bin" v-for="sb in storageBins[key]" :key="sb.id"></el-option>
								  </el-select>
				                </td>
								<td>
				                	<label class="badge badge-warning" v-if="warehouseManagement2.status[key] == 'old'">{{ warehouseManagement2.status[key] }}</label>
					              	<label class="badge badge-success" v-if="warehouseManagement2.status[key] == 'new'">{{ warehouseManagement2.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-trash text-danger" @click="removeWarehouseManagement2(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && warehouseManagement2.status[key] == 'new')) && !form.disabled"></i>
									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    </div>
				  </div>
				</div>
			</div>
			
	        <!-- ACCOUNTING -->

	        <div id="accordionAccounting" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.accounting.show, 'accounting')">
				            <b>{{ tt("accounting") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addAccounting" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

							<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseAccounting" :class="'collapse ' + accordion.accounting.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('valuation_class')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('valuation_category')}}</th>
				                <th class="text-white">{{tt('valuation_type')}}</th>
				                <th class="text-white">{{tt('price_determination')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('price_control')}}</th>
				                <th class="text-white">{{tt('standard_price')}}</th>
				                <th class="text-white">{{tt('price_unit')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('price_reference')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(vc, key) in prcMcrItemAccounting.valuation_class" :key="vc.code">
				                <td>
				                  <el-select v-model="prcMcrItemAccounting.plant_accounting[key]" :disabled="prcMcrItemAccounting.status[key] == 'old' || form.disabled" @focus="changeLog('plant_accounting_line_'+(key+1))" style="width: 100px">
				                    <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" :key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
				                  </el-select>
				                </td>
				                <td>
								  <el-select v-model="prcMcrItemAccounting.valuation_class[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('valuation_class_line_'+(key+1))">
				                    <el-option :label="vcl.code +' - '+ vcl.description" :value="vcl.code" v-for="vcl in valuationClass" :key="vcl.code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemAccounting.valuation_category[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3')) || input.prc_mtype_code != 'ZSP'" @focus="changeLog('valuation_category_line_'+(key+1))" @change="changeValuationCategory(key)">
				                    <el-option :label="vca.valuation_category_code +' - '+ vca.valuation_category_description" :value="vca.valuation_category_code" v-for="vca in valuationCategory" :key="vca.valuation_category_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemAccounting.valuation_type[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3')) || prcMcrItemAccounting.valuation_category[key] == '' || input.prc_mtype_code != 'ZSP'" @focus="changeLog('valuation_type_line_'+(key+1))">
				                    <el-option :label="vt.valuation_type_code" :value="vt.valuation_type_code" v-for="vt in valuationType" :key="vt.valuation_type_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                    <el-select v-model="prcMcrItemAccounting.price_determination[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('price_determination_line_'+(key+1))">
										<el-option value="2">2 - Transaction Based</el-option>
										<el-option value="3">3 - Single-/Multilevel</el-option>
									</el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemAccounting.price_control[key]" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('price_control_line_'+(key+1))">
				                    <el-option :label="pc.price_control_code +' - '+ pc.price_control_description" :value="pc.price_control_code" v-for="pc in priceControl" :key="pc.price_control_code"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                	<input type="number" min="0" class="form-control" :name="tt('standard_price')" :placeholder="tt('standard_price')" v-model="prcMcrItemAccounting.standard_price[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('standard_price_line_'+(key+1))">
				                </td>
				                <td>
				                	<input type="number" min="0" class="form-control" :name="tt('price_unit')" :placeholder="tt('price_unit')" v-model="prcMcrItemAccounting.price_unit[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('price_unit_line_'+(key+1))">
				                </td>
				                <td>
				                	<input class="form-control" :name="tt('price_reference')" :placeholder="tt('price_reference')" v-model="prcMcrItemAccounting.price_reference[key]" rules="required" :disabled="form.disabled || (prcMcrItemAccounting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @keyup="amount(key)" @focus="changeLog('price_reference_line_'+(key+1))">
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemAccounting.status[key] == 'old'">{{ prcMcrItemAccounting.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemAccounting.status[key] == 'new'">{{ prcMcrItemAccounting.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueAccounting(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeAccounting(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemAccounting.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- COSTING -->

			<div id="accordionCosting" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.costing.show, 'costing')">
				            <b>{{ tt("costing") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addCosting" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

							<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseCosting" :class="'collapse ' + accordion.costing.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('overhead_group')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('costing_lot_size')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(pc, key) in prcMcrItemCosting.plant_costing" v-bind:key="pc.code">
				                <td>
				                  <el-select v-model="prcMcrItemCosting.plant_costing[key]" :disabled="prcMcrItemCosting.status[key] == 'old' || form.disabled" @focus="changeLog('plant_costing_line_'+(key+1))">
				                    <el-option :label="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
				                  </el-select>
				                </td>
				                <td>
				                  <el-select v-model="prcMcrItemCosting.overhead_group[key]" :placeholder="tt('overhead_group')" :disabled="form.disabled || (prcMcrItemCosting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('overhead_group_line_'+(key+1))">
									<el-option :label="og.code +' - '+ og.description" :value="og.code" v-for="og in overheadGroup" :key="'costing' + og.code"></el-option>
								  </el-select>
				                </td>
				                <td>
				                	<input type="number" min="0" class="form-control" :name="tt('costing_lot_size')" :placeholder="tt('costing_lot_size')" v-model="prcMcrItemCosting.costing_lot_size[key]" rules="required" :disabled="form.disabled || (prcMcrItemCosting.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" value="1" @focus="changeLog('costing_lot_size_line_'+(key+1))">
				                </td>
				                <td>
				                	<label class="badge badge-warning" v-if="prcMcrItemCosting.status[key] == 'old'">{{ prcMcrItemCosting.status[key] }}</label>
					              	<label class="badge badge-success" v-if="prcMcrItemCosting.status[key] == 'new'">{{ prcMcrItemCosting.status[key] }}</label>
				                </td>
				                <td width="10">
									<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueCosting(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

									<!-- CREATE & EXTEND -->
									<i class="fa fa-trash text-danger" @click="removeCosting(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemCosting.status[key] == 'new')) && !form.disabled"></i>

									<span v-else></span>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- QUALITY MANAGEMENT -->

			<div id="accordionQm" v-if="['B2','B2S','B4','A1','A2','A3','A1S'].includes(mcrType) || (clientPlantLevel == 'Plant' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.quality_management.show, 'quality_management')">
				            <b>{{ tt("quality_management") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
							<!-- CREATE & EXTEND -->
							<base-button size="sm" type="default" @click="addQm" v-if="['B2','B2S','B4','A1','A3','A1S'].includes(mcrType) && !form.disabled">{{ tt('add_new') }}</base-button>

							<span v-else></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseQm" :class="'collapse ' + accordion.quality_management.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('plant')}}<span class="text-danger"> *</span></th>
				                <th class="text-white">{{tt('inspection_type')}}</th>
				                <th class="text-white">{{tt('qm_material_auth')}}</th>
				                <th class="text-white">{{tt('status')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				            	<tr v-for="(pc, key) in prcMcrItemQm.plant_qm" v-bind:key="pc.code">
					                <td>
					                  <el-select v-model="prcMcrItemQm.plant_qm[key]" :disabled="prcMcrItemQm.status[key] == 'old' || form.disabled" @focus="changeLog('plant_qm_line_'+(key+1))">
					                    <el-option :labe="pc" :value="pc" v-for="pc in prcMcrItemPlant.plant" v-bind:key="pc" :disabled="(plantCompany.includes(pc)) ? false : true"></el-option>
					                  </el-select>
					                </td>
					                <td>
					                  <el-select v-model="prcMcrItemQm.inspection_type[key]" :placeholder="tt('inspection_type')" :disabled="form.disabled || (prcMcrItemQm.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('inspection_type_line_'+(key+1))">
										<el-option :label="key +' - '+ it" :value="key" v-for="(it, key) in inspectionType" :key="'qm' + it"></el-option>
									  </el-select>
					                </td>
					                <td>
					                	<el-select v-model="prcMcrItemQm.qm_material_auth[key]" :placeholder="tt('qm_material_auth')" :disabled="form.disabled || (prcMcrItemQm.status[key] == 'old' && (mcrType == 'B4' || mcrType == 'A3'))" @focus="changeLog('qm_material_auth_line_'+(key+1))">
											<el-option :label="key +' - '+ qma" :value="key" v-for="(qma, key) in qmMaterialAuth" :key="'qm' + qma"></el-option>
										</el-select>
					                </td>
					                <td>
					                	<label class="badge badge-warning" v-if="prcMcrItemQm.status[key] == 'old'">{{ prcMcrItemQm.status[key] }}</label>
						              	<label class="badge badge-success" v-if="prcMcrItemQm.status[key] == 'new'">{{ prcMcrItemQm.status[key] }}</label>
					                </td>
					                <td width="10">
										<i class="fa fa-copy mr-3" style="cursor: pointer;" @click="copyValueQm(key)" v-if="(mcrType == 'B2' || mcrType == 'B2S' || mcrType == 'B4') && !form.disabled"></i>

										<!-- CREATE & EXTEND -->
										<i class="fa fa-trash text-danger" @click="removeQm(key)" v-if="(['B2','B2S','A1','A1S'].includes(mcrType) || ((mcrType == 'B4' || mcrType == 'A3') && prcMcrItemQm.status[key] == 'new')) && !form.disabled"></i>

										<span v-else></span>
					                </td>
					            </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- ATTACHMENT DOCUMENT -->

			<div id="accordionAttDoc" v-if="['A1','A1S','A3','B2','B2S','B4'].includes(mcrType) || (['B3','A2'].includes(mcrType) && (clientPlantLevel == 'Client' || clientPlantLevel == 'Plant'))">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.attDoc.show, 'attDoc')">
				            <b>{{ tt("attachment_document") }}</b>
				            <span class="text-danger"> *</span>
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
				      	<base-button size="sm" type="default" @click="smartcatAttachment" v-if="!form.disabled">SmartCat Attachment</base-button>

						<base-button size="sm" type="default" @click="addAttachment" v-if="!form.disabled">
							{{ tt('add_new') }}
						</base-button>
			          </div>
				    </div>
				  </div>
				  <div id="collapseAttDoc" :class="'collapse ' + accordion.attDoc.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('attachment_link')}}</th>
				                <th class="text-white">{{tt('description')}}</th>
				                <th class="text-white" width="5">{{ tt('view') }}</th>
				                <th class="text-white text-center" width="5">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(ad, key) in prcMcrItemAttachment.file_attachment_code" v-bind:key="ad.file_name">
				                <td>
				                    <input class="form-control" :name="tt('attachment_link')" :placeholder="tt('attachment_link')" v-model="prcMcrItemAttachment.file_attachment_code[key]" rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('file_attachment_code_line_'+(key+1))">
				                </td>
				                <td>
				                	<input class="form-control" :placeholder="tt('description')" v-model="prcMcrItemAttachment.description[key]" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @input="prcMcrItemAttachment.description[key] = prcMcrItemAttachment.description[key].toUpperCase()">
				                </td>
				                <td>
				                	<a :href="ad" v-if="ad != ''" target="_blank">{{ tt('view') }}</a>
				                </td>
				                <td width="10">
									<i class="fa fa-trash text-danger" @click="removeAttachment(key)"></i>
								</td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- KEYWORD -->
	        
	        <div id="accordionKeyword" v-if="['B2','B2S','B4','A1','A3','A2','A1S'].includes(mcrType) || (clientPlantLevel == 'Client' && mcrType == 'B3')">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.keyword.show, 'keyword')">
				            <b>{{ tt("keyword") }}</b>
				            <!-- <span class="text-danger"> *</span> -->
				          </a>
				        </h5>
				      </div>
				      <div class="col-md-6 text-right">
			              <base-button size="sm" type="default" @click="addKeyword" v-if="(!form.disabled && ['B2','B2S','A1','A1S'].includes(mcrType)) || (clientPlantLevel == 'Client' && ['B3','A2'].includes(mcrType))">{{ tt('add_new') }}</base-button>

			              <span v-else-if="mcrType == 'B4' || mcrType == 'A3'"></span>
			          </div>
				    </div>
				  </div>
				  <div id="collapseKeyword" :class="'collapse ' + accordion.keyword.show">
				    <!-- <div class="card-body border"> -->
				    	<div class="table-responsive">
				          <table class="table align-items-center table-bordered">
				            <thead class="card-header bg-primary">
				              <tr>
				                <th class="text-white">{{tt('keyword')}}</th>
				                <th class="text-white text-center">#</th>
				              </tr>
				            </thead>
				            <tbody>
				              <tr v-for="(k, key) in prcMcrItemKeyword.keyword" v-bind:key="k.keyword">
				                <td>
				                	<input class="form-control" :name="tt('file_name')"
				                    :placeholder="tt('keyword')" v-model="prcMcrItemKeyword.keyword[key]"
				                    rules="required" :disabled="form.disabled || mcrType == 'B4' || mcrType == 'A3'" @focus="changeLog('keyword_line_'+(key+1))" @input="prcMcrItemKeyword.keyword[key] = prcMcrItemKeyword.keyword[key].toUpperCase()">
				                </td>
				                <td width="10">
				                	<i class="fa fa-trash text-danger" @click="removeKeyword(key)" v-if="(!form.disabled && ['B2','B2S','A1','A1S'].includes(mcrType)) || (clientPlantLevel == 'Client' && ['B3','A2'].includes(mcrType))"></i>
				                </td>
				              </tr>
				            </tbody>
				          </table>
				        </div>
				    <!-- </div> -->
				  </div>
				</div>
			</div>

			<!-- NOTES -->

			<div v-if="['A1','A1S','B2','B2S'].includes(mcrType) || (['B3','A2'].includes(mcrType) && (clientPlantLevel == 'Client' || clientPlantLevel == 'Plant'))">
				<label class="form-control-label">{{ tt('note') }}</label>
				<base-input :name="tt('note')">
                    <textarea class="form-control" rows="5" v-model="input.note" :disabled="form.disabled" @focus="changeLog('note')" @input="input.note = input.note.toUpperCase(),fixCursor($event)"></textarea>
				</base-input>
			</div>

			<!-- PREVIEW SHORT DESCRIPTION & PO TEXT -->
	        
	        <div id="accordionPreviewDesc" v-if="['A1','A1S','A3','B2','B2S','B4'].includes(mcrType) || (['B3','A2'].includes(mcrType) && (clientPlantLevel == 'Client' || clientPlantLevel == 'Plant'))">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row">
				      <div class="col-md-6">
				        <h5 class="mb-0">
				          <a
				            href="javascript:void(0)"
				            @click="toggleAccordion(accordion.previewDesc.show, 'previewDesc')">
				            <b>{{ tt("preview_description") }}</b>
				          </a>
				        </h5>
				      </div>
				    </div>
				  </div>
				  <div id="collapsePreviewDesc" :class="'collapse ' + accordion.previewDesc.show">
				    <div class="card-body border">
				    	<div class="border">
                            <div class="p-3">
                                <span style='font-size: 12px'>{{ input.short_description }}</span>
                            </div>
                        </div>
                        <div class="border mt-3">
                            <div class="p-3">
                                <h5 v-if="input.prc_item_name">=== {{ input.prc_item_name }} ===</h5>
                                <div class='row' v-for="row in prcMcrItemPoText.charValue">
                                    <span class='col-3' style='font-size: 12px'>{{row.characteristic}}</span>
                                    <span class='col-5' style='font-size: 12px'>: {{row.characteristic_value}}</span>
                                </div>

                                <div class="mt-4" v-if="input.prc_po_text_memo">
                                    <h5>{{ tt('po_text_memo') }}</h5>
                                    <pre style='font-size: 12px'>{{input.prc_po_text_memo}}</pre>
                                </div>

                                <h5 class="mt-4" v-if="prcMcrItemPoText.equipment.length && input.prc_item_type_code == 'OEM'">{{ tt('equipment') }}</h5>
                                <div class='row mb-3' v-for="row in prcMcrItemPoText.equipment" v-if="input.prc_item_type_code == 'OEM' && row.show">
                                    <span class="col-12" style='font-size: 12px'>S/P FOR {{ row.equipment_code.join(', ') }}</span>
                                    <span class="col-12">
                                        <span class="row">
                                            <span class='col-3' style='font-size: 12px' v-if="row.equipment_name">EQUIPMENT NAME</span>
                                            <span class='col-5' style='font-size: 12px' v-if="row.equipment_name">: {{ row.equipment_name }}</span>
                                        </span>
                                    </span>

                                    <span class="col-12">
                                        <span class="row">
                                            <span class='col-3' style='font-size: 12px' v-if="row.type">TYPE</span>
                                            <span class='col-5' style='font-size: 12px' v-if="row.type">: {{ row.type }}</span>
                                        </span>
                                    </span>

                                    <span class="col-12">
                                        <span class="row">
                                            <span class='col-3' style='font-size: 12px' v-if="row.serial_number">SERIAL NUMBER</span>
                                            <span class='col-5' style='font-size: 12px' v-if="row.serial_number">: {{ row.serial_number }}</span>
                                        </span>
                                    </span>

                                    <span class="col-12">
                                        <span class="row">
                                            <span class='col-3' style='font-size: 12px' v-if="row.manufacturer">MANUFACTURER</span>
                                            <span class='col-5' style='font-size: 12px' v-if="row.manufacturer">: {{ row.manufacturer }}</span>
                                        </span>
                                    </span>

                                    <span class="col-12">
                                        <span class="row">
                                            <span class='col-3' style='font-size: 12px' v-if="row.additional_data">ADDITIONAL DATA</span>
                                            <span class='col-5' style='font-size: 12px' v-if="row.additional_data">: {{ row.additional_data }}</span>
                                        </span>
                                    </span>
                                </div>

                                <h5 class="mt-4" v-if="prcMcrItemMpn.manufacture_code.length">{{ tt('manufacturer_reference') }}</h5>
                                <div class='row' v-for="(mpn, key) in prcMcrItemMpn.manufacture_code">
                                    <span class='col-3' style='font-size: 12px'>{{prcMcrItemMpn.manufacture_code[key]}}</span>
                                    <span class='col-5' style='font-size: 12px'>: {{prcMcrItemMpn.source_type[key] +':'+ prcMcrItemMpn.mpn[key]}} <span v-if="prcMcrItemMpn.position[key]">POS:{{ prcMcrItemMpn.position[key] }}</span></span>
                                </div>
                            </div>
                        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- MEETING FOR THIS MATERIAL -->
			<div id="accordionBds" v-if="['B2','B2S','A1','A1S'].includes(mcrType) || (['B3','A2'].includes(mcrType) && (clientPlantLevel == 'Client' || clientPlantLevel == 'Plant'))">
				<div class="card z-depth-0 bordered">
				  <div class="card-header bg-secondary border" id="headingOneBds">
				    <div class="row d-flex justify-content-between align-items-center" max-height="100%">
                        <div class="row col-md-4 d-flex align-items-center">
                            <label class="form-control-label pl-3"
                                >{{ tt("meeting_for_this_material") }}
                            </label>
                            <base-input class="pl-3" :name="tt('meeting_for_this_material')">
                                <el-checkbox 
                                    v-model="input.material_need_meeting" 
                                    :disabled="form.disabled || input.display_fields_during_material_need_meeting_checked === true || checkDomain !== 'cataloguer'"
                                    style="margin-top:20px;"
                                    @focus="changeLog('meeting_for_this_material')"
                                >
                                </el-checkbox>
                            </base-input>
                        </div>
                        <div class="row col-md-4 d-flex align-items-center" v-if="input.display_fields_during_material_need_meeting_checked === true">
                            <label class="form-control-label pl-3">
                                {{ tt("material_has_been_discussed") }}
								<span class="text-danger">* </span>
                            </label>
                            <base-input class="pl-3" :name="tt('material_has_been_discussed')" :rules="input.display_fields_during_material_need_meeting_checked === true ? 'required' : ''">
                                <el-checkbox
                                    v-model="input.material_has_been_discussed"
                                    :disabled="form.disabled || checkDomain !== 'cataloguer'"
                                    style="margin-top:20px;"
                                    @focus="changeLog('material_has_been_discussed')"
                                ></el-checkbox>
                            </base-input>
                        </div>
                        <div class="col-md-4" v-if="input.display_fields_during_material_need_meeting_checked === true">
                            <label class="form-control-label">
                                {{ tt("material_date_discussion") }}
								<span class="text-danger">* </span>
                            </label>
                            <base-input 
                                :name="tt('material_date_discussion')" 
								:rules="input.display_fields_during_material_need_meeting_checked === true ? 'required' : ''"
                                :disabled="form.disabled || checkDomain !== 'cataloguer'"
                                v-model="input.material_date_discussion"
                                type="date"
								@focus="changeLog('material_date_discussion')">
                            </base-input>
                        </div>
				    </div>
				  </div>
				</div>
			</div>

			<!-- MODAL SMARTCAT ATTACHMENT -->
			<modal :show.sync="modalSmartcatAttachment" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('smartcat_attachment') }}</h5>
                </template>
                <template>
				    <SmartCatAttachment/>
				</template>
            </modal>

			<!-- MODAL UOM CONVERSION -->
			<modal :show.sync="modalUomConversion" size="lg">
                <template slot="header">
                    <h5 class="modal-title">UoM Conversion</h5>
                </template>
				<input type="button" class="btn btn-sm btn-dark float-right mb-2" value="Add" @click="addUomConversion">
				<table class="table align-items-center table-bordered">
					<thead class="card-header bg-primary text-center">
						<tr>
							<th class="text-white">X</th>
							<th class="text-white">{{ tt('alternative_unit') }}</th>
							<th class="text-white">Y</th>
							<th class="text-white">{{ tt('base_unit') }}</th>
							<th class="text-white text-center">{{ tt('del_flag') }}</th>
							<th class="text-white text-center">#</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(v, key) in prcMcrItemUomConversion.x" v-bind:key="key">
							<td><input type="number" min="0" class="form-control" v-model="prcMcrItemUomConversion.x[key]" placeholder="Ex: 1000"></td>
							<td>
								<el-select :placeholder="tt('alternative_unit')" :remote-method="querySearchOrderUnit" :loading="selectSearch.orderUnitLoading" filterable remote reserve-keyword v-model="prcMcrItemUomConversion.alternative_unit[key]">
									<el-option value=""></el-option>
									<el-option :value="ou.code" :label="ou.code+' - '+ou.description" :key="ou.code" v-for="ou in orderUnit" :disabled="input.prc_uom_code == ou.code"></el-option>
								</el-select>
							</td>
							<td><input type="number" min="0" class="form-control" v-model="prcMcrItemUomConversion.y[key]" placeholder="Ex: 1000"></td>
							<td class="text-center">{{ input.prc_uom_code }}</td>
							<td><div class="text-center"><input type="checkbox" :checked="prcMcrItemUomConversion.del_flag[key] === 'X'" v-model="prcMcrItemUomConversion.del_flag[key]"></div></td>
							<td><i class="fa fa-trash text-danger" @click="removeUomConversion(key)"></i></td>
						</tr>
					</tbody>
				</table>
				<template slot="footer">
					<base-button type="primary" @click="modalUomConversion = false">{{ tt('close') }}</base-button>
				</template>
            </modal>

            <!-- CHECK DUPLICATE -->
            <modal :show.sync="modalCheckDuplicate" size="lg">
                <template slot="header">
                    <h5 class="modal-title">{{ tt('check_duplicate') }}</h5>
                </template>
                <div class="row">
                	<div class="col-12">
                		<div class="card">
                            <div class="card-header pb-2">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>{{ tt('by_description') }}</h4>
                                    </div>
                                    <div class="col-6 text-right"></div>
                                </div>
                            </div>
                            <div>
                                <el-table height="500px" class="table-responsive" header-row-class-name="thead-light" :data="dataDuplicate.by_description">
                                    <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                                        <template v-slot="{row}">
                                            {{row.material_number}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                	</div>
                	<div class="col-12">
                        <div class="card">
                            <div class="card-header pb-2">
                                <div class="row">
                                    <div class="col-6">
                                        <h4>{{ tt('by_part_number') }}</h4>
                                    </div>
                                    <div class="col-6 text-right"></div>
                                </div>
                            </div>
                            <div>
                                <el-table height="500px" class="table-responsive" header-row-class-name="thead-light" :data="dataDuplicate.by_part_number">
                                    <el-table-column :label="tt('material_number')" :prop="tt('material_number')" min-width="200px" sortable>
                                        <template v-slot="{row}">
                                            {{row.material_number}}
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </div>
            </modal>

            <!-- CHANGE LOG -->
			<modal :show.sync="modalChangeLog" size="xl">
		        <template slot="header">
		              <h5 class="modal-title">{{ tt('change_log') }}</h5>
				</template>
				<el-table height="500px" class="table-responsive" header-row-class-name="thead-light" :data="dataChangeLog">
				  <el-table-column :label="tt('date')" :prop="tt('date')" min-width="120px" sortable>
				      <template v-slot="{row}">
				          {{ row.created_at }}
				      </template>
				  </el-table-column>
				  <el-table-column :label="tt('column')" :prop="tt('column')" min-width="120px" sortable>
				      <template v-slot="{row}">
				      	<span v-if="row.message.indexOf('line') != -1">
				          {{ row.message.substring(0, row.message.indexOf('line') - 1)}}
				      	</span>
				      	<span v-else>
				      		{{ row.message.substring( row.message.indexOf('line'), row.message.indexOf('changed')) }}
				      	</span>
				      </template>
				  </el-table-column>
				  <el-table-column :label="tt('line')" :prop="tt('line')" min-width="100px" sortable>
				      <template v-slot="{row}">
				      	<span v-if="row.message.indexOf('line') != -1">
				          {{ row.message.substring( row.message.indexOf('line'), row.message.indexOf('changed')) }}
				      	</span>
				      	<span v-else> {{ '-' }}</span>
				      </template>
				  </el-table-column>
				  <el-table-column :label="tt('old_values')" :prop="tt('old_values')" min-width="150px" sortable>
				      <template v-slot="{row}">
				          {{ row.message.substring(row.message.indexOf('from') + 6, row.message.indexOf('" to')) }}
				      </template>
				  </el-table-column>
				  <el-table-column :label="tt('new_values')" :prop="tt('new_values')" min-width="150px" sortable>
				      <template v-slot="{row}">
				          {{ row.message.substring(row.message.indexOf('to "') + 4, row.message.length - 1) }}
				      </template>
				  </el-table-column>
				  <el-table-column :label="tt('changed_by')" :prop="tt('changed_by')" min-width="120px" sortable>
				      <template v-slot="{row}">
				          {{ row.executed_by }}
				      </template>
				  </el-table-column>
				</el-table>
		    </modal>

			<!-- MODAL COPY ALL VALUE -->
            <validation-observer v-slot="{invalid}">
				<modal :show.sync="modalCopyAllValue">
	                <template slot="header">
	                    <h5 class="modal-title">{{ tt('copy_value') }}</h5>
	                </template>
	                <template>
					    <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
	                    <base-input :name="tt('material_number')" rules="required">
							<el-select v-model="materialNumberCopy" :placeholder="tt('choose_material_number')" :remote-method="querySearchMaterialNumber" :loading="selectSearch.matnumLoading" filterable remote reserve-keyword>
								<el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
							</el-select>
						</base-input>
					</template>
					<template slot="footer">
	                    <base-button type="secondary" @click="modalCopyAllValue = false">{{ tt('close') }}</base-button>
	                    <base-button type="primary" @click="copyAllValue()" :disabled="btnCopyValue.onLoading || invalid">
	                        <span v-if="btnCopyValue.onLoading"><i class="fas fa-spinner fa-spin"></i> {{ tt('please_wait') }}</span>
	                        <span v-else>
	                            <span>{{ tt('copy_value') }}</span>
	                        </span>
	                    </base-button>
	                </template>
	            </modal>
            </validation-observer>

	        <div class="text-right" v-if="!form.disabled">

	        	<base-button type="default" @click="checkChangeLog">Change Log</base-button>

	        	<base-button type="default" @click="checkDuplicate">Check Duplicate</base-button>

		        <base-button
		          type="primary"
		          @click="save()"
		          :disabled="form.btnSave.onLoading">
		          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
		          <span v-else>{{ tt("save") }}</span>
				</base-button>

	        </div>
		</div>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialType from '@/services/master/materialType.service';
    import plantCode from '@/services/master/plantCode.service';
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import sourceType from '@/services/master/sourceType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import valuationClass from '@/services/master/valuationClass.service';
    import valuationCategory from '@/services/master/valuationCategory.service';
    import valuationType from '@/services/master/valuationType.service';
    import priceControl from '@/services/master/priceControl.service';
    import salesOrganization from '@/services/master/salesOrganization.service';
    import distributionChannel from '@/services/master/distributionChannel.service';
    import division from '@/services/master/division.service';
    import taxClassification from '@/services/master/taxClassification.service';
    import itemCategoryGroup from '@/services/master/itemCategoryGroup.service';
    import accountAssignmentGroup from '@/services/master/accountAssignmentGroup.service';
    import transportationGroup from '@/services/master/transportationGroup.service';
    import loadingGroup from '@/services/master/loadingGroup.service';
    import profitCenter from '@/services/master/profitCenter.service';
    import warehouse from '@/services/master/warehouse.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import binloc from '@/services/master/binLocation.service';
    import catalogType from '@/services/master/catalogType.service';
    import materialGroup from '@/services/master/materialGroup.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import standardItemName from '@/services/dictionary/standardItemName.service';
    import characteristicItemName from '@/services/dictionary/characteristicItemName.service';
    import lotSize from '@/services/master/lotSize.service';
    import procurementType from '@/services/master/procurementType.service';
    import specialProcurementType from '@/services/master/specialProcurementType.service';
    import scopeOfSupply from '@/services/dictionary/scopeOfSupply.service';
    import natoGroupClass from '@/services/dictionary/natoGroupClass.service';
    import purchasingGroup from '@/services/master/purchasingGroup.service';
    import inspectionType from '@/services/master/inspectionType.service';
    import qmMaterialAuth from '@/services/master/qmMaterialAuth.service';
    import checkDuplicate from '@/services/report/checkDuplicate.service';
    import transactionLog from '@/services/report/transactionLog.service';
    import baseApiUrl from '@/configs/config';

    export default {    
		props: ['clientPlantLevel'],    
        data() {
            return { 
            	requestCalls: 0,
            	requestCallsCharValue: 0,
            	accordion: {
			        bds: {
						show: "show"
					},
					charValue: {
						show: ""
					},
					manref: {
						show: ""
					},
					plant: {
						show: ""
					},
					sloc: {
						show: ""
					},
					sales: {
						show: ""
					},
					mrp: {
						show: ""
					},
					accounting: {
						show: ""
					},
					purchasing: {
						show: ""
					},
					costing: {
						show: ""
					},
					quality_management: {
						show: ""
					},
					equipment: {
						show: ""
					},
					attDoc: {
						show: ""
					},
					keyword: {
						show: ""
					},
					previewDesc: {
						show: ""
					},
					wManagement:{
						show:""
					}
		      	},
		      	toggleAccordion(show, type) {
					if (type == "bds") {
						if (show == "") {
						  this.accordion.bds.show = "show";
						} else {
						  this.accordion.bds.show = "";
						}
					} else if (type == "charValue") {
						if (show == "") {
						  this.accordion.charValue.show = "show";
						} else {
						  this.accordion.charValue.show = "";
						}
					} else if (type == "manref") {
						if (show == "") {
						  this.accordion.manref.show = "show";
						} else {
						  this.accordion.manref.show = "";
						}
					} else if (type == "plant") {
						if (show == "") {
						  this.accordion.plant.show = "show";
						} else {
						  this.accordion.plant.show = "";
						}
					} else if (type == "sloc") {
						if (show == "") {
						  this.accordion.sloc.show = "show";
						} else {
						  this.accordion.sloc.show = "";
						}
					} else if (type == "sales") {
						if (show == "") {
						  this.accordion.sales.show = "show";
						} else {
						  this.accordion.sales.show = "";
						}
					} else if (type == "mrp") {
						if (show == "") {
						  this.accordion.mrp.show = "show";
						} else {
						  this.accordion.mrp.show = "";
						}
					} else if (type == "accounting") {
						if (show == "") {
						  this.accordion.accounting.show = "show";
						} else {
						  this.accordion.accounting.show = "";
						}
					} else if (type == "purchasing") {
						if (show == "") {
						  this.accordion.purchasing.show = "show";
						} else {
						  this.accordion.purchasing.show = "";
						}
					} else if (type == "costing") {
						if (show == "") {
						  this.accordion.costing.show = "show";
						} else {
						  this.accordion.costing.show = "";
						}
					} else if (type == "quality_management") {
						if (show == "") {
						  this.accordion.quality_management.show = "show";
						} else {
						  this.accordion.quality_management.show = "";
						}
					} else if (type == "equipment") {
						if (show == "") {
						  this.accordion.equipment.show = "show";
						} else {
						  this.accordion.equipment.show = "";
						}
					} else if (type == "attDoc") {
						if (show == "") {
						  this.accordion.attDoc.show = "show";
						} else {
						  this.accordion.attDoc.show = "";
						}
					} else if (type == "keyword") {
						if (show == "") {
						  this.accordion.keyword.show = "show";
						} else {
						  this.accordion.keyword.show = "";
						}
					} else if (type == "previewDesc") {
						if (show == "") {
						  this.accordion.previewDesc.show = "show";
						} else {
						  this.accordion.previewDesc.show = "";
						}
					} else if(type == "warehouse_management"){
						if(show == ""){
							this.accordion.wManagement.show = "show"
						}else{
							this.accordion.wManagement.show = ""
						}
					}
			    },       
                form: {
					disabled: false,
					btnSave: {
						onLoading: false
					}
			    },
			    btnShowCharValue: {
			    	onLoading: false
			    },
				btnCopyValue: {
					onLoading: false
				},
			    manrefType: {},
			    sourceType: {},
                materialType: {},
                abcIndicator: {},
                mrpTypes: {},
                valuationCategory: {},
                valuationType: {},
                priceControl: {},
                priceControlByMtype: '',
                salesOrganization: {},
                distributionChannel: {},
                division: {},
                taxClassification: {},
                itemCategoryGroup: {},
                accountAssignmentGroup: {},
                transportationGroup: {},
                loadingGroup: {},
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                criticalNonCritical: {},
                characteristicItemName: {},
                lotSize: {},
                procurementType: {},
                charValue: {},
                drawingAttachment: [],
                materialNumber: {},
                inspectionType: {},
                qmMaterialAuth: {},
				materialNumberCopy: '',

                // SELECT SEARCH
                selectSearch: {
                	sitLoading: false,
                	ngcLoading: false,
                	uomLoading: false,
                	mtLoading: false,
                	ctLoading: false,
                	mgLoading: false,
                	sosLoading: false,
                	mancodeLoading: false,
                	plantLoading: false,
                	slocLoading: false,
                	binlocLoading: false,
                	pcenterLoading: false,
                	mrpControllerLoading: false,
                	spcProcTypeLoading: false,
                	valClassLoading: false,
                	purGroupLoading: false,
                	equipmentLoading: false,
                	drawingNumLoading: false,
                	matnumLoading: false,
                	orderUnitLoading: false,
                    loadTimeout: null,
					warehouseNumber:false,

                },
                standardItemName: {},
                natoGroupClass: {},
                unitOfMeasure: {},
                orderUnit: {},
                catalogType: {},
                materialGroup: {},
                scopeOfSupply: {},
                masterManufacture: {},
                plantCode: {},
                plantCompany: [],
                locationCode: {},
                binloc: {},
                profitCenter: {},
                mrpController: {},
                specialProcurementType: {},
                valuationClass: {},
                purchasingGroup:{},
                overheadGroup: {},
                equipmentCode: {},
                drawingMaster: {},
                focusedEquipment: '',
                parameter: '',
                oldValue: {},
                newValue: {},

                input: {
                	id: '',
                	prc_inc: '',
                    prc_item_type_code: '',
                    prc_nsc: '',
					prc_nsc_display_description: '',
                    prc_uom_code: '',
                    prc_mtype_code: '',
                    prc_catalog_type_code: '',
                    prc_mgroup_code: '',
                    prc_fab_non_fab: '',
                    prc_sos_header: '',
                    prc_bom_non_bom: '',
                    prc_critical_non_critical: '',
                    prc_material_or_service: '',
                    prc_old_material_number: '',
                    prc_po_text_memo: '',
                    prc_order_unit: '',
                    prc_base_unit: '',
                    prc_alternative_unit: '',
                    note: '',
                    material_number: '',
                    prc_item_name: '',
                    short_description: '',
                    status_process: '',
					material_need_meeting: false,
					display_fields_during_material_need_meeting_checked: false,
					material_has_been_discussed: false,
					material_date_discussion: '',
					
					// SALES
					prc_division_code: '',
					prc_trans_grp: '',
					prc_gen_item_cat_grp: ''
                },
				varMcrType: '',
                prcMcrItemCharValue: {
                  cin_code: [],
                  characteristic: [],
                  value: [],
                  view: [],
                  multipleChar: [],
                  code: [],
                },
                prcMcrItemPoText: {
                	charValue: {},
                	equipment: {},
                },
                prcMcrItemMpn: {
                    manufacture_code: [],
                    source_type: [],
                    mpn: [],
                    position: [],
                    manufacture_type: [],
                    manufacture_note: [],
                    status: [],
                    del_flag: [],
                    shortdes: [],
                },
                prcMcrItemPlant: {
                    plant: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemSloc: {
                    plant_code: [],
                    storage_location: [],
                    array_storage_location: [],
                    warehouse_number: [],
                    storage_type: [],
                    bin_location: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemSales: {
                    sales_org: [],
                    distr_channel: [],
                    sales_unit: [],
                    // tax_cat: [],
                    tax_class: [],
                    account_assign_grp: [],
                    item_cat_grp: [],
                    status: [],
                    del_flag: [],
                },
				prcMcrItemSalesPlant: {
                    sls_plant_code:[],
                    loading_group: [],
                    profit_center: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemMrp: {
                    plant_mrp:[],
                    abc_indicator: [],
                    mrp_type: [],
                    mrp_controller: [],
                    lot_size: [],
                    safety_stock: [],
                    min: [],
                    max: [],
                    procurement_type: [],
                    special_proc: [],
                    max_stock: [],
                    fixed_lot: [],
                    ord_costs: [],
                    reorder_point: [],
                    avail_check: [],
                    sloc_for_ep: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemAccounting: {
                    plant_accounting:[],
                    valuation_class: [],
                    valuation_category: [],
                    valuation_type: [],
                    price_control: [],
                    price_determination: [],
                    standard_price:[],
                    price_unit: [],
                    price_reference: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemPurchasing: {
                    plant_purchasing: [],
                    purchasing_group: [],
                    planned_delivery: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemCosting: {
                    plant_costing: [],
                    overhead_group: [],
                    costing_lot_size: [],
                    status: [],
                    del_flag: [],
                },
                prcMcrItemQm: {
                    plant_qm: [],
                    inspection_type: [],
                    qm_material_auth: [],
                    status: [],
                },
                prcMcrItemAttachment: {
                    file_attachment_code: [],
                    description: [],
                },
                prcMcrItemKeyword: {
                    keyword: []
                },
				prcMcrItemUomConversion: {
                    x: [],
                    y: [],
                    alternative_unit: [],
					del_flag: []
                },
                dataDuplicate: {
                	by_description: [],
                	by_part_number: [],
                },
				warehouseManagement1:{
					warehouse_number:[],
					stock_removal:[],
					stock_replacement:[],
					storage_section_indicator:[],
					status:[],
				},
				warehouseManagement2:{
					warehouse_number:[],
					storage_type_indicator:[],
					storage_bin:[],
					status:[],
				},
				prcMcrItemEquipment: {
                    plant_equipment_code: [],
                    equipment_code: [],
					old_equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                    status: [],
                    del_flag: [],
                    show: [],
					code:[],
					equipment_code_stored_in_db:[],
					code_shp_stored_in_db:[],
					esti_lead_time_shp_stored_in_db:[],
					save_process:[]
                },
				valuesOfShpForm: [],
				formDataShp: {},
				warehouseNumbers:[],
				stockRemovals:{},
				stockReplacements:{},
				storageSections:{},
				storageTypes:{},
				storageBins:{},
                dataChangeLog: [],
                previewPoTextModal: false,
                modalSmartcatAttachment: false,
                modalCheckDuplicate: false,
                modalChangeLog: false,
				modalCopyAllValue: false,
				modalUomConversion: false,

                storageUrl: baseApiUrl.storageUrl,
                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type, // create, approval,dll
			    mcrType: this.$route.params.mcr_type, // B2,B3,dll
            }
        },
        computed: {
			checkDomain() {
				if (this.authUserRole['Cataloguer']) {
					return 'cataloguer';
				} else {
					return '';
				}
			},
			...mapState({
				authUserPermission :state => state.auth.userPermission,
                authUserRole :state => state.auth.userRole,
			}),
        	insertChangeLog() {
        		var basic_data = {
        			item_name: this.input.prc_inc,
        			item_type: this.input.prc_item_type_code,
        			group_class: this.input.prc_nsc,
                    uom: this.input.prc_uom_code,
                    material_type: this.input.prc_mtype_code,
                    catalog_type: this.input.prc_catalog_type_code,
                    material_group: this.input.prc_mgroup_code,
                    fab_non_fab: this.input.prc_fab_non_fab,
                    scope_of_supply: this.input.prc_sos_header,
                    bom_non_bom: this.input.prc_bom_non_bom,
                    critical_non_critical: this.input.prc_critical_non_critical,
                    material_or_service: this.input.prc_material_or_service,
                    old_material_number: this.input.prc_old_material_number,
                    po_text_memo: this.input.prc_po_text_memo,
                    order_unit: this.input.prc_order_unit,
                    base_unit: this.input.prc_base_unit,
                    alternative_unit: this.input.prc_alternative_unit,
                    note: this.input.note,

					// sales
					division: this.input.prc_division_code,
					trans_grp: this.input.prc_trans_grp,
					gen_item_cat_grp: this.input.prc_gen_item_cat_grp
        		};

        		var mpn = {};
        		for (var i=0; i<this.prcMcrItemMpn.manufacture_code.length; i++) {
                	mpn['manufacture_code_line_'+(i+1)] = this.prcMcrItemMpn.manufacture_code[i];
                	mpn['source_type_line_'+(i+1)] = this.prcMcrItemMpn.source_type[i];
                	mpn['manufacture_ref_line_'+(i+1)] = this.prcMcrItemMpn.mpn[i];
                	mpn['position_line_'+(i+1)] = this.prcMcrItemMpn.position[i];
                	mpn['manufacture_type_line_'+(i+1)] = this.prcMcrItemMpn.manufacture_type[i];
                	mpn['manufacture_note_line_'+(i+1)] = this.prcMcrItemMpn.manufacture_note[i];
                }

                var plant = {};
                for (var i=0; i<this.prcMcrItemPlant.plant.length; i++) {
                	plant['plant_line_'+(i+1)] = this.prcMcrItemPlant.plant[i];
                }

                var sloc = {};
                for (var i=0; i<this.prcMcrItemSloc.plant_code.length; i++) {
                	sloc['plant_sloc_line_'+(i+1)] = this.prcMcrItemSloc.plant_code[i];
                	sloc['storage_location_line_'+(i+1)] = this.prcMcrItemSloc.storage_location[i];
                	sloc['bin_location_line_'+(i+1)] = this.prcMcrItemSloc.bin_location[i];
                }

                var purchasing = {};
                for (var i=0; i<this.prcMcrItemPurchasing.plant_purchasing.length; i++) {
                	purchasing['plant_purchasing_line_'+(i+1)] = this.prcMcrItemPurchasing.plant_purchasing[i];
                	purchasing['purchasing_group_line_'+(i+1)] = this.prcMcrItemPurchasing.purchasing_group[i];
                	purchasing['planned_delivery_line_'+(i+1)] = this.prcMcrItemPurchasing.planned_delivery[i];
                }

                var mrp = {};
                for (var i=0; i<this.prcMcrItemMrp.plant_mrp.length; i++) {
                	mrp['plant_mrp_line_'+(i+1)] = this.prcMcrItemMrp.plant_mrp[i];
                	mrp['abc_indicator_line_'+(i+1)] = this.prcMcrItemMrp.abc_indicator[i];
                	mrp['mrp_type_line_'+(i+1)] = this.prcMcrItemMrp.mrp_type[i];
                	mrp['mrp_controller_line_'+(i+1)] = this.prcMcrItemMrp.mrp_controller[i];
                	mrp['lot_size_line_'+(i+1)] = this.prcMcrItemMrp.lot_size[i];
                	mrp['safety_stock_line_'+(i+1)] = this.prcMcrItemMrp.safety_stock[i];
                	mrp['min_line_'+(i+1)] = this.prcMcrItemMrp.min[i];
                	mrp['max_line_'+(i+1)] = this.prcMcrItemMrp.max[i];
                	mrp['procurement_type_line_'+(i+1)] = this.prcMcrItemMrp.procurement_type[i];
                	mrp['special_proc_line_'+(i+1)] = this.prcMcrItemMrp.special_proc[i];
                	mrp['max_stock_line_'+(i+1)] = this.prcMcrItemMrp.max_stock[i];
                	mrp['fixed_lot_line_'+(i+1)] = this.prcMcrItemMrp.fixed_lot[i];
                	mrp['reorder_point_line_'+(i+1)] = this.prcMcrItemMrp.reorder_point[i];
                	mrp['avail_check_line_'+(i+1)] = this.prcMcrItemMrp.avail_check[i];
                	mrp['sloc_for_ep_line_'+(i+1)] = this.prcMcrItemMrp.sloc_for_ep[i];
                }

				var salesGen = {};
                for (var i=0; i<this.prcMcrItemSales.sales_org.length; i++) {
                	salesGen['sales_org_line_'+(i+1)] = this.prcMcrItemSales.sales_org[i];
                	salesGen['distr_channel_line_'+(i+1)] = this.prcMcrItemSales.distr_channel[i];
                	salesGen['sales_unit_line_'+(i+1)] = this.prcMcrItemSales.sales_unit[i];
                	// salesGen['tax_cat_line_'+(i+1)] = this.prcMcrItemSales.tax_cat[i];
                	salesGen['tax_class_line_'+(i+1)] = this.prcMcrItemSales.tax_class[i];
                	salesGen['account_assign_grp_line_'+(i+1)] = this.prcMcrItemSales.account_assign_grp[i];
                	salesGen['item_cat_grp_line_'+(i+1)] = this.prcMcrItemSales.item_cat_grp[i];
                }

				var salesPlant = {};
                for (var i=0; i<this.prcMcrItemSalesPlant.sls_plant_code.length; i++) {
                	salesPlant['plant_sales_line_'+(i+1)] = this.prcMcrItemSalesPlant.sls_plant_code[i];
                	salesPlant['loading_group_line_'+(i+1)] = this.prcMcrItemSalesPlant.loading_group[i];
                	salesPlant['profit_center_line_'+(i+1)] = this.prcMcrItemSalesPlant.profit_center[i];
                }

                var accounting = {};
                for (var i=0; i<this.prcMcrItemAccounting.plant_accounting.length; i++) {
                	accounting['plant_accounting_line_'+(i+1)] = this.prcMcrItemAccounting.plant_accounting[i];
                	accounting['valuation_class_line_'+(i+1)] = this.prcMcrItemAccounting.valuation_class[i];
                	accounting['valuation_category_line_'+(i+1)] = this.prcMcrItemAccounting.valuation_category[i];
                	accounting['valuation_type_line_'+(i+1)] = this.prcMcrItemAccounting.valuation_type[i];
                	accounting['price_control_line_'+(i+1)] = this.prcMcrItemAccounting.price_control[i];
                	accounting['price_determination_line_'+(i+1)] = this.prcMcrItemAccounting.price_determination[i];
                	accounting['standard_price_line_'+(i+1)] = this.prcMcrItemAccounting.standard_price[i];
                	accounting['price_unit_line_'+(i+1)] = this.prcMcrItemAccounting.price_unit[i];
                	accounting['price_reference_line_'+(i+1)] = this.prcMcrItemAccounting.price_reference[i];
                }

                var costing = {};
                for (var i=0; i<this.prcMcrItemCosting.plant_costing.length; i++) {
                	costing['plant_costing_line_'+(i+1)] = this.prcMcrItemCosting.plant_costing[i];
                	costing['overhead_group_line_'+(i+1)] = this.prcMcrItemCosting.overhead_group[i];
                	costing['costing_lot_size_line_'+(i+1)] = this.prcMcrItemCosting.costing_lot_size[i];
                }

                var qm = {};
                for (var i=0; i<this.prcMcrItemQm.plant_qm.length; i++) {
                	qm['plant_qm_line_'+(i+1)] = this.prcMcrItemQm.plant_qm[i];
                	qm['inspection_type_line_'+(i+1)] = this.prcMcrItemQm.inspection_type[i];
                	qm['qm_material_auth_line_'+(i+1)] = this.prcMcrItemQm.qm_material_auth[i];
                }

                var equipment = {};
                for (var i=0; i<this.prcMcrItemEquipment.plant_equipment_code.length; i++) {
                	equipment['plant_equipment_code_line_'+(i+1)] = this.prcMcrItemEquipment.plant_equipment_code[i];
                	equipment['equipment_code_line_'+(i+1)] = this.prcMcrItemEquipment.equipment_code[i];
                	equipment['qty_installed_line_'+(i+1)] = this.prcMcrItemEquipment.qty_installed[i];
                	equipment['drawing_number_line_'+(i+1)] = this.prcMcrItemEquipment.drawing_number[i];
                }

                var attachment = {};
                for (var i=0; i<this.prcMcrItemAttachment.file_attachment_code.length; i++) {
                	attachment['file_attachment_code_line_'+(i+1)] = this.prcMcrItemAttachment.file_attachment_code[i];
                	attachment['file_attachment_description_line_'+(i+1)] = this.prcMcrItemAttachment.description[i];
                }

                var keyword = {};
                for (var i=0; i<this.prcMcrItemKeyword.keyword.length; i++) {
                	keyword['keyword_line_'+(i+1)] = this.prcMcrItemKeyword.keyword[i];
                }

        		var data = Object.assign(basic_data,mpn,plant,sloc,purchasing,mrp,salesGen,salesPlant,accounting,costing,qm,equipment,attachment,keyword);

        		return data;
        	},
			warehouseNumberWm1(){
				let uniqueWarehouse = this.warehouseManagement1.warehouse_number.filter((value, index, self) => {
											return self.indexOf(value) === index
										})
				return uniqueWarehouse;
			},
			prcNscDisplayVal: {
				get() { 
					// console.log(this.input.prc_nsc_display_description, this.input.prc_nsc, this.input.prc_mgroup_code, this.materialGroup)
					if (this.input.prc_nsc_display_description) {
						return this.input.prc_nsc_display_description
					} else {
						const code = this.input.prc_nsc || this.input.prc_mgroup_code;
						if((Array.isArray(this.materialGroup) && this.materialGroup.length !== 0)){
							const material = this.materialGroup.find(m => m.code === code);
							return material ? material.code + ' - ' + material.description : '';
						}
					}
				}, 
				set(value) { 
					if (value == '') {
						this.changeItemName()
					}
					this.input.prc_nsc = value; 
					this.input.prc_nsc_display_description = value;
					this.input.prc_mgroup_code = value;
				}
			}
        },
        watch: {
          'input.material_number': function() {
          	if ((this.mcrType == 'A2' || this.mcrType == 'A3') && (this.formType == 'approval' || this.formType == 'approval-detail')) {
				// Kalau belum di proses sama cataloguer, maka get data dari EU
				// Kalau sudah di proses gaboleh ambil dari EU tapi dari PRC
          		if (this.input.status_process.cataloguer_process == 'Unprocessed') {
          			this.changeMaterialNumber('changeMaterial');
          		}
          	}
          },
          'input.prc_mtype_code': function() {
          	this.getValuationClassByAccCatRef();
          	this.getValuationTypeByAccCatRef();
          	this.changePriceControl();
			this.changeValuationCategory('all');
          },
          'prcMcrItemPlant.plant': function() {
          	this.dependOnPlant()
			this.changePlantDependMaster()
          },
          insertChangeLog(newValue, oldValue) {
          	var parameter = this.parameter;

          	if (parameter != '') {
          		if (!Object.keys(this.oldValue).includes(parameter)) { // kalau sudah ada oldValue dengan parameter ini maka jangan ditimpa (insert lagi)
          			this.oldValue[parameter] = `${oldValue[parameter]}`;
          		}

          		this.newValue[parameter] = `${newValue[parameter]}`;
          	}
	      },
	      'input.prc_nsc': function() {
			  this.changeGroupClass()
		  }
        },
        mounted() {
			this.checkDuplicateByMcrItemCode();
			this.getDetailMcrItem();
        	this.checkFormType();
            this.getParameterMcr();
            this.getMaterialType();
            this.getPlantCompany();
            this.getChangeLog();
			// mrp
			this.getAbcIndicator();
			this.getMrpTypes();
			this.getLotSize();
			this.getProcurementType();
			// accounting
			this.getValuationCategory();
			// this.getValuationType();
			this.getPriceControl();
			// sales
			this.getSalesOrganization();
			this.getDistributionChannel();
			this.getDivision();
			this.getTaxClassification();
			this.getItemCategoryGroup();
			this.getAccountAssignmentGroup();
			this.getTransportationGroup();
			this.getLoadingGroup();
			// mpn
			this.getManrefType();
			this.getSourceType();
			this.getOverheadGroup();
			// qm
			this.getInspectionType()
			this.getQmMaterialAuth()
        },
        methods: {
        	amount(key) {
		        let res = this.prcMcrItemAccounting.price_reference[key].toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",");

		        this.prcMcrItemAccounting.price_reference[key] = res;
		        this.$forceUpdate()
        	},
        	// CHECK FORM TYPE

			checkFormType() {
				if(this.formType == 'edit'){
					this.form.disabled = false;
				} else if (this.formType == 'detail'){
					this.form.disabled = true;
				} else if (this.formType == 'approval' && this.authUserPermission['mcr-approval-cataloguer-form-edit']){
					this.form.disabled = false;
				} else if (this.formType == 'sapSyncTaskEdit'){
					this.form.disabled = false;
				} else {
					this.form.disabled = true;
				}
			},

			// GET DETAIL FROM MCR ITEM CODE

            getDetailMcrItem() {
            	this.requestCalls++;

            	this.accordion.charValue.show = "show";
            	this.accordion.manref.show = "show";
            	this.accordion.plant.show = "show";
            	this.accordion.sloc.show = "show";
            	this.accordion.sales.show = "show";
            	this.accordion.mrp.show = "show";
            	this.accordion.accounting.show = "show";
            	this.accordion.purchasing.show = "show";
            	this.accordion.costing.show = "show";
            	this.accordion.quality_management.show = "show";
            	this.accordion.equipment.show = "show";
            	this.accordion.attDoc.show = "show";
            	this.accordion.keyword.show = "show";
            	this.accordion.previewDesc.show = "show";
            	this.accordion.wManagement.show = "show";

                let context = this;

                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function (response) {
                  	// context.input = response.data.data[0];

                  	context.input.id = response.data.data[0].id
                  	context.input.material_number = (response.data.data[0].material_number == null) ? '' : response.data.data[0].material_number
                	context.input.prc_inc = (response.data.data[0].prc_inc == null) ? '' : response.data.data[0].prc_inc
                    context.input.prc_item_type_code = (response.data.data[0].prc_item_type_code == null) ? '' : response.data.data[0].prc_item_type_code
                    context.input.prc_nsc = (response.data.data[0].prc_nsc == null) ? '' : response.data.data[0].prc_nsc
                    context.input.prc_uom_code = (response.data.data[0].prc_uom_code == null) ? '' : response.data.data[0].prc_uom_code
                    context.input.prc_mtype_code = (response.data.data[0].prc_mtype_code == null) ? '' : response.data.data[0].prc_mtype_code
                    context.input.prc_catalog_type_code = (response.data.data[0].prc_catalog_type_code == null) ? '' : response.data.data[0].prc_catalog_type_code
                    context.input.prc_mgroup_code = (response.data.data[0].prc_mgroup_code == null) ? '' : response.data.data[0].prc_mgroup_code
                    context.input.prc_fab_non_fab = (response.data.data[0].prc_fab_non_fab == null) ? '' : response.data.data[0].prc_fab_non_fab
                    context.input.prc_sos_header = (response.data.data[0].prc_sos_header == null) ? '' : response.data.data[0].prc_sos_header
                    context.input.prc_bom_non_bom = (response.data.data[0].prc_bom_non_bom == null) ? '' : response.data.data[0].prc_bom_non_bom
                    context.input.prc_critical_non_critical = (response.data.data[0].prc_critical_non_critical == null) ? '' : response.data.data[0].prc_critical_non_critical
                    context.input.prc_material_or_service = (response.data.data[0].prc_material_or_service == null) ? '' : response.data.data[0].prc_material_or_service
                    context.input.prc_old_material_number = (response.data.data[0].prc_old_material_number == null) ? '' : response.data.data[0].prc_old_material_number
                    context.input.prc_order_unit = (response.data.data[0].prc_order_unit == null) ? '' : response.data.data[0].prc_order_unit
                    context.input.prc_base_unit = (response.data.data[0].prc_base_unit == null) ? '' : response.data.data[0].prc_base_unit
                    context.input.prc_alternative_unit = (response.data.data[0].prc_alternative_unit == null) ? '' : response.data.data[0].prc_alternative_unit
                    context.input.prc_po_text_memo = (response.data.data[0].prc_po_text_memo == null) ? '' : response.data.data[0].prc_po_text_memo
                    context.input.note = (response.data.data[0].internal_note == null) ? '' : response.data.data[0].internal_note
                    context.input.prc_item_name = (response.data.data[0].prc_item_name == null) ? '' : response.data.data[0].prc_item_name
                    context.input.short_description = (response.data.data[0].prc_short_description == null) ? '' : response.data.data[0].prc_short_description
					context.input.prc_division_code = (response.data.data[0].prc_division_code == null) ? '' : response.data.data[0].prc_division_code
					context.input.prc_trans_grp = (response.data.data[0].prc_trans_grp == null) ? '' : response.data.data[0].prc_trans_grp
					context.input.prc_gen_item_cat_grp = (response.data.data[0].prc_gen_item_cat_grp == null) ? '' : response.data.data[0].prc_gen_item_cat_grp
					context.input.status_process = JSON.parse(response.data.data[0].status_process)
					context.input.material_need_meeting = response.data.data[0].material_need_meeting === 'X' ? true : false
					context.input.display_fields_during_material_need_meeting_checked = response.data.data[0].material_need_meeting === 'X' ? true : false 
					context.input.material_has_been_discussed = response.data.data[0].material_has_been_discussed === 'X' ? true : false
					context.input.material_date_discussion = response.data.data[0].material_date_discussion == null ? '' : response.data.data[0].material_date_discussion

                    // SHOW LABEL SELECTED SELECT OPTION

        			if (!!response.data.data[0].prc_inc) {
						context.standardItemName = [
							{
								inc: response.data.data[0].prc_inc,
								item_name: response.data.data[0].prc_item_name,
							}
						]
					}

        			if (!!response.data.data[0].prc_mgroup_code) {
						context.materialGroup = [
							{
								code: response.data.data[0].prc_mgroup_code,
								description: response.data.data[0].prc_material_group_description,
							}
						]
					}

        			if (!!response.data.data[0].prc_uom_code) {
						context.unitOfMeasure = [
							{
								code: response.data.data[0].prc_uom_code,
								description: response.data.data[0].prc_uom_description,
							}
						]
					}

        			if (!!response.data.data[0].prc_sos_header) {
						context.scopeOfSupply = [
							{
								sos_header: response.data.data[0].prc_sos_header,
								sos_description: response.data.data[0].sos_description,
							}
						]
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.input = [];
	                }
                }).call()

                // PRC MCR ITEM CHARACTERISTIC VALUE
                Api(context, draftList.getPrcMcrItemCharacteristicValue(this.mcrItemCode)).onSuccess(function (response) {
					context.prcMcrItemCharValue.cin_code = [];
					context.prcMcrItemCharValue.characteristic = [];
					context.prcMcrItemCharValue.value = [];
					context.prcMcrItemCharValue.view = [];
					context.prcMcrItemCharValue.multipleChar = [];
					context.prcMcrItemCharValue.is_mandatory = [];
					context.prcMcrItemCharValue.code = [];

					for (var i = 0; i < response.data.data.length; i++) {
					    context.prcMcrItemCharValue.cin_code[i] = (response.data.data[i].cin_code == null) ? '' : response.data.data[i].cin_code
					    context.prcMcrItemCharValue.characteristic[i] = (response.data.data[i].characteristic == null) ? '' : response.data.data[i].characteristic
					    context.prcMcrItemCharValue.value[i] = (response.data.data[i].characteristic_value == null) ? '' : response.data.data[i].characteristic_value
					    context.prcMcrItemCharValue.view[i] = (response.data.data[i].view == 'checked' ? true : false);;
					    context.prcMcrItemCharValue.multipleChar[i] = (response.data.data[i].single_or_multiple == null) ? '' : response.data.data[i].single_or_multiple
					    context.prcMcrItemCharValue.is_mandatory[i] = (response.data.data[i].is_mandatory == null) ? '' : response.data.data[i].is_mandatory
					    context.prcMcrItemCharValue.code[i] = (response.data.data[i].code == null) ? '' : response.data.data[i].code
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemCharValue.cin_code = [];
	                    context.prcMcrItemCharValue.characteristic = [];
	                    context.prcMcrItemCharValue.value = [];
	                    context.prcMcrItemCharValue.view = [];
	                    context.prcMcrItemCharValue.multipleChar = [];
						context.prcMcrItemCharValue.is_mandatory = [];
	                    context.prcMcrItemCharValue.code = [];
	                }
                }).call()

                // DATA FOR PO TEXT
                Api(context, draftList.getPrcMcrItemPoText(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPoText.charValue = (response.data.data.dataCharValue == null) ? '' : response.data.data.dataCharValue
                	context.prcMcrItemPoText.equipment = (response.data.data.dataEquipment == null) ? '' : response.data.data.dataEquipment
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPoText.charValue = {};
	                    context.prcMcrItemPoText.equipment = {};
	                }
                }).call()

                // PRC MCR ITEM MPN
                Api(context, draftList.getPrcMcrItemMpn(this.mcrItemCode)).onSuccess(function (response) {
                  	context.prcMcrItemMpn.manufacture_code = []
					context.prcMcrItemMpn.mpn = []
					context.prcMcrItemMpn.position = []
					context.prcMcrItemMpn.source_type = []
					context.prcMcrItemMpn.manufacture_type = []
					context.prcMcrItemMpn.manufacture_note = []
					context.prcMcrItemMpn.shortdes = []
					context.prcMcrItemMpn.status = []

					for (var i = 0; i < response.data.data.length; i++) {
					  context.prcMcrItemMpn.manufacture_code[i] = (response.data.data[i].manufacture_code == null) ? '' : response.data.data[i].manufacture_code
					  context.prcMcrItemMpn.mpn[i] = (response.data.data[i].manufacture_ref == null) ? '' : response.data.data[i].manufacture_ref
					  context.prcMcrItemMpn.position[i] = (response.data.data[i].position == null) ? '' : response.data.data[i].position
					  context.prcMcrItemMpn.source_type[i] = (response.data.data[i].source_type_code == null) ? '' : response.data.data[i].source_type_code
					  context.prcMcrItemMpn.manufacture_type[i] = (response.data.data[i].manref_type_code == null) ? '' : response.data.data[i].manref_type_code
					  context.prcMcrItemMpn.manufacture_note[i] = (response.data.data[i].note == null) ? '' : response.data.data[i].note
					  context.prcMcrItemMpn.shortdes[i] = response.data.data[i].shortdes
					  context.prcMcrItemMpn.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemMpn.manufacture_code = [];
	                    context.prcMcrItemMpn.mpn = [];
	                    context.prcMcrItemMpn.source_type = [];
	                    context.prcMcrItemMpn.manufacture_type = [];
	                    context.prcMcrItemMpn.manufacture_note = [];
	                    context.prcMcrItemMpn.shortdes = [];
	                    context.prcMcrItemMpn.status = [];
	                }
                }).call()

                // PRC MCR ITEM PLANT
                Api(context, draftList.getPrcMcrItemPlant(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPlant.plant = [];
                	context.prcMcrItemPlant.status = [];
                	context.prcMcrItemPlant.del_flag = [];

                	let arrayPlant = [];

					for (var i = 0; i < response.data.data.length; i++) {
						arrayPlant.push({
							plant_code: response.data.data[i].plant_code,
							description: response.data.data[i].description
						})

						context.prcMcrItemPlant.plant[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemPlant.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemPlant.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}

					context.plantCode = arrayPlant;
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPlant.plant = [];
	                    context.prcMcrItemPlant.status = [];
	                    context.prcMcrItemPlant.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM STORAGE LOCATION
                Api(context, draftList.getPrcMcrItemStorageLocation(this.mcrItemCode)).onSuccess(function (response) {
					context.prcMcrItemSloc.plant_code = [];
					context.prcMcrItemSloc.storage_location = [];
					context.prcMcrItemSloc.bin_location = [];
					context.prcMcrItemSloc.status = [];
					context.prcMcrItemSloc.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemSloc.plant_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemSloc.storage_location[i] = (response.data.data[i].location_code == null) ? '' : response.data.data[i].location_code
						context.prcMcrItemSloc.bin_location[i] = (response.data.data[i].bin_code == null) ? '' : response.data.data[i].bin_code
						context.prcMcrItemSloc.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemSloc.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemSloc.plant_code = [];
						context.prcMcrItemSloc.storage_location = [];
						context.prcMcrItemSloc.bin_location = [];
						context.prcMcrItemSloc.status = [];
						context.prcMcrItemSloc.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM SALES
                Api(context, draftList.getPrcMcrItemSales(this.mcrItemCode)).onSuccess(function (response) {
                    context.prcMcrItemSales.sales_org = [];
                    context.prcMcrItemSales.distr_channel = [];
                    context.prcMcrItemSales.sales_unit = [];
                    // context.prcMcrItemSales.tax_cat = [];
                    context.prcMcrItemSales.tax_class = [];
                    context.prcMcrItemSales.account_assign_grp = [];
                    context.prcMcrItemSales.item_cat_grp = [];
                    context.prcMcrItemSales.status = [];
                    context.prcMcrItemSales.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemSales.sales_org[i] = (response.data.data[i].sales_org_code == null) ? '' : response.data.data[i].sales_org_code
						context.prcMcrItemSales.distr_channel[i] = (response.data.data[i].distr_channel_code == null) ? '' : response.data.data[i].distr_channel_code
						context.prcMcrItemSales.sales_unit[i] = (response.data.data[i].sales_unit == null) ? '' : response.data.data[i].sales_unit
						// context.prcMcrItemSales.tax_cat[i] = response.data.data[i].tax_cat_code;
						context.prcMcrItemSales.tax_class[i] = (response.data.data[i].tax_class_code == null) ? '' : response.data.data[i].tax_class_code
						context.prcMcrItemSales.account_assign_grp[i] = (response.data.data[i].acc_ass_grp_code == null) ? '' : response.data.data[i].acc_ass_grp_code
						context.prcMcrItemSales.item_cat_grp[i] = (response.data.data[i].item_cat_grp_code == null) ? '' : response.data.data[i].item_cat_grp_code
						context.prcMcrItemSales.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemSales.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemSales.sales_org = [];
	                    context.prcMcrItemSales.distr_channel = [];
	                    context.prcMcrItemSales.sales_unit = [];
	                    // context.prcMcrItemSales.tax_cat = [];
	                    context.prcMcrItemSales.tax_class = [];
	                    context.prcMcrItemSales.account_assign_grp = [];
	                    context.prcMcrItemSales.item_cat_grp = [];
	                    context.prcMcrItemSales.status = [];
	                    context.prcMcrItemSales.del_flag = [];
	                }
                }).call()

				// PRC MCR ITEM SALES (PLANT)
                Api(context, draftList.getPrcMcrItemSalesPlant(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemSalesPlant.sls_plant_code = [];
                    context.prcMcrItemSalesPlant.loading_group = [];
                    context.prcMcrItemSalesPlant.profit_center = [];
                    context.prcMcrItemSalesPlant.status = [];
                    context.prcMcrItemSalesPlant.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemSalesPlant.sls_plant_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemSalesPlant.loading_group[i] = (response.data.data[i].loading_group_code == null) ? '' : response.data.data[i].loading_group_code
						context.prcMcrItemSalesPlant.profit_center[i] = (response.data.data[i].profit_center_code == null) ? '' : response.data.data[i].profit_center_code
						context.prcMcrItemSalesPlant.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemSalesPlant.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemSalesPlant.sls_plant_code = [];
	                    context.prcMcrItemSalesPlant.loading_group = [];
	                    context.prcMcrItemSalesPlant.profit_center = [];
	                    context.prcMcrItemSalesPlant.status = [];
	                    context.prcMcrItemSalesPlant.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM MRP
                Api(context, draftList.getPrcMcrItemMrp(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemMrp.plant_mrp = [];
                    context.prcMcrItemMrp.abc_indicator = [];
                    context.prcMcrItemMrp.mrp_type = [];
                    context.prcMcrItemMrp.mrp_controller = [];
                    context.prcMcrItemMrp.lot_size = [];
                    context.prcMcrItemMrp.safety_stock = [];
                    context.prcMcrItemMrp.min = [];
                    context.prcMcrItemMrp.max = [];
                    context.prcMcrItemMrp.procurement_type = [];
                    context.prcMcrItemMrp.special_proc = [];
                    context.prcMcrItemMrp.max_stock = [];
                    context.prcMcrItemMrp.fixed_lot = [];
                    context.prcMcrItemMrp.ord_costs = [];
                    context.prcMcrItemMrp.reorder_point = [];
                    context.prcMcrItemMrp.avail_check = [];
                    context.prcMcrItemMrp.sloc_for_ep = [];
                    context.prcMcrItemMrp.status = [];
                    context.prcMcrItemMrp.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemMrp.plant_mrp[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemMrp.abc_indicator[i] = (response.data.data[i].abc_indicator_code == null) ? '' : response.data.data[i].abc_indicator_code
						context.prcMcrItemMrp.mrp_type[i] = (response.data.data[i].mrp_type_code == null) ? '' : response.data.data[i].mrp_type_code
						context.prcMcrItemMrp.mrp_controller[i] = (response.data.data[i].mrp_controller_code == null) ? '' : response.data.data[i].mrp_controller_code
						context.prcMcrItemMrp.lot_size[i] = (response.data.data[i].lot_size_code == null) ? '' : response.data.data[i].lot_size_code
						context.prcMcrItemMrp.safety_stock[i] = (response.data.data[i].safety_stock == null) ? '' : response.data.data[i].safety_stock
						context.prcMcrItemMrp.min[i] = (response.data.data[i].min == null) ? '' : response.data.data[i].min
						context.prcMcrItemMrp.max[i] = (response.data.data[i].max == null) ? '' : response.data.data[i].max
						context.prcMcrItemMrp.procurement_type[i] = (response.data.data[i].procurement_type_code == null) ? '' : response.data.data[i].procurement_type_code
						context.prcMcrItemMrp.special_proc[i] = (response.data.data[i].special_proc_type_code == null) ? '' : response.data.data[i].special_proc_type_code
						context.prcMcrItemMrp.max_stock[i] = (response.data.data[i].max_stock == null) ? '' : response.data.data[i].max_stock
						context.prcMcrItemMrp.fixed_lot[i] = (response.data.data[i].fixed_lot == null) ? '' : response.data.data[i].fixed_lot
						context.prcMcrItemMrp.ord_costs[i] = (response.data.data[i].ord_costs == null) ? '' : response.data.data[i].ord_costs
						context.prcMcrItemMrp.reorder_point[i] = (response.data.data[i].reorder_point == null) ? '' : response.data.data[i].reorder_point
						context.prcMcrItemMrp.avail_check[i] = (response.data.data[i].avail_check == null) ? '' : response.data.data[i].avail_check
						context.prcMcrItemMrp.sloc_for_ep[i] = (response.data.data[i].sloc_for_ep == null) ? '' : response.data.data[i].sloc_for_ep
						context.prcMcrItemMrp.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemMrp.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemMrp.plant_mrp = [];
	                    context.prcMcrItemMrp.abc_indicator = [];
	                    context.prcMcrItemMrp.mrp_type = [];
	                    context.prcMcrItemMrp.mrp_controller = [];
	                    context.prcMcrItemMrp.lot_size = [];
	                    context.prcMcrItemMrp.safety_stock = [];
	                    context.prcMcrItemMrp.min = [];
	                    context.prcMcrItemMrp.max = [];
	                    context.prcMcrItemMrp.procurement_type = [];
	                    context.prcMcrItemMrp.special_proc = [];
	                    context.prcMcrItemMrp.max_stock = [];
	                    context.prcMcrItemMrp.fixed_lot = [];
	                    context.prcMcrItemMrp.ord_costs = [];
	                    context.prcMcrItemMrp.reorder_point = [];
	                    context.prcMcrItemMrp.avail_check = [];
	                    context.prcMcrItemMrp.sloc_for_ep = [];
	                    context.prcMcrItemMrp.status = [];
	                    context.prcMcrItemMrp.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM ACCOUNTING
                Api(context, draftList.getPrcMcrItemAccounting(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemAccounting.plant_accounting = [];
                    context.prcMcrItemAccounting.valuation_class = [];
                    context.prcMcrItemAccounting.valuation_category = [];
                    context.prcMcrItemAccounting.valuation_type = [];
                    context.prcMcrItemAccounting.price_control = [];
                    context.prcMcrItemAccounting.price_determination = [];
                    context.prcMcrItemAccounting.standard_price = [];
                    context.prcMcrItemAccounting.price_unit = [];
                    context.prcMcrItemAccounting.price_reference = [];
                    context.prcMcrItemAccounting.status = [];
                    context.prcMcrItemAccounting.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemAccounting.plant_accounting[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemAccounting.valuation_class[i] = (response.data.data[i].valuation_class_code == null) ? '' : response.data.data[i].valuation_class_code
						context.prcMcrItemAccounting.valuation_category[i] = (response.data.data[i].valuation_category_code == null) ? '' : response.data.data[i].valuation_category_code
						context.prcMcrItemAccounting.valuation_type[i] = (response.data.data[i].valuation_type_code == null) ? '' : response.data.data[i].valuation_type_code
						context.prcMcrItemAccounting.price_control[i] = (response.data.data[i].price_control_code == null) ? '' : response.data.data[i].price_control_code
						context.prcMcrItemAccounting.price_determination[i] = (response.data.data[i].price_determination == null) ? '' : response.data.data[i].price_determination
						context.prcMcrItemAccounting.standard_price[i] = (response.data.data[i].standard_price == null) ? '' : response.data.data[i].standard_price
						context.prcMcrItemAccounting.price_unit[i] = (response.data.data[i].price_unit == null) ? '' : response.data.data[i].price_unit
						context.prcMcrItemAccounting.price_reference[i] = (response.data.data[i].price_reference == null) ? '' : response.data.data[i].price_reference.toString().replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
						context.prcMcrItemAccounting.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemAccounting.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemAccounting.plant_accounting = [];
	                    context.prcMcrItemAccounting.valuation_class = [];
	                    context.prcMcrItemAccounting.valuation_category = [];
	                    context.prcMcrItemAccounting.valuation_type = [];
	                    context.prcMcrItemAccounting.price_control = [];
	                    context.prcMcrItemAccounting.price_determination = [];
	                    context.prcMcrItemAccounting.standard_price = [];
	                    context.prcMcrItemAccounting.price_unit = [];
	                    context.prcMcrItemAccounting.price_reference = [];
	                    context.prcMcrItemAccounting.status = [];
	                    context.prcMcrItemAccounting.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM PURCHASING
                Api(context, draftList.getPrcMcrItemPurchasing(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemPurchasing.plant_purchasing = [];
                    context.prcMcrItemPurchasing.purchasing_group = [];
                    context.prcMcrItemPurchasing.planned_delivery = [];
                    context.prcMcrItemPurchasing.status = [];
                    context.prcMcrItemPurchasing.del_flag = [];

                    let arrayPurchasing = [];

					for (var i = 0; i < response.data.data.length; i++) {
						arrayPurchasing.push({
							id: response.data.data[i].id,
							purchasing_group_code: response.data.data[i].purchasing_group_code,
							purchasing_group_description: response.data.data[i].purchasing_group_description
						})

						context.prcMcrItemPurchasing.plant_purchasing[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemPurchasing.purchasing_group[i] = (response.data.data[i].purchasing_group_code == null) ? '' : response.data.data[i].purchasing_group_code
						context.prcMcrItemPurchasing.planned_delivery[i] = (response.data.data[i].planned_delivery == null) ? '' : response.data.data[i].planned_delivery
						context.prcMcrItemPurchasing.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemPurchasing.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}

					context.purchasingGroup = arrayPurchasing;
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemPurchasing.plant_purchasing = [];
	                    context.prcMcrItemPurchasing.purchasing_group = [];
	                    context.prcMcrItemPurchasing.planned_delivery = [];
	                    context.prcMcrItemPurchasing.status = [];
	                    context.prcMcrItemPurchasing.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM COSTING
                Api(context, draftList.getPrcMcrItemCosting(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemCosting.plant_costing = [];
                    context.prcMcrItemCosting.overhead_group = [];
                    context.prcMcrItemCosting.costing_lot_size = [];
                    context.prcMcrItemCosting.status = [];
                    context.prcMcrItemCosting.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemCosting.plant_costing[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemCosting.overhead_group[i] = (response.data.data[i].overhead_group == null) ? '' : response.data.data[i].overhead_group
						context.prcMcrItemCosting.costing_lot_size[i] = (response.data.data[i].costing_lot_size == null) ? '' : response.data.data[i].costing_lot_size
						context.prcMcrItemCosting.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemCosting.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemCosting.plant_costing = [];
	                    context.prcMcrItemCosting.overhead_group = [];
	                    context.prcMcrItemCosting.costing_lot_size = [];
	                    context.prcMcrItemCosting.status = [];
	                    context.prcMcrItemCosting.del_flag = [];
	                }
                }).call()

                // PRC MCR ITEM QM
                Api(context, draftList.getPrcMcrItemQm(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemQm.plant_qm = [];
                    context.prcMcrItemQm.inspection_type = [];
                    context.prcMcrItemQm.qm_material_auth = [];
                    context.prcMcrItemQm.status = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemQm.plant_qm[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemQm.inspection_type[i] = (response.data.data[i].inspection_type == null) ? '' : response.data.data[i].inspection_type
						context.prcMcrItemQm.qm_material_auth[i] = (response.data.data[i].qm_material_auth == null) ? '' : response.data.data[i].qm_material_auth
						context.prcMcrItemQm.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemQm.plant_qm = [];
	                    context.prcMcrItemQm.inspection_type = [];
	                    context.prcMcrItemQm.qm_material_auth = [];
	                    context.prcMcrItemQm.status = [];
	                }
                }).call()

                // PRC MCR ITEM EQUIPMENT
                Api(context, draftList.getPrcMcrItemEquipment(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemEquipment.plant_equipment_code = [];
                    context.prcMcrItemEquipment.equipment_code = [];
					context.prcMcrItemEquipment.old_equipment_code = [];
                    context.prcMcrItemEquipment.qty_installed = [];
                    context.prcMcrItemEquipment.drawing_number = [];
                    context.prcMcrItemEquipment.del_flag = [];
                    context.prcMcrItemEquipment.status = [];
                    context.prcMcrItemEquipment.show = [];
                    context.prcMcrItemEquipment.equipment_code_stored_in_db = [];
					context.prcMcrItemEquipment.code_shp_stored_in_db = [];
					context.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemEquipment.plant_equipment_code[i] = (response.data.data[i].plant_code == null) ? '' : response.data.data[i].plant_code
						context.prcMcrItemEquipment.equipment_code[i] = (response.data.data[i].equipment_code == null) ? '' : response.data.data[i].equipment_code
						context.prcMcrItemEquipment.old_equipment_code[i] = (response.data.data[i].equipment_code == null) ? '' : response.data.data[i].equipment_code
						context.prcMcrItemEquipment.qty_installed[i] = (response.data.data[i].qty_installed == null) ? '' : response.data.data[i].qty_installed
						context.prcMcrItemEquipment.drawing_number[i] = (response.data.data[i].drawing_number == null) ? '' : response.data.data[i].drawing_number
						context.prcMcrItemEquipment.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
						context.prcMcrItemEquipment.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status
						context.prcMcrItemEquipment.code[i] = (response.data.data[i].code == null) ? '' : response.data.data[i].code
						context.prcMcrItemEquipment.equipment_code_stored_in_db[i] = (response.data.data[i].equipment_code == null ? '' : response.data.data[i].equipment_code)
						context.prcMcrItemEquipment.code_shp_stored_in_db[i] = (response.data.data[i].code == null) ? '' : response.data.data[i].code
						context.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db[i] = (response.data.data[i].shp_estimated_lead_time == null) ? '' : response.data.data[i].shp_estimated_lead_time
						context.prcMcrItemEquipment.save_process[i] = 'save';
						context.prcMcrItemEquipment.show[i] = response.data.data[i].show
						context.drawingAttachment[i] = (response.data.data[i].file_name == null) ? '' : response.data.data[i].file_name

						// GET DATA SHP
						if (context.mcrType == 'A1S' || context.mcrType == 'B2S') {
							context.getMcrFormShp(context.prcMcrItemEquipment.code[i], context.prcMcrItemEquipment.equipment_code[i], i)
						}
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemEquipment.plant_equipment_code = [];
	                    context.prcMcrItemEquipment.equipment_code = [];
						context.prcMcrItemEquipment.old_equipment_code = [];
	                    context.prcMcrItemEquipment.qty_installed = [];
	                    context.prcMcrItemEquipment.drawing_number = [];
	                    context.prcMcrItemEquipment.del_flag = [];
	                    context.prcMcrItemEquipment.status = [];
						context.prcMcrItemEquipment.save_process = [];
	                    context.prcMcrItemEquipment.show = [];
	                    context.prcMcrItemEquipment.code = [];
						context.prcMcrItemEquipment.equipment_code_stored_in_db = [];
						context.prcMcrItemEquipment.code_shp_stored_in_db = [];
						context.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db = [];
	                }
                }).onFinish(function() {
					// IF MCR TYPE SCP OR N/A AND ITS EQP CODE HAS BEEN STORED IN DB BUT SHP FORM HAS NOT BEEN FILLED YET
					// <!-- ---------------------------- EMIT STORED EQUIPMENT CODE AND CODE IN EQUIPMENT QUANTITY INSTALL ------------------------- -->
					if ((context.varMcrType === 'suku_cadang_penyangga' || context.varMcrType === 'not_applicable') && 
						context.prcMcrItemEquipment.equipment_code_stored_in_db.length > 0
					) {
						context.$emit('validateEqpMcrWithShpForm', context.prcMcrItemEquipment.equipment_code_stored_in_db, context.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db, 'cataloguer')
					}
				}).call()

                // PRC MCR ITEM ATTACHMENT
                Api(context, draftList.getPrcMcrItemAttachment(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemAttachment.file_attachment_code = [];
                	context.prcMcrItemAttachment.description = [];

                  	for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemAttachment.file_attachment_code[i] = (response.data.data[i].file_attachment_code == null) ? '' : response.data.data[i].file_attachment_code
						context.prcMcrItemAttachment.description[i] = (response.data.data[i].attachment_description == null) ? '' : response.data.data[i].attachment_description
					}
                }).onError(function (error) {
					if (error.response.status == 404) {
						context.prcMcrItemAttachment.file_attachment_code = [];
						context.prcMcrItemAttachment.description = [];
					}
                }).call()

                // PRC MCR ITEM KEYWORD
                Api(context, draftList.getPrcMcrItemKeyword(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemKeyword.keyword = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemKeyword.keyword[i] = (response.data.data[i].keyword == null) ? '' : response.data.data[i].keyword
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemKeyword.keyword = [];
	                }
                }).call()

				// PRC MCR ITEM UOM CONVERSION
                Api(context, draftList.getPrcMcrItemUomConversion(this.mcrItemCode)).onSuccess(function (response) {
                	context.prcMcrItemUomConversion.x = [];
                	context.prcMcrItemUomConversion.y = [];
                	context.prcMcrItemUomConversion.alternative_unit = [];
                	context.prcMcrItemUomConversion.del_flag = [];

					for (var i = 0; i < response.data.data.length; i++) {
						context.prcMcrItemUomConversion.x[i] = (response.data.data[i].x == null) ? '' : response.data.data[i].x
						context.prcMcrItemUomConversion.y[i] = (response.data.data[i].y == null) ? '' : response.data.data[i].y
						context.prcMcrItemUomConversion.alternative_unit[i] = (response.data.data[i].alternative_unit == null) ? '' : response.data.data[i].alternative_unit
						context.prcMcrItemUomConversion.del_flag[i] = (response.data.data[i].del_flag == null) ? '' : response.data.data[i].del_flag
					}
                }).onError(function (error) {
	                if (error.response.status == 404) {
	                    context.prcMcrItemUomConversion.x = [];
	                    context.prcMcrItemUomConversion.y = [];
	                    context.prcMcrItemUomConversion.alternative_unit = [];
						context.prcMcrItemUomConversion.del_flag = []
	                }
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()

				// PRC MCR ITEM WM 1
                Api(context, draftList.getPrcMcrItemWM1(this.mcrItemCode)).onSuccess(function (response) {
                	context.warehouseManagement1.warehouse_number = [];
                	context.warehouseManagement1.stock_removal = [];
                	context.warehouseManagement1.stock_replacement = [];
                	context.warehouseManagement1.storage_section_indicator = [];
                	context.warehouseManagement1.status = [];

                  	for (var i = 0; i < response.data.data.length; i++) {
						context.warehouseNumbers.push({
							warehouse_number : response.data.data[i].warehouse_number,
							warehouse_description : response.data.data[i].warehouse_description
						})
						context.dependWarehouseNumber1(i, response.data.data[i].warehouse_number)

						context.warehouseManagement1.warehouse_number[i] = (response.data.data[i].warehouse_number == null) ? '' : response.data.data[i].warehouse_number;
						context.warehouseManagement1.stock_removal[i] = (response.data.data[i].stock_removal == null) ? '' : response.data.data[i].stock_removal;
						context.warehouseManagement1.stock_replacement[i] = (response.data.data[i].stock_replacement == null) ? '' : response.data.data[i].stock_replacement;
						context.warehouseManagement1.storage_section_indicator[i] = (response.data.data[i].storage_section_indicator == null) ? '' : response.data.data[i].storage_section_indicator;
						context.warehouseManagement1.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status;
					}
                }).onError(function (error) {
					if (error.response.status == 404) {
						context.warehouseManagement1.warehouse_number = [];
						context.warehouseManagement1.stock_removal = [];
						context.warehouseManagement1.stock_replacement = [];
						context.warehouseManagement1.storage_section_indicator = [];
						context.warehouseManagement1.status = [];
					}
                }).call()
				
				// PRC MCR ITEM WM 2
                Api(context, draftList.getPrcMcrItemWM2(this.mcrItemCode)).onSuccess(function (response) {
                	context.warehouseManagement2.warehouse_number = [];
                	context.warehouseManagement2.storage_type_indicator = [];
                	context.warehouseManagement2.storage_bin = [];
                	context.warehouseManagement2.status = [];

                  	for (var i = 0; i < response.data.data.length; i++) {
						context.dependWarehouseNumber2(i, response.data.data[i].warehouse_number)
						context.dependStorageType(i, response.data.data[i].warehouse_number, response.data.data[i].storage_type_indicator)
						context.warehouseManagement2.warehouse_number[i] = (response.data.data[i].warehouse_number == null) ? '' : response.data.data[i].warehouse_number;
						context.warehouseManagement2.storage_type_indicator[i] = (response.data.data[i].storage_type_indicator == null) ? '' : response.data.data[i].storage_type_indicator;
						context.warehouseManagement2.storage_bin[i] = (response.data.data[i].storage_bin == null) ? '' : response.data.data[i].storage_bin;
						context.warehouseManagement2.status[i] = (response.data.data[i].status == null) ? '' : response.data.data[i].status;
					}
                }).onError(function (error) {
					if (error.response.status == 404) {
						context.warehouseManagement2.warehouse_number = [];
						context.warehouseManagement2.storage_type_indicator = [];
						context.warehouseManagement2.storage_bin = [];
						context.warehouseManagement2.status = [];
					}
                }).call()
            },
            changeMaterialNumber(changeType) {
            	this.requestCalls++;

            	this.accordion.charValue.show = "show";
            	this.accordion.manref.show = "show";
            	this.accordion.plant.show = "show";
            	this.accordion.sloc.show = "show";
            	this.accordion.sales.show = "show";
            	this.accordion.mrp.show = "show";
            	this.accordion.accounting.show = "show";
            	this.accordion.purchasing.show = "show";
            	this.accordion.costing.show = "show";
            	this.accordion.quality_management.show = "show";
            	this.accordion.equipment.show = "show";
            	this.accordion.attDoc.show = "show";
            	this.accordion.keyword.show = "show";
				this.accordion.wManagement.show = "show";

            	let context = this;

            	Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.input.material_number})).onSuccess(function(response) {
            		// GET BASIC DATA MATERIAL 

            		// context.input.id = response.data.data.part_master[0].id
                	context.input.prc_inc = (response.data.data.part_master[0].inc == null) ? '' : response.data.data.part_master[0].inc
                	context.input.prc_item_name = (response.data.data.part_master[0].item_name == null) ? '' : response.data.data.part_master[0].item_name
                    context.input.prc_item_type_code = (response.data.data.part_master[0].item_type_code == null) ? '' : response.data.data.part_master[0].item_type_code
                    context.input.prc_nsc = (response.data.data.part_master[0].nsc == null) ? '' : response.data.data.part_master[0].nsc
                    context.input.prc_uom_code = (response.data.data.part_master[0].uom == null) ? '' : response.data.data.part_master[0].uom
                    context.input.prc_mtype_code = (response.data.data.part_master[0].material_type == null) ? '' : response.data.data.part_master[0].material_type
                    context.input.prc_catalog_type_code = (response.data.data.part_master[0].cat_type == null) ? '' : response.data.data.part_master[0].cat_type
                    context.input.prc_mgroup_code = (response.data.data.part_master[0].material_group == null) ? '' : response.data.data.part_master[0].material_group
                    context.input.prc_fab_non_fab = (response.data.data.part_master[0].fab_non_fab == null) ? '' : response.data.data.part_master[0].fab_non_fab
                    context.input.prc_sos_header = (response.data.data.part_master[0].scope_of_supply_code == null) ? '' : response.data.data.part_master[0].scope_of_supply_code
                    context.input.prc_bom_non_bom = (response.data.data.part_master[0].bom_non_bom == null) ? '' : response.data.data.part_master[0].bom_non_bom
                    context.input.prc_critical_non_critical = (response.data.data.part_master[0].critical_non_critical == null) ? '' : response.data.data.part_master[0].critical_non_critical
					context.input.prc_material_or_service = (response.data.data.part_master[0].prc_material_or_service == null) ? '' : response.data.data.part_master[0].prc_material_or_service
                    context.input.prc_old_material_number = (response.data.data.part_master[0].prc_old_material_number == null) ? '' : response.data.data.part_master[0].prc_old_material_number
                    context.input.prc_order_unit = (response.data.data.part_master[0].order_unit == null) ? '' : response.data.data.part_master[0].order_unit
                    context.input.prc_base_unit = (response.data.data.part_master[0].base_unit == null) ? '' : response.data.data.part_master[0].base_unit
                    context.input.prc_alternative_unit = (response.data.data.part_master[0].alternative_unit == null) ? '' : response.data.data.part_master[0].alternative_unit
                    if (changeType == 'changeMaterial') {
						context.input.prc_po_text_memo = (response.data.data.part_master[0].po_text_memo == null) ? '' : response.data.data.part_master[0].po_text_memo
						context.input.note = (response.data.data.part_master[0].note == null) ? '' : response.data.data.part_master[0].note
					}
                    context.input.short_description = (response.data.data.part_master[0].short_description == null) ? '' : response.data.data.part_master[0].short_description
					context.input.prc_division_code = (response.data.data.part_master[0].division_code == null) ? '' : response.data.data.part_master[0].division_code
					context.input.prc_trans_grp = (response.data.data.part_master[0].trans_grp == null) ? '' : response.data.data.part_master[0].trans_grp
					context.input.prc_gen_item_cat_grp = (response.data.data.part_master[0].gen_item_cat_grp == null) ? '' : response.data.data.part_master[0].gen_item_cat_grp

                    // select option to show description
        			context.standardItemName = [
        				{
	        				inc: response.data.data.part_master[0].inc,
	        				item_name: response.data.data.part_master[0].item_name,
	        			}
        			]
        			context.materialGroup = [
        				{
	        				code: response.data.data.part_master[0].material_group,
	        				description: response.data.data.part_master[0].material_group_description,
	        			}
        			]
        			context.scopeOfSupply = [
        				{
	        				sos_header: response.data.data.part_master[0].scope_of_supply_code,
	        				sos_description: response.data.data.part_master[0].sos_description,
	        			}
        			]
        			context.unitOfMeasure = [
        				{
	        				code: response.data.data.part_master[0].uom,
	        				description: response.data.data.part_master[0].uom_description,
	        			}
        			]

        			// GET CHARACTERISTIC VALUE

        			context.prcMcrItemCharValue.cin_code = [];
					context.prcMcrItemCharValue.characteristic = [];
					context.prcMcrItemCharValue.value = [];
					context.prcMcrItemCharValue.view = [];
					context.prcMcrItemCharValue.multipleChar = [];
					context.prcMcrItemCharValue.is_mandatory = [];
					context.prcMcrItemCharValue.code = [];

					for (var i = 0; i < response.data.data.data_part_char_value.length; i++) {
					    context.prcMcrItemCharValue.cin_code[i] = (response.data.data.data_part_char_value[i].cin_code == null) ? '' : response.data.data.data_part_char_value[i].cin_code
					    context.prcMcrItemCharValue.characteristic[i] = (response.data.data.data_part_char_value[i].characteristic == null) ? '' : response.data.data.data_part_char_value[i].characteristic
					    context.prcMcrItemCharValue.value[i] = (response.data.data.data_part_char_value[i].characteristic_value == null) ? '' : response.data.data.data_part_char_value[i].characteristic_value
					    context.prcMcrItemCharValue.view[i] = (response.data.data.data_part_char_value[i].view == 'checked' ? true : false)
					    context.prcMcrItemCharValue.multipleChar[i] = (response.data.data.data_part_char_value[i].single_or_multiple == null) ? '' : response.data.data.data_part_char_value[i].single_or_multiple
					    context.prcMcrItemCharValue.is_mandatory[i] = (response.data.data.data_part_char_value[i].is_mandatory == null) ? '' : response.data.data.data_part_char_value[i].is_mandatory
					    context.prcMcrItemCharValue.code[i] = (response.data.data.data_part_char_value[i].code == null) ? '' : response.data.data.data_part_char_value[i].code

					    // for po text
					    context.prcMcrItemPoText.charValue = (response.data.data.po_text == null) ? '' : response.data.data.po_text
					}

					// GET MANUFACTURE

					context.prcMcrItemMpn.manufacture_code = []
					context.prcMcrItemMpn.source_type_code = []
					context.prcMcrItemMpn.mpn = []
					context.prcMcrItemMpn.position = []
					context.prcMcrItemMpn.manufacture_type = []
					context.prcMcrItemMpn.manufacture_note = []
					context.prcMcrItemMpn.shortdes = []
					context.prcMcrItemMpn.status = []

					for (var i = 0; i < response.data.data.part_manufacture_ref.length; i++) {
						context.prcMcrItemMpn.manufacture_code[i] = (response.data.data.part_manufacture_ref[i].manufacture_code == null) ? '' : response.data.data.part_manufacture_ref[i].manufacture_code
						context.prcMcrItemMpn.source_type[i] = (response.data.data.part_manufacture_ref[i].source_type_code == null) ? '' : response.data.data.part_manufacture_ref[i].source_type_code
						context.prcMcrItemMpn.mpn[i] = (response.data.data.part_manufacture_ref[i].manufacture_ref == null) ? '' : response.data.data.part_manufacture_ref[i].manufacture_ref
						context.prcMcrItemMpn.position[i] = (response.data.data.part_manufacture_ref[i].position == null) ? '' : response.data.data.part_manufacture_ref[i].position
						context.prcMcrItemMpn.manufacture_type[i] = (response.data.data.part_manufacture_ref[i].type == null) ? '' : response.data.data.part_manufacture_ref[i].type
						context.prcMcrItemMpn.manufacture_note[i] = (response.data.data.part_manufacture_ref[i].note == null) ? '' : response.data.data.part_manufacture_ref[i].note
						context.prcMcrItemMpn.shortdes[i] = response.data.data.part_manufacture_ref[i].shortdes
						context.prcMcrItemMpn.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
	                }

	                if (changeType == 'changeMaterial') {
						// GET PLANT

						context.prcMcrItemPlant.status = [];
						context.prcMcrItemPlant.plant = [];
						context.prcMcrItemPlant.del_flag = [];

						let arrayPlant = [];

						for (var i = 0; i < response.data.data.part_plant.length; i++) {
							arrayPlant.push({
								plant_code: response.data.data.part_plant[i].plant_code,
								description: response.data.data.part_plant[i].description
							})

							context.prcMcrItemPlant.plant[i] = (response.data.data.part_plant[i].plant_code == null) ? '' : response.data.data.part_plant[i].plant_code
							context.prcMcrItemPlant.del_flag[i] = (response.data.data.part_plant[i].del_flag == null) ? '' : response.data.data.part_plant[i].del_flag
							context.prcMcrItemPlant.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						context.plantCode = arrayPlant;

						// GET STORAGE LOCATION

						context.prcMcrItemSloc.status = [];
						context.prcMcrItemSloc.plant_code = [];
						context.prcMcrItemSloc.storage_location = [];
						context.prcMcrItemSloc.warehouse_number = [];
						context.prcMcrItemSloc.storage_type = [];
						context.prcMcrItemSloc.bin_location = [];
						context.prcMcrItemSloc.status = [];
						context.prcMcrItemSloc.del_flag = [];

						for (var i = 0; i < response.data.data.part_storage_location.length; i++) {
						context.prcMcrItemSloc.plant_code[i] = (response.data.data.part_storage_location[i].plant_code == null) ? '' : response.data.data.part_storage_location[i].plant_code
						context.prcMcrItemSloc.storage_location[i] = (response.data.data.part_storage_location[i].location_code == null) ? '' : response.data.data.part_storage_location[i].location_code
						context.prcMcrItemSloc.bin_location[i] = (response.data.data.part_storage_location[i].bin_code == null) ? '' : response.data.data.part_storage_location[i].bin_code
						context.prcMcrItemSloc.del_flag[i] = (response.data.data.part_storage_location[i].del_flag == null) ? '' : response.data.data.part_storage_location[i].del_flag
						context.prcMcrItemSloc.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET SALES

						context.prcMcrItemSales.sales_org = [];
						context.prcMcrItemSales.distr_channel = [];
						context.prcMcrItemSales.sales_unit = [];
						context.prcMcrItemSales.tax_class = [];
						context.prcMcrItemSales.account_assign_grp = [];
						context.prcMcrItemSales.item_cat_grp = [];
						context.prcMcrItemSales.status = [];
						context.prcMcrItemSales.del_flag = [];

						for (var i = 0; i < response.data.data.part_sales.length; i++) {
							context.prcMcrItemSales.sales_org[i] = (response.data.data.part_sales[i].sales_org_code == null) ? '' : response.data.data.part_sales[i].sales_org_code
							context.prcMcrItemSales.distr_channel[i] = (response.data.data.part_sales[i].distr_channel_code == null) ? '' : response.data.data.part_sales[i].distr_channel_code
							context.prcMcrItemSales.sales_unit[i] = (response.data.data.part_sales[i].sales_unit == null) ? '' : response.data.data.part_sales[i].sales_unit
							context.prcMcrItemSales.tax_class[i] = (response.data.data.part_sales[i].tax_class_code == null) ? '' : response.data.data.part_sales[i].tax_class_code
							context.prcMcrItemSales.account_assign_grp[i] = (response.data.data.part_sales[i].acc_ass_grp_code == null) ? '' : response.data.data.part_sales[i].acc_ass_grp_code
							context.prcMcrItemSales.item_cat_grp[i] = (response.data.data.part_sales[i].item_cat_grp_code == null) ? '' : response.data.data.part_sales[i].item_cat_grp_code
							context.prcMcrItemSales.del_flag[i] = (response.data.data.part_sales[i].del_flag == null) ? '' : response.data.data.part_sales[i].del_flag
							context.prcMcrItemSales.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET SALES (PLANT)

						context.prcMcrItemSalesPlant.sls_plant_code = [];
						context.prcMcrItemSalesPlant.loading_group = [];
						context.prcMcrItemSalesPlant.profit_center = [];
						context.prcMcrItemSalesPlant.status = [];
						context.prcMcrItemSalesPlant.del_flag = [];

						for (var i = 0; i < response.data.data.part_sales_plant.length; i++) {
							context.prcMcrItemSalesPlant.sls_plant_code[i] = (response.data.data.part_sales_plant[i].plant_code == null) ? '' : response.data.data.part_sales_plant[i].plant_code
							context.prcMcrItemSalesPlant.loading_group[i] = response.data.data.part_sales_plant[i].loading_group_code
							context.prcMcrItemSalesPlant.profit_center[i] = (response.data.data.part_sales_plant[i].profit_center_code == null) ? '' : response.data.data.part_sales_plant[i].profit_center_code
							context.prcMcrItemSalesPlant.del_flag[i] = (response.data.data.part_sales_plant[i].del_flag == null) ? '' : response.data.data.part_sales_plant[i].del_flag
							context.prcMcrItemSalesPlant.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET MRP

						context.prcMcrItemMrp.status = [];
						context.prcMcrItemMrp.plant_mrp = [];
						context.prcMcrItemMrp.abc_indicator = [];
						context.prcMcrItemMrp.mrp_type = [];
						context.prcMcrItemMrp.mrp_controller = [];
						context.prcMcrItemMrp.lot_size = [];
						context.prcMcrItemMrp.safety_stock = [];
						context.prcMcrItemMrp.min = [];
						context.prcMcrItemMrp.max = [];
						context.prcMcrItemMrp.procurement_type = [];
						context.prcMcrItemMrp.special_proc = [];
						context.prcMcrItemMrp.max_stock = [];
						context.prcMcrItemMrp.fixed_lot = [];
						context.prcMcrItemMrp.ord_costs = [];
						context.prcMcrItemMrp.reorder_point = [];
						context.prcMcrItemMrp.avail_check = [];
						context.prcMcrItemMrp.sloc_for_ep = [];
						context.prcMcrItemMrp.status = [];
						context.prcMcrItemMrp.del_flag = [];

						for (var i = 0; i < response.data.data.part_mrp.length; i++) {
							context.prcMcrItemMrp.plant_mrp[i] = (response.data.data.part_mrp[i].plant_code == null) ? '' : response.data.data.part_mrp[i].plant_code
							context.prcMcrItemMrp.abc_indicator[i] = (response.data.data.part_mrp[i].abc_indicator_code == null) ? '' : response.data.data.part_mrp[i].abc_indicator_code
							context.prcMcrItemMrp.mrp_type[i] = (response.data.data.part_mrp[i].mrp_type_code == null) ? '' : response.data.data.part_mrp[i].mrp_type_code
							context.prcMcrItemMrp.mrp_controller[i] = (response.data.data.part_mrp[i].mrp_controller_code == null) ? '' : response.data.data.part_mrp[i].mrp_controller_code
							context.prcMcrItemMrp.lot_size[i] = (response.data.data.part_mrp[i].lot_size_code == null) ? '' : response.data.data.part_mrp[i].lot_size_code
							context.prcMcrItemMrp.safety_stock[i] = (response.data.data.part_mrp[i].safety_stock == null) ? '' : response.data.data.part_mrp[i].safety_stock
							context.prcMcrItemMrp.min[i] = (response.data.data.part_mrp[i].min == null) ? '' : response.data.data.part_mrp[i].min
							context.prcMcrItemMrp.max[i] = (response.data.data.part_mrp[i].max == null) ? '' : response.data.data.part_mrp[i].max
							context.prcMcrItemMrp.procurement_type[i] = (response.data.data.part_mrp[i].procurement_type_code == null) ? '' : response.data.data.part_mrp[i].procurement_type_code
							context.prcMcrItemMrp.special_proc[i] = (response.data.data.part_mrp[i].special_proc_type_code == null) ? '' : response.data.data.part_mrp[i].special_proc_type_code
							context.prcMcrItemMrp.max_stock[i] = (response.data.data.part_mrp[i].max_stock == null) ? '' : response.data.data.part_mrp[i].max_stock
							context.prcMcrItemMrp.fixed_lot[i] = (response.data.data.part_mrp[i].fixed_lot == null) ? '' : response.data.data.part_mrp[i].fixed_lot
							context.prcMcrItemMrp.ord_costs[i] = (response.data.data.part_mrp[i].ord_costs == null) ? '' : response.data.data.part_mrp[i].ord_costs
							context.prcMcrItemMrp.reorder_point[i] = (response.data.data.part_mrp[i].reorder_point == null) ? '' : response.data.data.part_mrp[i].reorder_point
							context.prcMcrItemMrp.avail_check[i] = (response.data.data.part_mrp[i].avail_check == null) ? '' : response.data.data.part_mrp[i].avail_check
							context.prcMcrItemMrp.sloc_for_ep[i] = (response.data.data.part_mrp[i].sloc_for_ep == null) ? '' : response.data.data.part_mrp[i].sloc_for_ep
							context.prcMcrItemMrp.del_flag[i] = (response.data.data.part_mrp[i].del_flag == null) ? '' : response.data.data.part_mrp[i].del_flag
							context.prcMcrItemMrp.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET ACCOUNTING

						context.prcMcrItemAccounting.status = []; 
						context.prcMcrItemAccounting.plant_accounting = []; 
						context.prcMcrItemAccounting.valuation_class = []; 
						context.prcMcrItemAccounting.valuation_category = []; 
						context.prcMcrItemAccounting.valuation_type = []; 
						context.prcMcrItemAccounting.price_control = []; 
						context.prcMcrItemAccounting.price_determination = []; 
						context.prcMcrItemAccounting.standard_price = []; 
						context.prcMcrItemAccounting.price_unit = []; 
						context.prcMcrItemAccounting.price_reference = [];
						context.prcMcrItemAccounting.status = [];
						context.prcMcrItemAccounting.del_flag = [];

						for (var i = 0; i < response.data.data.part_accounting.length; i++) {
							context.prcMcrItemAccounting.plant_accounting[i] = (response.data.data.part_accounting[i].plant_code == null) ? '' : response.data.data.part_accounting[i].plant_code
							context.prcMcrItemAccounting.valuation_class[i] = (response.data.data.part_accounting[i].valuation_class_code == null) ? '' : response.data.data.part_accounting[i].valuation_class_code
							context.prcMcrItemAccounting.valuation_category[i] = (response.data.data.part_accounting[i].valuation_category_code == null) ? '' : response.data.data.part_accounting[i].valuation_category_code
							context.prcMcrItemAccounting.valuation_type[i] = (response.data.data.part_accounting[i].valuation_type_code == null) ? '' : response.data.data.part_accounting[i].valuation_type_code
							context.prcMcrItemAccounting.price_control[i] = (response.data.data.part_accounting[i].price_control_code == null) ? '' : response.data.data.part_accounting[i].price_control_code
							context.prcMcrItemAccounting.price_determination[i] = (response.data.data.part_accounting[i].price_determination == null) ? '' : response.data.data.part_accounting[i].price_determination
							context.prcMcrItemAccounting.standard_price[i] = (response.data.data.part_accounting[i].standard_price == null) ? '' : response.data.data.part_accounting[i].standard_price
							context.prcMcrItemAccounting.price_unit[i] = (response.data.data.part_accounting[i].price_unit == null) ? '' : response.data.data.part_accounting[i].price_unit
							context.prcMcrItemAccounting.price_reference[i] = (response.data.data.part_accounting[i].price_reference == null) ? '' : response.data.data.part_accounting[i].price_reference
							context.prcMcrItemAccounting.del_flag[i] = (response.data.data.part_accounting[i].del_flag == null) ? '' : response.data.data.part_accounting[i].del_flag
							context.prcMcrItemAccounting.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET PURCHASING

						context.prcMcrItemPurchasing.status = [];
						context.prcMcrItemPurchasing.plant_purchasing = [];
						context.prcMcrItemPurchasing.purchasing_group = [];
						context.prcMcrItemPurchasing.planned_delivery = [];
						context.prcMcrItemPurchasing.status = [];
						context.prcMcrItemPurchasing.del_flag = [];

						let arrayPurchasing = [];

						for (var i = 0; i < response.data.data.part_purchasing.length; i++) {
							arrayPurchasing.push({
								id: response.data.data.part_purchasing[i].id,
								purchasing_group_code: response.data.data.part_purchasing[i].purchasing_group_code,
								purchasing_group_description: response.data.data.part_purchasing[i].purchasing_group_description
							})

							context.prcMcrItemPurchasing.plant_purchasing[i] = (response.data.data.part_purchasing[i].plant_code == null) ? '' : response.data.data.part_purchasing[i].plant_code
							context.prcMcrItemPurchasing.purchasing_group[i] = (response.data.data.part_purchasing[i].purchasing_group_code == null) ? '' : response.data.data.part_purchasing[i].purchasing_group_code
							context.prcMcrItemPurchasing.planned_delivery[i] = (response.data.data.part_purchasing[i].planned_delivery == null) ? '' : response.data.data.part_purchasing[i].planned_delivery
							context.prcMcrItemPurchasing.del_flag[i] = (response.data.data.part_purchasing[i].del_flag == null) ? '' : response.data.data.part_purchasing[i].del_flag
							context.prcMcrItemPurchasing.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						context.purchasingGroup = arrayPurchasing;

						// GET COSTING

						context.prcMcrItemCosting.plant_costing = [];
						context.prcMcrItemCosting.overhead_group = [];
						context.prcMcrItemCosting.costing_lot_size = [];
						context.prcMcrItemCosting.status = [];
						context.prcMcrItemCosting.del_flag = [];

						for (var i = 0; i < response.data.data.part_costing.length; i++) {
							context.prcMcrItemCosting.plant_costing[i] = (response.data.data.part_costing[i].plant_code == null) ? '' : response.data.data.part_costing[i].plant_code
							context.prcMcrItemCosting.overhead_group[i] = (response.data.data.part_costing[i].overhead_group == null) ? '' : response.data.data.part_costing[i].overhead_group
							context.prcMcrItemCosting.costing_lot_size[i] = (response.data.data.part_costing[i].costing_lot_size == null) ? '' : response.data.data.part_costing[i].costing_lot_size
							context.prcMcrItemCosting.del_flag[i] = (response.data.data.part_costing[i].del_flag == null) ? '' : response.data.data.part_costing[i].del_flag
							context.prcMcrItemCosting.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET QM

						context.prcMcrItemQm.plant_qm = [];
						context.prcMcrItemQm.inspection_type = [];
						context.prcMcrItemQm.qm_material_auth = [];
						context.prcMcrItemQm.status = [];

						for (var i = 0; i < response.data.data.part_qm.length; i++) {
							context.prcMcrItemQm.plant_qm[i] = (response.data.data.part_qm[i].plant_code == null) ? '' : response.data.data.part_qm[i].plant_code
							context.prcMcrItemQm.inspection_type[i] = (response.data.data.part_qm[i].inspection_type == null) ? '' : response.data.data.part_qm[i].inspection_type
							context.prcMcrItemQm.qm_material_auth[i] = (response.data.data.part_qm[i].qm_material_auth == null) ? '' : response.data.data.part_qm[i].qm_material_auth
							context.prcMcrItemQm.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET EQUIPMENT

						context.prcMcrItemEquipment.plant_equipment_code = []; 
						context.prcMcrItemEquipment.equipment_code = []; 
						context.prcMcrItemEquipment.old_equipment_code = []; 
						context.prcMcrItemEquipment.qty_installed = []; 
						context.prcMcrItemEquipment.drawing_number = []; 
						context.prcMcrItemEquipment.status = []; 
						context.prcMcrItemEquipment.del_flag = []; 
						context.prcMcrItemEquipment.show = []; 
						context.drawingAttachment = [];

						for (var i = 0; i < response.data.data.part_equipment_code.length; i++) {
							context.prcMcrItemEquipment.plant_equipment_code[i] = (response.data.data.part_equipment_code[i].plant_code == null) ? '' : response.data.data.part_equipment_code[i].plant_code
							context.prcMcrItemEquipment.equipment_code[i] = (response.data.data.part_equipment_code[i].equipment_code == null) ? '' : response.data.data.part_equipment_code[i].equipment_code
							context.prcMcrItemEquipment.old_equipment_code[i] = (response.data.data.part_equipment_code[i].equipment_code == null) ? '' : response.data.data.part_equipment_code[i].equipment_code
							context.prcMcrItemEquipment.qty_installed[i] = (response.data.data.part_equipment_code[i].qty_installed == null) ? '' : response.data.data.part_equipment_code[i].qty_installed
							context.prcMcrItemEquipment.drawing_number[i] = (response.data.data.part_equipment_code[i].drawing_number == null) ? '' : response.data.data.part_equipment_code[i].drawing_number
							context.prcMcrItemEquipment.del_flag[i] = (response.data.data.part_equipment_code[i].del_flag == null) ? '' : response.data.data.part_equipment_code[i].del_flag
							context.prcMcrItemEquipment.show[i] = response.data.data.part_equipment_code[i].show
							context.prcMcrItemEquipment.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
							context.drawingAttachment[i] = response.data.data.part_equipment_code[i].file_name;

							// for po text
							context.prcMcrItemPoText.equipment = (response.data.data.part_equipment_code == null) ? '' : response.data.data.part_equipment_code
						}

						// PRC MCR ITEM WM 1\
						context.warehouseManagement1.warehouse_number = []
						context.warehouseManagement1.stock_removal = []
						context.warehouseManagement1.stock_replacement = []
						context.warehouseManagement1.storage_section_indicator = []
						context.warehouseManagement1.status = []
						context.warehouseNumbers = [];
						context.stockRemovals = []
						context.stockReplacements = []
						context.storageSections = []
						
						for (var i = 0; i < response.data.data.part_warehouse_management_1.length; i++) {
							context.warehouseNumbers.push({
								warehouse_number : response.data.data.part_warehouse_management_1[i].warehouse_number,
								warehouse_description : response.data.data.part_warehouse_management_1[i].warehouse_description
							})
							context.dependWarehouseNumber1(i, response.data.data.part_warehouse_management_1[i].warehouse_number)

							context.warehouseManagement1.warehouse_number[i] = (response.data.data.part_warehouse_management_1[i].warehouse_number == null) ? '' : response.data.data.part_warehouse_management_1[i].warehouse_number;
							context.warehouseManagement1.stock_removal[i] = (response.data.data.part_warehouse_management_1[i].stock_removal == null) ? '' : response.data.data.part_warehouse_management_1[i].stock_removal;
							context.warehouseManagement1.stock_replacement[i] = (response.data.data.part_warehouse_management_1[i].stock_replacement == null) ? '' : response.data.data.part_warehouse_management_1[i].stock_replacement;
							context.warehouseManagement1.storage_section_indicator[i] = (response.data.data.part_warehouse_management_1[i].storage_section_indicator == null) ? '' : response.data.data.part_warehouse_management_1[i].storage_section_indicator;
							context.warehouseManagement1.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// PRC MCR ITEM WM 2
						context.warehouseManagement2.warehouse_number = [];
						context.warehouseManagement2.storage_type_indicator = [];
						context.warehouseManagement2.storage_bin = [];
						context.warehouseManagement2.status = [];
						context.storageTypes= [];
						context.storageBins = [];
						

						for (var i = 0; i < response.data.data.part_warehouse_management_2.length; i++) {
							context.dependWarehouseNumber2(i, response.data.data.part_warehouse_management_2[i].warehouse_number)
							context.dependStorageType(i, response.data.data.part_warehouse_management_2[i].warehouse_number, response.data.data.part_warehouse_management_2[i].storage_type_indicator)
							context.warehouseManagement2.warehouse_number[i] = (response.data.data.part_warehouse_management_2[i].warehouse_number == null) ? '' : response.data.data.part_warehouse_management_2[i].warehouse_number;
							context.warehouseManagement2.storage_type_indicator[i] = (response.data.data.part_warehouse_management_2[i].storage_type_indicator == null) ? '' : response.data.data.part_warehouse_management_2[i].storage_type_indicator;
							context.warehouseManagement2.storage_bin[i] = (response.data.data.part_warehouse_management_2[i].storage_bin == null) ? '' : response.data.data.part_warehouse_management_2[i].storage_bin;
							context.warehouseManagement2.status[i] = (context.mcrType == 'B2') ? 'new' : 'old';
						}

						// GET KEYWORD

						context.prcMcrItemKeyword.keyword = [];

						for (var i = 0; i < response.data.data.part_keyword.length; i++) {
							context.prcMcrItemKeyword.keyword[i] = (response.data.data.part_keyword[i].keyword == null) ? '' : response.data.data.part_keyword[i].keyword
						}
					}

					// GET ATTACHMENT

					context.prcMcrItemAttachment.file_attachment_code = [];
					context.prcMcrItemAttachment.description = [];

                  	for (var i = 0; i < response.data.data.part_attachment.length; i++) {
						context.prcMcrItemAttachment.file_attachment_code[i] = (response.data.data.part_attachment[i].file_attachment_code == null) ? '' : response.data.data.part_attachment[i].file_attachment_code
						context.prcMcrItemAttachment.description[i] = (response.data.data.part_attachment[i].attachment_description == null) ? '' : response.data.data.part_attachment[i].attachment_description
					}

					// GET UOM CONVERSION

					context.prcMcrItemUomConversion.x = [];
					context.prcMcrItemUomConversion.y = [];
					context.prcMcrItemUomConversion.alternative_unit = [];
					context.prcMcrItemUomConversion.del_flag = [];

					for (var i = 0; i < response.data.data.part_uom_conversion.length; i++) {
						context.prcMcrItemUomConversion.x[i] = (response.data.data.part_uom_conversion[i].x == null) ? '' : response.data.data.part_uom_conversion[i].x
						context.prcMcrItemUomConversion.y[i] = (response.data.data.part_uom_conversion[i].y == null) ? '' : response.data.data.part_uom_conversion[i].y
						context.prcMcrItemUomConversion.alternative_unit[i] = (response.data.data.part_uom_conversion[i].alternative_unit == null) ? '' : response.data.data.part_uom_conversion[i].alternative_unit
						context.prcMcrItemUomConversion.del_flag[i] = (response.data.data.part_uom_conversion[i].del_flag == null) ? '' : response.data.data.part_uom_conversion[i].del_flag
					}
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialNumber = [];
                    }
                }).onFinish(function() {
                	context.requestCalls--;
					context.modalCopyAllValue = false
					context.materialNumberCopy = ''
                }).call()
            },
            getMaterialType() {
            	let context = this;
            	
            	Api(context, materialType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.materialType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.materialType = [];
                    }
                })
                .call()
            },
            getValuationClassByAccCatRef() {
            	let context = this;

		    	Api(context, valuationClass.getByAccCatRef(this.input.prc_mtype_code))
		        .onSuccess(function(response) {
					context.valuationClass = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.valuationClass = [];
		          }
		        })
		        .call();
            },
            getValuationTypeByAccCatRef() {
            	let context = this;

            	Api(context, valuationType.getByAccCatRef(this.input.prc_mtype_code))
		        .onSuccess(function(response) {
					context.valuationType = response.data.data;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.valuationType = [];
		          }
		        })
		        .call();
            },
            changePriceControl() {
            	let context = this;

            	Api(context, materialType.getByMtypeCode(context.input.prc_mtype_code)).onSuccess(function(response) {   
					context.priceControlByMtype = response.data.data[0].price_control_code;
					
                    context.prcMcrItemAccounting.price_control.forEach(function(item, index) {
                    	context.prcMcrItemAccounting.price_control[index] = response.data.data[0].price_control_code;
                    })
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        
                    }
                })
                .call() 
            },
     		dependOnPlant() {
     			if (this.prcMcrItemPlant.plant.length > 0) {
     				if (this.prcMcrItemPlant.plant[0] != '') {

		     			let context = this;

		     			// GET MRP CONTROLLER
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							if (item != '') {
								Api(context, mrpController.getByPlant(item)).onSuccess(function(response) {
									context.mrpController[item] = response.data.data;
									context.$forceUpdate()
								})
								.call();
							}
		     			})

		     			// GET LOCATION
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							if (item != '') {
								Api(context, locationCode.getByPlant(item)).onSuccess(function(response) {
									context.locationCode[item] = response.data.data;
									context.$forceUpdate()
								})
								.call();
							}
		     			})

		     			// GET SPECIAL PROCUREMENT TYPE
		     			context.prcMcrItemPlant.plant.forEach(function(item) {
							if (item != '') {
								Api(context, specialProcurementType.getByPlant(item)).onSuccess(function(response) {
									context.specialProcurementType[item] = response.data.data;
									context.$forceUpdate()
								})
								.call();
							}
		     			})
     				}
     			}
     		},
			changeValuationCategory(key) {
				if(this.prcMcrItemAccounting.valuation_category[key] == 'S') {
					this.prcMcrItemAccounting.valuation_type[key] = 'ZORI'
				} else {
					this.prcMcrItemAccounting.valuation_type[key] = ''
				}
			},
            getParameterMcr() {
            	this.requestCalls++;

		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
					context.itemType            = response.data.data.item_type;
					context.fabNonFab           = response.data.data.fab_non_fab;
					context.bomNonBom           = response.data.data.bom_non_bom;
					context.criticalNonCritical = response.data.data.critical_non_critical;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
		    },
            getManrefType() {
                let context = this;               
                Api(context, manrefType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                })
                .call()
            },
            getSourceType() {
            	let context = this;               
                Api(context, sourceType.get(null,{per_page: 'none'})).onSuccess(function(response) {   
                    context.sourceType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.sourceType = [];
                    }
                })
                .call()
            },
            getOverheadGroup() {
          		let data = {
          			'0': {
          				code: 'SAP1',
	          			description: 'Overhead Group 1'
          			},
          			'1': {
          				code: 'SAP2',
	          			description: 'Overhead Group 2'
          			},
          		}

          		this.overheadGroup = data;
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes() {
                let context = this;               
                Api(context, mrpTypes.get(null,{per_page: 'none', query: 'form-b'})).onSuccess(function(response) {    
                    context.mrpTypes = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.mrpTypes = [];
                    }
                })
                .call()
            },
            getValuationCategory() {
                let context = this;               
                Api(context, valuationCategory.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.valuationCategory = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.valuationCategory = [];
                    }
                })
                .call()
            },
            // getValuationType() {
            //     let context = this;               
            //     Api(context, valuationType.get(null,{per_page: 'none'})).onSuccess(function(response) {    
            //         context.valuationType = response.data.data.data.data;
            //     }).onError(function(error) {                    
            //         if (error.response.status == 404) {
            //             context.valuationType = [];
            //         }
            //     })
            //     .call()
            // },
            getPriceControl() {
                let context = this;               
                Api(context, priceControl.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.priceControl = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.priceControl = [];
                    }
                })
                .call()
            },
            getSalesOrganization() {
                let context = this;               
                Api(context, salesOrganization.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.salesOrganization = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.salesOrganization = [];
                    }
                })
                .call()
            },
            getDistributionChannel() {
                let context = this;               
                Api(context, distributionChannel.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.distributionChannel = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.distributionChannel = [];
                    }
                })
                .call()
            },
            getDivision() {
                let context = this;               
                Api(context, division.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.division = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.division = [];
                    }
                })
                .call()
            },
            getTaxClassification() {
                let context = this;               
                Api(context, taxClassification.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.taxClassification = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.taxClassification = [];
                    }
                })
                .call()
            },
            getItemCategoryGroup() {
                let context = this;               
                Api(context, itemCategoryGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.itemCategoryGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.itemCategoryGroup = [];
                    }
                })
                .call()
            },
            getAccountAssignmentGroup() {
                let context = this;               
                Api(context, accountAssignmentGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.accountAssignmentGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.accountAssignmentGroup = [];
                    }
                })
                .call()
            },
            getTransportationGroup() {
                let context = this;               
                Api(context, transportationGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.transportationGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.transportationGroup = [];
                    }
                })
                .call()
            },
            getLoadingGroup() {
                let context = this;               
                Api(context, loadingGroup.get(null,{per_page: 'none'})).onSuccess(function(response) {    
                    context.loadingGroup = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.loadingGroup = [];
                    }
                })
                .call()
            },
            getLotSize() {
                let context = this;               
                Api(context, lotSize.get(null,{query:'form-b'})).onSuccess(function(response) {    
                    context.lotSize = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.lotSize = [];
                    }
                })
                .call()
            },
            getProcurementType() {
                let context = this;               
                Api(context, procurementType.get()).onSuccess(function(response) {    
                    context.procurementType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.procurementType = [];
                    }
                })
                .call()
            },
            getInspectionType() {
                let context = this;               
                Api(context, inspectionType.get()).onSuccess(function(response) {    
                    context.inspectionType = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inspectionType = [];
                    }
                })
                .call()
            },
            getQmMaterialAuth() {
                let context = this;               
                Api(context, qmMaterialAuth.get()).onSuccess(function(response) {    
                    context.qmMaterialAuth = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.qmMaterialAuth = [];
                    }
                })
                .call()
            },
            addPurchasing() {
            	this.accordion.purchasing.show = "show";

                if (this.prcMcrItemPurchasing.plant_purchasing.length == 0) {
                    this.prcMcrItemPurchasing.plant_purchasing.push('')
                    this.prcMcrItemPurchasing.purchasing_group.push('')
                    this.prcMcrItemPurchasing.planned_delivery.push('')
                    this.prcMcrItemPurchasing.status.push('new')
                    this.prcMcrItemPurchasing.del_flag.push('')
                } else if (this.prcMcrItemPurchasing.plant_purchasing.length > 0) {
                    if (this.prcMcrItemPurchasing.plant_purchasing[this.prcMcrItemPurchasing.plant_purchasing.length-1] != '' &&
                        this.prcMcrItemPurchasing.purchasing_group[this.prcMcrItemPurchasing.purchasing_group.length-1] != '') {
                        
                        this.prcMcrItemPurchasing.plant_purchasing.push('')
                        this.prcMcrItemPurchasing.purchasing_group.push('')
                        this.prcMcrItemPurchasing.planned_delivery.push('')
                        this.prcMcrItemPurchasing.status.push('new')
                        this.prcMcrItemPurchasing.del_flag.push('')
                    } else {
                        this.completeDataNotify('purchasing')
                    }
                }
            },
            removePurchasing(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemPurchasing.plant_purchasing.splice(key, 1)
		                this.prcMcrItemPurchasing.purchasing_group.splice(key, 1)
		                this.prcMcrItemPurchasing.planned_delivery.splice(key, 1)
		                this.prcMcrItemPurchasing.status.splice(key, 1)
		                this.prcMcrItemPurchasing.del_flag.splice(key, 1)
			        }
			    });
            },
            addCosting() {
            	this.accordion.costing.show = "show";

            	if (Object.entries(this.overheadGroup).length === 0) {
            		this.getOverheadGroup();
            	}

                if (this.prcMcrItemCosting.plant_costing.length == 0) {
                    this.prcMcrItemCosting.plant_costing.push('')
                    this.prcMcrItemCosting.overhead_group.push('')
                    this.prcMcrItemCosting.costing_lot_size.push(1)
                    this.prcMcrItemCosting.status.push('new')
                    this.prcMcrItemCosting.del_flag.push('')
                } else if (this.prcMcrItemCosting.plant_costing.length > 0) {
                    if (this.prcMcrItemCosting.plant_costing[this.prcMcrItemCosting.plant_costing.length-1] != '' &&
                        this.prcMcrItemCosting.overhead_group[this.prcMcrItemCosting.overhead_group.length-1] != '') {
                        
                        this.prcMcrItemCosting.plant_costing.push('')
                        this.prcMcrItemCosting.overhead_group.push('')
                        this.prcMcrItemCosting.costing_lot_size.push(1)
                        this.prcMcrItemCosting.status.push('new')
                        this.prcMcrItemCosting.del_flag.push('')
                    } else {
                        this.completeDataNotify('costing')
                    }
                }
            },
            removeCosting(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemCosting.plant_costing.splice(key, 1)
		                this.prcMcrItemCosting.overhead_group.splice(key, 1)
		                this.prcMcrItemCosting.costing_lot_size.splice(key, 1)
		                this.prcMcrItemCosting.status.splice(key, 1)
		                this.prcMcrItemCosting.del_flag.splice(key, 1)
			        }
			    });
            },
            addQm() {
            	this.accordion.quality_management.show = "show";

            	if (Object.entries(this.inspectionType).length === 0) {
            		this.getInspectionType();
            		this.getQmMaterialAuth();
            	}

                if (this.prcMcrItemQm.plant_qm.length == 0) {
                    this.prcMcrItemQm.plant_qm.push('')
                    this.prcMcrItemQm.inspection_type.push('')
                    this.prcMcrItemQm.qm_material_auth.push('')
                    this.prcMcrItemQm.status.push('new')
                } else if (this.prcMcrItemQm.plant_qm.length > 0) {
                    if (this.prcMcrItemQm.plant_qm[this.prcMcrItemQm.plant_qm.length-1] != '') {
                        
                        this.prcMcrItemQm.plant_qm.push('')
                        this.prcMcrItemQm.inspection_type.push('')
                        this.prcMcrItemQm.qm_material_auth.push('')
                        this.prcMcrItemQm.status.push('new')
                    } else {
                        this.completeDataNotify('quality_management')
                    }
                }
            },
            removeQm(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemQm.plant_qm.splice(key, 1)
		                this.prcMcrItemQm.inspection_type.splice(key, 1)
		                this.prcMcrItemQm.qm_material_auth.splice(key, 1)
		                this.prcMcrItemQm.status.splice(key, 1)
			        }
			    });
            },
            addMpn() {
            	this.accordion.manref.show = "show";

            	if (Object.entries(this.masterManufacture).length === 0 || Object.entries(this.manrefType).length === 0) {
		            this.getManrefType();
		            this.getSourceType();
            	}

                if (this.prcMcrItemMpn.manufacture_code.length == 0) {
                    this.prcMcrItemMpn.manufacture_code.push('')
                    this.prcMcrItemMpn.mpn.push('')
                    this.prcMcrItemMpn.position.push('')
                    this.prcMcrItemMpn.source_type.push('')
                    this.prcMcrItemMpn.manufacture_type.push('')
                    this.prcMcrItemMpn.manufacture_note.push('')
                    this.prcMcrItemMpn.shortdes.push(false)
                    this.prcMcrItemMpn.status.push('new');
                } else if (this.prcMcrItemMpn.manufacture_code.length > 0) {
                    if (this.prcMcrItemMpn.manufacture_code[this.prcMcrItemMpn.manufacture_code.length-1] != '' &&
                        this.prcMcrItemMpn.mpn[this.prcMcrItemMpn.mpn.length-1] != '' &&
                        this.prcMcrItemMpn.source_type[this.prcMcrItemMpn.source_type.length-1] != '' &&
                        this.prcMcrItemMpn.manufacture_type[this.prcMcrItemMpn.manufacture_type.length-1] != '') {

						if (this.prcMcrItemMpn.source_type[this.prcMcrItemMpn.source_type.length - 1] == "DWG" && this.prcMcrItemMpn.position[this.prcMcrItemMpn.position.length - 1] == "") {
							this.completeDataNotify('mpn')
							return false;
						}
                        
                        this.prcMcrItemMpn.manufacture_code.push('')
                        this.prcMcrItemMpn.mpn.push('')
                        this.prcMcrItemMpn.position.push('')
                        this.prcMcrItemMpn.source_type.push('')
                        this.prcMcrItemMpn.manufacture_type.push('')
                        this.prcMcrItemMpn.manufacture_note.push('')
						this.prcMcrItemMpn.shortdes.push(false)
                        this.prcMcrItemMpn.status.push('new');
                    } else {
                        this.completeDataNotify('mpn')
                    }
                }
            },
            removeMpn(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemMpn.manufacture_code.splice(key, 1)
		                this.prcMcrItemMpn.mpn.splice(key, 1)
		                this.prcMcrItemMpn.position.splice(key, 1)
		                this.prcMcrItemMpn.source_type.splice(key, 1)
		                this.prcMcrItemMpn.manufacture_type.splice(key, 1)
		                this.prcMcrItemMpn.manufacture_note.splice(key, 1)
		                this.prcMcrItemMpn.shortdes.splice(key, 1)
		                this.prcMcrItemMpn.status.splice(key, 1)
			        }
			    });
            },
            addEquipment() {
            	this.accordion.equipment.show = "show";

                if (this.prcMcrItemEquipment.plant_equipment_code.length == 0) {
                    this.prcMcrItemEquipment.plant_equipment_code.push('')
                    this.prcMcrItemEquipment.equipment_code.push('')
					this.prcMcrItemEquipment.old_equipment_code.push('')
					this.prcMcrItemEquipment.equipment_code_stored_in_db.push('')
					this.prcMcrItemEquipment.code_shp_stored_in_db.push('')
					this.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db.push('')
                    this.prcMcrItemEquipment.qty_installed.push('')
                    this.prcMcrItemEquipment.drawing_number.push('')
                    this.prcMcrItemEquipment.status.push('new')
                    this.prcMcrItemEquipment.del_flag.push('')
                    this.prcMcrItemEquipment.code.push('')
                    this.prcMcrItemEquipment.show.push(false)
                    this.prcMcrItemEquipment.save_process.push('unsave')

					this.addValuesOfShpForm(0, '')
                } else if (this.prcMcrItemEquipment.plant_equipment_code.length > 0) {
                    if (this.prcMcrItemEquipment.plant_equipment_code[this.prcMcrItemEquipment.plant_equipment_code.length-1] != '' &&
                        this.prcMcrItemEquipment.equipment_code[this.prcMcrItemEquipment.equipment_code.length-1] != '' &&
                        this.prcMcrItemEquipment.qty_installed[this.prcMcrItemEquipment.qty_installed.length-1] != '' &&
                        this.prcMcrItemEquipment.drawing_number[this.prcMcrItemEquipment.drawing_number.length-1] != '') {
                        
                        this.prcMcrItemEquipment.plant_equipment_code.push('')
                        this.prcMcrItemEquipment.equipment_code.push('')
						this.prcMcrItemEquipment.old_equipment_code.push('')
						this.prcMcrItemEquipment.equipment_code_stored_in_db.push('')
						this.prcMcrItemEquipment.code_shp_stored_in_db.push('')
						this.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db.push('')
                        this.prcMcrItemEquipment.qty_installed.push('')
                        this.prcMcrItemEquipment.drawing_number.push('')
                        this.prcMcrItemEquipment.status.push('new')
                        this.prcMcrItemEquipment.del_flag.push('')
                        this.prcMcrItemEquipment.code.push('')
                        this.prcMcrItemEquipment.show.push(false)
						this.prcMcrItemEquipment.save_process.push('unsave')

						this.addValuesOfShpForm(this.prcMcrItemEquipment.equipment_code.length - 1, '')
                    } else {
                        this.completeDataNotify('equipment')
                    }
                }
            },
			addValuesOfShpForm(key, code){
				let context = this
				this.$set(this.valuesOfShpForm, key, { 
					input: {
						id: context.input.id,
						code: code,
						material_number: '',
						shp_failure_mode: [],
						shp_qty: '',
						shp_uom: context.input.prc_uom_code,
						shp_mce_strategy: {
							mce_strategy: [],
							mce_interval: [],
							mce_net_pf: [],
							mce_tindakan: []
						},
						shp_effect_spare_part_not_available: '',
						shp_demand_rate: '',
						shp_demand_rate_unit: '',
						shp_action_to_handle: '',
						shp_lead_time_action_to_handle: '',
						shp_consequences_criticality: '',
						shp_owner_estimate: context.prcMcrItemAccounting.price_reference[0] ?? 0,
						shp_estimated_loss_idr: '',
						shp_estimated_loss_day: '',
						shp_total_estimated_loss: '',
						shp_estimated_lead_time: '',
						shp_category_lead_time: '',
						shp_abc_indicator: '',
						shp_unit_price: '',
						shp_quantity_on_hand: '0',
						shp_value_unrestricted: '',
						shp_change_mrp_type: '',
						shp_resume_justification_stock_strategy: '',
						shp_equipment: Number.isInteger(key) ? "" : key,
						shp_original_stock_strategy: '',
						shp_stock_strategy: '',
						shp_original_stock_level_type_calculation: '',
						shp_stock_level_type_calculation: '',
						shp_original_service_level: '',
						shp_service_level: '',
						shp_original_stock_level: '',
						shp_stock_level: '',
						shp_original_min: '',
						shp_min: '',
						shp_original_max: '',
						shp_max: '',
						shp_date_during_analysis: '',
					},
					equipments: [],						
					materialNumbers: [], 
					manufacturePartNumber: {
						manufacture_code: [],
						manufacture_code_view: [],
						mpn: [],
						source_type: [],
						position: [],
						manufacture_type: [],
						manufacture_note: []
					},
					changeLog: []
				});
			},
            removeEquipment(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemEquipment.plant_equipment_code.splice(key, 1)
		                this.prcMcrItemEquipment.equipment_code.splice(key, 1)
						this.prcMcrItemEquipment.old_equipment_code.splice(key, 1)
						this.prcMcrItemEquipment.equipment_code_stored_in_db.splice(key, 1)
						this.prcMcrItemEquipment.code_shp_stored_in_db.splice(key, 1)
						this.prcMcrItemEquipment.esti_lead_time_shp_stored_in_db.splice(key, 1)
		                this.prcMcrItemEquipment.qty_installed.splice(key, 1)
		                this.prcMcrItemEquipment.drawing_number.splice(key, 1)
		                this.prcMcrItemEquipment.status.splice(key, 1)
		                this.prcMcrItemEquipment.del_flag.splice(key, 1)
		                this.prcMcrItemEquipment.show.splice(key, 1)
		                this.prcMcrItemEquipment.code.splice(key, 1)
		                this.prcMcrItemEquipment.save_process.splice(key, 1)

						this.valuesOfShpForm.splice(key, 1)
			        }
			    });         
            },
			setSelectedEqpCodeIntoShpForm(equipment, key){
				// PASANG SELECTED EQP TO SHP FORM
				if (!this.prcMcrItemEquipment.old_equipment_code[key]) {
					//BLOCK INI UNTUK EQP QTY INSTALL BARU
					//JIKA EQUIPMENT BARU KEY valuesOfShpForm NYA ADALAH ANGKA KEY ARRAY
					this.changeEqpsAndShpEqpAndKeyValOfShpForm(equipment, key, key)

				} else if  (equipment !== this.prcMcrItemEquipment.old_equipment_code[key]) {
					//BLOCK INI UNTUK EQP QTY INSTALL YG SEBELUMNYA SUDAH ADA
					//JIKA EQUIPMENT LAMA KEY valuesOfShpForm NYA SUDAH EQUIPMENT CODE
					this.changeEqpsAndShpEqpAndKeyValOfShpForm(equipment, this.prcMcrItemEquipment.old_equipment_code[key], key)
				}
			},
			changeEqpsAndShpEqpAndKeyValOfShpForm(paramNewShp, paramOldShp, paramForOldEqpCode){
				if(this.valuesOfShpForm[paramOldShp]) {

					// PASANG SELECTED EQP TO SHP VALUE
					const selectedEqpForObjShpVal = this.equipmentCode.find(ecode => ecode.equipment_code === paramNewShp);
					if (selectedEqpForObjShpVal) {

						this.$set(this.valuesOfShpForm[paramOldShp], 'equipments', [{
							equipment_code: selectedEqpForObjShpVal.equipment_code,
							equipment_name: selectedEqpForObjShpVal.equipment_name,
						}]);

						// MENGGANTIKAN KEY (INTEGER) PADA valueOfShpForm YANG TELAH DIBUAT DI AWAL MENJADI EQUIPMENT CODE
						// DAN MEMASSANG VALUE KEY PADA VALUE SHP_EQUIPMENT
						this.$set(this.valuesOfShpForm[paramOldShp].input, 'shp_equipment', paramNewShp);
						this.$set(this.valuesOfShpForm, paramNewShp, this.valuesOfShpForm[paramOldShp]);

						// DELETE PARAM TWO
						this.$delete(this.valuesOfShpForm, paramOldShp);

						//CHANGE IN OLD EQP CODE
						this.prcMcrItemEquipment.old_equipment_code[paramForOldEqpCode] = paramNewShp
					}
				}
			},
			getMcrFormShp(code, key, id){
				let context = this
				let query = {code: code}

				if (code) {
					if (!context.valuesOfShpForm[key]) {
						context.$set(context.valuesOfShpForm, key, { 
							input: {
								shp_mce_strategy: {
									mce_strategy: [],
									mce_interval: [],
									mce_net_pf: [],
									mce_tindakan: []
								},
								shp_failure_mode: [],
							}, 
							equipments: {},
							materialNumbers: [],
							manufacturePartNumber: {
								manufacture_code: [],
								manufacture_code_view: [],
								mpn: [],
								source_type: [],
								position: [],
								manufacture_type: [],
								manufacture_note: []
							},
							changeLog:[],
						})
					}

					Api(context, draftList.shp_mcr_item(query)).onSuccess(function(response) {
						context.valuesOfShpForm[key].input.id     									 = response.data.data.mcr_item_id != null ? response.data.data.mcr_item_id : ''
						context.valuesOfShpForm[key].input.code     								 = code
						context.valuesOfShpForm[key].input.material_number   						 = response.data.data.material_number != null ? response.data.data.material_number : ''
						if(response.data.data.shp_failure_mode) {
							let failureModes = JSON.parse(response.data.data.shp_failure_mode)
							context.valuesOfShpForm[key].input.shp_failure_mode = failureModes
						} else {
							context.valuesOfShpForm[key].input.shp_failure_mode = []
						}
						context.valuesOfShpForm[key].input.shp_qty  								 = response.data.data.shp_qty != null ? response.data.data.shp_qty : ''
						context.valuesOfShpForm[key].input.shp_uom  								 = response.data.data.shp_uom != null ? response.data.data.shp_uom : context.input.uom_code
						if(response.data.data.shp_mce_strategy){
							let mceStrategies = JSON.parse(response.data.data.shp_mce_strategy);
							let mceIntervals = JSON.parse(response.data.data.shp_interval);
							let mceNetPf = JSON.parse(response.data.data.shp_net_pf);
							let mceTindakan = JSON.parse(response.data.data.shp_tindakan);

							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy = mceStrategies
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval = mceIntervals
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_net_pf = mceNetPf
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan = mceTindakan
						} else {
							context.valuesOfShpForm[key].input.shp_mce_strategy = {
								mce_strategy: [],
								mce_interval: [],
								mce_net_pf: [],
								mce_tindakan: []
							};
						}
						context.valuesOfShpForm[key].input.shp_effect_spare_part_not_available  	 = response.data.data.shp_effect_spare_part_not_available != null ? response.data.data.shp_effect_spare_part_not_available : ''
						context.valuesOfShpForm[key].input.shp_demand_rate 							 = response.data.data.shp_demand_rate != null ? response.data.data.shp_demand_rate : ''
						context.valuesOfShpForm[key].input.shp_demand_rate_unit 					 = response.data.data.shp_demand_rate_unit != null ? response.data.data.shp_demand_rate_unit : ''
						context.valuesOfShpForm[key].input.shp_action_to_handle  					 = response.data.data.shp_action_to_handle != null ? response.data.data.shp_action_to_handle : ''
						context.valuesOfShpForm[key].input.shp_lead_time_action_to_handle  			 = response.data.data.shp_lead_time_action_to_handle != null ? response.data.data.shp_lead_time_action_to_handle : ''
						context.valuesOfShpForm[key].input.shp_consequences_criticality  			 = response.data.data.shp_consequences_criticality != null ? response.data.data.shp_consequences_criticality : ''
						context.valuesOfShpForm[key].input.shp_owner_estimate						 = response.data.data.shp_owner_estimate != null ? parseInt(response.data.data.shp_owner_estimate) : parseInt(context.input.price_reference)
						context.valuesOfShpForm[key].input.shp_estimated_loss_idr  					 = response.data.data.shp_estimated_loss_idr != null ? response.data.data.shp_estimated_loss_idr : ''
						context.valuesOfShpForm[key].input.shp_estimated_loss_day  					 = response.data.data.shp_estimated_loss_day != null ? response.data.data.shp_estimated_loss_day : ''
						context.valuesOfShpForm[key].input.shp_total_estimated_loss  				 = response.data.data.shp_total_estimated_loss != null ? response.data.data.shp_total_estimated_loss : ''
						context.valuesOfShpForm[key].input.shp_estimated_lead_time  				 = response.data.data.shp_estimated_lead_time != null ? response.data.data.shp_estimated_lead_time : ''
						context.valuesOfShpForm[key].input.shp_category_lead_time  					 = response.data.data.shp_category_lead_time != null ? response.data.data.shp_category_lead_time : ''
						context.valuesOfShpForm[key].input.shp_abc_indicator  						 = response.data.data.shp_abc_indicator != null ? response.data.data.shp_abc_indicator : ''
						context.valuesOfShpForm[key].input.shp_unit_price  							 = response.data.data.shp_unit_price != null ? response.data.data.shp_unit_price : ''
						context.valuesOfShpForm[key].input.shp_quantity_on_hand  					 = response.data.data.shp_quantity_on_hand != null ? response.data.data.shp_quantity_on_hand : '0'
						context.valuesOfShpForm[key].input.shp_value_unrestricted  					 = response.data.data.shp_value_unrestricted != null ? response.data.data.shp_value_unrestricted : ''
						context.valuesOfShpForm[key].input.shp_change_mrp_type  					 = response.data.data.shp_change_mrp_type != null ? response.data.data.shp_change_mrp_type : ''
						context.valuesOfShpForm[key].input.shp_resume_justification_stock_strategy   = response.data.data.shp_resume_justification_stock_strategy != null ? response.data.data.shp_resume_justification_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_equipment  							 = response.data.data.equipment_code != null ? response.data.data.equipment_code : ''
						context.valuesOfShpForm[key].input.shp_original_stock_strategy  			 = response.data.data.shp_original_stock_strategy  != null ? response.data.data.shp_original_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_stock_strategy  						 = response.data.data.shp_stock_strategy  != null ? response.data.data.shp_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_original_stock_level_type_calculation = response.data.data.shp_original_stock_level_type_calculation != null ? response.data.data.shp_original_stock_level_type_calculation : ''
						context.valuesOfShpForm[key].input.shp_stock_level_type_calculation 		 = response.data.data.shp_stock_level_type_calculation != null ? response.data.data.shp_stock_level_type_calculation : ''
						context.valuesOfShpForm[key].input.shp_original_service_level  				 = response.data.data.shp_original_service_level != null ? response.data.data.shp_original_service_level : ''
						context.valuesOfShpForm[key].input.shp_service_level  						 = response.data.data.shp_service_level != null ? response.data.data.shp_service_level : ''
						context.valuesOfShpForm[key].input.shp_original_stock_level  				 = response.data.data.shp_original_stock_level != null ? response.data.data.shp_original_stock_level : ''
						context.valuesOfShpForm[key].input.shp_stock_level  						 = response.data.data.shp_stock_level != null ? response.data.data.shp_stock_level : ''
						context.valuesOfShpForm[key].input.shp_original_min  						 = response.data.data.shp_original_min != null ? response.data.data.shp_original_min : ''
						context.valuesOfShpForm[key].input.shp_min  								 = response.data.data.shp_min != null ? response.data.data.shp_min : ''
						context.valuesOfShpForm[key].input.shp_original_max  						 = response.data.data.shp_original_max != null ? response.data.data.shp_original_max : ''
						context.valuesOfShpForm[key].input.shp_max  								 = response.data.data.shp_max != null ? response.data.data.shp_max : ''
						context.valuesOfShpForm[key].input.shp_date_during_analysis  				 = response.data.data.shp_date_during_analysis != null ? response.data.data.shp_date_during_analysis : ''	

						context.valuesOfShpForm[key].equipments = [{
							equipment_code: response.data.data.equipment_code,
							equipment_name: response.data.data.equipment_name,
						}];

						if(!!context.valuesOfShpForm[key].input.shp_equipment){
							context.matNumsInShpDependEquipment(context.valuesOfShpForm[key].input.shp_equipment, key)
						}

						if(response.data.data.shp_failure_mode) {
							let failureModes = JSON.parse(response.data.data.shp_failure_mode)
							context.valuesOfShpForm[key].input.shp_failure_mode = failureModes
						}

					}).onError(function(error){
						if (error.response.status == 404) {
							// jika error hanya menambahkan object tanpa ada values
							this.addValuesOfShpForm(key, code)	
						}
					}).call()

				} else {
					// jika equipment baru ditambahkan, maka menambahkan object SHP Form tanpa values
					this.addValuesOfShpForm(key, "")
					
					// jika sudah ada equipmentnya di MCR, maka inputkan ke dalam object SHP
					if (context.prcMcrItemEquipment.equipment_code[id]) {
						context.$set(context.valuesOfShpForm[key], 'equipments', [{
							equipment_code: context.prcMcrItemEquipment.equipment_code[id],
							equipment_name: context.prcMcrItemEquipment.equipment_code_view[id].split(" - ")[1],
						}])
					}

				}
			},
			matNumsInShpDependEquipment(eqp, key) {                                   
				let context = this;   
				Api(context, materialNumber.get({search: '', equipment: eqp})).onSuccess(function(response) {
					context.valuesOfShpForm[key].materialNumbers = response.data.data;                        
				}).onError(function(error) {
					context.valuesOfShpForm[key].materialNumbers = [];
				}).call();  
            },
            addAttachment() {
            	this.accordion.attDoc.show = "show";

                if (this.prcMcrItemAttachment.file_attachment_code.length == 0) {
                    this.prcMcrItemAttachment.file_attachment_code.push('')
                    this.prcMcrItemAttachment.description.push('')
                } else if (this.prcMcrItemAttachment.file_attachment_code.length > 0) {
                    if (this.prcMcrItemAttachment.file_attachment_code[this.prcMcrItemAttachment.file_attachment_code.length-1] == '' && this.prcMcrItemAttachment.description[this.prcMcrItemAttachment.description.length-1] == '') {
                        this.completeDataNotify('attachment')
                    } else {
                        this.prcMcrItemAttachment.file_attachment_code.push('')
                        this.prcMcrItemAttachment.description.push('')
                    }
                }
            },
            removeAttachment(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemAttachment.file_attachment_code.splice(key, 1)
			        	this.prcMcrItemAttachment.description.splice(key, 1)
			        }
			    });
            },
            addKeyword() {
            	this.accordion.keyword.show = "show";

                if (this.prcMcrItemKeyword.keyword.length == 0) {
                    this.prcMcrItemKeyword.keyword.push('')
                } else if (this.prcMcrItemKeyword.keyword.length > 0) {
                    if (this.prcMcrItemKeyword.keyword[this.prcMcrItemKeyword.keyword.length-1] == '') {
                        this.completeDataNotify('keyword')
                    } else {
                        this.prcMcrItemKeyword.keyword.push('')
                    }
                }
            },
            removeKeyword(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemKeyword.keyword.splice(key, 1)
			        }
			    });
            },
			addUomConversion() {
				if (this.prcMcrItemUomConversion.x.length == 0) {
                    this.prcMcrItemUomConversion.x.push('')
					this.prcMcrItemUomConversion.y.push('')
					this.prcMcrItemUomConversion.alternative_unit.push('')
					this.prcMcrItemUomConversion.del_flag.push('')
                } else if (this.prcMcrItemUomConversion.x.length > 0) {
                    if (this.prcMcrItemUomConversion.x[this.prcMcrItemUomConversion.x.length-1] == '' &&
						this.prcMcrItemUomConversion.y[this.prcMcrItemUomConversion.y.length-1] == '' &&
						this.prcMcrItemUomConversion.alternative_unit[this.prcMcrItemUomConversion.alternative_unit.length-1] == ''
					) {
                        this.completeDataNotify('uom_conversion')
                    } else {
                        this.prcMcrItemUomConversion.x.push('')
						this.prcMcrItemUomConversion.y.push('')
						this.prcMcrItemUomConversion.alternative_unit.push('')
						this.prcMcrItemUomConversion.del_flag.push('')
                    }
                }
			},
			removeUomConversion(key){
                this.prcMcrItemUomConversion.x.splice(key, 1)
				this.prcMcrItemUomConversion.y.splice(key, 1)
				this.prcMcrItemUomConversion.alternative_unit.splice(key, 1)
				this.prcMcrItemUomConversion.del_flag.splice(key, 1)
            },
            addPlant() {
            	this.accordion.plant.show = "show";

                if (this.prcMcrItemPlant.plant.length == 0) {
                    this.prcMcrItemPlant.plant.push('')
                    this.prcMcrItemPlant.status.push('new')
                    this.prcMcrItemPlant.del_flag.push('')
                } else if (this.prcMcrItemPlant.plant.length > 0) {
                    if (this.prcMcrItemPlant.plant[this.prcMcrItemPlant.plant.length-1] != '') {
                        this.prcMcrItemPlant.plant.push('')
                        this.prcMcrItemPlant.status.push('new')
                        this.prcMcrItemPlant.del_flag.push('')
                    } else {
                        this.completeDataNotify('plant')
                    }
                }
            },
            removePlant(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemPlant.plant.splice(key, 1)
			        	this.prcMcrItemPlant.status.splice(key, 1)
			        	this.prcMcrItemPlant.del_flag.splice(key, 1)
			        }
			    });
            },
            addSloc() {
            	this.accordion.sloc.show = "show";

                if (this.prcMcrItemSloc.plant_code.length == 0) {
                    this.prcMcrItemSloc.plant_code.push('')
                    this.prcMcrItemSloc.storage_location.push('')
                    // this.prcMcrItemSloc.warehouse_number.push('')
                    // this.prcMcrItemSloc.storage_type.push('')
  					this.prcMcrItemSloc.bin_location.push('')
  					this.prcMcrItemSloc.status.push('new');
  					this.prcMcrItemSloc.del_flag.push('');
                } else if (this.prcMcrItemSloc.plant_code.length > 0) {
                    if (this.prcMcrItemSloc.plant_code[this.prcMcrItemSloc.plant_code.length-1] != '' &&
                        this.prcMcrItemSloc.storage_location[this.prcMcrItemSloc.storage_location.length-1] != '') {
                        
                        this.prcMcrItemSloc.plant_code.push('')
                        this.prcMcrItemSloc.storage_location.push('')
                        // this.prcMcrItemSloc.warehouse_number.push('')
                        // this.prcMcrItemSloc.storage_type.push('')
						this.prcMcrItemSloc.bin_location.push('')
						this.prcMcrItemSloc.status.push('new');
						this.prcMcrItemSloc.del_flag.push('');
                    } else {
                        this.completeDataNotify('storage_location')
                    }
                }
            },
            removeSloc(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemSloc.plant_code.splice(key, 1)
		                this.prcMcrItemSloc.storage_location.splice(key, 1)
		                // this.prcMcrItemSloc.warehouse_number.splice(key, 1)
		                // this.prcMcrItemSloc.storage_type.splice(key, 1)   
						this.prcMcrItemSloc.bin_location.splice(key, 1)   
		                this.prcMcrItemSloc.status.splice(key, 1)
		                this.prcMcrItemSloc.del_flag.splice(key, 1)
			        }
			    });
            },
            addMultipleChar(key, cin_code, characteristic, view) {
				this.prcMcrItemCharValue.cin_code.splice(key + 1, 0, cin_code)
				this.prcMcrItemCharValue.characteristic.splice(key + 1, 0, characteristic)
				this.prcMcrItemCharValue.value.splice(key + 1, 0, '')
				this.prcMcrItemCharValue.view.splice(key + 1, 0, view)
				this.prcMcrItemCharValue.multipleChar.splice(key + 1, 0, '')
				this.prcMcrItemCharValue.code.splice(key + 1, 0, 1)
            },
            removeMultipleChar(key) {
				this.prcMcrItemCharValue.cin_code.splice(key, 1)
				this.prcMcrItemCharValue.characteristic.splice(key, 1)
				this.prcMcrItemCharValue.value.splice(key, 1)
				this.prcMcrItemCharValue.view.splice(key, 1)
				this.prcMcrItemCharValue.multipleChar.splice(key, 1)
				this.prcMcrItemCharValue.code.splice(key, 1)
            },
			changePrcItemTypeCode() {
				if (this.input.prc_inc === 'PI001') {
					this.input.prc_item_type_code = 'OEM'
				} else {
					this.input.prc_item_type_code = 'GEN'
				}
			},
            showCharValue() {    
            	this.accordion.charValue.show = "show";

            	let context = this;
                if (context.input.prc_inc !== '' && context.input.prc_item_type_code !== '') {    
                	this.requestCallsCharValue++;
                	this.btnShowCharValue.onLoading = true;

	                Api(context, characteristicItemName.getByType(context.input.prc_inc, context.input.prc_item_type_code)).onSuccess(function(response) {    
	                    // context.characteristicItemName = response.data.data.data;

	                    context.prcMcrItemCharValue.cin_code = [];
	                    context.prcMcrItemCharValue.characteristic = [];
	                    context.prcMcrItemCharValue.value = [];
	                    context.prcMcrItemCharValue.view = [];
	                    context.prcMcrItemCharValue.multipleChar = [];
	                    context.prcMcrItemCharValue.is_mandatory = [];
	                    context.prcMcrItemCharValue.code = [];

	                    for (var i = 0; i < response.data.data.length; i++) {
	                        context.prcMcrItemCharValue.cin_code[i] = response.data.data[i].cin_code;
	                        context.prcMcrItemCharValue.characteristic[i] = response.data.data[i].characteristic;
	                        context.prcMcrItemCharValue.view[i] = true;
	                        context.prcMcrItemCharValue.multipleChar[i] = response.data.data[i].single_or_multiple;
	                        context.prcMcrItemCharValue.is_mandatory[i] = response.data.data[i].is_mandatory;
	                        context.prcMcrItemCharValue.code[i] = '';
	                    }
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        // context.characteristicItemName = [];
	                        context.prcMcrItemCharValue.cin_code = [];
		                    context.prcMcrItemCharValue.characteristic = [];
		                    context.prcMcrItemCharValue.view = [];
		                    context.prcMcrItemCharValue.multipleChar = [];
							context.prcMcrItemCharValue.is_mandatory = [];
		                    context.prcMcrItemCharValue.code = [];
	                    }
	                }).onFinish(function() {
                        context.btnShowCharValue.onLoading = false;   

                        context.requestCallsCharValue--;
                    }).call()
                } else {
                	this.$notify({
                        message: this.tt("please_complete_data", { title: 'Item Name & Item Type' }),                  
                        type: 'danger',
                        verticalAlign: "bottom",
			            horizontalAlign: "left"
                    });
                    return false;
                }        
            },
            previewPoText() {
            	this.previewPoTextModal = true;
            },
            addMrp() {
            	this.accordion.mrp.show = "show";

            	if (Object.entries(this.abcIndicator).length === 0||
            		Object.entries(this.mrpTypes).length === 0 ||
            		Object.entries(this.lotSize).length === 0 ||
            		Object.entries(this.procurementType).length === 0) {

            		this.getAbcIndicator();
		            this.getMrpTypes();
		            this.getLotSize();
		            this.getProcurementType();
            	}

                if (this.prcMcrItemMrp.abc_indicator.length == 0) {
                    this.prcMcrItemMrp.abc_indicator.push('')
                    this.prcMcrItemMrp.mrp_type.push('')
                    this.prcMcrItemMrp.mrp_controller.push('')
                    this.prcMcrItemMrp.reorder_point.push('')
                    this.prcMcrItemMrp.lot_size.push('')
                    this.prcMcrItemMrp.safety_stock.push('')
                    this.prcMcrItemMrp.min.push('')
                    this.prcMcrItemMrp.max.push('')
                    this.prcMcrItemMrp.procurement_type.push('F')
                    this.prcMcrItemMrp.special_proc.push('')
                    this.prcMcrItemMrp.plant_mrp.push('')
					this.prcMcrItemMrp.max_stock.push('')
					this.prcMcrItemMrp.fixed_lot.push('')
					this.prcMcrItemMrp.ord_costs.push('')
					this.prcMcrItemMrp.avail_check.push('')
					this.prcMcrItemMrp.sloc_for_ep.push('')
                    this.prcMcrItemMrp.status.push('new')
                    this.prcMcrItemMrp.del_flag.push('')
                } else if (this.prcMcrItemMrp.abc_indicator.length > 0) {
                    if (this.prcMcrItemMrp.plant_mrp[this.prcMcrItemMrp.plant_mrp.length-1] != '' &&
                        this.prcMcrItemMrp.mrp_type[this.prcMcrItemMrp.mrp_type.length-1] != '' &&
                        this.prcMcrItemMrp.avail_check[this.prcMcrItemMrp.avail_check.length-1] != '') {

						// Condition depend on MRP Type
                    	if (this.prcMcrItemMrp.mrp_type[this.prcMcrItemMrp.mrp_type.length-1] == 'PD') {
	          			    if (this.prcMcrItemMrp.mrp_controller[this.prcMcrItemMrp.mrp_controller.length-1] == '' || this.prcMcrItemMrp.lot_size[this.prcMcrItemMrp.lot_size.length-1] == '') {
	          			    	this.completeDataNotify('mrp_controller_&_lot_size')
								return false;
	          			    }
	              		} else if (this.prcMcrItemMrp.mrp_type[this.prcMcrItemMrp.mrp_type.length-1] == 'V1' || this.prcMcrItemMrp.mrp_type[this.prcMcrItemMrp.mrp_type.length-1] == 'VB') {
	              			if (this.prcMcrItemMrp.mrp_controller[this.prcMcrItemMrp.mrp_controller.length-1] == '' || this.prcMcrItemMrp.lot_size[this.prcMcrItemMrp.lot_size.length-1] == '' || this.prcMcrItemMrp.reorder_point[this.prcMcrItemMrp.reorder_point.length-1] == '' || this.prcMcrItemMrp.max_stock[this.prcMcrItemMrp.max_stock.length-1] == '') {
	              				this.completeDataNotify('mrp_controller_lot_size_reorder_point_&_max_stock')
								return false;
	              			}
	              		}

						if (this.prcMcrItemMrp.lot_size[this.prcMcrItemMrp.lot_size.length-1] == 'FX' && this.prcMcrItemMrp.fixed_lot[this.prcMcrItemMrp.fixed_lot.length-1] == '') {
							this.completeDataNotify('fixed_lot')
							return false;
						}
                        
                        this.prcMcrItemMrp.abc_indicator.push('')
                        this.prcMcrItemMrp.mrp_type.push('')
                        this.prcMcrItemMrp.mrp_controller.push('')
                        this.prcMcrItemMrp.reorder_point.push('')
                        this.prcMcrItemMrp.lot_size.push('')
                        this.prcMcrItemMrp.safety_stock.push('')
                        this.prcMcrItemMrp.min.push('')
                        this.prcMcrItemMrp.max.push('')
                        this.prcMcrItemMrp.procurement_type.push('F')
                        this.prcMcrItemMrp.special_proc.push('')
                        this.prcMcrItemMrp.plant_mrp.push('')
                        this.prcMcrItemMrp.max_stock.push('')
						this.prcMcrItemMrp.fixed_lot.push('')
						this.prcMcrItemMrp.ord_costs.push('')
						this.prcMcrItemMrp.avail_check.push('')
						this.prcMcrItemMrp.sloc_for_ep.push('')
                        this.prcMcrItemMrp.status.push('new')
                        this.prcMcrItemMrp.del_flag.push('')
                    } else {
                        this.completeDataNotify('mrp')
                    }
                }
            },
            removeMrp(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemMrp.abc_indicator.splice(key, 1)
		                this.prcMcrItemMrp.mrp_type.splice(key, 1)
		                this.prcMcrItemMrp.mrp_controller.splice(key, 1)
		                this.prcMcrItemMrp.reorder_point.splice(key, 1)
		                this.prcMcrItemMrp.lot_size.splice(key, 1)
		                this.prcMcrItemMrp.safety_stock.splice(key, 1)
		                this.prcMcrItemMrp.min.splice(key, 1)
		                this.prcMcrItemMrp.max.splice(key, 1)
		                this.prcMcrItemMrp.procurement_type.splice(key, 1)
		                this.prcMcrItemMrp.special_proc.splice(key, 1)
		                this.prcMcrItemMrp.plant_mrp.splice(key, 1)
		                this.prcMcrItemMrp.max_stock.splice(key, 1)
						this.prcMcrItemMrp.fixed_lot.splice(key, 1)
						this.prcMcrItemMrp.ord_costs.splice(key, 1)
						this.prcMcrItemMrp.avail_check.splice(key, 1)
						this.prcMcrItemMrp.sloc_for_ep.splice(key, 1)
						this.prcMcrItemMrp.status.splice(key, 1)
						this.prcMcrItemMrp.del_flag.splice(key, 1)
			        }
			    });
            },
            addAccounting() {
            	this.accordion.accounting.show = "show";

            	if (Object.entries(this.valuationCategory).length === 0) {

		            this.getValuationCategory();
		            // this.getValuationType();
		            this.getPriceControl();
            	}

                if (this.prcMcrItemAccounting.valuation_class.length == 0) {
                    this.prcMcrItemAccounting.valuation_class.push('')
                    this.prcMcrItemAccounting.valuation_category.push((this.input.prc_mtype_code != 'ZSP') ? '' : 'S')
                    this.prcMcrItemAccounting.valuation_type.push('')
                    this.prcMcrItemAccounting.price_control.push(this.priceControlByMtype)
                    this.prcMcrItemAccounting.price_determination.push('')
                    this.prcMcrItemAccounting.standard_price.push(0)
                    this.prcMcrItemAccounting.price_unit.push(1)
                    this.prcMcrItemAccounting.price_reference.push('')
                    this.prcMcrItemAccounting.plant_accounting.push('')
                    this.prcMcrItemAccounting.status.push('new')
                    this.prcMcrItemAccounting.del_flag.push('')
                } else if (this.prcMcrItemAccounting.valuation_class.length > 0) {
                    if (this.prcMcrItemAccounting.plant_accounting[this.prcMcrItemAccounting.plant_accounting.length-1] != '' &&
                        this.prcMcrItemAccounting.valuation_class[this.prcMcrItemAccounting.valuation_class.length-1] != '' &&
                        // this.prcMcrItemAccounting.valuation_category[this.prcMcrItemAccounting.valuation_category.length-1] != '' &&
                        // this.prcMcrItemAccounting.valuation_type[this.prcMcrItemAccounting.valuation_type.length-1] != '' &&
                        this.prcMcrItemAccounting.price_determination[this.prcMcrItemAccounting.price_determination.length-1] != '' &&
                        // this.prcMcrItemAccounting.price_control[this.prcMcrItemAccounting.price_control.length-1] != '' &&
                        // this.prcMcrItemAccounting.standard_price[this.prcMcrItemAccounting.standard_price.length-1] != '' &&
                        this.prcMcrItemAccounting.price_unit[this.prcMcrItemAccounting.price_unit.length-1] != '') {
                        
                        this.prcMcrItemAccounting.valuation_class.push('')
                        this.prcMcrItemAccounting.valuation_category.push((this.input.prc_mtype_code != 'ZSP') ? '' : 'S')
                        this.prcMcrItemAccounting.valuation_type.push('')
                        this.prcMcrItemAccounting.price_control.push(this.priceControlByMtype)
                        this.prcMcrItemAccounting.price_determination.push('')
                        this.prcMcrItemAccounting.standard_price.push(0)
                        this.prcMcrItemAccounting.price_unit.push(1)
                        this.prcMcrItemAccounting.price_reference.push('')
                        this.prcMcrItemAccounting.plant_accounting.push('')
                        this.prcMcrItemAccounting.status.push('new')
                        this.prcMcrItemAccounting.del_flag.push('')
                    } else {
                        this.completeDataNotify('accounting')
                    }
                }
            },
            removeAccounting(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemAccounting.valuation_class.splice(key, 1)
		                this.prcMcrItemAccounting.valuation_category.splice(key, 1)
		                this.prcMcrItemAccounting.valuation_type.splice(key, 1)
		                this.prcMcrItemAccounting.price_control.splice(key, 1)
		                this.prcMcrItemAccounting.price_determination.splice(key, 1)
		                this.prcMcrItemAccounting.standard_price.splice(key, 1)
		                this.prcMcrItemAccounting.price_unit.splice(key, 1)
		                this.prcMcrItemAccounting.price_reference.splice(key, 1)
		                this.prcMcrItemAccounting.plant_accounting.splice(key, 1)
		                this.prcMcrItemAccounting.status.splice(key, 1)
		                this.prcMcrItemAccounting.del_flag.splice(key, 1)
			        }
			    });
            },
			addSales() {
				this.accordion.sales.show = "show";

				if (Object.entries(this.division).length === 0 || 
            		Object.entries(this.itemCategoryGroup).length === 0|| 
            		Object.entries(this.transportationGroup).length === 0) {

		            this.getDivision();
		            this.getItemCategoryGroup();
		            this.getTransportationGroup();
            	}
			},
			addWarehouseManagement1(){
				if(this.warehouseManagement1.warehouse_number.length == 0){
					this.warehouseManagement1.warehouse_number.push('')
					this.warehouseManagement1.stock_removal.push('')
					this.warehouseManagement1.stock_replacement.push('')
					this.warehouseManagement1.storage_section_indicator.push('')
					this.warehouseManagement1.status.push('new')
				} else {
					if(this.warehouseManagement1.warehouse_number[-1] != '' && this.warehouseManagement1.stock_removal[-1] != '' && this.warehouseManagement1.stock_replacement[-1] != '' && this.warehouseManagement1.storage_section_indicator[-1] != ''){
						this.warehouseManagement1.warehouse_number.push('')
						this.warehouseManagement1.stock_removal.push('')
						this.warehouseManagement1.stock_replacement.push('')
						this.warehouseManagement1.storage_section_indicator.push('')
						this.warehouseManagement1.status.push('new')
					}else{
						this.completeDataNotify('warehouse_management_1')
					}
				}
			},
			removeWarehouseManagement1(key){
				this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.warehouseManagement1.warehouse_number.splice(key, 1)
		                this.warehouseManagement1.stock_removal.splice(key, 1)
		                this.warehouseManagement1.stock_replacement.splice(key, 1)
		                this.warehouseManagement1.storage_section_indicator.splice(key, 1)
		                this.warehouseManagement1.status.splice(key, 1)
						
						delete this.stockRemovals[key]
						delete this.stockReplacements[key]
						delete this.storageSections[key]

						this.warehouseManagement2.warehouse_number.forEach((w2, index) => {
							let existsWarehouse = this.warehouseManagement1.warehouse_number.some((w1) => w1 === w2)
							if(!existsWarehouse){
								this.warehouseManagement2.warehouse_number.splice(index, 1)
								this.warehouseManagement2.storage_type_indicator.splice(index, 1)
								this.warehouseManagement2.storage_bin.splice(index, 1)
								delete this.storageBins[index]
								delete this.storageTypes[index]
							}
						})
			        }
			    });
			},
			addWarehouseManagement2(){
				if(this.warehouseManagement2.warehouse_number.length == 0){
					this.warehouseManagement2.warehouse_number.push('')
					this.warehouseManagement2.storage_type_indicator.push('')
					this.warehouseManagement2.storage_bin.push('')
					this.warehouseManagement2.status.push('new')
				}else{
					if(this.warehouseManagement2.warehouse_number[-1] != '' && this.warehouseManagement2.storage_type_indicator[-1] != '' && this.warehouseManagement2.storage_bin != ''){
						this.warehouseManagement2.warehouse_number.push('')
						this.warehouseManagement2.storage_type_indicator.push('')
						this.warehouseManagement2.storage_bin.push('')
						this.warehouseManagement2.status.push('new')
					}else{
						this.completeDataNotify('warehouse_management_2')
					}
				}
			},
			removeWarehouseManagement2(key){
				this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.warehouseManagement2.warehouse_number.splice(key, 1)
		                this.warehouseManagement2.storage_type_indicator.splice(key, 1)
		                this.warehouseManagement2.storage_bin.splice(key, 1)
		                this.warehouseManagement2.status.splice(key, 1)
						delete this.storageBins[key]
						delete this.storageTypes[key]
			        }
			    });
			},
            addSalesGeneralLevel() {
            	if (Object.entries(this.salesOrganization).length === 0 ||
            		Object.entries(this.distributionChannel).length === 0 || 
            		Object.entries(this.taxClassification).length === 0 || 
            		Object.entries(this.itemCategoryGroup).length === 0|| 
            		Object.entries(this.accountAssignmentGroup).length === 0) {

            		this.getSalesOrganization();
		            this.getDistributionChannel();
		            this.getTaxClassification();
		            this.getItemCategoryGroup();
		            this.getAccountAssignmentGroup();
            	}

                if (this.prcMcrItemSales.sales_org.length == 0) {
                    this.prcMcrItemSales.sales_org.push('')
                    this.prcMcrItemSales.distr_channel.push('')
                    this.prcMcrItemSales.sales_unit.push('')
                    // this.prcMcrItemSales.tax_cat.push('')
                    this.prcMcrItemSales.tax_class.push('')
                    this.prcMcrItemSales.account_assign_grp.push('')
                    this.prcMcrItemSales.item_cat_grp.push('')
                    this.prcMcrItemSales.status.push('new')
                    this.prcMcrItemSales.del_flag.push('')
                } else if (this.prcMcrItemSales.sales_org.length > 0) {
                    if (this.prcMcrItemSales.sales_org[this.prcMcrItemSales.sales_org.length-1] != '' &&
                        this.prcMcrItemSales.distr_channel[this.prcMcrItemSales.distr_channel.length-1] != '' &&
                        this.prcMcrItemSales.sales_unit[this.prcMcrItemSales.sales_unit.length-1] != '' &&
                        // this.prcMcrItemSales.tax_cat[this.prcMcrItemSales.tax_cat.length-1] != '' &&
                        this.prcMcrItemSales.tax_class[this.prcMcrItemSales.tax_class.length-1] != '' &&
                        this.prcMcrItemSales.account_assign_grp[this.prcMcrItemSales.account_assign_grp.length-1] != '' &&
                        this.prcMcrItemSales.item_cat_grp[this.prcMcrItemSales.item_cat_grp.length-1] != '') {
                        
                        this.prcMcrItemSales.sales_org.push('')
						this.prcMcrItemSales.distr_channel.push('')
						this.prcMcrItemSales.sales_unit.push('')
						// this.prcMcrItemSales.tax_cat.push('')
						this.prcMcrItemSales.tax_class.push('')
						this.prcMcrItemSales.account_assign_grp.push('')
						this.prcMcrItemSales.item_cat_grp.push('')
						this.prcMcrItemSales.status.push('new')
						this.prcMcrItemSales.del_flag.push('')
                    } else {
                        this.completeDataNotify('sales')
                    }
                }                
            },
			addSalesPlantLevel() {
            	if (Object.entries(this.loadingGroup).length === 0) {
		            this.getLoadingGroup();
            	}

                if (this.prcMcrItemSalesPlant.sls_plant_code.length == 0) {
					this.prcMcrItemSalesPlant.sls_plant_code.push('')
                    this.prcMcrItemSalesPlant.loading_group.push('')
                    this.prcMcrItemSalesPlant.profit_center.push('')
                    this.prcMcrItemSalesPlant.status.push('new')
                    this.prcMcrItemSalesPlant.del_flag.push('')
                } else if (this.prcMcrItemSalesPlant.sls_plant_code.length > 0) {
                    if (this.prcMcrItemSalesPlant.sls_plant_code[this.prcMcrItemSalesPlant.sls_plant_code.length-1] != '' &&
                        this.prcMcrItemSalesPlant.loading_group[this.prcMcrItemSalesPlant.loading_group.length-1] != '' &&
                        this.prcMcrItemSalesPlant.profit_center[this.prcMcrItemSalesPlant.profit_center.length-1] != '') {
                        
						this.prcMcrItemSalesPlant.sls_plant_code.push('')
						this.prcMcrItemSalesPlant.loading_group.push('')
						this.prcMcrItemSalesPlant.profit_center.push('')
						this.prcMcrItemSalesPlant.status.push('new')
						this.prcMcrItemSalesPlant.del_flag.push('')
                    } else {
                        this.completeDataNotify('sales')
                    }
                }                
            },
            removeSalesGeneralLevel(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
			        	this.prcMcrItemSales.sales_org.splice(key, 1)
		                this.prcMcrItemSales.distr_channel.splice(key, 1)
		                this.prcMcrItemSales.sales_unit.splice(key, 1)
		                // this.prcMcrItemSales.tax_cat.splice(key, 1)
		                this.prcMcrItemSales.tax_class.splice(key, 1)
		                this.prcMcrItemSales.account_assign_grp.splice(key, 1)
		                this.prcMcrItemSales.item_cat_grp.splice(key, 1)
		                this.prcMcrItemSales.status.splice(key, 1)
		                this.prcMcrItemSales.del_flag.splice(key, 1)
			        }
			    });
            },
			removeSalesPlantLevel(key) {
                this.confirmDialog(this.tt("confirm_delete")).then(result => {
			        if (result.value) {
						this.prcMcrItemSalesPlant.sls_plant_code.splice(key, 1)
		                this.prcMcrItemSalesPlant.loading_group.splice(key, 1)
		                this.prcMcrItemSalesPlant.profit_center.splice(key, 1)
		                this.prcMcrItemSalesPlant.status.splice(key, 1)
		                this.prcMcrItemSalesPlant.del_flag.splice(key, 1)
			        }
			    });
            },
            changeFileAttachmentName(files) {
                this.prcMcrItemAttachment.file_attachment_code[this.prcMcrItemAttachment.file_attachment_code.length-1] = files[0]                
            },
            save() {
                let context = this;
                let formData = new FormData();

                formData.append('mcr_code', context.mcr_code)
				
                if (context.mcrType == 'B3') {
					formData.append('client_plant_level', context.clientPlantLevel)
				}

                // BASIC DATA & CLASSIFICATION

        		if (context.mcrType == 'B3' || context.mcrType == 'B4') {
        			if (context.input.material_number == '') {
						this.completeDataNotify('material_number')
						return false;
					}
        		} else {
        			if (context.input.prc_inc == '' &&
	                    context.input.prc_item_type_code == '' &&
	                    context.input.prc_nsc == '' &&
	                    context.input.prc_uom_code == '' &&
	                    context.input.prc_mtype_code == '' &&
	                    context.input.prc_fab_non_fab == '' &&
	                    context.input.prc_bom_non_bom == ''
						) {
                    	this.completeDataNotify('basic_data_&_specification')
						console.log('masuk di dalam', this.input.prc_nsc, this.input.prc_nsc_display_description, this.input.prc_mgroup_code)
	                    return false;
	                }

					console.log('masuk di luar', this.input.prc_nsc, this.input.prc_nsc_display_description, this.input.prc_mgroup_code)
        		}

				if (context.mcrType != 'B2') {
					formData.append('material_number', context.input.material_number)
				}
				formData.append('prc_inc', context.input.prc_inc)
				formData.append('prc_item_type_code', context.input.prc_item_type_code)
				formData.append('prc_nsc', context.input.prc_nsc)
				formData.append('prc_uom_code', context.input.prc_uom_code)
				formData.append('prc_mtype_code', context.input.prc_mtype_code)
				formData.append('prc_mgroup_code', context.input.prc_nsc)
				formData.append('prc_fab_non_fab', context.input.prc_fab_non_fab)
				formData.append('prc_sos_header', context.input.prc_sos_header)
				formData.append('prc_bom_non_bom', context.input.prc_bom_non_bom)
				formData.append('prc_critical_non_critical', context.input.prc_critical_non_critical)
				formData.append('prc_old_material_number', context.input.prc_old_material_number)

				// sales (header level)
				formData.append('prc_division_code', context.input.prc_division_code)
				formData.append('prc_gen_item_cat_grp', context.input.prc_gen_item_cat_grp)
				formData.append('prc_trans_grp', context.input.prc_trans_grp)

                // CHARACTERISTIC & VALUE // mandatory
        		if (context.prcMcrItemCharValue.cin_code.length) {
					let emptyCharMandatory = []
            		for (var i=0; i<context.prcMcrItemCharValue.cin_code.length; i++) {
						if (context.prcMcrItemCharValue.is_mandatory[i] == 'yes' && (context.prcMcrItemCharValue.value[i] == undefined || context.prcMcrItemCharValue.value[i] == '')) {
							emptyCharMandatory.push(context.prcMcrItemCharValue.characteristic[i])
						} else {
							formData.append('cin_code['+i+']', context.prcMcrItemCharValue.cin_code[i])
							formData.append('characteristic_value['+i+']', (context.prcMcrItemCharValue.value[i] != undefined) ? context.prcMcrItemCharValue.value[i] : '')
							formData.append('view['+i+']', (context.prcMcrItemCharValue.view[i] == true ? 'checked' : ''))
							formData.append('code['+i+']', context.prcMcrItemCharValue.code[i])
						}
					}

					if (emptyCharMandatory.length) {
						context.requiredCharNotify(emptyCharMandatory)
						return false
					}
            	} else {
            		if (context.mcrType == 'B2' || (context.mcrType == 'B3' && context.clientPlantLevel == 'Client')) {
            			context.requiredTabNotify('Characteristic Value');
						return false;
            		}
            	}

                // POT TEXT MEMO

            	if (context.input.prc_po_text_memo != '') {
            		formData.append('prc_po_text_memo', context.input.prc_po_text_memo)
            	} else {
					formData.append('prc_po_text_memo', '')
            	}

            	// MANUFACTURE // not mandatory

            	if (context.prcMcrItemMpn.manufacture_code.length) {
                  for (var i=0; i<context.prcMcrItemMpn.manufacture_code.length; i++) {
                      if (context.prcMcrItemMpn.manufacture_code[i] != '' &&
                          context.prcMcrItemMpn.mpn[i] != '' &&
                          context.prcMcrItemMpn.source_type[i] != '' &&
                          context.prcMcrItemMpn.manufacture_type[i] != '') {
							  
							if (context.prcMcrItemMpn.source_type[i] == "DWG" && context.prcMcrItemMpn.position[i] == "") {
								context.completeDataNotify('manufacture_refference')
								return false;
							}
                         
							formData.append('prc_manufacture_code['+i+']', context.prcMcrItemMpn.manufacture_code[i])
							formData.append('prc_mpn['+i+']', context.prcMcrItemMpn.mpn[i])
							formData.append('prc_mpn_position['+i+']', context.prcMcrItemMpn.position[i])
							formData.append('prc_source_type['+i+']', context.prcMcrItemMpn.source_type[i])
							formData.append('prc_manufacture_type['+i+']', context.prcMcrItemMpn.manufacture_type[i])
							formData.append('prc_manufacture_note['+i+']', context.prcMcrItemMpn.manufacture_note[i])
							formData.append('prc_manufacture_shortdes['+i+']', context.prcMcrItemMpn.shortdes[i])
							formData.append('prc_manufacture_status['+i+']', context.prcMcrItemMpn.status[i])
                      } else {
                          context.completeDataNotify('manufacture_refference')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_manufacture_code', '')
                  formData.append('prc_mpn', '')
                  formData.append('prc_mpn_position', '')
                  formData.append('prc_source_type', '')
                  formData.append('prc_manufacture_type', '')
                  formData.append('prc_manufacture_note', '')
                  formData.append('prc_manufacture_shortdes', '')
                  formData.append('prc_manufacture_status', '')
                }

                // PLANT // mandatory
                    
            	if (context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemPlant.plant.length; i++) {
                      if (context.prcMcrItemPlant.plant[i] != '') {
                          formData.append('prc_plant_code['+i+']', context.prcMcrItemPlant.plant[i])
                          formData.append('prc_plant_status['+i+']', context.prcMcrItemPlant.status[i])
                          formData.append('prc_plant_del_flag['+i+']', context.prcMcrItemPlant.del_flag[i])
                      } else {
                          context.completeDataNotify('plant')
                          return false;
                      }
                  }
                } else {
                  if (context.mcrType != 'B3' && context.clientPlantLevel != 'Client') {
                  	context.requiredTabNotify('plant');
	                return false;
                  }
                }

                // STORAGE LOCATION

                if (context.prcMcrItemSloc.storage_location.length) {
                  for (var i=0; i<context.prcMcrItemSloc.storage_location.length; i++) {
                      if (context.prcMcrItemSloc.storage_location[i] != '') {
                          formData.append('prc_plant_sloc['+i+']', context.prcMcrItemSloc.plant_code[i])
                          formData.append('prc_storage_location['+i+']', context.prcMcrItemSloc.storage_location[i])
                          formData.append('prc_bin_code['+i+']', context.prcMcrItemSloc.bin_location[i])
                          formData.append('prc_sloc_status['+i+']', context.prcMcrItemSloc.status[i])
                          formData.append('prc_sloc_del_flag['+i+']', context.prcMcrItemSloc.del_flag[i])
                      } else {
                          context.completeDataNotify('storage_location')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_sloc', '')
                  formData.append('prc_storage_location', '')
                  formData.append('prc_bin_code', '')
                  formData.append('prc_sloc_status', '')
                  formData.append('prc_sloc_del_flag', '')
                }

                // PURCHASING

            	if (context.prcMcrItemPurchasing.plant_purchasing.length >=  context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemPurchasing.plant_purchasing.length; i++) {
                      if (context.prcMcrItemPurchasing.plant_purchasing[i] != '' &&
                          context.prcMcrItemPurchasing.purchasing_group[i] != ''
                          ) {

                          formData.append('prc_plant_purchasing['+i+']', context.prcMcrItemPurchasing.plant_purchasing[i])
                          formData.append('prc_purchasing_group_code['+i+']', context.prcMcrItemPurchasing.purchasing_group[i])
                          formData.append('prc_planned_delivery['+i+']', context.prcMcrItemPurchasing.planned_delivery[i])
                          formData.append('prc_purchasing_status['+i+']', context.prcMcrItemPurchasing.status[i])
                          formData.append('prc_purchasing_del_flag['+i+']', context.prcMcrItemPurchasing.del_flag[i])
                      } else {
                          context.completeDataNotify('purchasing')
                          return false;
                      }
                  }
                } else {
                	if (context.mcrType != 'B3' && context.clientPlantLevel != 'Client') {
	                  context.requiredTabNotify('purchasing');
	                  return false;
	                }
                }

                // MRP

            	if (context.prcMcrItemMrp.plant_mrp.length >=  context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemMrp.plant_mrp.length; i++) {
                      if (context.prcMcrItemMrp.plant_mrp[i] != '' &&
                          context.prcMcrItemMrp.mrp_type[i] != '' &&
                          context.prcMcrItemMrp.avail_check[i] != ''
                        ) {
                      		if (context.prcMcrItemMrp.mrp_type[i] == 'PD') {
                  			    if (context.prcMcrItemMrp.mrp_controller[i] == '' ||  context.prcMcrItemMrp.lot_size[i] == '') {
                  			    	context.completeDataNotify('mrp_controller_&_lot_size')
									return false;
                  			    }
                      		} else if (context.prcMcrItemMrp.mrp_type[i] == 'V1' || context.prcMcrItemMrp.mrp_type[i] == 'VB') {
                      			if (context.prcMcrItemMrp.mrp_controller[i] == '' ||  context.prcMcrItemMrp.lot_size[i] == '' || context.prcMcrItemMrp.reorder_point[i] == '' || context.prcMcrItemMrp.max_stock[i] == '') {
                      				context.completeDataNotify('mrp_controller_lot_size_reorder_point_&_max_stock')
									return false;
                      			}
                      		}

							if (context.prcMcrItemMrp.lot_size[i] == 'FX' && context.prcMcrItemMrp.fixed_lot[i] == '') {
								context.completeDataNotify('fixed_lot')
								return false;
							}

							if (context.prcMcrItemMrp.lot_size[i] == 'HB' && context.prcMcrItemMrp.max_stock[i] == '') {
								context.completeDataNotify('max_stock')
								return false;
							}

							if (context.prcMcrItemMrp.reorder_point[i] > context.prcMcrItemMrp.max_stock[i]) {
								context.completeDataNotify('reorder_point_greater_than_max_stock')
								return false;
							}

                      		formData.append('prc_plant_mrp['+i+']', context.prcMcrItemMrp.plant_mrp[i])
							formData.append('prc_abc_indicator_code['+i+']', context.prcMcrItemMrp.abc_indicator[i])
							formData.append('prc_mrp_type_code['+i+']', context.prcMcrItemMrp.mrp_type[i])
							formData.append('prc_mrp_controller_code['+i+']', context.prcMcrItemMrp.mrp_controller[i])
							formData.append('prc_lot_size_code['+i+']', context.prcMcrItemMrp.lot_size[i])
							formData.append('prc_reorder_point['+i+']', context.prcMcrItemMrp.reorder_point[i])
							formData.append('prc_safety_stock['+i+']', context.prcMcrItemMrp.safety_stock[i])
							formData.append('prc_min_stock['+i+']', context.prcMcrItemMrp.min[i])
							formData.append('prc_max_stock['+i+']', context.prcMcrItemMrp.max[i])
							formData.append('prc_procurement_type_code['+i+']', context.prcMcrItemMrp.procurement_type[i])
							formData.append('prc_special_proc_type_code['+i+']', context.prcMcrItemMrp.special_proc[i])
							formData.append('prc_max_stock_mrp['+i+']', context.prcMcrItemMrp.max_stock[i])
							formData.append('prc_fixed_lot['+i+']', context.prcMcrItemMrp.fixed_lot[i])
							formData.append('prc_ord_costs['+i+']', context.prcMcrItemMrp.ord_costs[i])
							formData.append('prc_avail_check['+i+']', context.prcMcrItemMrp.avail_check[i])
							formData.append('prc_sloc_for_ep['+i+']', context.prcMcrItemMrp.sloc_for_ep[i])
							formData.append('prc_mrp_status['+i+']', context.prcMcrItemMrp.status[i])
							formData.append('prc_mrp_del_flag['+i+']', context.prcMcrItemMrp.del_flag[i])
                      } else {
						context.completeDataNotify('mrp')
						return false;
                      }
                  }
                } else {
                	if (context.mcrType != 'B3' && context.clientPlantLevel != 'Client') {
	                  context.requiredTabNotify('mrp');
	                  return false;
	                }
                }

                // SALES
                    
                if (context.prcMcrItemSales.sales_org.length) {
                  for (var i=0; i<context.prcMcrItemSales.sales_org.length; i++) {
                      if (context.prcMcrItemSales.sales_org[i] != '' &&
                          context.prcMcrItemSales.distr_channel[i] != '' &&
                          context.prcMcrItemSales.sales_unit[i] != '' &&
                        //   context.prcMcrItemSales.tax_cat[i] != '' &&
                          context.prcMcrItemSales.tax_class[i] != '' &&
                          context.prcMcrItemSales.account_assign_grp[i] != '' &&
                          context.prcMcrItemSales.item_cat_grp[i] != '') {
                          
                          formData.append('prc_sales_org_code['+i+']', context.prcMcrItemSales.sales_org[i])
                          formData.append('prc_distr_channel_code['+i+']', context.prcMcrItemSales.distr_channel[i])
                          formData.append('prc_sales_unit['+i+']', context.prcMcrItemSales.sales_unit[i])
                        //   formData.append('prc_tax_cat_code['+i+']', 'MWST')
                          formData.append('prc_tax_class_code['+i+']', context.prcMcrItemSales.tax_class[i])
                          formData.append('prc_acc_ass_grp_code['+i+']', context.prcMcrItemSales.account_assign_grp[i])
                          formData.append('prc_item_cat_grp_code['+i+']', context.prcMcrItemSales.item_cat_grp[i])
                          formData.append('prc_sales_status['+i+']', context.prcMcrItemSales.status[i])
                          formData.append('prc_sales_del_flag['+i+']', context.prcMcrItemSales.del_flag[i])
                      } else {
                          context.completeDataNotify('sales')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_sales_org_code', '')
                  formData.append('prc_distr_channel_code', '')
                  formData.append('prc_sales_unit', '')
                  formData.append('prc_tax_class_code', '')
                  formData.append('prc_acc_ass_grp_code', '')
                  formData.append('prc_sales_status', '')
                  formData.append('prc_sales_del_flag', '')
                }

				// SALES (PLANT)

				if (context.prcMcrItemSalesPlant.sls_plant_code.length) {
                  for (var i=0; i<context.prcMcrItemSalesPlant.sls_plant_code.length; i++) {
                      if (context.prcMcrItemSalesPlant.sls_plant_code[i] != '' &&
                          context.prcMcrItemSalesPlant.loading_group[i] != '' &&
                          context.prcMcrItemSalesPlant.profit_center[i] != '') {
                          
                          formData.append('prc_plant_sales['+i+']', context.prcMcrItemSalesPlant.sls_plant_code[i])
                          formData.append('prc_loading_group_code['+i+']', context.prcMcrItemSalesPlant.loading_group[i])
                          formData.append('prc_profit_center_code['+i+']', context.prcMcrItemSalesPlant.profit_center[i])
                          formData.append('prc_sales_plant_status['+i+']', context.prcMcrItemSalesPlant.status[i])
                          formData.append('prc_sales_plant_del_flag['+i+']', context.prcMcrItemSalesPlant.del_flag[i])
                      } else {
                          context.completeDataNotify('sales')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_sales', '')
                  formData.append('prc_loading_group_code', '')
                  formData.append('prc_profit_center_code', '')
                  formData.append('prc_sales_plant_status', '')
                  formData.append('prc_sales_plant_del_flag', '')
                }

				// WAREHOUSE MANAGEMENT 1
				if(context.warehouseManagement1.warehouse_number.length){
					for(var i = 0; i < context.warehouseManagement1.warehouse_number.length;i++){
						if(context.warehouseManagement1.warehouse_number[i] != '' && context.warehouseManagement1.stock_removal[i] != '' && context.warehouseManagement1.stock_replacement[i] != '' && context.warehouseManagement1.storage_section_indicator[i] != ''){
							formData.append('prc_warehouse_management_1_warehouse_number['+i+']', context.warehouseManagement1.warehouse_number[i])
							formData.append('prc_warehouse_management_1_stock_removal['+i+']', context.warehouseManagement1.stock_removal[i])
							formData.append('prc_warehouse_management_1_stock_replacement['+i+']', context.warehouseManagement1.stock_replacement[i])
							formData.append('prc_warehouse_management_1_storage_section_indicator['+i+']', context.warehouseManagement1.storage_section_indicator[i])
							formData.append('prc_warehouse_management_1_status['+i+']', context.warehouseManagement1.status[i])
						}else{
							context.completeDataNotify('warehouse_management_1')
                          	return false;
						}
					}
				}else{
					formData.append('prc_warehouse_management_1_warehouse_number', '')
					formData.append('prc_warehouse_management_1_stock_removal', '')
					formData.append('prc_warehouse_management_1_stock_replacement', '')
					formData.append('prc_warehouse_management_1_storage_section_indicator', '')
					formData.append('prc_warehouse_management_1_status', '')
				}

				// WAREHOUSE MANAGEMENT 2
				if(context.warehouseManagement2.warehouse_number.length){
					for(var i = 0; i < context.warehouseManagement2.warehouse_number.length;i++){
						if(context.warehouseManagement2.warehouse_number[i] != '' && context.warehouseManagement2.storage_type_indicator[i] != '' && context.warehouseManagement2.storage_bin[i] != ''){
							formData.append('prc_warehouse_management_2_warehouse_number['+i+']', context.warehouseManagement2.warehouse_number[i])
							formData.append('prc_warehouse_management_2_storage_type_indicator['+i+']', context.warehouseManagement2.storage_type_indicator[i])
							formData.append('prc_warehouse_management_2_storage_bin['+i+']', context.warehouseManagement2.storage_bin[i])
							formData.append('prc_warehouse_management_2_status['+i+']', context.warehouseManagement2.status[i])
						}else{
							context.completeDataNotify('warehouse_management_2')
                          	return false;
						}
					}
				}else{
					formData.append('prc_warehouse_management_2_warehouse_number', '')
					formData.append('prc_warehouse_management_2_storage_type_indicator', '')
					formData.append('prc_warehouse_management_2_storage_bin', '')
					formData.append('prc_warehouse_management_2_status', '')
				}

                // ACCOUNTING
                    
            	if (context.prcMcrItemAccounting.plant_accounting.length >= context.prcMcrItemPlant.plant.length) {
                  for (var i=0; i<context.prcMcrItemAccounting.valuation_class.length; i++) {
                      if (context.prcMcrItemAccounting.valuation_class[i] != '' &&
                          context.prcMcrItemAccounting.price_determination[i] != '' &&
                          context.prcMcrItemAccounting.price_unit[i] != ''
                        ) {
							if (context.prcMcrItemAccounting.price_control[i] == 'S' && context.prcMcrItemAccounting.standard_price[i] == '') {
								context.completeDataNotify('standard_price')
								return false;
							}

							if(context.prcMcrItemAccounting.price_unit[i].toString().length > 5){
								context.completeDataNotify('price_unit_than_5')
								return false;
							}

							formData.append('prc_plant_accounting['+i+']', context.prcMcrItemAccounting.plant_accounting[i])
							formData.append('prc_valuation_class_code['+i+']', context.prcMcrItemAccounting.valuation_class[i])
							formData.append('prc_valuation_category_code['+i+']', context.prcMcrItemAccounting.valuation_category[i])
							formData.append('prc_valuation_type_code['+i+']', context.prcMcrItemAccounting.valuation_type[i])
							formData.append('prc_price_control_code['+i+']', context.prcMcrItemAccounting.price_control[i])
							formData.append('prc_price_determination['+i+']', context.prcMcrItemAccounting.price_determination[i])
							formData.append('prc_standard_price['+i+']', (context.prcMcrItemAccounting.standard_price[i] == null) ? '' : context.prcMcrItemAccounting.standard_price[i])
							formData.append('prc_price_unit['+i+']', context.prcMcrItemAccounting.price_unit[i])
							formData.append('prc_price_reference['+i+']', context.prcMcrItemAccounting.price_reference[i])
							formData.append('prc_accounting_status['+i+']', context.prcMcrItemAccounting.status[i])
							formData.append('prc_accounting_del_flag['+i+']', context.prcMcrItemAccounting.del_flag[i])
                      } else {
                          context.completeDataNotify('accounting')
                          return false;
                      }
                  }
                } else {
                	if (context.mcrType != 'B3' && context.clientPlantLevel != 'Client') {
	                	context.requiredTabNotify('accounting');
						return false;
	                }
                }

                // COSTING

                if (context.prcMcrItemCosting.plant_costing.length) {
                  for (var i=0; i<context.prcMcrItemCosting.plant_costing.length; i++) {
                      if (context.prcMcrItemCosting.plant_costing[i] != '' &&
                          context.prcMcrItemCosting.overhead_group[i] != ''
                          ) {

                          formData.append('prc_plant_costing['+i+']', context.prcMcrItemCosting.plant_costing[i])
                          formData.append('prc_overhead_group['+i+']', context.prcMcrItemCosting.overhead_group[i])
                          formData.append('prc_costing_lot_size['+i+']', context.prcMcrItemCosting.costing_lot_size[i])
                          formData.append('prc_costing_status['+i+']', context.prcMcrItemCosting.status[i])
                          formData.append('prc_costing_del_flag['+i+']', context.prcMcrItemCosting.del_flag[i])
                      } else {
                          context.completeDataNotify('costing')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_costing', '')
                  formData.append('prc_overhead_group', '')
                  formData.append('prc_costing_lot_size', '')
                  formData.append('prc_costing_status', '')
                  formData.append('prc_costing_del_flag', '')
                }

                // QUALITY MANAGEMENT

                if (context.prcMcrItemQm.plant_qm.length) {
                  for (var i=0; i<context.prcMcrItemQm.plant_qm.length; i++) {
                      if (context.prcMcrItemQm.plant_qm[i] != '') {
                          formData.append('prc_plant_qm['+i+']', context.prcMcrItemQm.plant_qm[i])
                          formData.append('prc_inspection_type['+i+']', context.prcMcrItemQm.inspection_type[i])
                          formData.append('prc_qm_material_auth['+i+']', context.prcMcrItemQm.qm_material_auth[i])
                          formData.append('prc_qm_status['+i+']', context.prcMcrItemQm.status[i])
                      } else {
                          context.completeDataNotify('qm')
                          return false;
                      }
                  }
                } else {
                  formData.append('prc_plant_qm', '')
                  formData.append('prc_inspection_type', '')
                  formData.append('prc_qm_material_auth', '')
                  formData.append('prc_qm_status', '')
                }

                // EQUIPMENT
                    
            	if (context.prcMcrItemEquipment.plant_equipment_code.length) {
                  for (var i=0; i<context.prcMcrItemEquipment.plant_equipment_code.length; i++) {
                      if (context.prcMcrItemEquipment.plant_equipment_code[i] != '' &&
							context.prcMcrItemEquipment.equipment_code[i] != '' &&
							context.prcMcrItemEquipment.qty_installed[i] != '' &&
							context.prcMcrItemEquipment.drawing_number[i] != '') {

							formData.append('prc_plant_equipment['+i+']', context.prcMcrItemEquipment.plant_equipment_code[i])
							formData.append('prc_equipment_code['+i+']', context.prcMcrItemEquipment.equipment_code[i])
							formData.append('prc_qty_installed['+i+']', context.prcMcrItemEquipment.qty_installed[i])
							formData.append('prc_drawing_number['+i+']', context.prcMcrItemEquipment.drawing_number[i])
							formData.append('prc_equipment_del_flag['+i+']', (context.prcMcrItemEquipment.del_flag[i]) ? 'X' : '')
							formData.append('prc_equipment_status['+i+']', context.prcMcrItemEquipment.status[i])
							formData.append('prc_equipment_show['+i+']', context.prcMcrItemEquipment.show[i])
                      } else {
							context.completeDataNotify('equipment')
							return false;
                      }
                  }
                } else {
                	if ((context.mcrType == 'B2' || (context.mcrType == 'B3' && context.clientPlantLevel == 'Client')) && (context.input.prc_item_type_code == 'OEM' || context.input.prc_bom_non_bom == 'BOM')) {
                		context.requiredTabNotify('equipment');
						return false;
                	} else {
						formData.append('prc_plant_equipment', '')
						formData.append('prc_equipment_code', '')
						formData.append('prc_qty_installed', '')
						formData.append('prc_drawing_number', '')
						formData.append('prc_equipment_del_flag', '')
						formData.append('prc_equipment_status', '')
                	}
                }

                // ATTACHMENT
                    
            	if (context.prcMcrItemAttachment.file_attachment_code.length) {
                  for (var i=0; i<context.prcMcrItemAttachment.file_attachment_code.length; i++) {
                      if (context.prcMcrItemAttachment.file_attachment_code.length > 0 &&
                          context.prcMcrItemAttachment.file_attachment_code[context.prcMcrItemAttachment.file_attachment_code.length-1] != '' &&
                          context.prcMcrItemAttachment.description[i] != '') {
                          formData.append('file_attachment_code['+i+']', context.prcMcrItemAttachment.file_attachment_code[i])
                          formData.append('attachment_description['+i+']', context.prcMcrItemAttachment.description[i])
                      } else {
                          context.completeDataNotify('attachment')
                          return false;
                      }
                  }
                } else {
            		context.requiredTabNotify('attachment');
					return false;
                }

                // KEYWORD
                    
            	if (context.prcMcrItemKeyword.keyword.length) {
                  for (var i=0; i<context.prcMcrItemKeyword.keyword.length; i++) {
                      if (context.prcMcrItemKeyword.keyword[i] != '') {
                          formData.append('keyword['+i+']', context.prcMcrItemKeyword.keyword[i])
                      } else {
                          context.completeDataNotify('keyword')
                          return false;
                      }
                  }
                } else {
                  formData.append('keyword', '')
                }

				// UOM CONVERSION
                    
            	if (context.prcMcrItemUomConversion.x.length) {
                  for (var i=0; i<context.prcMcrItemUomConversion.x.length; i++) {
                      if (context.prcMcrItemUomConversion.x[i] != '' &&
					 	  context.prcMcrItemUomConversion.y[i] != '' &&
						  context.prcMcrItemUomConversion.alternative_unit[i] != ''
					  	) {
                          formData.append('uom_conversion_x['+i+']', context.prcMcrItemUomConversion.x[i])
                          formData.append('uom_conversion_y['+i+']', context.prcMcrItemUomConversion.y[i])
                          formData.append('uom_conversion_alt_unit['+i+']', context.prcMcrItemUomConversion.alternative_unit[i])
                          formData.append('uom_conversion_del_flag['+i+']', context.prcMcrItemUomConversion.del_flag[i] ? 'X' : '')
                      } else {
                          context.completeDataNotify('uom_conversion')
                          return false;
                      }
                  }
                } else {
                  formData.append('uom_conversion_x', '')
                }

                // NOTE

                if (context.input.note != '') {
            		formData.append('internal_note', context.input.note)
            	} else {
					formData.append('internal_note', '')
            	}

				// MATERIAL NEED MEETING
				if (context.input.material_need_meeting === true && typeof context.input.material_need_meeting !== 'undefined') {
					formData.append("material_need_meeting", 'X')
				}

				if (context.input.display_fields_during_material_need_meeting_checked === true) {
				// MATERIAL HAS BEEN DISCUSSED
					if (context.input.material_has_been_discussed !== false && typeof context.input.material_has_been_discussed !== 'undefined') {
						formData.append("material_has_been_discussed", 'X')
					} else {
						context.completeDataNotify('material_has_been_discussed')
						return false
					}
				// MATERIAL DATE DISCUSSION
					if (context.input.material_date_discussion !== '') {
						formData.append("material_date_discussion", (context.input.material_date_discussion))
					} else {
						context.completeDataNotify('material_date_discussion')
						return false
					}	
				}

            	// CHANGE LOG
            	var logCount = 0;
            	for(const key in context.oldValue) {
            		var paramReplaced = key.replace(/_/g, ' '); 
            		formData.append('change_log['+logCount+++']', `${paramReplaced} changed from "${context.oldValue[key]}" to "${context.newValue[key]}"`)
            	}

				// STATUS PROCESS
				formData.append('status_process', 'cataloguer_process')

				// MCR TYPE
				formData.append('mcr_type', context.mcrType)

                this.form.btnSave.onLoading = true;
                
                Api(context, draftList.updateMcrItem(formData, context.input.id)).onSuccess(function(response) {    
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: "bottom",
						horizontalAlign: "left"
                    });

                    context.getDetailMcrItem();
					context.checkFormType();
					context.getParameterMcr();
					context.checkDuplicateByMcrItemCode();

					context.$emit('saveB')

					// UPDATE FORM SHP VALUES 
					if (context.prcMcrItemEquipment.equipment_code.length && (context.mcrType == 'A1S' || context.mcrType == 'B2S')) {
						for (var i = 0; i < context.prcMcrItemEquipment.equipment_code.length; i++) {
							console.log('masuk equipment ke ' + [i], context.prcMcrItemEquipment.equipment_code[i])
							
							context.saveFormDataForShpForm(context.prcMcrItemEquipment.equipment_code[i])
						}
					}
                }).onError(function(error) {  
                    if (error.response.status == 404) {
                        context.draftList = [];
                    }
                }).onFinish(function() {
                    context.form.btnSave.onLoading = false;
					if(context.mcrType == 'B2S'){
						context.$emit('saveB', 'shp')
					}else{
						context.$emit('saveB')
					}

					let cekDataPerRowShp = []
					for (let dex in context.formDataShp) {
						if (context.formDataShp.hasOwnProperty(dex)) {
							for (let [formKey, formValue] of context.formDataShp[dex].entries()) {
								cekDataPerRowShp.push(`${formKey}: ${formValue}`);
							}
						}	
					}

					console.log('cekDataPerRowShp', cekDataPerRowShp)
                })
                .call()

                // debug formData
                // for (var pair of formData.entries()) {
                //     console.log(pair[0]+ ', ' + pair[1]); 
                // }
            },
			testShow(key){
				console.log('test show', key, this.valuesOfShpForm, this.valuesOfShpForm[key])
			},
			saveFormDataForShpForm(key){
				let context 	= this
				let dataShpForm = new FormData();

				context.$set(context.formDataShp, key ,[])

				dataShpForm.append("client_plant_level", 'SHP');
				dataShpForm.append('status_process', 'shp_cat_process')
				dataShpForm.append('shp_equipment', context.valuesOfShpForm[key].input.shp_equipment)
				
				dataShpForm.append("shp_effect_spare_part_not_available", context.valuesOfShpForm[key].input.shp_effect_spare_part_not_available);
				dataShpForm.append("shp_action_to_handle", context.valuesOfShpForm[key].input.shp_action_to_handle);
				dataShpForm.append("shp_lead_time_action_to_handle", context.valuesOfShpForm[key].input.shp_lead_time_action_to_handle);
				dataShpForm.append("shp_consequences_criticality", context.valuesOfShpForm[key].input.shp_consequences_criticality);
				dataShpForm.append("shp_estimated_loss_idr", context.valuesOfShpForm[key].input.shp_estimated_loss_idr);
				dataShpForm.append("shp_estimated_loss_day", context.valuesOfShpForm[key].input.shp_estimated_loss_day);
				dataShpForm.append("shp_total_estimated_loss", context.valuesOfShpForm[key].input.shp_total_estimated_loss);
				dataShpForm.append("shp_estimated_lead_time", context.valuesOfShpForm[key].input.shp_estimated_lead_time);
				dataShpForm.append("shp_category_lead_time", context.valuesOfShpForm[key].input.shp_category_lead_time);
				dataShpForm.append("shp_abc_indicator", context.valuesOfShpForm[key].input.shp_abc_indicator);
				dataShpForm.append("shp_unit_price", context.valuesOfShpForm[key].input.shp_unit_price);
				dataShpForm.append("shp_quantity_on_hand", context.valuesOfShpForm[key].input.shp_quantity_on_hand);
				dataShpForm.append("shp_value_unrestricted", context.valuesOfShpForm[key].input.shp_value_unrestricted);
				dataShpForm.append("shp_original_stock_strategy", context.valuesOfShpForm[key].input.shp_original_stock_strategy);
				dataShpForm.append("shp_stock_strategy", context.valuesOfShpForm[key].input.shp_stock_strategy);
				dataShpForm.append("shp_change_mrp_type", context.valuesOfShpForm[key].input.shp_change_mrp_type);
				dataShpForm.append("shp_resume_justification_stock_strategy", context.valuesOfShpForm[key].input.shp_resume_justification_stock_strategy);
				dataShpForm.append("shp_original_stock_level_type_calculation", context.valuesOfShpForm[key].input.shp_original_stock_level_type_calculation);
				dataShpForm.append("shp_stock_level_type_calculation", context.valuesOfShpForm[key].input.shp_stock_level_type_calculation);
				dataShpForm.append("shp_original_service_level", context.valuesOfShpForm[key].input.shp_original_service_level);
				dataShpForm.append("shp_service_level", context.valuesOfShpForm[key].input.shp_service_level);
				dataShpForm.append("shp_original_stock_level", context.valuesOfShpForm[key].input.shp_original_stock_level);
				dataShpForm.append("shp_stock_level", context.valuesOfShpForm[key].input.shp_stock_level);
				dataShpForm.append("shp_demand_rate", context.valuesOfShpForm[key].input.shp_demand_rate);
				dataShpForm.append("shp_demand_rate_unit", context.valuesOfShpForm[key].input.shp_demand_rate_unit);
				dataShpForm.append("shp_qty", context.valuesOfShpForm[key].input.shp_qty);
				dataShpForm.append("shp_uom", context.valuesOfShpForm[key].input.shp_uom);
				dataShpForm.append("shp_original_min", context.valuesOfShpForm[key].input.shp_original_min);
				dataShpForm.append("shp_min", context.valuesOfShpForm[key].input.shp_min);
				dataShpForm.append("shp_original_max", context.valuesOfShpForm[key].input.shp_original_max);
				dataShpForm.append("shp_max", context.valuesOfShpForm[key].input.shp_max);
				dataShpForm.append("shp_date_during_analysis", context.valuesOfShpForm[key].input.shp_date_during_analysis);
				dataShpForm.append("shp_owner_estimate", parseInt(context.valuesOfShpForm[key].input.shp_owner_estimate));

				if(context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy.length){
					for(var i = 0; i < context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy.length;i++){
						if(context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy[i] != "" && context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval[i] != "" && context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan[i] != ""){
							dataShpForm.append("shp_interval[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval[i]);
							dataShpForm.append("shp_mce_strategy[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy[i]);
							dataShpForm.append("shp_net_pf[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_net_pf[i]);
							dataShpForm.append("shp_tindakan[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan[i]);
						}else{
							context.completeDataNotify('mce_strategy_interval')
							return false;
						}
					}
				}

				if(context.valuesOfShpForm[key].input.shp_failure_mode.length){
					for(var i = 0; i < context.valuesOfShpForm[key].input.shp_failure_mode.length;i++){
						if(context.valuesOfShpForm[key].input.shp_failure_mode[i] != ""){
							dataShpForm.append("shp_failure_mode[" + i + "]",context.valuesOfShpForm[key].input.shp_failure_mode[i]);
						}else{
							context.completeDataNotify('failure_mode')
							return false;
						}
					}
				}

				// CHANGE LOG
				if (context.valuesOfShpForm[key].changeLog) {
					for(const num in context.valuesOfShpForm[key].changeLog) {
						dataShpForm.append('change_log['+ num +']', context.valuesOfShpForm[key].changeLog[num])
					}	
				}

				context.formDataShp[key] = dataShpForm

				// console.log('cek dalam saveFormDataForShpForm', dataShpForm, context.valuesOfShpForm[key].input.id)

				Api(context, draftList.updateMcrItem(dataShpForm, context.valuesOfShpForm[key].input.id))
				.onSuccess(function(response) {
		            context.$notify({
						message: response.data.message,
						type: 'success',
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
		            });
				}).call();
			},
            selectedCinCode(cin_code) {
              let context = this;

              context.charValue = [];

              Api(context, characteristicItemName.get_approved_characteristic_value(cin_code))
              .onSuccess(function(response) {
                response.data.data.forEach(function(item, index) {
                    context.charValue[index] = {
                        'code': item['cin_code'],
                        'value': item['characteristic_value']
                    };
                });
              })
              .onError(function(error) {
                if (error.response.status == 404) {
                  context.charValue = [];
                }
              })
              .call();
            },
            changeDrawingNumber(key) {
            	let context = this;

            	Api(context, drawingMaster.getAttachment(context.prcMcrItemEquipment.drawing_number[key])).onSuccess(function(response) {  
                    context.drawingAttachment[key] = response.data.data[0].file_name;
                    context.$forceUpdate()
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.drawingAttachment[key] = [];
                    }
                }).call()
            },
            checkDuplicateByMcrItemCode() {
            	let context = this;

            	Api(context, checkDuplicate.checkDuplicateByMcrItemCode(context.mcrItemCode)).onSuccess(function(response) {  
                    context.dataDuplicate.by_description = response.data.data.byDescription;
                    context.dataDuplicate.by_part_number = response.data.data.byPartNumber;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dataDuplicate.by_description = [];
                        context.dataDuplicate.by_part_number = [];
                    }
                }).call()
            },
            getChangeLog() {
            	let context = this;

            	Api(context, transactionLog.McrChangeLog(context.mcrItemCode)).onSuccess(function(response) {  
                    context.dataChangeLog = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dataChangeLog = [];
                    }
                }).call()
            },
            getValue(queryString, cb) {
              var links = this.charValue;
              var results = queryString ? links.filter(this.createFilter(queryString)) : links;
              cb(results)
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            querySearchItemName(query) {
            	if (query.length > 3) {
            		clearTimeout(this.selectSearch.loadTimeout);             
	                let context = this;   

	                this.selectSearch.sitLoading = true;
					
	                this.selectSearch.loadTimeout = setTimeout(() => {
	                    Api(context, standardItemName.get(null, {per_page: 'none',item_name: query})).onSuccess(function(response) {
	                        context.standardItemName = response.data.data.data.data;    
	                        context.selectSearch.sitLoading = false;                                           
	                    }).onError(function(error) {
	                        context.standardItemName = [];
	                        context.selectSearch.sitLoading = false;
	                    }).call();             
	                }, 200)
            	}
            },
            querySearchGroupClass(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.ngcLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, natoGroupClass.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.natoGroupClass = response.data.data.data.data;
	                    context.selectSearch.ngcLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.natoGroupClass = [];
	                        context.selectSearch.ngcLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            changeItemName() {
            	let context = this;

            	context.input.prc_nsc = ''

            	Api(context, standardItemName.data(context.input.prc_inc)).onSuccess(function(response) {
            		context.materialGroup = response.data.data.data_material_group;

            		if (response.data.data.data_material_group.length == 1) {
            			context.input.prc_nsc = response.data.data.data_material_group[0].code
            			context.input.prc_mgroup_code = response.data.data.data_material_group[0].code
            		} else {
						context.input.prc_nsc = ''
						context.input.prc_mgroup_code = ''
					}
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.natoGroupClass = [];
                        context.materialGroup = [];
                    }
                })
                .call()
            },
            changeGroupClass() {
            	let context = this;
            	Api(context, scopeOfSupply.sosByMGroup(context.input.prc_nsc)).onSuccess(function(response) {    
            		context.input.prc_sos_header = response.data.data[0].sos_header;

                    context.scopeOfSupply[0] = {
			          	sos_header: response.data.data[0].sos_header,
			          	sos_description: response.data.data[0].sos_description,
			        }
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.scopeOfSupply = [];
                    }
                })
                .call()
            },
			getValMatGroup(queryString, cb) {
				var links = this.materialGroup
				var results = queryString ? links.filter(this.filterMatGroup(queryString)) : links
				cb(results)
			},
			filterMatGroup(queryString) { 
				return (link) => {
					return (link.code.toLowerCase().indexOf(queryString.toLowerCase()) === 0 || link.description.toLowerCase().indexOf(queryString.toLowerCase()) === 0)
				}
			},
			handleSelect(item) {
				this.input.prc_nsc = item.code
				this.input.prc_nsc_display_description = item.code + ' - ' + item.description
			},
            querySearchUom(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.uomLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.unitOfMeasure = response.data.data.data.data;
	                    context.selectSearch.uomLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.unitOfMeasure = [];
	                        context.selectSearch.uomLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchOrderUnit(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.orderUnitLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.orderUnit = response.data.data.data.data;
	                    context.selectSearch.orderUnitLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.orderUnit = [];
	                        context.selectSearch.orderUnitLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMtype(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.mtLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, materialType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.materialType = response.data.data.data.data;
	                    context.selectSearch.mtLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.materialType = [];
	                        context.selectSearch.mtLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchCtype(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.ctLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, catalogType.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.catalogType = response.data.data.data.data;
	                    context.selectSearch.ctLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.catalogType = [];
	                        context.selectSearch.ctLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMgroup(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.mgLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, materialGroup.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.materialGroup = response.data.data.data.data;
	                    context.selectSearch.mgLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.materialGroup = [];
	                        context.selectSearch.mgLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchSos(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.sosLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, scopeOfSupply.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {    
	                    context.scopeOfSupply = response.data.data.data.data;
	                    context.selectSearch.sosLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.scopeOfSupply = [];
	                        context.selectSearch.sosLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMancode(query) {
            	if (query.length > 1) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.mancodeLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, manufacture.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.masterManufacture = response.data.data.data.data;
		                    context.selectSearch.mancodeLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.masterManufacture = [];
		                        context.selectSearch.mancodeLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchPlant(query) {
            	if (query.length > 1) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.plantLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    if (context.mcrType == 'B4' || context.mcrType == 'A3') {
					    	Api(context, plantCode.materialPlantException(context.input.material_number, {search: query})).onSuccess(function(response) {  
			                    context.plantCode = response.data.data;
			                    context.selectSearch.plantLoading = false;
			                }).onError(function(error) {                    
			                    if (error.response.status == 404) {
			                        context.plantCode = [];
			                        context.selectSearch.plantLoading = false;
			                    }
			                })
			                .call()
					    } else {
					    	Api(context, plantCode.companyPlant({search: query})).onSuccess(function(response) {  
			                    context.plantCode = response.data.data;
			                    context.selectSearch.plantLoading = false;
			                }).onError(function(error) {                    
			                    if (error.response.status == 404) {
			                        context.plantCode = [];
			                        context.selectSearch.plantLoading = false;
			                    }
			                })
			                .call()
					    }     
					}, 200)
            	}
            },
            querySearchSloc(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.slocLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, locationCode.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.locationCode = response.data.data.data.data;
		                    context.selectSearch.slocLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.locationCode = [];
		                        context.selectSearch.slocLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchBinloc(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.binlocLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, binloc.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.binloc = response.data.data.data.data;
		                    context.selectSearch.binlocLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.binloc = [];
		                        context.selectSearch.binlocLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchProfitCenter(query) {
            	// if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.pcenterLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, profitCenter.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {  
		                    context.profitCenter = response.data.data.data.data;
		                    context.selectSearch.pcenterLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.profitCenter = [];
		                        context.selectSearch.pcenterLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	// }
            },
            querySearchPurGroup(query) {
            	// if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.purGroupLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, purchasingGroup.getPurchaseByCompany({search: query})).onSuccess(function(response) {  
		                    context.purchasingGroup = response.data.data;
		                    context.selectSearch.purGroupLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.purchasingGroup = [];
		                        context.selectSearch.purGroupLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	// }
            },
            querySearchEquipment(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.equipmentLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, equipmentCode.getByCompany(null, {search: query})).onSuccess(function(response) {  
		                    context.equipmentCode = response.data.data;
		                    context.selectSearch.equipmentLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.equipmentCode = [];
		                        context.selectSearch.equipmentLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            querySearchDrawingNumber(query) {
            	clearTimeout(this.selectSearch.loadTimeout);             
				let context = this;   

				this.selectSearch.drawingNumLoading = true;
				this.selectSearch.loadTimeout = setTimeout(() => {
				    Api(context, drawingMaster.getByEquipment(null, {equipment_code: context.focusedEquipment,search: query})).onSuccess(function(response) {  
	                    context.drawingMaster = response.data.data;
	                    context.selectSearch.drawingNumLoading = false;
	                }).onError(function(error) {                    
	                    if (error.response.status == 404) {
	                        context.drawingMaster = [];
	                        context.selectSearch.drawingNumLoading = false;
	                    }
	                })
	                .call()        
				}, 200)
            },
            querySearchMaterialNumber(query) {
            	if (query.length > 2) {
            		clearTimeout(this.selectSearch.loadTimeout);             
					let context = this;   

					this.selectSearch.matnumLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, materialNumber.get({search: query})).onSuccess(function(response) {  
		                    context.materialNumber = response.data.data;
		                    context.selectSearch.matnumLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.materialNumber = [];
		                        context.selectSearch.matnumLoading = false;
		                    }
		                })
		                .call()        
					}, 200)
            	}
            },
            completeDataNotify(title) {
            	this.$notify({
					message: this.tt("please_complete_data", { title: this.tt(title) }),                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
            requiredTabNotify(tabName) {
            	this.$notify({
					message: 'Tab ' +tabName+ ' is required',                  
					type: 'danger',
					verticalAlign: "bottom",
					horizontalAlign: "left"
				});
            },
			requiredCharNotify(char) {
				let Arr = char.map(arrs => arrs.toLowerCase().replace(/\b\w/g, first => first.toUpperCase())).join(', ')
				
				if (char.length == 1) {
					this.$notify({
						message: Arr+' in Characteristic Value is required',
						type: 'danger',
						verticalAlign: "bottom",
						horizontalAlign: "left"
					})
				} else {
					this.$notify({
						message: Arr+' in Characteristic Value are required',
						type: 'danger',
						verticalAlign: "bottom",
						horizontalAlign: "left"
					})
				}
			},
            smartcatAttachment() {
            	this.modalSmartcatAttachment = true;
            },
            checkDuplicate() {
            	this.modalCheckDuplicate = true;
            },
            storeFocusedEquipment(equipment) {
            	this.focusedEquipment = equipment;
            },
            clearDrawing(key) {
            	this.prcMcrItemEquipment.drawing_number[key] = ''
            	this.drawingAttachment[key] = ''
            },
            countPlantSloc(plant) {
				var count = 0;

				for (var i = 0; i < this.prcMcrItemSloc.plant_code.length; i++) {
					if(this.prcMcrItemSloc.plant_code[i] === plant) count++;
				}

				if (count > 1) {
					return true;
				} else {
					return false
				}
            },
            countPlantMrp(plant) {
				var count = 0;

				for (var i = 0; i < this.prcMcrItemMrp.plant_mrp.length; i++) {
					if(this.prcMcrItemMrp.plant_mrp[i] === plant) count++;
				}

				if (count > 1) {
					return true;
				} else {
					return false
				}
            },
            changeLog(parameter) {
            	this.parameter = parameter;
            },
            checkChangeLog() {
            	this.getChangeLog();
            	this.modalChangeLog = true;
            },
            changeSourceType(key) {
            	this.prcMcrItemMpn.position[key] = '';
            },
            getPlantCompany() {
            	let context = this;

            	Api(context, plantCode.companyPlant()).onSuccess(function(response) {  
                    response.data.data.forEach(function(item) {
                    	context.plantCompany.push(item.plant_code)
                    })
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCompany = [];
                    }
                })
                .call()
            },

			// ===== COPY VALUE ITEM =====
            copyValuePlant(key) {
				let plant = this.prcMcrItemPlant.plant[key]

				this.prcMcrItemPlant.plant.push(plant)
                this.prcMcrItemPlant.status.push('new')
                this.prcMcrItemPlant.del_flag.push('')
            },
            copyValueSloc(key) {
				let plant_code = this.prcMcrItemSloc.plant_code[key]
				let location   = this.prcMcrItemSloc.storage_location[key]
				let bin        = this.prcMcrItemSloc.bin_location[key]

				this.prcMcrItemSloc.plant_code.push(plant_code)
                this.prcMcrItemSloc.storage_location.push(location)
				this.prcMcrItemSloc.bin_location.push(bin)
				this.prcMcrItemSloc.status.push('new');
				this.prcMcrItemSloc.del_flag.push('');
            },
            copyValuePurchasing(key) {
				let plant_purchasing = this.prcMcrItemPurchasing.plant_purchasing[key]
				let purchasing_group = this.prcMcrItemPurchasing.purchasing_group[key]
				let planned_delivery = this.prcMcrItemPurchasing.planned_delivery[key]

				this.prcMcrItemPurchasing.plant_purchasing.push(plant_purchasing)
                this.prcMcrItemPurchasing.purchasing_group.push(purchasing_group)
                this.prcMcrItemPurchasing.planned_delivery.push(planned_delivery)
                this.prcMcrItemPurchasing.status.push('new')
                this.prcMcrItemPurchasing.del_flag.push('')
            },
            copyValueMRP(key) {
				let plant_mrp        = this.prcMcrItemMrp.plant_mrp[key]
				let abc_indicator    = this.prcMcrItemMrp.abc_indicator[key]
				let mrp_type         = this.prcMcrItemMrp.mrp_type[key]
				let mrp_controller   = this.prcMcrItemMrp.mrp_controller[key]
				let reorder_point    = this.prcMcrItemMrp.reorder_point[key]
				let lot_size         = this.prcMcrItemMrp.lot_size[key]
				let safety_stock     = this.prcMcrItemMrp.safety_stock[key]
				let min              = this.prcMcrItemMrp.min[key]
				let max              = this.prcMcrItemMrp.max[key]
				let procurement_type = this.prcMcrItemMrp.procurement_type[key]
				let special_proc     = this.prcMcrItemMrp.special_proc[key]
				let max_stock        = this.prcMcrItemMrp.max_stock[key]
				let fixed_lot        = this.prcMcrItemMrp.fixed_lot[key]
				let ord_costs        = this.prcMcrItemMrp.ord_costs[key]
				let avail_check      = this.prcMcrItemMrp.avail_check[key]
				let sloc_for_ep      = this.prcMcrItemMrp.sloc_for_ep[key]

                this.prcMcrItemMrp.plant_mrp.push(plant_mrp)
				this.prcMcrItemMrp.abc_indicator.push(abc_indicator)
                this.prcMcrItemMrp.mrp_type.push(mrp_type)
                this.prcMcrItemMrp.mrp_controller.push(mrp_controller)
                this.prcMcrItemMrp.reorder_point.push(reorder_point)
                this.prcMcrItemMrp.lot_size.push(lot_size)
                this.prcMcrItemMrp.safety_stock.push(safety_stock)
                this.prcMcrItemMrp.min.push(min)
                this.prcMcrItemMrp.max.push(max)
                this.prcMcrItemMrp.procurement_type.push(procurement_type)
                this.prcMcrItemMrp.special_proc.push(special_proc)
                this.prcMcrItemMrp.max_stock.push(max_stock)
				this.prcMcrItemMrp.fixed_lot.push(fixed_lot)
				this.prcMcrItemMrp.ord_costs.push(ord_costs)
				this.prcMcrItemMrp.avail_check.push(avail_check)
				this.prcMcrItemMrp.sloc_for_ep.push(sloc_for_ep)
                this.prcMcrItemMrp.status.push('new')
                this.prcMcrItemMrp.del_flag.push('')
            },
            copyValueSalesGeneralLevel(key) {
				let sales_org          = this.prcMcrItemSales.sales_org[key]
				let distr_channel      = this.prcMcrItemSales.distr_channel[key]
				let sales_unit         = this.prcMcrItemSales.sales_unit[key]
				let tax_class          = this.prcMcrItemSales.tax_class[key]
				let account_assign_grp = this.prcMcrItemSales.account_assign_grp[key]
				let item_cat_grp       = this.prcMcrItemSales.item_cat_grp[key]

				this.prcMcrItemSales.sales_org.push(sales_org)
				this.prcMcrItemSales.distr_channel.push(distr_channel)
				this.prcMcrItemSales.sales_unit.push(sales_unit)
				// this.prcMcrItemSales.tax_cat.push('')
				this.prcMcrItemSales.tax_class.push(tax_class)
				this.prcMcrItemSales.account_assign_grp.push(account_assign_grp)
				this.prcMcrItemSales.item_cat_grp.push(item_cat_grp)
				this.prcMcrItemSales.status.push('new')
				this.prcMcrItemSales.del_flag.push('')
            },
			copyValueSalesPlantLevel(key) {
				let sls_plant_code = this.prcMcrItemSalesPlant.sls_plant_code[key]
				let loading_group  = this.prcMcrItemSalesPlant.loading_group[key]
				let profit_center  = this.prcMcrItemSalesPlant.profit_center[key]

				this.prcMcrItemSalesPlant.sls_plant_code.push(sls_plant_code)
				this.prcMcrItemSalesPlant.loading_group.push(loading_group)
				this.prcMcrItemSalesPlant.profit_center.push(profit_center)
				this.prcMcrItemSalesPlant.status.push('new')
				this.prcMcrItemSalesPlant.del_flag.push('')
            },
            copyValueAccounting(key) {
				let plant_accounting    = this.prcMcrItemAccounting.plant_accounting[key]
				let valuation_class     = this.prcMcrItemAccounting.valuation_class[key]
				let valuation_category  = this.prcMcrItemAccounting.valuation_category[key]
				let valuation_type      = this.prcMcrItemAccounting.valuation_type[key]
				let price_control       = this.prcMcrItemAccounting.price_control[key]
				let price_determination = this.prcMcrItemAccounting.price_determination[key]
				let standard_price      = this.prcMcrItemAccounting.standard_price[key]
				let price_unit          = this.prcMcrItemAccounting.price_unit[key]
				let price_reference     = this.prcMcrItemAccounting.price_reference[key]

                this.prcMcrItemAccounting.plant_accounting.push(plant_accounting)
				this.prcMcrItemAccounting.valuation_class.push(valuation_class)
                this.prcMcrItemAccounting.valuation_category.push(valuation_category)
                this.prcMcrItemAccounting.valuation_type.push(valuation_type)
                this.prcMcrItemAccounting.price_control.push(price_control)
                this.prcMcrItemAccounting.price_determination.push(price_determination)
                this.prcMcrItemAccounting.standard_price.push(standard_price)
                this.prcMcrItemAccounting.price_unit.push(price_unit)
                this.prcMcrItemAccounting.price_reference.push(price_reference)
                this.prcMcrItemAccounting.status.push('new')
                this.prcMcrItemAccounting.del_flag.push('')
            },
            copyValueCosting(key) {
				let plant_costing    = this.prcMcrItemCosting.plant_costing[key]
				let overhead_group   = this.prcMcrItemCosting.overhead_group[key]
				let costing_lot_size = this.prcMcrItemCosting.costing_lot_size[key]

				this.prcMcrItemCosting.plant_costing.push(plant_costing)
                this.prcMcrItemCosting.overhead_group.push(overhead_group)
                this.prcMcrItemCosting.costing_lot_size.push(costing_lot_size)
                this.prcMcrItemCosting.status.push('new')
                this.prcMcrItemCosting.del_flag.push('')
            },
            copyValueQm(key) {
				let plant_qm         = this.prcMcrItemQm.plant_qm[key]
				let inspection_type  = this.prcMcrItemQm.inspection_type[key]
				let qm_material_auth = this.prcMcrItemQm.qm_material_auth[key]

				this.prcMcrItemQm.plant_qm.push(plant_qm)
                this.prcMcrItemQm.inspection_type.push(inspection_type)
                this.prcMcrItemQm.qm_material_auth.push(qm_material_auth)
                this.prcMcrItemQm.status.push('new')
            },
			customInputPoTextMemo() {
				this.input.prc_po_text_memo = this.input.prc_po_text_memo.toUpperCase()

				let charlimit = 130;
				let lines = this.input.prc_po_text_memo.split('\n');
				for(var i = 0; i < lines.length;i++){
					if (lines[i].length <= charlimit){
						continue;
					}
					var j = 0; 
					var space = charlimit;
					while (j++ <= charlimit) {
						if (lines[i].charAt(j) === ' '){
							space = j;
						}
					}
					lines[i + 1] = lines[i].substring(space + 1) + (lines[i + 1] || "");
					lines[i] = lines[i].substring(0, space);
				}
				this.input.prc_po_text_memo = lines.slice(0, charlimit).join('\n');
			},
			copyAllValue(){ // Copy all value from other material
				this.confirmDialog(this.tt("confirm_copy")).then(result => {
			        if (result.value) {
						this.input.material_number = this.materialNumberCopy
						
						// ==== GET DATA VALUE ALL ====
						this.changeMaterialNumber('copyValue');
					}
			    });
			},
			getWarehouse(query){
				if(query.length > 0){
					let context = this;  
					context.selectSearch.warehouseNumber = true;             
					Api(context, warehouse.get('none', {search: query})).onSuccess(function(response) {    
						context.warehouseNumbers = response.data.data.data.data;   
					}).onError(function(error) {                    
						if (error.response.status == 404) {
							context.warehouseNumbers = [];
						}
					}).onFinish(() => {
						context.selectSearch.warehouseNumber= false;
					})
					.call()
				}
			},
			changeWarehouseNumber1(key, warehouse_number){
				this.warehouseManagement1.stock_removal[key] = ''   
				this.warehouseManagement1.stock_replacement[key] = '' 
				this.warehouseManagement1.storage_section_indicator[key] = ''  
				
				this.warehouseManagement2.warehouse_number.forEach((w2, index) => {
					let existsWarehouse = this.warehouseManagement1.warehouse_number.some((w1) => w1 === w2)
					if(!existsWarehouse){
						this.warehouseManagement2.warehouse_number.splice(index, 1)
						this.warehouseManagement2.storage_type_indicator.splice(index, 1)
						this.warehouseManagement2.storage_bin.splice(index, 1)
						delete this.storageBins[index]
						delete this.storageTypes[index]
					}
				})
				this.dependWarehouseNumber1(key, warehouse_number)
			},
			dependWarehouseNumber1(key, warehouse_number){
				let context = this; 

				Api(context, warehouse.storageType('none', {warehouse_number: warehouse_number})).onSuccess(function(response) {   
					context.stockRemovals[key] = response.data.data.data.data;  
					context.stockReplacements[key] = response.data.data.data.data;  
					context.$forceUpdate()        
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.stockRemovals[key] = [];  
						context.stockReplacements[key] = [];  		
                    }
                }).call()  

                Api(context, warehouse.storageSection('none', {warehouse_number: warehouse_number})).onSuccess(function(response) {  
                    context.storageSections[key] = response.data.data.data.data;   
					context.$forceUpdate() 
                }).onError(function(error) {                    
                    if (error.response.status == 404) { 
                        context.storageSections[key] = [];
                    }
                }).call()
			},
			changeWarehouseNumber2(key, warehouse_number){
				this.warehouseManagement2.storage_type_indicator[key] = ''
				this.warehouseManagement2.storage_bin[key] = ''
				this.dependWarehouseNumber2(key, warehouse_number)

			},
			dependWarehouseNumber2(key, warehouse_number){
				let context = this;               
                Api(context, warehouse.storageType('none', {warehouse_number: warehouse_number})).onSuccess(function(response) { 
                    context.storageTypes[key] = response.data.data.data.data; 
					context.$forceUpdate()     
                }).onError(function(error) {                    
                    if (error.response.status == 404) {	
                        context.storageTypes[key] = [];
                    }
                }).call()
				
			},
			changeStorageType(key, warehouse_number, storage_type){
				this.warehouseManagement2.storage_bin[key] = ''
				this.dependStorageType(key, warehouse_number, storage_type);
			},
			dependStorageType(key, warehouse_number, storage_type){
				let context = this;
				let queries = {
					storage_type    : storage_type,
					warehouse_number : warehouse_number,
				}               
                Api(context, warehouse.storageBin('none', queries)).onSuccess(function(response) {
                    context.storageBins[key] = response.data.data.data.data; 
					context.$forceUpdate()     
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.storageBins[key] = [];
                    }
                }).call()
			},
			// showShp(eqp,code,prc_uom_code){
				// this.$emit('showShpB', eqp, code, this.input.id, prc_uom_code)
			// },
			showShp(eqp){
				this.$emit('showShpB', eqp, this.valuesOfShpForm[eqp])
			},
			checkEquipment(key){
				let plantCode = this.prcMcrItemEquipment.plant_equipment_code[key]
				let equipmentCode = this.prcMcrItemEquipment.equipment_code[key]
				let checked = false;

				this.prcMcrItemEquipment.plant_equipment_code.forEach((element, index) => {
					if(plantCode.includes(element) && index != key){
						if(this.prcMcrItemEquipment.equipment_code[index] == equipmentCode){
							checked = true;
						}
					}	
				})

				if(checked){
					this.$notify({
                        message: 'Plant Code and Equipment is Already Exists',                  
                        type: 'danger',
                        verticalAlign: "bottom",
			            horizontalAlign: "left"
                    });
					this.prcMcrItemEquipment.plant_equipment_code[key] = ''
					this.prcMcrItemEquipment.equipment_code[key] = ''
				}
			},
			prcMcrItemMpnShortdes(key) {
				var total = this.prcMcrItemMpn.shortdes.filter((v) => {
					return v == true;
				}).length

				if (total > 0) {
					if (this.prcMcrItemMpn.shortdes[key]) {
						return false
					} else {
						return true
					}
				}
			},
			changePlantDependMaster(){

				let newPlant = this.prcMcrItemPlant.plant

				if(Array.isArray(this.prcMcrItemSloc.plant_code)){
					this.prcMcrItemSloc.plant_code.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemSloc.plant_code[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemPurchasing.plant_purchasing)){
					this.prcMcrItemPurchasing.plant_purchasing.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemPurchasing.plant_purchasing[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemMrp.plant_mrp)){
					this.prcMcrItemMrp.plant_mrp.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemMrp.plant_mrp[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemSalesPlant.sls_plant_code)){
					this.prcMcrItemSalesPlant.sls_plant_code.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemSalesPlant.sls_plant_code[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemAccounting.plant_accounting)){
					this.prcMcrItemAccounting.plant_accounting.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemAccounting.plant_accounting[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemCosting.plant_costing)){
					this.prcMcrItemCosting.plant_costing.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemCosting.plant_costing[index] = ""
						}
					})
				}

				if(Array.isArray(this.prcMcrItemEquipment.plant_equipment_code)){
					this.prcMcrItemEquipment.plant_equipment_code.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemEquipment.plant_equipment_code[index] = ""
						}
					})
				}
				
				if(Array.isArray(this.prcMcrItemQm.plant_qm)){
					this.prcMcrItemQm.plant_qm.forEach((element, index) => {
						if(!newPlant.some((np) => element == np)){
							this.prcMcrItemQm.plant_qm[index] = ""
						}
					})
				}
			}
        }   
    };
</script>
<style>
	.my-autocomplete li {
		line-height: normal;
		padding: 7px;
	}

	.my-autocomplete li .value {
		text-overflow: ellipsis;
		overflow: hidden;
	}
</style>

<!-- ///// TYPE B2 /////
Create
1. Semua tombol add, delete muncul 

Edit
1. Semua tombol add,delete muncul
2. Get all data from table prc, getDetailMcrItem() 
--> 

<!-- ///// TYPE B3 /////
Create
1. Plant disable, tidak bisa di edit
2. Tombol delete di hide, tidak bisa delete
3. tombol delete hanya muncul di tab yang bisa di add dan hanya di item yang statusnya "new", kecuali attachment dan keyword

Edit
1. Plant disable, tidak bisa edit di edit
2. Tombol delete di hide, tidak bisa delete
3. Get all data from table prc dan jangan jalankan function changeMaterialNumber()
4. tombol delete hanya muncul di tab yang bisa di add dan hanya di item yang statusnya "new", kecuali attachment dan keyword
--> 

<!-- ///// TYPE APPROVAL PROCESS A1 /////
1. Load data from mcr_item, mcr_item_* untuk contekan di view A1 dan jadi default value di B2 (kondisi bila belum di proses)
2. Load data from table prc getDetailMcrItem(), untuk loop data yang sudah di proses
3. Semua tombol add, delete muncul
-->