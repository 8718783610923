import Service from '@/helpers/service'

export default {

    get(page, params) {
        return Service().get('manufacture?page=' + page, {params});
    }, 
    show(id) {
        return Service().get('manufacture/show/'+id);
    },    
    create(params) {
        return Service().post('manufacture/create', params);
    },
    update(id, params) {
        return Service().post('manufacture/update/'+id, params);
    },
    delete(id) {
        return Service().post('manufacture/delete/'+id);
    }
}