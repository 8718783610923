<template>
    <div>
        <label class="form-control-label">Level <span class="text-danger">*</span></label>
        <base-input :name="tt('client_level')" rules="required">
            <el-select v-model="client_plant_level" :placeholder="tt('choose_client_level_or_plant_level')" :disabled="formType == 'detail' || formType == 'approval'">
                <el-option value="Client" label="Client" key="Client"></el-option>
                <el-option value="Plant" label="Plant" key="Plant"></el-option>
                <el-option value="SHP" label="Stock Holding Policy" key="Stock Holding Policy"></el-option>
            </el-select>
        </base-input>

        <McrFormB v-if="client_plant_level == 'Client' || client_plant_level == 'Plant'" :clientPlantLevel="client_plant_level" @saveB="processB3"></McrFormB>
        <McrFormShp v-if="client_plant_level == 'SHP'" :clientPlantLevel="client_plant_level" @saveShp="processB3"></McrFormShp>
    </div>
</template>
<script>
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import McrFormB from "./McrFormB.vue";
    import McrFormShp from "./McrFormShp.vue";

    export default {       
        components: {
            McrFormB,
            McrFormShp,
        } ,
        props:['saveB'],
        data() {
            return {
                client_plant_level: '',
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
            }
        },
        mounted() {
            this.getDetailMcrItem()
        },
        methods: {
            getDetailMcrItem() {
                let context = this; 
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.client_plant_level = response.data.data[0].client_plant_level;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.client_plant_level = '';
                    }
                }).call()
            },
            processB3(){
                this.$emit('saveB3')
            }
        }
    };
</script>