<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div id="accordionBds">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneBds">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.bds.show, 'bds')"
	                    >{{ tt("basic_data_&_specification") }}
	                    <span class="text-danger">*</span></a
	                  >
	                </h5>
	              </div>
	            </div>
	          </div>
	          <div id="collapseBds" :class="'collapse ' + accordion.bds.show">
	            <div class="card-body border">
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("material_type") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('material_type')" rules="required">
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.mtype_code"
	                      :placeholder="tt('choose_material_type')"
	                      @change="changeZSP601"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="mt.code"
	                        :label="mt.code + ' - ' + mt.description"
	                        :key="mt.code"
	                        v-for="mt in materialType"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
					<div class="col-md-6">
						<label class="form-control-label"
						>{{ tt("item_type") }}
							<span class="text-danger">*</span></label
						>
						<base-input :name="tt('item_type')" rules="required">
							<el-select
								:disabled="form.disabled"
								class="select-danger"
								v-model="input.item_type_code"
								:placeholder="tt('choose_item_type')"
							>
								<el-option
									class="select-danger"
									:value="it"
									:label="it"
									:key="it"
									v-for="it in itemType"
								></el-option>
							</el-select>
						</base-input>
					</div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label">{{ tt("plant") }} <span class="text-danger">*</span></label >
	                  <base-input :name="tt('plant')" rules="required">
	                  	<el-select v-model="input.plant_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.disabled">
	                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("fabric_or_non_fabrication") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input
	                    :name="tt('fabric_or_non_fabrication')"
	                    rules="required"
	                  >
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.fab_non_fab"
	                      :placeholder="tt('choose_fabric_or_non_fabrication')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="fnf"
	                        :label="fnf"
	                        :key="fnf"
	                        v-for="fnf in fabNonFab"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("uom") }} <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('uom')" rules="required">
	                  	<el-select v-model="input.uom_code" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 1})" :remote-method="getUnitOfMeasure" :loading="inputTo.loading" :disabled="form.disabled">
	                        <el-option v-for="uc in unitOfMeasure" :key="uc.code" :label="uc.code +' - '+ uc.description" :value="uc.code"></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	                <div class="col-md-6">
	                  <label class="form-control-label"
	                    >{{ tt("bom_or_non_bom") }}
	                    <span class="text-danger">*</span></label
	                  >
	                  <base-input :name="tt('bom_or_non_bom')" rules="required">
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.bom_non_bom"
	                      :placeholder="tt('choose_bom_or_non_bom')"
	                    >
	                      <el-option
	                        class="select-danger"
	                        :value="bnb"
	                        :label="bnb"
	                        :key="bnb"
	                        v-for="bnb in bomNonBom"
	                      ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
	              </div>
	              <div class="row">
	                <div class="col-md-6">
	                  <label class="form-control-label">{{ tt("critical_non_critical") }}<span class="text-danger">*</span></label>
	                  <base-input
	                    :name="tt('critical_non_critical')"
	                    rules="required"
	                  >
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.critical_non_critical"
	                      :placeholder="tt('choose_critical_non_critical')"
	                    >
		                    <el-option
		                        class="select-danger"
		                        :value="cnc"
		                        :label="cnc"
		                        :key="cnc"
		                        v-for="cnc in criticalNonCritical"
		                    ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
					<div class="col-md-6">
	                  <label class="form-control-label">{{ tt("repairable_non_repairable") }}<span class="text-danger">*</span></label>
	                  <base-input
	                    :name="tt('repairable_non_repairable')"
	                    rules="required"
	                  >
	                    <el-select
	                      :disabled="form.disabled"
	                      class="select-danger"
	                      v-model="input.is_repairable"
	                      placeholder="Choose Repairable/Non Repairable"
	                    >
		                    <el-option
		                        class="select-danger"
		                        :value="rnr"
		                        :label="rnr"
		                        :key="rnr"
		                        v-for="rnr in repairableNonRepairable"
		                    ></el-option>
	                    </el-select>
	                  </base-input>
	                </div>
					<div class="col-md-6">
						<label class="form-control-label">{{ tt("price_reference") }}</label>
						<div class="row">
							<el-select v-model="input.currency" placeholder="Currency" :disabled="form.disabled" class="col-4">
								<el-option value="" label="" key=""></el-option>
								<el-option value="Rp" label="Rupiah (Rp)" key="Rp"></el-option>
								<el-option value="$" label="Dollar ($)" key="$"></el-option>
							</el-select>
							<base-input
								:disabled="form.disabled"
								:name="tt('price_reference')"
								:placeholder="tt('price_reference')"
								v-model="input.price_reference"
								class="col-8"
								type="number"
							></base-input>
						</div>
					</div>
	              </div>
	              <label class="form-control-label">{{ tt("item_name") }} <span class="text-danger">*</span></label>
	              <base-input
	                :disabled="form.disabled"
	                :name="tt('item_name')"
	                :placeholder="tt('item_name')"
	                v-model="input.item_name"
	                @input="input.item_name = input.item_name.toUpperCase()"
	                rules="required"
	              ></base-input>

	              <label class="form-control-label">{{ tt("specification") }}<span class="text-danger">*</span></label>
	              <base-input :name="tt('specification')" rules="required">
	                <textarea
	                  :disabled="form.disabled"
	                  class="form-control"
	                  rows="5"
	                  v-model="input.specification"
	                  @input="input.specification = input.specification.toUpperCase(), fixCursor($event)"
	                ></textarea>
	              </base-input>
	            </div>
	          </div>
	        </div>
	      </div>

	      <!-- ------------------------ MANUFACTURE -------------------------- -->
	      <div id="accordionMpn">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneMpn">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
						<a
							href="javascript:void(0)"
							@click="toggleAccordion(accordion.mpn.show, 'mpn')">
							{{ tt("manufacturer_part_number") }}
						</a>
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addMpn">
	                  {{ tt("add_new") }}
	                  </base-button>
	              </div>
	            </div>
	          </div>
	          <div id="collapseMpn" :class="'collapse ' + accordion.mpn.show">
	            <table class="table align-items-center table-flush table-bordered">
	                <thead class="card-header bg-primary">
	                  <tr>
	                    <th width="500" class="text-white">{{ tt("manufacturer_code") }} <span class="text-danger">*</span></th>
	                    <th class="text-white">{{ tt("manufacture_ref") }} <span class="text-danger">*</span></th>
	                    <th class="text-white">{{ tt("source_type") }} <span class="text-danger">*</span></th>
	                    <th class="text-white">{{tt('position')}}</th>
	                    <th class="text-white" width="15%">{{ tt("type") }} <span class="text-danger">*</span></th>
	                    <th class="text-white">{{ tt("note") }}</th>
	                    <th class="text-white">#</th>
	                  </tr>
	                </thead>
	                <tbody>
	                  <tr
	                    v-for="(mpn, key) in manufacturePartNumber.manufacture_code"
	                    v-bind:key="mpn.manufacture_code"
	                  >
	                    <td>
	                    	<el-select style="width: 100%;" v-model="manufacturePartNumber.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.disabled">
		                        <el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
		                    </el-select>
	                    </td>
	                    <td>
	                      <input
	                      	:disabled="form.disabled"
	                        class="form-control"
	                        :name="tt('mpn')"
	                        :placeholder="tt('mpn')"
	                        v-model="manufacturePartNumber.mpn[key]"
	                        @input="manufacturePartNumber.mpn[key] = manufacturePartNumber.mpn[key].toUpperCase()"
	                        rules="required"/>
	                    </td>
	                    <td>
	                      <el-select :disabled="form.disabled" v-model="manufacturePartNumber.source_type[key]">
	                        <el-option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{ st.code +' - '+ st.description }}</el-option>
	                      </el-select>
	                    </td>
	                    <td>
	                      <input :disabled="form.disabled || manufacturePartNumber.source_type[key] != 'DWG'" class="form-control" :name="tt('position')" :placeholder="tt('position')" v-model="manufacturePartNumber.position[key]" style='width: 100px;'
	                      />
	                    </td>
	                    <td>
	                      <el-select :disabled="form.disabled" v-model="manufacturePartNumber.manufacture_type[key]">
	                        <el-option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{ mt.description }}</el-option>
	                      </el-select>
	                    </td>
	                    <td>
	                      <input :disabled="form.disabled" class="form-control" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.manufacture_note[key]" @input="manufacturePartNumber.manufacture_note[key] = manufacturePartNumber.manufacture_note[key].toUpperCase()" style='width: 100px;'
	                      />
	                    </td>
	                    <td>
	                      <i v-if="!form.disabled" class="fa fa-trash text-danger" @click="removeMpn(key)"></i>
	                    </td>
	                  </tr>
	                </tbody>
	            </table>
	          </div>
	        </div>
	      </div>

	     <!-- --------------------- EQUIPMENT & QUANTITY INSTALL ---------------  -->
	      <div id="accordionEqi">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneEqi">
	            <div class="row">
	              <div class="col-md-6">
	                <h5>
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.eqi.show, 'eqi')"
	                    >{{ tt("equipment_&_quantity_install") }}</a>
	                    <span class="text-danger" v-if= "input.bom_non_bom == 'BOM' || input.item_type_code == 'OEM' || input.fab_non_fab == 'Fabrication'"> *</span>
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addEqi"
	                  >{{ tt("add_new") }}</base-button
	                >
	              </div>
	            </div>
	          </div>
	          <div id="collapseEqi" :class="'collapse ' + accordion.eqi.show">
	            <table class="table align-items-center table-flush table-bordered">
	                <thead class="card-header bg-primary">
	                  <tr>
	                    <th width="500" class="text-white">{{ tt("equipment_code") }} <span class="text-danger">*</span></th>
	                    <th width="200" class="text-white">{{ tt("quantity_install") }} <span class="text-danger">*</span></th>
	                    <th width="200" class="text-white">{{ tt("drawing_number") }} <span class="text-danger">*</span></th>
	                    <th width="200" class="text-white">{{ tt("attachment") }} </th>
						<th width="100" class="text-white" v-if="mcrType == 'A1S'">{{ tt('shp')}} <span class="text-danger" v-if="['suku_cadang_penyangga','not_applicable'].includes(varMcrType)">*</span></th>
	                    <th class="text-white">#</th>
	                  </tr>
	                </thead>
	                <tbody>
	                  <tr
	                    v-for="(eqi,
	                    key) in equipmentQuantityInstall.equipment_code"
	                    v-bind:key="eqi.equipment_code"
	                  >
	                    <td>
	                    	<el-select style="width: 100%;" size="small" v-model="equipmentQuantityInstall.equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipmentCode" :loading="inputTo.loading" :disabled="form.disabled" @change="storeFocusedEquipment(equipmentQuantityInstall.equipment_code[key]), clearDrawing(key), setSelectedEqpCodeIntoShpForm(equipmentQuantityInstall.equipment_code[key], key)">
		                        <el-option v-for="ec in equipmentCode" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
		                    </el-select>
	                    </td>
	                    <td>
	                      <input
	                      	:disabled="form.disabled"
	                        class="form-control"
	                        :name="tt('qty_install')"
	                        :placeholder="tt('qty_install')"
	                        v-model="equipmentQuantityInstall.qty_installed[key]"
	                        @input="equipmentQuantityInstall.qty_installed[key] = equipmentQuantityInstall.qty_installed[key].toUpperCase()"
	                        rules="required"/>
	                    </td>
	                    <td>
	                    	<el-autocomplete 
	                    		:disabled="form.disabled" 
	                    		class="form-control-sm col-md-12 mb-4" 
	                    		v-model="equipmentQuantityInstall.drawing_number[key]" 
	                    		@input="equipmentQuantityInstall.drawing_number[key] = equipmentQuantityInstall.drawing_number[key].toUpperCase()"
	                    		:fetch-suggestions="querySearchDrawingMaster" 
	                    		:placeholder="tt('drawing_number')" 
	                    		:trigger-on-focus="false" 
	                    		@select="selectDrawingNumber" 
	                    		size="big" 
	                    		@focus="selectedDrawingMasterKey(key)" 
	                    		@change="dmAttachment(key)">
	                    	</el-autocomplete>
	                    </td>
	                    <td>
	                    	<span style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'drawing_master/'+dm_attachment[key])">
                                {{dm_attachment[key]}}
                            </span>
	                    </td>
						<td v-if="mcrType == 'A1S'">
							<!-- <i 
								class="ni ni-fat-add text-primary" 
								:style="{cursor :equipmentQuantityInstall.save_process[key] == 'unsaved' || varMcrType == 'consumable' ? 'not-allowed' : 'pointer', 
								pointerEvents:equipmentQuantityInstall.save_process[key] == 'unsaved' || varMcrType == 'consumable' ? 'none' : '', fontSize: '20px'}" 
								@click="showShp(equipmentQuantityInstall.equipment_code[key], equipmentQuantityInstall.code[key], input.uom_code, input.price_reference, valuesOfShpForm[key], key)"
							></i>
							<i class="ni ni-check-bold text-success ml-2" v-if="equipmentQuantityInstall.code[key]" style="font-size: 17px"></i> -->

							<i 
								class="ni ni-fat-add text-primary" 
								:style="{cursor : equipmentQuantityInstall.equipment_code[key] == '' || varMcrType == 'consumable' ? 'not-allowed' : 'pointer', 
								pointerEvents: equipmentQuantityInstall.equipment_code[key] == '' || varMcrType == 'consumable' ? 'none' : '', fontSize: '20px'}" 
								@click="showShp(equipmentQuantityInstall.equipment_code[key])"
							></i>
							<i class="ni ni-check-bold text-success ml-2" v-if="equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db[key]" style="font-size: 17px"></i>
						</td>
	                    <td>
	                      <i v-if="!form.disabled" class="fa fa-trash text-danger" @click="removeEqi(key)" style="cursor: pointer"></i>
	                    </td>
	                  </tr>
	                </tbody>
				</table>
	          </div>
	        </div>
	      </div>

	      <!-- ---------------------------- ATTACHMENT ------------------------- -->
	      <div id="accordionAd">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneAd">
	            <div class="row">
	              <div class="col-md-6">
	                <h5>
						<a
							href="javascript:void(0)"
							@click="toggleAccordion(accordion.ad.show, 'ad')"
							>{{ tt("attachment_document") }}
							<span class="text-danger">*</span>
						</a>
	                </h5>
	              </div>
	              <div class="col-md-6 text-right">
	                <base-button
	                  v-if="!form.disabled"
	                  size="sm"
	                  type="default"
	                  @click="addAd"
	                  >{{ tt("add_new") }}
					</base-button>
	              </div>
	            </div>
	          </div>
	          <div id="collapseAd" :class="'collapse ' + accordion.ad.show">
	            <div class="table-responsive">
	                <table class="table align-items-center table-flush table-bordered">
	                  <thead class="card-header bg-primary">
	                    <tr>
	                      <th class="text-white">{{ tt("file_name") }} <span class="text-danger">*</span></th>
	                      <th class="text-white">{{ tt("description") }} <span class="text-danger">*</span></th>
	                      <th class="text-white">#</th>
	                    </tr>
	                  </thead>
	                  <tbody>

	                  	<!-- PREVIEW KETIKA EDIT -->
	                  	<tr v-for="att in prevAttachmentDocument">
	                  		<td>
	                  			<span style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'smartcat_attachment/'+att.file_attachment_name)">
	                                {{att.file_attachment_name}}
	                            </span>
	                  		</td>
	                  		<td>
	                  			<span>{{att.description_attachment}}</span>
	                  		</td>
	                  		<td>
	                  			<a href="javascript:void(0)" v-if="!form.disabled" @click="removePrevAd(att.id)"><i class="fa fa-trash text-danger"></i></a>
	                  		</td>
	                  	</tr>

	                  	<!-- INPUTAN ATTACHMENT -->
	                    <tr v-for="(ad, key) in attachmentDocument.file_attachment_name" v-bind:key="ad.file_name">
	                      <td>
	                        <file-input
	                          :disabled="form.disabled"
	                          @change="changeFileAttachmentName"
	                          :initialLabel="(ad.file_attachment_name) ? ad.file_attachment_name : maxValidation.text"
	                          :ref="'file' + key"
	                          accept="application/pdf"
							  :max-file="maxValidation.size"
	                        ></file-input>
	                      </td>
	                      <td>
	                      	<input :disabled="form.disabled" class="form-control" :name="tt('description')" :placeholder="tt('description')" v-model="attachmentDocument.description_attachment[key]" @input="attachmentDocument.description_attachment[key] = attachmentDocument.description_attachment[key].toUpperCase()" rules="required">
	                      </td>
	                      <td>
	                        <a href="javascript:void(0)" v-if="!form.disabled" @click="removeAd(key)"><i class="fa fa-trash text-danger"></i></a>
	                      </td>
	                    </tr>
	                  </tbody>
	                </table>
	            </div>
	          </div>
	        </div>
	      </div>

		<label class="form-control-label">{{ tt("note") }}</label>
		<base-input :name="tt('note')">
			<textarea
			  :disabled="form.disabled"
			  class="form-control"
			  rows="5"
			  v-model="input.note"
			  @input="input.note = input.note.toUpperCase(), fixCursor($event)">
			</textarea>
		</base-input>

        <div class="text-right mb-3" v-if="!form.disabled">
	        <base-button
	          type="primary"
	          @click="save()"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("save") }}</span>
			</base-button>
        </div>

        <!-- PREVIEW ATTACHMENT -->
        <preview-attachment :previewAttachmentModalProp='previewAttachmentModal' :attachmentFileProp='attachmentFile' :attachmentTypeProp='attachmentFileType' @changeModal="changeModalPrev"/>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import materialType from "@/services/master/materialType.service";
	import plantCode from "@/services/master/plantCode.service";
	import unitOfMeasure from "@/services/dictionary/unitOfMeasure.service";
	import manufacture from "@/services/master/manufacture.service";
	import manrefType from "@/services/master/manrefType.service";
	import equipmentCode from "@/services/master/equipmentCode.service";
	import drawingMaster from "@/services/dictionary/drawingMaster.service";
	import sourceType from "@/services/master/sourceType.service";
	import baseApiUrl from '@/configs/config';
	import mcrApproval from '@/services/dashboard/mcrApproval.service';
	import materialNumber from '@/services/master/materialNumber.service';

	export default {
		data() {
			return {
				requestCalls: 0,
				accordion: {
			        bds: {
						show: "show"
					},
					mpn: {
						show: ""
					},
					eqi: {
						show: ""
					},
					ad: {
						show: ""
					}
		      	},
		      	form: {
					disabled: false,
					btnSave: {
						onLoading: false
					}
			    },
			    inputTo: {
                    loading: false,
                    loadTimeout: null
                },
			    materialType: {},
			    itemType: {},
			    fabNonFab: {},
			    bomNonBom: {},
			    criticalNonCritical: {},
			    repairableNonRepairable: {},
			    manrefType: {},
			    sourceType: {},
			    drawingMaster: [],
			    plantCode: {},
				unitOfMeasure: {},
				manufacture: [],
				equipmentCode: [],
				fabNonFab: {},
				focusedEquipment: '',
				varMcrType: '',
				input: {
					id: "",
			        mtype_code: "",
			        item_type_code: "",
			        plant_code: "",
			        fab_non_fab: "",
			        uom_code: "",
			        uom_description: "",
			        bom_non_bom: "",
			        item_name: "",
			        specification: "",
			        critical_non_critical: "",
			        is_repairable: "",
			        currency: "",
			        price_reference: "",
			        note: "",
			    },
			    manufacturePartNumber: {
			        manufacture_code: [],
			        manufacture_code_view: [],
			        mpn: [],
			        source_type: [],
			        position: [],
			        manufacture_type: [],
			        manufacture_note: []
			    },
			    equipmentQuantityInstall: {
			        equipment_code: [],
			        old_equipment_code: [],
			        equipment_code_view: [],
			        qty_installed: [],
			        drawing_number: [],
					code:[],
					equipment_code_stored_in_db:[],
					code_shp_stored_in_db:[],
					save_process:[],
					shp_effect_spare_part_not_available_stored_in_db: [],
			    },
			    attachmentDocument: {
			        file_attachment_name: [],
			        description_attachment: []
			    },
				valuesOfShpForm: [],
				// formDataShp: {},
			    prevAttachmentDocument: {},
			    apiUrl: baseApiUrl.storageUrl,
			    dm_attachment: [],
			    previewAttachmentModal: false,
                attachmentFile: '',
                attachmentFileType: '',
                drawingMasterKey: '',

			    mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type,
			    token: this.$route.params.token,
				mcrType: this.$route.params.mcr_type,
				maxValidation:{
					size:null,
					text:null
				},

			}
		},
		computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
		mounted() {
			this.maximumValidation()
			this.getDetailMcrItem();
			this.getParameterMcr();
			this.getMaterialType();
		    this.getManrefType();
		    this.getSourceType();
			this.checkFormType();
		},
		methods: {
			maximumValidation(){
				this.maximumSizeAlert().then((res) =>{
					this.maxValidation.size = res.size
					this.maxValidation.text = res.text
				})
			},
			// CHECK FORM TYPE
			checkFormType() {
				if(this.formType == 'edit'){
					this.form.disabled = false;
				} else if (this.formType == 'detail'){
					this.form.disabled = true;
				} else if (this.formType == 'approval' && this.authUserPermission['mcr-approval-user-form-edit']){
					this.form.disabled = false;
				} else if (this.formType == 'sapSyncTaskEdit'){
					this.form.disabled = false;
				} else {
					this.form.disabled = true;
				}
			},
			// GET DATA MCR
			getDetailMcrItem() {
				this.requestCalls++;
				
				// show all accordion
				this.accordion.mpn.show = "show";
				this.accordion.eqi.show = "show";
				this.accordion.ad.show = "show";

		      	let context = this;

		      	Api(context, draftList.getDetailMcrItem(this.mcrItemCode))
		        .onSuccess(function(response) {
		          context.input = response.data.data[0];

				  if (response.data.data[0].plant_code != null) {
					context.plantCode[0] = {
						plant_code: response.data.data[0].plant_code,
						description: response.data.data[0].description,
					};
				  }

		          if (response.data.data[0].uom_code != null) {
					context.unitOfMeasure[0] = {
						code: response.data.data[0].uom_code,
						description: response.data.data[0].uom_description,
					}
				  }
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        })
		        .call();

		        // <!-- ------------------------ MANUFACTURE -------------------------- -->
				Api(context, draftList.getMcrItemMpn(this.mcrItemCode))
				.onSuccess(function(response) {
				  for (var i = 0; i < response.data.data.length; i++) {
				  	context.manufacture[i] = response.data.data[i];
				    context.manufacturePartNumber.manufacture_code[i] = response.data.data[i].manufacture_code;
				    context.manufacturePartNumber.manufacture_code_view[i] = response.data.data[i].manufacture_code +' - '+ response.data.data[i].manufacture_name;
				    context.manufacturePartNumber.mpn[i] = response.data.data[i].mpn;
				    context.manufacturePartNumber.source_type[i] = response.data.data[i].source_type;
				    context.manufacturePartNumber.position[i] = response.data.data[i].position;
				    context.manufacturePartNumber.manufacture_type[i] = response.data.data[i].manufacture_type;
				    context.manufacturePartNumber.manufacture_note[i] = response.data.data[i].manufacture_note;
				  }
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.manufacturePartNumber.manufacture_code = [];
				    context.manufacturePartNumber.mpn = [];
				    context.manufacturePartNumber.source_type = [];
				    context.manufacturePartNumber.position = [];
				    context.manufacturePartNumber.manufacture_type = [];
				    context.manufacturePartNumber.manufacture_note = [];
				  }
				})
				.call();

				// <!-- --------------------- EQUIPMENT & QUANTITY INSTALL ---------------  -->
				Api(context, draftList.getMcrItemEquipment(this.mcrItemCode))
				.onSuccess(function(response) {
				  for (var i = 0; i < response.data.data.length; i++) {
				  	context.equipmentCode[i] = response.data.data[i];
				    context.equipmentQuantityInstall.equipment_code[i] = response.data.data[i].equipment_code;
				    context.equipmentQuantityInstall.old_equipment_code[i] = response.data.data[i].equipment_code;
				    context.equipmentQuantityInstall.equipment_code_view[i] = response.data.data[i].equipment_code +' - '+ response.data.data[i].equipment_name;
				    context.equipmentQuantityInstall.qty_installed[i] = response.data.data[i].qty_installed;
				    context.equipmentQuantityInstall.drawing_number[i] = response.data.data[i].drawing_number;
				    context.equipmentQuantityInstall.code[i] = response.data.data[i].code;
				    context.equipmentQuantityInstall.equipment_code_stored_in_db[i] = response.data.data[i].equipment_code;
				    context.equipmentQuantityInstall.code_shp_stored_in_db[i] = response.data.data[i].code;
					context.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db[i] = response.data.data[i].shp_effect_spare_part_not_available
				    context.equipmentQuantityInstall.save_process[i] = 'save';
				    context.drawingMaster[i] = {
			          	drawing_number: response.data.data[i].drawing_number,
			          	drawing_name: response.data.data[i].drawing_name,
			        };
			        context.dmAttachment(i);
			        context.drawingMaster = []

					// GET DATA SHP
					if (context.mcrType == 'A1S') {
						context.getMcrFormShp(context.equipmentQuantityInstall.code[i], context.equipmentQuantityInstall.equipment_code[i], i)
					}
				  }
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.equipmentQuantityInstall.equipment_code = [];
					context.equipmentQuantityInstall.old_equipment_code = [];
				    context.equipmentQuantityInstall.qty_installed = [];
				    context.equipmentQuantityInstall.drawing_number = [];
				    context.equipmentQuantityInstall.code = [];
				    context.equipmentQuantityInstall.equipment_code_stored_in_db = [];
				    context.equipmentQuantityInstall.code_shp_stored_in_db = [];
					context.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db = []
				    context.equipmentQuantityInstall.save_process = [];
				  }
				})
				.onFinish(function() {
					// IF MCR TYPE SCP OR N/A AND ITS EQP CODE HAS BEEN STORED IN DB BUT SHP FORM HAS NOT BEEN FILLED YET
					// <!-- ---------------------------- EMIT STORED EQUIPMENT CODE AND CODE IN EQUIPMENT QUANTITY INSTALL --------------------------->
					if ((context.varMcrType === 'suku_cadang_penyangga' || context.varMcrType === 'not_applicable') && 
					context.equipmentQuantityInstall.equipment_code_stored_in_db.length > 0
					) {
						context.$emit('validateEqpMcrWithShpForm', context.equipmentQuantityInstall.equipment_code_stored_in_db, context.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db, 'reliability')
					}
				})
				.call();

				// <!-- ---------------------------- ATTACHMENT ------------------------- -->
				Api(context, draftList.getMcrItemAttachment(this.mcrItemCode))
				.onSuccess(function(response) {
					context.prevAttachmentDocument = response.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.prevAttachmentDocument = [];
				  }
				}).onFinish(function() {
                	context.requestCalls--;
                }).call()
		    },
		    getParameterMcr() {
		    	this.requestCalls++;
		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
					context.itemType            = response.data.data.item_type;
					context.fabNonFab           = response.data.data.fab_non_fab;
					context.bomNonBom           = response.data.data.bom_non_bom;
					context.criticalNonCritical = response.data.data.critical_non_critical;
					context.repairableNonRepairable = response.data.data.repairable_non_repairable;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        }).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getMaterialType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, materialType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.materialType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.materialType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getPlantCode(query) {
		 		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, plantCode.companyPlant({search: query})).onSuccess(function(response) {
                        context.plantCode =  response.data.data;   
                        if (query == '601') {
                        	context.input.plant_code = response.data.data[0].plant_code
                        }                                 
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.plantCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
		    getUnitOfMeasure(query) {
				clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, unitOfMeasure.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.unitOfMeasure =  response.data.data.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.unitOfMeasure = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
		    },
		    getManufacture(query) {
		    	if (query.length > 1) {
					clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, manufacture.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
	                        context.manufacture =  response.data.data.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.manufacture = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
            	}
		    },
		    getManrefType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, manrefType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.manrefType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.manrefType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getSourceType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, sourceType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.sourceType = response.data.data.data.data;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.sourceType = [];
				  }
				}).onFinish(function(){
                	context.requestCalls--;
                }).call();
		    },
		    getEquipmentCode(query) {
		    	if (query.length > 3) {
			  		clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, equipmentCode.getByCompany(null, {search: query})).onSuccess(function(response) {
	                        context.equipmentCode =  response.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.equipmentCode = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
	            }
		    },
		    getDrawingMaster(query) {
		   		clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                Api(context, drawingMaster.getByEquipment(null, {equipment_code: context.focusedEquipment})).onSuccess(function(response) {
                    response.data.data.forEach(function(item, index) {
                        context.drawingMaster[index] = {
                            'value': item['drawing_number'],
                        };
                    });                                
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                }).call();
		    },
		    dmAttachment(key) {
		    	let context = this;
                Api(context, drawingMaster.get(null, {per_page: 'none', search: context.equipmentQuantityInstall.drawing_number[key] })).onSuccess(function(response) {
       				context.dm_attachment[key] = response.data.data.data.data[0].file_name;
       				context.$forceUpdate()
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.dm_attachment = [];
                    }
                })
                .call()
		    },
		    storeFocusedEquipment(equipment) {
            	this.focusedEquipment = equipment;
            	this.getDrawingMaster()
            },
			setSelectedEqpCodeIntoShpForm(equipment, key) {
				// PASANG SELECTED EQP TO SHP FORM
				if (!this.equipmentQuantityInstall.old_equipment_code[key]) {
					//BLOCK INI UNTUK EQP QTY INSTALL BARU
					//JIKA EQUIPMENT BARU KEY valuesOfShpForm NYA ADALAH ANGKA KEY ARRAY
					this.changeEqpsAndShpEqpAndKeyValOfShpForm(equipment, key, key)

				} else if  (equipment !== this.equipmentQuantityInstall.old_equipment_code[key]) {
					//BLOCK INI UNTUK EQP QTY INSTALL YG SEBELUMNYA SUDAH ADA
					//JIKA EQUIPMENT LAMA KEY valuesOfShpForm NYA SUDAH EQUIPMENT CODE
					this.changeEqpsAndShpEqpAndKeyValOfShpForm(equipment, this.equipmentQuantityInstall.old_equipment_code[key], key)
				}
			},
			changeEqpsAndShpEqpAndKeyValOfShpForm(paramNewShp, paramOldShp, paramForOldEqpCode){
				if(this.valuesOfShpForm[paramOldShp]) {

					// PASANG SELECTED EQP TO SHP VALUE
					const selectedEqpForObjShpVal = this.equipmentCode.find(ec => ec.equipment_code === paramNewShp);
					if (selectedEqpForObjShpVal) {

						this.$set(this.valuesOfShpForm[paramOldShp], 'equipments', [{
							equipment_code: selectedEqpForObjShpVal.equipment_code,
							equipment_name: selectedEqpForObjShpVal.equipment_name,
						}]);

						// MENGGANTIKAN KEY (INTEGER) PADA valueOfShpForm YANG TELAH DIBUAT DI AWAL MENJADI EQUIPMENT CODE
						// DAN MEMASSANG VALUE KEY PADA VALUE SHP_EQUIPMENT
						this.$set(this.valuesOfShpForm[paramOldShp].input, 'shp_equipment', paramNewShp);
						this.$set(this.valuesOfShpForm, paramNewShp, this.valuesOfShpForm[paramOldShp]);

						// DELETE PARAM TWO
						this.$delete(this.valuesOfShpForm, paramOldShp);

						//CHANGE IN OLD EQP CODE
						this.equipmentQuantityInstall.old_equipment_code[paramForOldEqpCode] = paramNewShp
					}
				}
			},
			getMcrFormShp(code, key, id) {
				let context = this
				let query = {code: code}

				if (code) {
					if (!context.valuesOfShpForm[key]) {
						context.$set(context.valuesOfShpForm, key, { 
							input: {
								shp_mce_strategy: {
									mce_strategy: [],
									mce_interval: [],
									mce_net_pf: [],
									mce_tindakan: []
								},
								shp_failure_mode: [],
							}, 
							equipments: {},
							materialNumbers: [],
							manufacturePartNumber: {
								manufacture_code: [],
								manufacture_code_view: [],
								mpn: [],
								source_type: [],
								position: [],
								manufacture_type: [],
								manufacture_note: []
							},
							changeLog:[],
						})
					}

					Api(context, draftList.shp_mcr_item(query)).onSuccess(function(response) {
						context.valuesOfShpForm[key].input.id     									 = response.data.data.mcr_item_id != null ? response.data.data.mcr_item_id : ''
						context.valuesOfShpForm[key].input.code     								 = code
						context.valuesOfShpForm[key].input.material_number   						 = response.data.data.material_number != null ? response.data.data.material_number : ''
						if(response.data.data.shp_failure_mode) {
							let failureModes = JSON.parse(response.data.data.shp_failure_mode)
							context.valuesOfShpForm[key].input.shp_failure_mode = failureModes
						} else {
							context.valuesOfShpForm[key].input.shp_failure_mode = []
						}
						context.valuesOfShpForm[key].input.shp_qty  								 = response.data.data.shp_qty != null ? response.data.data.shp_qty : ''
						context.valuesOfShpForm[key].input.shp_uom  								 = response.data.data.shp_uom != null ? response.data.data.shp_uom : context.input.uom_code
						if(response.data.data.shp_mce_strategy){
							let mceStrategies = JSON.parse(response.data.data.shp_mce_strategy);
							let mceIntervals = JSON.parse(response.data.data.shp_interval);
							let mceNetPf = JSON.parse(response.data.data.shp_net_pf);
							let mceTindakan = JSON.parse(response.data.data.shp_tindakan);

							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy = mceStrategies
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval = mceIntervals
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_net_pf = mceNetPf
							context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan = mceTindakan
						} else {
							context.valuesOfShpForm[key].input.shp_mce_strategy = {
								mce_strategy: [],
								mce_interval: [],
								mce_net_pf: [],
								mce_tindakan: []
							};
						}
						context.valuesOfShpForm[key].input.shp_effect_spare_part_not_available  	 = response.data.data.shp_effect_spare_part_not_available != null ? response.data.data.shp_effect_spare_part_not_available : ''
						context.valuesOfShpForm[key].input.shp_demand_rate 							 = response.data.data.shp_demand_rate != null ? response.data.data.shp_demand_rate : ''
						context.valuesOfShpForm[key].input.shp_demand_rate_unit 					 = response.data.data.shp_demand_rate_unit != null ? response.data.data.shp_demand_rate_unit : ''
						context.valuesOfShpForm[key].input.shp_action_to_handle  					 = response.data.data.shp_action_to_handle != null ? response.data.data.shp_action_to_handle : ''
						context.valuesOfShpForm[key].input.shp_lead_time_action_to_handle  			 = response.data.data.shp_lead_time_action_to_handle != null ? response.data.data.shp_lead_time_action_to_handle : ''
						context.valuesOfShpForm[key].input.shp_consequences_criticality  			 = response.data.data.shp_consequences_criticality != null ? response.data.data.shp_consequences_criticality : ''
						context.valuesOfShpForm[key].input.shp_owner_estimate						 = response.data.data.shp_owner_estimate != null ? parseInt(response.data.data.shp_owner_estimate) : parseInt(context.input.price_reference)
						context.valuesOfShpForm[key].input.shp_estimated_loss_idr  					 = response.data.data.shp_estimated_loss_idr != null ? response.data.data.shp_estimated_loss_idr : ''
						context.valuesOfShpForm[key].input.shp_estimated_loss_day  					 = response.data.data.shp_estimated_loss_day != null ? response.data.data.shp_estimated_loss_day : ''
						context.valuesOfShpForm[key].input.shp_total_estimated_loss  				 = response.data.data.shp_total_estimated_loss != null ? response.data.data.shp_total_estimated_loss : ''
						context.valuesOfShpForm[key].input.shp_estimated_lead_time  				 = response.data.data.shp_estimated_lead_time != null ? response.data.data.shp_estimated_lead_time : ''
						context.valuesOfShpForm[key].input.shp_category_lead_time  					 = response.data.data.shp_category_lead_time != null ? response.data.data.shp_category_lead_time : ''
						context.valuesOfShpForm[key].input.shp_abc_indicator  						 = response.data.data.shp_abc_indicator != null ? response.data.data.shp_abc_indicator : ''
						context.valuesOfShpForm[key].input.shp_unit_price  							 = response.data.data.shp_unit_price != null ? response.data.data.shp_unit_price : ''
						context.valuesOfShpForm[key].input.shp_quantity_on_hand  					 = response.data.data.shp_quantity_on_hand != null ? response.data.data.shp_quantity_on_hand : '0'
						context.valuesOfShpForm[key].input.shp_value_unrestricted  					 = response.data.data.shp_value_unrestricted != null ? response.data.data.shp_value_unrestricted : ''
						context.valuesOfShpForm[key].input.shp_change_mrp_type  					 = response.data.data.shp_change_mrp_type != null ? response.data.data.shp_change_mrp_type : ''
						context.valuesOfShpForm[key].input.shp_resume_justification_stock_strategy   = response.data.data.shp_resume_justification_stock_strategy != null ? response.data.data.shp_resume_justification_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_equipment  							 = response.data.data.equipment_code != null ? response.data.data.equipment_code : ''
						context.valuesOfShpForm[key].input.shp_original_stock_strategy  			 = response.data.data.shp_original_stock_strategy  != null ? response.data.data.shp_original_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_stock_strategy  						 = response.data.data.shp_stock_strategy  != null ? response.data.data.shp_stock_strategy : ''
						context.valuesOfShpForm[key].input.shp_original_stock_level_type_calculation = response.data.data.shp_original_stock_level_type_calculation != null ? response.data.data.shp_original_stock_level_type_calculation : ''
						context.valuesOfShpForm[key].input.shp_stock_level_type_calculation 		 = response.data.data.shp_stock_level_type_calculation != null ? response.data.data.shp_stock_level_type_calculation : ''
						context.valuesOfShpForm[key].input.shp_original_service_level  				 = response.data.data.shp_original_service_level != null ? response.data.data.shp_original_service_level : ''
						context.valuesOfShpForm[key].input.shp_service_level  						 = response.data.data.shp_service_level != null ? response.data.data.shp_service_level : ''
						context.valuesOfShpForm[key].input.shp_original_stock_level  				 = response.data.data.shp_original_stock_level != null ? response.data.data.shp_original_stock_level : ''
						context.valuesOfShpForm[key].input.shp_stock_level  						 = response.data.data.shp_stock_level != null ? response.data.data.shp_stock_level : ''
						context.valuesOfShpForm[key].input.shp_original_min  						 = response.data.data.shp_original_min != null ? response.data.data.shp_original_min : ''
						context.valuesOfShpForm[key].input.shp_min  								 = response.data.data.shp_min != null ? response.data.data.shp_min : ''
						context.valuesOfShpForm[key].input.shp_original_max  						 = response.data.data.shp_original_max != null ? response.data.data.shp_original_max : ''
						context.valuesOfShpForm[key].input.shp_max  								 = response.data.data.shp_max != null ? response.data.data.shp_max : ''
						context.valuesOfShpForm[key].input.shp_date_during_analysis  				 = response.data.data.shp_date_during_analysis != null ? response.data.data.shp_date_during_analysis : ''	

						context.valuesOfShpForm[key].equipments = [{
							equipment_code: response.data.data.equipment_code,
							equipment_name: response.data.data.equipment_name,
						}];

						if(!!context.valuesOfShpForm[key].input.shp_equipment){
							context.matNumsInShpDependEquipment(context.valuesOfShpForm[key].input.shp_equipment, key)
						}

						// console.log('simpan shp value dari table', context.valuesOfShpForm[key])

					}).onError(function(error){
						if (error.response.status == 404) {
							// jika error hanya menambahkan object tanpa ada values
							context.addValuesOfShpForm(key, code)	
						}
					}).call()

				} else {
					// jika equipment baru ditambahkan, maka menambahkan object SHP Form tanpa values
					context.addValuesOfShpForm(key, "")
					
					// jika sudah ada equipmentnya di MCR, maka inputkan ke dalam object SHP
					if (context.equipmentQuantityInstall.equipment_code[id]) {
						context.$set(context.valuesOfShpForm[key], 'equipments', [{
							equipment_code: context.equipmentQuantityInstall.equipment_code[id],
							equipment_name: context.equipmentQuantityInstall.equipment_code_view[id].split(" - ")[1],
						}])
					}

				}
			},
			matNumsInShpDependEquipment(eqp, key) {                                   
				let context = this;   
				Api(context, materialNumber.get({search: '', equipment: eqp})).onSuccess(function(response) {
					context.valuesOfShpForm[key].materialNumbers = response.data.data;                        
				}).onError(function(error) {
					context.valuesOfShpForm[key].materialNumbers = [];
				}).call();  
            },
		    // ACTION IN MCR
			addMpn() {
			  this.accordion.mpn.show = "show";

		      if (this.manufacturePartNumber.manufacture_code.length == 0) {
		        this.manufacturePartNumber.manufacture_code.push("");
		        this.manufacturePartNumber.mpn.push("");
		        this.manufacturePartNumber.source_type.push("");
		        this.manufacturePartNumber.position.push("");
		        this.manufacturePartNumber.manufacture_type.push("");
		        this.manufacturePartNumber.manufacture_note.push("");
		      } else if (this.manufacturePartNumber.manufacture_code.length > 0) {
		        if (
		          this.manufacturePartNumber.manufacture_code[this.manufacturePartNumber.manufacture_code.length - 1] != "" &&
		          this.manufacturePartNumber.mpn[this.manufacturePartNumber.mpn.length - 1] != "" &&
		          this.manufacturePartNumber.source_type[this.manufacturePartNumber.source_type.length - 1] != "" &&
		          this.manufacturePartNumber.manufacture_type[this.manufacturePartNumber.manufacture_type.length - 1] != ""
		        ) {
					if (this.manufacturePartNumber.source_type[this.manufacturePartNumber.source_type.length - 1] == "DWG" && this.manufacturePartNumber.position[this.manufacturePartNumber.position.length - 1] == "") {
						this.$notify({
							message: this.tt("please_complete_data", { title: this.tt("mpn") }),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});

						return false;
					}

					this.manufacturePartNumber.manufacture_code.push("");
					this.manufacturePartNumber.mpn.push("");
					this.manufacturePartNumber.source_type.push("");
					this.manufacturePartNumber.position.push("");
					this.manufacturePartNumber.manufacture_type.push("");
					this.manufacturePartNumber.manufacture_note.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", { title: this.tt("mpn") }),
		            type: "danger",
					verticalAlign: "bottom",
					horizontalAlign: "left"
		          });
		        }
		      }
		    },
		    removeMpn(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.manufacturePartNumber.manufacture_code.splice(key, 1);
						this.manufacturePartNumber.mpn.splice(key, 1);
						this.manufacturePartNumber.source_type.splice(key, 1);
						this.manufacturePartNumber.position.splice(key, 1);
						this.manufacturePartNumber.manufacture_type.splice(key, 1);
						this.manufacturePartNumber.manufacture_note.splice(key, 1);
                    }
                })
		    },
		    addEqi() {
		      this.accordion.eqi.show = "show";

		      if (this.equipmentQuantityInstall.equipment_code.length == 0) {
		        this.equipmentQuantityInstall.equipment_code.push("");
				this.equipmentQuantityInstall.old_equipment_code.push("");
				this.equipmentQuantityInstall.equipment_code_stored_in_db.push("");
				this.equipmentQuantityInstall.code_shp_stored_in_db.push("");
				this.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db.push("")
		        this.equipmentQuantityInstall.qty_installed.push("");
		        this.equipmentQuantityInstall.drawing_number.push("");
		        this.equipmentQuantityInstall.code.push("");
		        this.equipmentQuantityInstall.save_process.push("unsaved");

				// Menginisialisasi Objek valuesOfShpForm
				this.addValuesOfShpForm(0, '')

		      } else if (this.equipmentQuantityInstall.equipment_code.length > 0) {
		        if (
		          this.equipmentQuantityInstall.equipment_code[
		            this.equipmentQuantityInstall.equipment_code.length - 1
		          ] != "" &&
		          this.equipmentQuantityInstall.qty_installed[
		            this.equipmentQuantityInstall.qty_installed.length - 1
		          ] != "" &&
		          this.equipmentQuantityInstall.drawing_number[
		            this.equipmentQuantityInstall.drawing_number.length - 1
		          ] != ""
		        ) {
		          this.equipmentQuantityInstall.equipment_code.push("");
		          this.equipmentQuantityInstall.old_equipment_code.push("");
				  this.equipmentQuantityInstall.equipment_code_stored_in_db.push("");
				  this.equipmentQuantityInstall.code_shp_stored_in_db.push("");
				  this.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db.push("")
		          this.equipmentQuantityInstall.qty_installed.push("");
		          this.equipmentQuantityInstall.drawing_number.push("");
		          this.equipmentQuantityInstall.save_process.push('unsaved');
		          this.equipmentQuantityInstall.code.push("");
		          this.dm_attachment.push("");

				  // Menginisialisasi Objek valuesOfShpForm
			      this.addValuesOfShpForm(this.equipmentQuantityInstall.equipment_code.length - 1, '')
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", {title: this.tt("equipment_&_quantity_install")}),
		            type: "danger",
					verticalAlign: "bottom",
					horizontalAlign: "left"
		          });
		        }
		      }

		    },
			addValuesOfShpForm(key, code) {
				let context = this
				this.$set(this.valuesOfShpForm, key, { 
					input: {
						id: context.input.id,
						code: code,
						material_number: '',
						shp_failure_mode: [],
						shp_qty: '',
						shp_uom: context.input.uom_code,
						shp_mce_strategy: {
							mce_strategy: [],
							mce_interval: [],
							mce_net_pf: [],
							mce_tindakan: []
						},
						shp_effect_spare_part_not_available: '',
						shp_demand_rate: '',
						shp_demand_rate_unit: '',
						shp_action_to_handle: '',
						shp_lead_time_action_to_handle: '',
						shp_consequences_criticality: '',
						shp_owner_estimate: context.input.price_reference,
						shp_estimated_loss_idr: '',
						shp_estimated_loss_day: '',
						shp_total_estimated_loss: '',
						shp_estimated_lead_time: '',
						shp_category_lead_time: '',
						shp_abc_indicator: '',
						shp_unit_price: '',
						shp_quantity_on_hand: '0',
						shp_value_unrestricted: '',
						shp_change_mrp_type: '',
						shp_resume_justification_stock_strategy: '',
						shp_equipment: Number.isInteger(key) ? "" : key,
						shp_original_stock_strategy: '',
						shp_stock_strategy: '',
						shp_original_stock_level_type_calculation: '',
						shp_stock_level_type_calculation: '',
						shp_original_service_level: '',
						shp_service_level: '',
						shp_original_stock_level: '',
						shp_stock_level: '',
						shp_original_min: '',
						shp_min: '',
						shp_original_max: '',
						shp_max: '',
						shp_date_during_analysis: '',
					},
					equipments: [],						
					materialNumbers: [], 
					manufacturePartNumber: {
						manufacture_code: [],
						manufacture_code_view: [],
						mpn: [],
						source_type: [],
						position: [],
						manufacture_type: [],
						manufacture_note: []
					},
					changeLog: []
				});
			},
		    removeEqi(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.equipmentQuantityInstall.equipment_code.splice(key, 1);
						this.equipmentQuantityInstall.old_equipment_code.splice(key, 1);
						this.equipmentQuantityInstall.equipment_code_stored_in_db.splice(key, 1);
						this.equipmentQuantityInstall.code_shp_stored_in_db.splice(key, 1);
						this.equipmentQuantityInstall.shp_effect_spare_part_not_available_stored_in_db.splice(key, 1);
						this.equipmentQuantityInstall.qty_installed.splice(key, 1);
						this.equipmentQuantityInstall.drawing_number.splice(key, 1);
						this.equipmentQuantityInstall.code.splice(key, 1);
						this.equipmentQuantityInstall.save_process.splice(key, 1);
						this.dm_attachment.splice(key, 1);

						// Menghapus Objek valuesOfShpForm
						this.valuesOfShpForm.splice(key,1)
                    }
                })
		    },
		    addAd() {
		      this.accordion.ad.show = "show";

		      if (this.attachmentDocument.file_attachment_name.length == 0) {
		        this.attachmentDocument.file_attachment_name.push("");
		        this.attachmentDocument.description_attachment.push("");
		      } else if (this.attachmentDocument.file_attachment_name.length > 0) {
		        if (
		          this.attachmentDocument.file_attachment_name[this.attachmentDocument.file_attachment_name.length - 1] != "" &&
		          this.attachmentDocument.description_attachment[this.attachmentDocument.description_attachment.length - 1] != "" 
		        ) {
		        	this.attachmentDocument.file_attachment_name.push("");
		        	this.attachmentDocument.description_attachment.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", {title: this.tt("attachment_document")}),
		            type: "danger",
					verticalAlign: "bottom",
					horizontalAlign: "left"
		          });
		        }
		      }
		    },
		    removeAd(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.attachmentDocument.file_attachment_name.splice(key, 1);
				        this.attachmentDocument.description_attachment.splice(key, 1);
                    }
                })
		    },
		    removePrevAd(id){
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemAttachment(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
								verticalAlign: "bottom",
								horizontalAlign: "left"
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
		    },
		    changeFileAttachmentName(files) {
				if(files.length === 0){
					return;
				}

		    	if (files[0].size > this.maxValidation.size) {
		    		this.attachmentDocument == [];
		    		this.$notify({
						message :this.maxValidation.text,
						type: 'danger',
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
					});
		    	} else {
		    		this.attachmentDocument.file_attachment_name[this.attachmentDocument.file_attachment_name.length - 1] = files[0];
		    	}
		    },
		    save() {
		      let context = this;
		      let formData = new FormData();

		      if (
		        context.input.mtype_code != "" &&
		        context.input.item_type_code != "" &&
		        context.input.plant_code != "" &&
		        context.input.fab_non_fab != "" &&
		        context.input.uom_code != "" &&
		        context.input.bom_non_bom != "" &&
		        context.input.item_name != "" &&
		        context.input.specification != "" &&
		        context.input.critical_non_critical != "" &&
		        context.input.is_repairable != ""
		       ) {

		      	// --------------------  BASIC DATA & SPECIFICATION ----------------------------
		        formData.append("mcr_code", context.mcr_code);
		        formData.append("mtype_code", context.input.mtype_code);
		        formData.append("item_type_code", context.input.item_type_code);
		        formData.append("plant_code", context.input.plant_code);
		        formData.append("fab_non_fab", context.input.fab_non_fab);
		        formData.append("uom_code", context.input.uom_code);
		        formData.append("bom_non_bom", context.input.bom_non_bom);
		        formData.append("item_name", context.input.item_name);
		        formData.append("specification", context.input.specification);
		        formData.append("critical_non_critical", context.input.critical_non_critical);
		        formData.append("repairable_non_repairable", context.input.is_repairable);
		        formData.append("currency", context.input.currency);
		        formData.append("price_reference", context.input.price_reference);
		        formData.append("note", (context.input.note == null || context.input.note.length == 0 ? '' : context.input.note));

		        // insert for prc
		        formData.append("prc_mtype_code", context.input.mtype_code);
		        formData.append("prc_item_type_code", context.input.item_type_code);
		        formData.append("prc_fab_non_fab", context.input.fab_non_fab);
		        formData.append("prc_uom_code", context.input.uom_code);
		        formData.append("prc_bom_non_bom", context.input.bom_non_bom);
		        formData.append("prc_critical_non_critical", context.input.critical_non_critical);
		        formData.append("prc_plant_code[]", context.input.plant_code);

		        // --------------------  FORM DATA MPN ----------------------------
		        if (context.manufacturePartNumber.manufacture_code.length) {
			        for (var i = 0; i < context.manufacturePartNumber.manufacture_code.length; i++) {
			          if (
			            context.manufacturePartNumber.manufacture_code[i] != "" &&
			            context.manufacturePartNumber.mpn[i] != "" &&
			            context.manufacturePartNumber.source_type[i] != "" &&
			            context.manufacturePartNumber.manufacture_type[i] != ""
			          ) {
						if (context.manufacturePartNumber.source_type[i] == "DWG" && context.manufacturePartNumber.position[i] == "") {
							this.$notify({
								message: this.tt("please_complete_data", { title: this.tt("mpn") }),
								type: "danger",
								verticalAlign: "bottom",
								horizontalAlign: "left"
							});

							return false;
						}

			            formData.append("manufacture_code[" + i + "]", context.manufacturePartNumber.manufacture_code[i]);
			            formData.append("mpn[" + i + "]", context.manufacturePartNumber.mpn[i]);
			            formData.append("source_type[" + i + "]", context.manufacturePartNumber.source_type[i]);
			            formData.append("position[" + i + "]", context.manufacturePartNumber.position[i]);
			            formData.append("manufacture_type[" + i + "]", context.manufacturePartNumber.manufacture_type[i]);
			            formData.append("manufacture_note[" + i + "]", context.manufacturePartNumber.manufacture_note[i]);

			            formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumber.manufacture_code[i])
						formData.append('prc_mpn['+i+']', context.manufacturePartNumber.mpn[i])
						formData.append('prc_source_type['+i+']', context.manufacturePartNumber.source_type[i])
						formData.append('prc_mpn_position['+i+']', context.manufacturePartNumber.position[i])
						formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumber.manufacture_type[i])
						formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumber.manufacture_note[i])
						formData.append('prc_manufacture_status['+i+']', 'new')
						formData.append('prc_manufacture_shortdes['+i+']', false)
			          } else {
			            context.$notify({
			              message: this.tt("please_complete_data", {title: this.tt("mpn")}),
			              type: "danger",
						  verticalAlign: "bottom",
						  horizontalAlign: "left"
			            });
			            return false;
			          }
			        }
			    } else {
                    formData.append("manufacture_code", '');
                    formData.append("mpn", '');
                    formData.append("source_type", '');
                    formData.append("position", '');
                    formData.append("manufacture_type", '');
                    formData.append("manufacture_note", '');

                    formData.append('prc_manufacture_code', '')
					formData.append('prc_mpn', '')
					formData.append('prc_source_type', '')
					formData.append('prc_mpn_position', '')
					formData.append('prc_manufacture_type', '')
					formData.append('prc_manufacture_note', '')
                }

		        // --------------------  FORM DATA EQUIPMENT & QUANTITY INSTALL ----------------------------
                if (context.equipmentQuantityInstall.equipment_code.length) {
			        for (var i = 0; i < context.equipmentQuantityInstall.equipment_code.length; i++) {
			          if (
			            context.equipmentQuantityInstall.equipment_code[i] != "" &&
			            context.equipmentQuantityInstall.qty_installed[i] != "" &&
			            context.equipmentQuantityInstall.drawing_number[i] != ""
			          ) {
			            formData.append("equipment_code[" + i + "]",context.equipmentQuantityInstall.equipment_code[i]);
			            formData.append("qty_installed[" + i + "]",context.equipmentQuantityInstall.qty_installed[i]);
			            formData.append("drawing_number[" + i + "]",context.equipmentQuantityInstall.drawing_number[i]);
			            formData.append("plant_equipment_code[" + i + "]",context.input.plant_code);

			            // insert for adopting to prc (cataloguer form)
			            formData.append('prc_plant_equipment['+i+']', context.input.plant_code)
			            formData.append("prc_equipment_code[" + i + "]",context.equipmentQuantityInstall.equipment_code[i]);
			            formData.append("prc_qty_installed[" + i + "]",context.equipmentQuantityInstall.qty_installed[i]);
			            // formData.append("prc_drawing_number[" + i + "]",context.equipmentQuantityInstall.drawing_number[i]);
						formData.append('prc_equipment_status['+i+']', 'new')
						formData.append('prc_equipment_show['+i+']', false)
			          } else {
			            context.$notify({
			              message: this.tt("please_complete_data", {
			                title: this.tt("equipment_&_quantity_install")
			              }),
			              type: "danger",
						  verticalAlign: "bottom",
						  horizontalAlign: "left"
			            });
			            return false;
			          }
			        }
			    } else {
                    if (context.input.bom_non_bom == 'BOM' || context.input.item_type_code == 'OEM' || context.input.fab_non_fab == 'Fabrication') {
                        context.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                            type: 'danger',
							verticalAlign: "bottom",
							horizontalAlign: "left"
                        });
                        return false;
                    } else {
                        formData.append("plant_equipment_code", '');
                        formData.append("equipment_code", '');
                        formData.append("qty_installed", '');
                        formData.append("drawing_number", '');

                        formData.append('prc_plant_equipment', '')
			            formData.append("prc_equipment_code", '');
			            formData.append("prc_qty_installed", '');
			            formData.append("prc_drawing_number", '');
                    }
                }

                // --------------------  FORM DATA ATTACHMENT ----------------------------
                if (context.attachmentDocument.file_attachment_name.length || context.prevAttachmentDocument.length ) {
			        for (var i = 0; i < context.attachmentDocument.file_attachment_name.length; i++) {
			          	if (context.attachmentDocument.file_attachment_name[i] != "" && context.attachmentDocument.description_attachment[i] != "") {
				            formData.append("file_attachment_name[" + i + "]", context.attachmentDocument.file_attachment_name[i]);
				            formData.append("description_attachment[" + i + "]", context.attachmentDocument.description_attachment[i]);
			          	} else {
			          		context.$notify({
				              message: this.tt("please_complete_data", {
				                title: this.tt("attachment_document")
				              }),
				              type: "danger",
							  verticalAlign: "bottom",
							  horizontalAlign: "left"
				            });
				            return false;
			          	}
			        }
			    }else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("attachment_document") }),                  
                        type: 'danger',
						verticalAlign: "bottom",
						horizontalAlign: "left"
                    });
                    return false;
                }

				// STATUS PROCESS
				formData.append('status_process', 'user_process')

		        context.form.btnSave.onLoading = true;

		        Api(context, draftList.updateMcrItem(formData, context.input.id)).onSuccess(function(response) {
		            context.$notify({
						message: response.data.message,
						type: "success",
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
		            });
					
					// UPDATE FORM SHP VALUES 
					if (context.equipmentQuantityInstall.equipment_code.length && context.mcrType == 'A1S') {
						for (var i = 0; i < context.equipmentQuantityInstall.equipment_code.length; i++) {
							context.saveFormDataForShpForm(context.equipmentQuantityInstall.equipment_code[i])
						}
					}
				})
				.onFinish(function() {
					context.form.btnSave.onLoading = false;
					if(context.mcrType == 'A1S'){
						context.$emit('saveA1', 'shp')
					}else{
						context.$emit('saveA1')
					}
					context.getDetailMcrItem();
					context.attachmentDocument.file_attachment_name =[]
					context.attachmentDocument.description_attachment =[]

					// let cekDataPerRowShp = []
					// for (let dex in context.formDataShp) {
					// 	if (context.formDataShp.hasOwnProperty(dex)) {
					// 		for (let [formKey, formValue] of context.formDataShp[dex].entries()) {
					// 			cekDataPerRowShp.push(`${formKey}: ${formValue}`);
					// 		}
					// 	}	
					// }
				})
				.call();

		        // for (var pair of formData.entries()) {
          //           console.log(pair[0]+ ', ' + pair[1]); 
          //       }
		      } else {
		        context.$notify({
		          message: this.tt("please_complete_data", {title: this.tt("basic_data_&_specification")}),
		          type: "danger",
				  verticalAlign: "bottom",
				  horizontalAlign: "left"
		        });
		      }
		    },
			saveFormDataForShpForm(key){
				let context 	= this
				let dataShpForm = new FormData();

				dataShpForm.append("client_plant_level", 'SHP');
				dataShpForm.append('status_process', 'shp_rel_process')
				dataShpForm.append('shp_equipment', context.valuesOfShpForm[key].input.shp_equipment)
				
				dataShpForm.append("shp_effect_spare_part_not_available", context.valuesOfShpForm[key].input.shp_effect_spare_part_not_available);
				dataShpForm.append("shp_action_to_handle", context.valuesOfShpForm[key].input.shp_action_to_handle);
				dataShpForm.append("shp_lead_time_action_to_handle", context.valuesOfShpForm[key].input.shp_lead_time_action_to_handle);
				dataShpForm.append("shp_consequences_criticality", context.valuesOfShpForm[key].input.shp_consequences_criticality);
				dataShpForm.append("shp_estimated_loss_idr", context.valuesOfShpForm[key].input.shp_estimated_loss_idr);
				dataShpForm.append("shp_estimated_loss_day", context.valuesOfShpForm[key].input.shp_estimated_loss_day);
				dataShpForm.append("shp_total_estimated_loss", context.valuesOfShpForm[key].input.shp_total_estimated_loss);
				dataShpForm.append("shp_estimated_lead_time", context.valuesOfShpForm[key].input.shp_estimated_lead_time);
				dataShpForm.append("shp_category_lead_time", context.valuesOfShpForm[key].input.shp_category_lead_time);
				dataShpForm.append("shp_abc_indicator", context.valuesOfShpForm[key].input.shp_abc_indicator);
				dataShpForm.append("shp_unit_price", context.valuesOfShpForm[key].input.shp_unit_price);
				dataShpForm.append("shp_quantity_on_hand", context.valuesOfShpForm[key].input.shp_quantity_on_hand);
				dataShpForm.append("shp_value_unrestricted", context.valuesOfShpForm[key].input.shp_value_unrestricted);
				dataShpForm.append("shp_original_stock_strategy", context.valuesOfShpForm[key].input.shp_original_stock_strategy);
				dataShpForm.append("shp_stock_strategy", context.valuesOfShpForm[key].input.shp_stock_strategy);
				dataShpForm.append("shp_change_mrp_type", context.valuesOfShpForm[key].input.shp_change_mrp_type);
				dataShpForm.append("shp_resume_justification_stock_strategy", context.valuesOfShpForm[key].input.shp_resume_justification_stock_strategy);
				dataShpForm.append("shp_original_stock_level_type_calculation", context.valuesOfShpForm[key].input.shp_original_stock_level_type_calculation);
				dataShpForm.append("shp_stock_level_type_calculation", context.valuesOfShpForm[key].input.shp_stock_level_type_calculation);
				dataShpForm.append("shp_original_service_level", context.valuesOfShpForm[key].input.shp_original_service_level);
				dataShpForm.append("shp_service_level", context.valuesOfShpForm[key].input.shp_service_level);
				dataShpForm.append("shp_original_stock_level", context.valuesOfShpForm[key].input.shp_original_stock_level);
				dataShpForm.append("shp_stock_level", context.valuesOfShpForm[key].input.shp_stock_level);
				dataShpForm.append("shp_demand_rate", context.valuesOfShpForm[key].input.shp_demand_rate);
				dataShpForm.append("shp_demand_rate_unit", context.valuesOfShpForm[key].input.shp_demand_rate_unit);
				dataShpForm.append("shp_qty", context.valuesOfShpForm[key].input.shp_qty);
				dataShpForm.append("shp_uom", context.valuesOfShpForm[key].input.shp_uom);
				dataShpForm.append("shp_original_min", context.valuesOfShpForm[key].input.shp_original_min);
				dataShpForm.append("shp_min", context.valuesOfShpForm[key].input.shp_min);
				dataShpForm.append("shp_original_max", context.valuesOfShpForm[key].input.shp_original_max);
				dataShpForm.append("shp_max", context.valuesOfShpForm[key].input.shp_max);
				dataShpForm.append("shp_date_during_analysis", context.valuesOfShpForm[key].input.shp_date_during_analysis);
				dataShpForm.append("shp_owner_estimate", parseInt(context.valuesOfShpForm[key].input.shp_owner_estimate));

				if(context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy.length){
					for(var i = 0; i < context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy.length;i++){
						if(context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy[i] != "" && context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval[i] != "" && context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan[i] != ""){
							dataShpForm.append("shp_interval[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_interval[i]);
							dataShpForm.append("shp_mce_strategy[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_strategy[i]);
							dataShpForm.append("shp_net_pf[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_net_pf[i]);
							dataShpForm.append("shp_tindakan[" + i + "]",context.valuesOfShpForm[key].input.shp_mce_strategy.mce_tindakan[i]);
						}else{
							context.$notify({
								message: context.tt("please_complete_data", {title: context.tt("mce_strategy_interval")}),
								type: "danger",
								verticalAlign: "bottom",
								horizontalAlign: "left"
							});
							return false;
						}
					}
				}

				if(context.valuesOfShpForm[key].input.shp_failure_mode.length){
					for(var i = 0; i < context.valuesOfShpForm[key].input.shp_failure_mode.length;i++){
						if(context.valuesOfShpForm[key].input.shp_failure_mode[i] != ""){
							dataShpForm.append("shp_failure_mode[" + i + "]",context.valuesOfShpForm[key].input.shp_failure_mode[i]);
						}else{
							context.$notify({
								message: context.tt("please_complete_data", {title: context.tt("failure_mode")}),
								type: "danger",
								verticalAlign: "bottom",
								horizontalAlign: "left"
							});
							return false;
						}
					}
				}

				// CHANGE LOG
				if (context.valuesOfShpForm[key].changeLog.length) {
					for(const num in context.valuesOfShpForm[key].changeLog) {
						dataShpForm.append('change_log['+ num +']', context.valuesOfShpForm[key].changeLog[num])
					}	
				}

				Api(context, draftList.updateMcrItem(dataShpForm, context.valuesOfShpForm[key].input.id))
				.onSuccess(function(response) {
		            context.$notify({
						message: response.data.message,
						type: "success",
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
		            });
				}).call();

				// context.formDataShp[key] = dataShpForm

			},
			toggleAccordion(show, type) {
				if (type == "bds") {
					if (show == "") {
					  this.accordion.bds.show = "show";
					} else {
					  this.accordion.bds.show = "";
					}
				} else if (type == "mpn") {
					if (show == "") {
					  this.accordion.mpn.show = "show";
					} else {
					  this.accordion.mpn.show = "";
					}
				} else if (type == "eqi") {
					if (show == "") {
					  this.accordion.eqi.show = "show";
					} else {
					  this.accordion.eqi.show = "";
					}
				} else if (type == "ad") {
					if (show == "") {
					  this.accordion.ad.show = "show";
					} else {
					  this.accordion.ad.show = "";
					}
				}
		    },
		    changeBom(){
		    	if (this.input.bom_non_bom == 'BOM' || this.input.item_type_code == 'OEM') {
		    		this.addEqi();
		    	}else{
		    		this.accordion.eqi.show = "";
		    		this.removeEqi();
		    	}
		    },
		    changeZSP601(){
		    	if (this.input.mtype_code == 'ZSP') {
		    		this.getPlantCode('601')
		    	}
		    },
		    previewAttachment(attachment) {
                this.previewAttachmentModal = '';
                this.attachmentFile         = '';
                this.attachmentFileType     = '';
                this.previewAttachmentModal = true;
                this.attachmentFile         = attachment;
                if (attachment.substring(attachment.length-3) == 'peg' || attachment.substring(attachment.length-3) == 'PEG') {
                    this.attachmentFileType = attachment.substring(attachment.length-4);
                }else{
                    this.attachmentFileType = attachment.substring(attachment.length-3);
                }
            },
            changeModalPrev(value) {
            	this.previewAttachmentModal = value;
            },
            querySearchDrawingMaster(queryString, cb) {
                if (queryString.length > 1) {
                    var links   = this.drawingMaster;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            selectDrawingNumber(item) {
                this.equipmentQuantityInstall.drawing_number[this.drawingMasterKey] = item.value
            },
            selectedDrawingMasterKey(key) {
		      this.drawingMasterKey = key;
		    },
		    clearDrawing(key) {
            	this.equipmentQuantityInstall.drawing_number[key] = ''
            	this.dm_attachment[key] = ''
            },
			showShp(key){
				this.$emit('showShpA1s', key, this.valuesOfShpForm[key])
			}
		}
	};
</script>