import Service from '@/helpers/service'

export default {
	get() {
		return Service().get('MyTask');
	},
	show(key, page, params) {
		return Service().get('MyTask/show/'+key+'?page=' + page, {params});
	},
	closed(key, page, params) {
		return Service().get('MyTask/closed/'+key+'?page=' + page, {params});
	},
	check_task_ticket(mcr_code) {
		return Service().get('MyTask/check-task-ticket/' + mcr_code);
	},
	process(url) {
		return Service().get(url);
	}
}
