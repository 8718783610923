<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div id="accordionEm">
	        <div class="card z-depth-0 bordered">
	          <div class="card-header bg-secondary border" id="headingOneBds">
	            <div class="row">
	              <div class="col-md-6">
	                <h5 class="mb-0">
	                  <a
	                    href="javascript:void(0)"
	                    @click="toggleAccordion(accordion.a3.em.show, 'em')"
	                    >{{ tt("extend_material") }}
	                    <span class="text-danger">*</span>
                      </a>
	                </h5>
	              </div>
	            </div>
	          </div>
		      	<div id="collapseEm" :class="'collapse ' + accordion.a3.em.show">
		            <div class="card-body border">
				      	<div class="row">
                            <div class="col-md-12">
                                <label class="form-control-label">{{ tt('extend_to_plant') }}</label>
                            </div>
					        <div class="col-md-6">
					            <span style="font-size: 14px">{{ tt('material_number') }} <span class="text-danger">*</span></span>
					            <base-input :name="tt('material_number')" rules="required">
					            	<el-select v-model="inputDraftListA3.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" :disabled="form.a3.disabled" @change="changeMaterialNumber()">
			                            <el-option label="" value=""></el-option>
			                            <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
			                        </el-select>
					            </base-input>
					        </div>
					        <div class="col-md-6">
					            <span style="font-size: 14px">{{ tt('plant') }}</span>
					            <base-input :name="tt('plant')">
					            	<el-select v-model="inputDraftListA3.plant_code" filterable remote reserve-keyword :placeholder="tt('plant')" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
				                        <el-option label="" value=""></el-option>
				                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code" :disabled="(euPartPlant.includes(pc.plant_code)) ? true : false"></el-option>
				                    </el-select>
					            </base-input>
					        </div>
					    </div>

					    <div class="row">
                            <div class="col-md-12">
                                <label class="form-control-label">{{ tt('extend_to_sloc') }}</label>
                            </div>
					    	<div class="col-md-6">
					            <span style="font-size: 14px">{{ tt('plant') }}</span>
					            <base-input :name="tt('plant')">
					            	<el-select v-model="inputDraftListA3.plant_to_sloc" filterable remote reserve-keyword :placeholder="tt('plant')" :remote-method="getPlantCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
				                        <el-option label="" value=""></el-option>
				                        <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
				                    </el-select>
					            </base-input>
					        </div>
					        <div class="col-md-6">
					            <span style="font-size: 14px">{{ tt('storage_location') }}</span>
					            <base-input :name="tt('storage_location')">
					            	<el-select v-model="inputDraftListA3.location_code" filterable remote reserve-keyword :placeholder="tt('storage_location')" :remote-method="getLocationCode" :loading="inputTo.loading" :disabled="form.a3.disabled">
				                        <el-option label="" value=""></el-option>
				                        <el-option v-for="lc in locationCode" :key="lc.location_code" :label="lc.location_code +' - '+ lc.location_description" :value="lc.location_code" :disabled="(euPartSloc.includes(lc.location_code)) ? true : false"></el-option>
				                    </el-select>
					            </base-input>
					        </div>
					    </div>
					</div>
				</div>
	      	</div>
	  	</div>

	    <label class="form-control-label">{{ tt('note') }} </label>
	    <base-input :name="tt('note')">
	        <textarea :disabled="form.a3.disabled" class="form-control" rows="5" v-model="inputDraftListA3.note" @input="inputDraftListA3.note = inputDraftListA3.note.toUpperCase()"></textarea>
	    </base-input>

	    <div class="text-right mb-3" v-if="!form.a3.disabled">
	        <base-button
	          type="primary"
	          @click="save()"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("save") }}</span>
			</base-button>
	    </div>
	</div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import locationCode from '@/services/master/locationCode.service';
    import plantCode from '@/services/master/plantCode.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';

    export default {        
        data() {
            return { 
            	requestCalls: 0,
            	onLoad: true,
                form: {
                    a3: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
						onLoading: false
					}
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                inputDraftListA3: {
                    id: '',
                    material_number: '',
                    plant_code: '',
                    plant_to_sloc: '',
                    location_code: '',
                    valuation_type_code: '',
                    note: '',
                },
                locationCode: {},
                valuationType: {},
                manufacture: {},
                manrefType: {},
                accordion: { 
                    a3: {
	                    em: {
							show: 'show'
						},
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                manufacturePartNumberA3: {
                    manufacture_code: [],
                    mpn: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                equipmentQuantityInstallA3: {
                    plant_equipment_code: [],
                    equipment_code: [],
                    qty_installed: [],
                    drawing_number: [],
                },
                materialNumber: [],
                plantCode: [],
                plantEquipmentCode: [],	
                equipmentCode: [],
                drawingMaster: [],
                data: {
                    search: {
                        material_number: [],
                        plant_code: [],
                    },
                }, 
                euPartPlant: [],
                euPartSloc: [],
                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type,
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
        	this.getDetailMcrItem();
            this.checkFormType();
        },
        methods: {
        	checkFormType() {
                if(this.formType == 'edit'){
                    this.form.a3.disabled = false;
                } else if(this.formType == 'detail'){
                    this.form.a3.disabled = true;
                } else if(this.formType == 'approval' && this.authUserPermission['mcr-approval-user-form-edit']){
                    this.form.a3.disabled = false;
                } else if(this.formType == 'sapSyncTaskEdit'){
                    this.form.a3.disabled = false;
                } else {
                    this.form.a3.disabled = true
                }
			},
        	getDetailMcrItem() {
				this.requestCalls++;

                let context = this;               
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.inputDraftListA3 = response.data.data[0];

                    if (response.data.data[0].material_number != null) {
                        context.materialNumber[0] = {
                            material_number: response.data.data[0].material_number,
                            short_description: response.data.data[0].short_description,
                        };
                    }
                    
                    if (response.data.data[0].plant_code != null) {
                        context.plantCode[0] = {
                            plant_code: response.data.data[0].plant_code,
                            description: response.data.data[0].description,
                        };
                    }

                    if (response.data.data[0].location_code != null) {
                        context.locationCode[0] = {
                            location_code: response.data.data[0].location_code,
                            location_description: response.data.data[0].location_description,
                        };
                    }

                    context.onLoad = false;                  
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inputDraftListA3 = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getMaterialNumber(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                    
                            context.inputTo.loading = false;      
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            changeMaterialNumber() {
                this.inputDraftListA3.plant_code    = '';
            	this.inputDraftListA3.plant_to_sloc = '';
            	this.inputDraftListA3.location_code = '';
                this.plantCode = [];

                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA3.material_number})).onSuccess(function(response) {
                    for (var i = 0; i < response.data.data.part_plant.length; i++) {
					  	context.euPartPlant[i] = response.data.data.part_plant[i].plant_code;
					}
					for (var i = 0; i < response.data.data.part_storage_location.length; i++) {
					  	context.euPartSloc[i] = response.data.data.part_storage_location[i].location_code;
					}
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.euPartPlant = [];
                        context.euPartSloc  = [];
                    }
                })
                .call()
            },
            getPlantCode(query) {
		 		if (this.inputDraftListA3.material_number != null) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context, plantCode.companyPlant({per_page: 'none', search: query})).onSuccess(function(response) {
                            context.plantCode =  response.data.data;                          
                            context.inputTo.loading = false;   
                        }).onError(function(error) {
                            if (error.response.status == 404) {
                                context.plantCode = [];
                                context.inputTo.loading = false;
                            }
                        }).call();             
                    }) 
                }
		    },
            getLocationCode(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, locationCode.getByPlant(context.inputDraftListA3.plant_to_sloc, {search: query})).onSuccess(function(response) {
                        context.locationCode =  response.data.data;                                    
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.locationCode = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
            },
            toggleAccordion(show, type) { 
                if (type == 'mpnA3') {
                    if (show == '') {
                        this.accordion.a3.mpn.show = 'show'
                    } else {
                        this.accordion.a3.mpn.show = ''
                    }
                } else if (type == 'eqiA3') {
                    if (show == '') {
                        this.accordion.a3.eqi.show = 'show'
                    } else {
                        this.accordion.a3.eqi.show = ''
                    }
                }else if(type == 'em'){
                	if (show == '') {
                        this.accordion.a3.em.show = 'show'
                    } else {
                        this.accordion.a3.em.show = ''
                    }
                }
            },
            save() {
                let context = this;       
                let data = {
                    mcr_code: context.mcr_code,
                    material_number: context.inputDraftListA3.material_number,
                    plant_code: context.inputDraftListA3.plant_code == '' ? null : context.inputDraftListA3.plant_code,
                    location_code: context.inputDraftListA3.location_code == '' ? null : context.inputDraftListA3.location_code,
                    note: context.inputDraftListA3.note,
                    status_process: 'user_process',
                }
                
                if (context.inputDraftListA3.material_number != '') {
                   	context.form.btnSave.onLoading = true;

                    Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA3.id)).onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
							horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.$emit('saveA3')
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    }).call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("extend_material") }),          
                        type: 'danger'
                    });
                }
            },
        }   
    };
</script>

// NOTES
// 1. dibuat 2 (extend plant dan extend storage location), karena kalau field plantnya cuman 1 dan disable jadi gabisa extend sloc