import Service from '@/helpers/service'

export default {
    get(page, params){
        return Service().get('abc-evaluation?page=' + page, {params})
    },
    update(params){
        return Service().post('abc-evaluation/update', params)
    },
    generate(param){
        return Service().post('abc-evaluation/generate', param)
    }

}
