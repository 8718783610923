<template>
    <div v-if="requestCalls > 0">
        <skeleton-loading></skeleton-loading>
    </div>
    <div v-else>
        <div id="accordionFm">
            <div class="card z-depth-0 bordered">
                <div class="card-header bg-secondary border" id="headingOneBds">
                    <div class="row">
                      <div class="col-md-6">
                        <h5 class="mb-0">
                          <a
                            href="javascript:void(0)"
                            @click="toggleAccordion(accordion.fm.show, 'fm')"
                            >{{ tt("material_consolidation") }}
                            <span class="text-danger">*</span></a
                          >
                        </h5>
                      </div>
                    </div>
                </div>
                <div id="collapseFm" :class="'collapse ' + accordion.fm.show">
                    <div class="card-body border">
                        <div class="row">
                            <div class="col-md-12">
                                <label class="form-control-label">{{ tt('consolidation_type') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('consolidation_type')" rules="required">
                                    <select :disabled="form.b5.disabled" class="form-control custom-select" v-model="paramsB5.deletion_type" @change="emptyField()">
                                        <option class="select-danger" :value="{delType: 'A', delReason: 'Duplikasi/Konsolidasi Internal'}" :label="'A' +' - '+ 'Duplikasi/Konsolidasi Internal'"></option>
                                        <option class="select-danger" :value="{delType: 'B', delReason: 'Duplikasi/Konsolidasi Eksternal'}" :label="'B' +' - '+ 'Duplikasi/Konsolidasi Eksternal'"></option>
                                    </select>
                                </base-input>
                            </div>
                        </div>

                        <!-- CONSOLIDATION FROM -->
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('material_from') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required">
                                    <el-select v-model="paramsB5.consolidate_from_material" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" @change="changeMaterialNumberB5()" :disabled="form.b5.disabled">
                                        <el-option label="" value=""></el-option>
                                        <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('plant_from') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required">
                                    <el-select :disabled="form.b5.disabled" class="select-danger" v-model="paramsB5.consolidate_from_plant" :placeholder="tt('choose_plant')">
                                        <el-option class="select-danger" :value="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" v-bind:key="pc.plant_code" v-for="pc in  plantMaterial"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>

                        <!-- CONSOLIDATION TO -->
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('material_to') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required">
                                    <el-select v-model="paramsB5.consolidate_to_material" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumberTo" :loading="inputTo.loading" @change="changeMaterialNumberToB5()" :disabled="form.b5.disabled">
                                        <el-option label="" value=""></el-option>
                                        <el-option v-for="mn in materialNumberTo" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('plant_to') }} <span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required">
                                    <el-select :disabled="form.b5.disabled" class="select-danger" v-model="paramsB5.consolidate_to_plant" :placeholder="tt('choose_plant')">
                                        <el-option class="select-danger" v-for="pc in  plantMaterialTo" :value="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" v-bind:key="pc.plant_code"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <label class="form-control-label">{{ tt('note') }}</label>
        <base-input :name="tt('note')">
            <textarea :disabled="form.b5.disabled" class="form-control" v-model="inputDraftListB5.note" @input="inputDraftListB5.note = inputDraftListB5.note.toUpperCase()" rows="5"></textarea>
        </base-input>

        <div class="text-right mb-3">
            <base-button
              type="primary"
              @click="save('update')"
              :disabled="form.btnSave.onLoading">
              <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
              <span v-else>{{ tt("edit") }}</span>
            </base-button>
        </div>
    </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';

    export default {        
        data() {
            return { 
                requestCalls: 0,
                form: {
                    b5: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
                        onLoading: false
                    }
                },
                accordion: {
                    fm: {
                        show: 'show'
                    },
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                paramsB5: {
                    consolidate_from_material: '',
                    consolidate_from_plant: '',
                    consolidate_to_material: '',
                    consolidate_to_plant: '',
                    deletion_type: [],
                },
                inputDraftListB5: {
                    reason: '',
                    note: '',
                },
                plantMaterial: [],
                plantMaterialTo: [],
                deletionType: [],
                materialNumber: [],
                materialNumberTo: [],

                mcr_code: this.$route.params.mcr_code,
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
                mcrType: this.$route.params.mcr_type // B2,B3,dll
            }
        },
        computed: {
			...mapState({
				authUserPermission :state => state.auth.userPermission
			}),
        },
        mounted() {
            this.getDetailMcrItem();
            this.getParameterMcr();
            this.checkFormType();
        },
        methods: {
            checkFormType() {
                if(this.formType == 'edit'){
					this.form.b5.disabled = false;
				} else if (this.formType == 'detail'){
					this.form.b5.disabled = true;
				} else if (this.formType == 'approval' && this.authUserPermission['mcr-approval-cataloguer-form-edit']){
					this.form.b5.disabled = false;
				} else if (this.formType == 'sapSyncTaskEdit'){
					this.form.b5.disabled = false;
				} else {
					this.form.b5.disabled = true;
				}
            },
            getDetailMcrItem() {
                this.requestCalls++;
                let context = this; 

                context.inputDraftListB5.id ='';
                context.paramsB5.consolidate_from_material ='';
                context.paramsB5.consolidate_from_plant ='';
                context.paramsB5.consolidate_to_material ='';
                context.paramsB5.consolidate_to_plant ='';
                context.paramsB5.deletion_type = [];
                context.inputDraftListB5.note = [];

                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                    context.inputDraftListB5.id = response.data.data[0].id;
                    context.inputDraftListB5.note = response.data.data[0].note;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inputDraftListB5 = [];
                    }
                }).call()

                Api(context, draftList.getMcrItemConsolidation(this.mcrItemCode)).onSuccess(function(response) {
                    context.paramsB5.deletion_type = {
                        delType: response.data.data[0].deletion_type,
                        delReason: response.data.data[0].del_reason,
                    }

                    context.materialNumber[0] = {
                        material_number: response.data.data[0].material_number_from,
                        short_description: response.data.data[0].short_material_from,
                    };
                    context.plantMaterial[0] = {
                        plant_code: response.data.data[0].plant_code_from,
                        description: response.data.data[0].plant_code_from_description,
                    };
                    context.materialNumberTo[0] = {
                        material_number: response.data.data[0].material_number_to,
                        short_description: response.data.data[0].short_material_to,
                    };
                    context.plantMaterialTo[0] = {
                        plant_code: response.data.data[0].plant_code_to,
                        description: response.data.data[0].plant_code_to_description,
                    };

                    context.paramsB5.consolidate_from_material = response.data.data[0].material_number_from;
                    context.paramsB5.consolidate_from_plant    = response.data.data[0].plant_code_from;
                    context.paramsB5.consolidate_to_material   = response.data.data[0].material_number_to;
                    context.paramsB5.consolidate_to_plant      = response.data.data[0].plant_code_to;
                                           
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.paramsB5.consolidate_from_material = '';
                        context.paramsB5.consolidate_from_plant = '';
                        context.paramsB5.consolidate_to_material = '';
                        context.paramsB5.consolidate_to_plant = '';
                        context.paramsB5.deletion_type = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getParameterMcr() {
                this.requestCalls++;
                let context = this;

                Api(context, draftList.getParameterMcr(this.mcrItemCode))
                .onSuccess(function(response) {
                  context.deletionType = response.data.data.deletion_type;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.input = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getMaterialNumber(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            getMaterialNumberTo(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumberTo = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumberTo = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            // addParamsB5() {
            //     this.paramsB5.consolidate_from_material.push('');
            //     this.paramsB5.consolidate_from_plant.push('');
            //     this.paramsB5.consolidate_to_material.push('');
            //     this.paramsB5.consolidate_to_plant.push('');
            //     this.paramsB5.deletion_type.push('');
            // },
            // removeParamsB5(key) {
            //     this.paramsB5.consolidate_from_material.splice(key,1)
            //     this.paramsB5.consolidate_from_plant.splice(key,1)
            //     this.paramsB5.consolidate_to_material.splice(key,1)
            //     this.paramsB5.consolidate_to_plant.splice(key,1)
            //     this.paramsB5.deletion_type.splice(key,1)
            // },
            selectMaterialNumber() {
                // this.data.search.material_number = item.value
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.paramsB5.consolidate_from_material})).onSuccess(function(response) {
                    context.plantMaterial = response.data.data.part_plant;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantMaterial = [];
                    }
                })
                .call()
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            toggleAccordion(show, type) {
                if (type == 'fm') {
                    if (show == '') {
                        this.accordion.fm.show = 'show'
                    } else {
                        this.accordion.fm.show = ''
                    }
                }
            },
            save(save) {
                let context = this;
                let data = {
                    mcr_code: context.mcr_code,
                    consolidate_from_material: [],
                    consolidate_from_plant: [],
                    consolidate_to_material: [],
                    consolidate_to_plant: [],
                    deletion_type: [],
                    deletion_reason: [],
                    note: [],
                    status_process: 'user_process',
                }

                if (context.paramsB5.consolidate_from_material != '' &&
                    context.paramsB5.consolidate_from_plant != '' &&
                    context.paramsB5.consolidate_to_material != '' &&
                    context.paramsB5.consolidate_to_plant != '' &&
                    context.paramsB5.deletion_type != '') {

                    data.consolidate_from_material[0] = context.paramsB5.consolidate_from_material;
                    data.consolidate_from_plant[0] = context.paramsB5.consolidate_from_plant;
                    data.consolidate_to_material[0] = context.paramsB5.consolidate_to_material;
                    data.consolidate_to_plant[0] = context.paramsB5.consolidate_to_plant;
                    data.deletion_type[0] = context.paramsB5.deletion_type.delType;
                    data.deletion_reason[0] = context.paramsB5.deletion_type.delReason;
                } else {
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("material_consolidation") }),                   
                        type: 'danger'
                    });
                    return false;
                }

                data.note = context.inputDraftListB5.note;

                context.form.btnSave.onLoading = true;

                let api = '';
                if (save == 'add') {
                    api = Api(context, draftList.createNewMcrItemNoFormData(data));
                } else {
                    api = Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListB5.id));
                }
                
                api.onSuccess(function(response) {    
                    context.$notify({
                        message: response.data.message,                  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                    context.form.btnSave.onLoading = false;
                    context.$emit('saveB5')
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.draftList = [];
                        context.form.btnSave.onLoading = false;
                    }
                })
                .call()         
            },
            changeMaterialNumberB5() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.paramsB5.consolidate_from_material})).onSuccess(function(response) {
                    context.plantMaterial = response.data.data.plant_company_where_in;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantMaterial = [];
                    }
                })
                .call()
            },
            changeMaterialNumberToB5() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.paramsB5.consolidate_to_material})).onSuccess(function(response) {
                    if (context.paramsB5.deletion_type.delType == 'A') {
                        context.plantMaterialTo = response.data.data.plant_company_where_in;
                    }else if(context.paramsB5.deletion_type.delType == 'B'){
                        context.plantMaterialTo = response.data.data.plant_company_where_notin;
                    }else{
                        context.plantMaterialTo = response.data.data.part_plant;
                    }
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantMaterialTo = [];
                    }
                })
                .call()
            },
            emptyField() {
                let context = this;
                context.paramsB5.consolidate_from_material = '';
                context.paramsB5.consolidate_from_plant = '';
                context.paramsB5.consolidate_to_material = '';
                context.paramsB5.consolidate_to_plant = '';
            }
        }   
    };
</script>