import Service from '@/helpers/service'

export default {

    get(params) {
        return Service().get('ValuationType', {params});
    }, 
    getByAccCatRef(material_type) {
        return Service().get('ValuationType/get-by-acc-cat-ref/'+material_type);
    }, 
    show(id) {
        return Service().get('ValuationType/show/'+id);
    },    
    create(params) {
        return Service().post('ValuationType/create', params);
    },
    update(id, params) {
        return Service().post('ValuationType/update/'+id, params);
    },
    delete(id) {
        return Service().post('ValuationType/delete/'+id);
    }
}