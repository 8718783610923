<template>
	<div>
        <div class="row">
			<!-- SEND MCR -->
			<div v-if="formType == 'edit'" class="col text-center">
				<router-link :to="'/material/draft-list'" class="mr-2"><base-button type="dark">Draft List</base-button></router-link>  
				<base-button type="primary" v-on:click="validateVal('send')" :disabled="conditionBtnSend()">{{ tt('send') }}</base-button>
			</div>

			<!-- MCR APPROVAL -->
			<div v-if="formType == 'approval'" class="col text-center">
				<!-- kalau yg create MCR adalah user yg login, makan tombol revise gaperlu dimunculkan -->
				<base-button type="warning" @click="insertNote('revise')" v-if="authUserPermission['mcr-approval-revise'] && createdBy != authUser['namecode']">
					<i class="ni ni-ruler-pencil"></i>
					{{ tt('revise') }}
				</base-button>
				<base-button type="danger" @click="insertNote('reject')" v-if="authUserPermission['mcr-approval-reject']">
					<i class="fa fa-times"></i>
					<span v-if="createdBy == authUser['namecode']">{{ tt('close') }}</span>
					<span v-else>{{ tt('reject') }}</span>
				</base-button>
				<base-button type="success" @click="validateVal('approve')" v-if="(authUserPermission['mcr-approval-approve'] || authUserPermission['mcr-approval-resend'])" :disabled="conditionBtnApprove()">
					<i class="ni ni-check-bold"></i>
					<span v-if="checkStatusWorkflow == 'Revised'">{{ tt('resend') }}</span>
					<span v-else>{{ tt('approve') }}</span>
				</base-button>
				<!-- <base-button type="primary" @click="insertNote('resend')" v-if="authUserPermission['mcr-approval-resend'] && checkStatusWorkflow == 'Revised'">
					<i class="ni ni-send"></i>{{ tt('resend') }}
				</base-button> -->
			</div>

			<!-- MCR APPROVAL MULTIPLE KEY -->
			<div v-if="taskTicketId == 'MCRAPPROVAL' && formType == undefined" class="col text-center">
				<base-button type="warning" @click="insertNote('multi-revise')" v-if="authUserPermission['mcr-approval-multi-revise']" :disabled="!multipleKey.length">
					<i class="ni ni-ruler-pencil"></i>{{ tt('revise') }}
				</base-button>
				<base-button type="danger" @click="insertNote('multi-reject')" v-if="authUserPermission['mcr-approval-multi-reject']" :disabled="!multipleKey.length">
					<i class="fa fa-times"></i>{{ tt('reject') }}
				</base-button>
				<base-button type="success" @click="insertNote('multi-approve')" v-if="authUserPermission['mcr-approval-multi-approve']" :disabled="!multipleKey.length">
					<i class="ni ni-check-bold"></i>{{ tt('approve') }}
				</base-button>
			</div>
		</div>

		<!-- NOTE MODAL -->
		<validation-observer v-slot="{invalid}">
			<modal :show.sync="noteModal.show" size="lg">
				<template slot="header">
					<h5 class="modal-title">{{ noteModal.title }}</h5>
				</template>

				<tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow" v-if="((authUserRole['Source Creator'] && createdBy == authUser['namecode']) || authUserRole['Staff Renhar'] || (authUserRole['Staff Reliability'] && createdBy == authUser['namecode']) || authUserRole['Cataloguer']) && formCode != 'MCR19' && formCode != 'MCR26' && formCode != 'MCR27' && formCode != 'MCR28' && formCode != 'MCR29' && (noteModal.type == 'approve' || noteModal.type == 'send' || noteModal.type == 'resend')">
					<tab-pane>
						<span slot="title">Receiver</span>
						<p class="card-body description">
							<table class="table align-items-center table-bordered">
								<thead class="card-header bg-primary">
									<tr>
										<th class="text-white">{{tt('no')}}</th>
										<th class="text-white">{{tt('name')}}</th>
										<th class="text-white">{{tt('role')}}</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(rc, index) in allReceiver" :key="index">
										<td>{{ index+1 }}</td>
										<td>
											<el-select v-model="allReceiver[index].namecode" :placeholder="tt('choose_receiver')" :remote-method="querySearchReceiver" :loading="selectSearch.searchReceiver" filterable remote reserve-keyword @focus="setFocusedReceiverRole(index)" @change="checkReceiver()" clearable>
												<el-option v-for="r in receivers" :key="r.namecode" :label="r.username +' - '+ r.name" :value="r.namecode"></el-option>
											</el-select>
										</td>
										<td>{{ rc.role }}</td>
									</tr>
								</tbody>
							</table>
						</p>
					</tab-pane>

					<tab-pane>
						<span slot="title">Notes</span>
						<p class="card-body description">
							<label class="form-control-label">Note <span class="text-danger" v-if="noteModal.mandatory == 'required'">*</span></label>
							<base-input :name="tt('note')" :rules="noteModal.mandatory">
								<el-input
									type="textarea"
									:rows="4"
									v-model="noteModal.text">
								</el-input>
							</base-input>
						</p>
					</tab-pane>
				</tabs>

				<div v-else>
					<label class="form-control-label">Note <span class="text-danger" v-if="noteModal.mandatory == 'required'">*</span></label>
					<base-input :name="tt('note')" :rules="noteModal.mandatory">
						<el-input
							type="textarea"
							:rows="4"
							v-model="noteModal.text">
						</el-input>
					</base-input>
				</div>

				<template slot="footer">
					<base-button type="secondary" @click="noteModal.show = false">{{ tt('close') }}</base-button>
					<base-button type="primary" v-on:click="approval()" :disabled="noteModal.receiverCheck || btnApproval.onLoading || invalid">
						<span v-if="btnApproval.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt('please_wait') }}</span>
						<span v-else>{{ noteModal.button }}</span>
					</base-button>
				</template>
			</modal>
		</validation-observer>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import mcrApproval from '@/services/dashboard/mcrApproval.service';
	import manageAccount from '@/services/manage/manageAccount.service';

	// MCR FORM
	import McrHeader from "./McrHeader.vue";
	import McrFormA1 from "./McrFormA1.vue";
	import McrFormA2 from "./McrFormA2.vue";
	import McrFormA3 from "./McrFormA3.vue";
	import McrFormA4 from "./McrFormA4.vue";
	import McrFormB1 from "./McrFormB1.vue";
	import McrFormB from "./McrFormB.vue";
	import McrFormB5 from "./McrFormB5.vue";
	import McrFormShp from "./McrFormShp.vue";

	export default {
		props: ['multipleKey'],
		components: {
	      McrHeader,
		  McrFormA1,
		  McrFormA2,
		  McrFormA3,
		  McrFormA4,
		  McrFormB1,
		  McrFormB,
		  McrFormB5,
		  McrFormShp,
	    },
	    data() {
	    	return {
	    		btnApproval: {
	    			onLoading: false
	    		},
	    		btnSend: {
	    			onLoading: false
	    		},
                noteModal: {
                	show: false,
                	title: '',
                	text: '',
                	mandatory: '',
                	button: '',
                	type: '',
					receiverCheck: false,
                },
				selectSearch: {
					searchReceiverLoading: false,
					loadTimeout: null
				},
				receiverModal: {
					show: false
				},
				allReceiver: {},
				receivers: [],
				focusedReceiverRole: '',
				statusProcess: {},
                createdBy: '',
                formCode: '',
                requestCalls: 0,
                urlApproval: '',
                checkStatusWorkflow: '',
                clientPlantLevel: '',
                material_need_meeting: false,
				material_has_been_discussed: false,
				material_date_discussion: '',
	    		mcr_code: this.$route.params.mcr_code,
	    		mcr_item_code: this.$route.params.mcr_item_code,
				token: this.$route.params.token,
				mcrType: this.$route.params.mcr_type,
				formType: this.$route.params.type,
				taskTicketId: this.$route.params.id,
				// GOT FROM REF FUNCTION eqpCodeAndCodeFromShpForm IN McrAllForm
					tabReliability: '', 
					eqpCodeStoredForReliability: [],
					codeFormShpForReliability: [],
					tabCataloguer: '',
					eqpCodeStoredForCataloguer: [],
					codeFormShpForCataloguer: [],
	    	}
	    },
	    computed: {
            ...mapState({
                authUser :state => state.auth.user,
                authUserPermission :state => state.auth.userPermission,
                authUserRole :state => state.auth.userRole
            }),
        },
	    mounted() {
			if (this.formType == 'approval' || this.formType == 'edit') {
				this.get();
				this.getReceiver()
			}

			if (this.formType == 'approval') {
				this.statusWorkflow();
			}

		},
		methods: {
			get() {
				this.requestCalls++;

				let context = this;

				Api(context, draftList.getMcrHeader(context.mcr_code, context.token))
		        .onSuccess(function(response) {
					context.createdBy = response.data.data[0].created_by;
					context.formCode = response.data.data[0].type;
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();

				Api(context, draftList.getDetailMcrItem(context.mcr_item_code))
		        .onSuccess(function(response) {
					context.clientPlantLevel = response.data.data[0].client_plant_level;
					context.material_need_meeting = response.data.data[0].material_need_meeting === 'X' ? true : false
					context.material_has_been_discussed = response.data.data[0].material_has_been_discussed === 'X' ? true : false
					context.material_date_discussion = response.data.data[0].material_date_discussion == null ? '' : response.data.data[0].material_date_discussion
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();

				Api(context, draftList.statusProcess(context.mcr_item_code))
		        .onSuccess(function(response) {
					context.statusProcess = response.data.data;
		        })
		        .onFinish(function() {
		        	context.requestCalls--;
		        })
		        .call();
		    },
			validateVal(type) {
				if (this.authUserRole['Staff Reliability'] && this.mcrType != 'A2' && this.tabReliability === 'reliability') {
					const eqpWithoutShpReliability = this.eqpCodeStoredForReliability.filter((eqp, i) => eqp && !this.codeFormShpForReliability[i]) 
					if (eqpWithoutShpReliability.length > 0) {
						this.notifWarningEqpCodeWithShpForm(eqpWithoutShpReliability.join(', '), 'Reliability')
						return false
					} else if ( eqpWithoutShpReliability.length === 0 ) {
						this.insertNote(type)
					}
				} else if ( this.authUserRole['Cataloguer'] && this.mcrType != 'A2' && this.tabCataloguer === 'cataloguer' ) {
					const eqpWithoutShpCataloguer = this.eqpCodeStoredForCataloguer.filter((eqp, i) => eqp && !this.codeFormShpForCataloguer[i])
					if (eqpWithoutShpCataloguer.length > 0) {
						this.notifWarningEqpCodeWithShpForm(eqpWithoutShpCataloguer.join(', '), 'Cataloguer')
						return false
					} else if (eqpWithoutShpCataloguer.length === 0) {
						this.insertNote(type)
					}
				} else {
					this.insertNote(type)
				}
			},
		    insertNote(type) {
		    	let context = this;

		    	context.noteModal.type = type;
		    	context.noteModal.mandatory = '';
		    	context.noteModal.show = true;
		    	context.noteModal.text = '';
				context.noteModal.receiverCheck = false

		    	switch(type) {
					case 'send':
		    			context.noteModal.title = 'Send MCR';
						context.noteModal.button = 'Send';
						context.checkReceiver()
		    			break;
		    		case 'revise':
		    			context.noteModal.title = 'Revise MCR';
						context.noteModal.button = 'Revise';
		    			context.noteModal.mandatory = 'required';
		    			context.urlApproval = 'revise'
		    			break;
		    		case 'reject':
		    			context.noteModal.title = 'Reject MCR';
						context.noteModal.button = 'Reject';
		    			context.noteModal.mandatory = 'required';
		    			context.urlApproval = 'reject'
		    			break;
		    		case 'approve':
		    			context.noteModal.title = 'Approve MCR';
						context.noteModal.button = 'Approve';
		    			context.urlApproval = 'approve'
						context.checkReceiver()

		    			break;
		    		// case 'resend':
		    		// 	context.noteModal.title = 'Resend MCR';
					// 	context.noteModal.button = 'Resend';
		    		// 	context.urlApproval = 'reapprove'
		    		// 	break;
					case 'multi-revise':
		    			context.noteModal.title = 'Revise MCR';
						context.noteModal.button = 'Revise';
						context.noteModal.mandatory = 'required';
		    			break;
					case 'multi-reject':
		    			context.noteModal.title = 'Reject MCR';
						context.noteModal.button = 'Reject';
						context.noteModal.mandatory = 'required';
		    			break;
					case 'multi-approve':
		    			context.noteModal.title = 'Approve MCR';
						context.noteModal.button = 'Approve';
		    			break;
		    	}
		    },
		    approval() {
		    	let context = this;
		    	context.btnApproval.onLoading = true;
				
				if (context.$route.params.type == 'approval') {
					// untuk single approval
					var api = Api(context, mcrApproval.approval(context.urlApproval, context.mcr_code, {to: context.allReceiver, note : context.noteModal.text}));
				} else if(context.$route.params.type == 'edit') {
					// untuk send New MCR
					var api = Api(context, draftList.sendMcr(context.mcr_code, {to: context.allReceiver, note: context.noteModal.text}))
				} else {
					// untuk multiple approval
					var api = Api(context, mcrApproval.multiApproval(context.noteModal.type, {mcr_code : context.multipleKey, note : context.noteModal.text, to: []}));
				}
				
				api.onSuccess(function(response) {
                    context.$notify({
                        message: response.data.message,  
                        type: 'success',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });

                    context.noteModal.text = '';

					if (context.$route.params.type == 'approval') {
						context.$router.push('/dashboard/my-task-show/MCRAPPROVAL');
					} else if(context.$route.params.type == 'edit') {
						context.$router.push('/material/draft-list');
					} else {
						context.$parent.getMyTask()
					}
                }).onError(function(error) {
                    context.$notify({
                        message: error.response.data.message,                  
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }).onFinish(function() {
					context.noteModal.show = false;
                    context.btnApproval.onLoading = false;
                })
                .call();
		    },
            statusWorkflow() { // untuk cek apakah status workflow adalah 'Revised', agar tombol resend tidak selalu muncul
            	this.requestCalls++;

            	let context = this;
                Api(context, mcrApproval.statusWorkflow(this.mcr_code)).onSuccess(function(response) {
                    context.checkStatusWorkflow = response.data;
                }).onError(function(error) {
                    context.checkStatusWorkflow = '';
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
            },
			getReceiver() {
            	this.requestCalls++;

            	let context = this;
                Api(context, mcrApproval.receiver(this.mcr_code)).onSuccess(function(response) {
                    context.allReceiver = response.data.data;
                }).onError(function(error) {
                    context.allReceiver = [];
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
            },
			conditionBtnSend() {
				if (['A1','A1S','A3','A4'].includes(this.mcrType)) {
					return !(
						(this.material_need_meeting === true && this.material_has_been_discussed === true  && this.material_date_discussion !== '') &&
						this.statusProcess.user_process === 'Processed' ||
						(this.material_need_meeting === false && this.statusProcess.user_process === 'Processed')
					)
				} else if(this.mcrType == 'A2') {
					if (this.clientPlantLevel == 'Specification' || this.clientPlantLevel == 'RopMinMax' || this.clientPlantLevel == 'BoM') {
						return (this.statusProcess.user_process == 'Processed') ? false : true
					} else if(this.clientPlantLevel == 'SHP') {
						if (this.authUserRole['Staff Reliability']) {
							return (this.statusProcess.shp_rel_process == 'Processed') ? false : true
						} else if(this.authUserRole['User Pemeliharaan'] || this.authUserRole['Staff Renhar']) {
							return (this.statusProcess.user_process == 'Processed') ? false : true
						}
					} else {
						return true
					}
				} else if (['B1','B2','B2S','B3','B4','B5'].includes(this.mcrType)) {
					return !(
						(this.material_need_meeting === true && this.material_has_been_discussed === true  && this.material_date_discussion !== '') &&
						this.statusProcess.cataloguer_process === 'Processed' ||
						(this.material_need_meeting === false && this.statusProcess.cataloguer_process === 'Processed')
					)
				} else if (this.mcrType == 'C1') {
					return (this.statusProcess.abc_evaluation_process == 'Processed') ? false : true
				}
			},
			conditionBtnApprove() { // Hanya untuk single approval "approve"
				if (['A1','A1S','A2','A3'].includes(this.mcrType)) {
					if (this.authUserRole['Cataloguer'] && this.mcrType != 'A2') {
						return !(
							(this.material_need_meeting === true && this.material_has_been_discussed === true && this.material_date_discussion !== '') &&
							this.statusProcess.cataloguer_process === 'Processed' ||
							(this.material_need_meeting === false && this.statusProcess.cataloguer_process === 'Processed')
						)
					} else {
						return false
					}
				}
			},
			querySearchReceiver(query) {
            	// if (query.length > 2) {    
					clearTimeout(this.selectSearch.loadTimeout);      
					let context = this;   

					this.selectSearch.searchReceiverLoading = true;
					this.selectSearch.loadTimeout = setTimeout(() => {
					    Api(context, manageAccount.get(null, {company: context.authUser['company_code'], roles: [context.focusedReceiverRole], name: query})).onSuccess(function(response) {  
		                    context.receivers = response.data.data.data.data;
		                    context.selectSearch.searchReceiverLoading = false;
		                }).onError(function(error) {                    
		                    if (error.response.status == 404) {
		                        context.receivers = [];
		                        context.selectSearch.searchReceiverLoading = false;
		                    }
		                }).onFinish(function() {
							// memasukan role kedalam list receivers
							// context.receivers.push({
							// 	namecode: context.focusedReceiverRole,
							// 	name : context.focusedReceiverRole
							// })
						})
		                .call()        
					}, 200)
            	// }
            },
			setFocusedReceiverRole(index) {
				this.receivers = []
				this.focusedReceiverRole = this.allReceiver[index].role

				this.querySearchReceiver(null)
			},
			checkReceiver() {
				if (this.authUserRole['User Pemeliharaan'] || this.authUserRole['User Non Pemeliharaan'] || this.authUserRole['Staff Renhar'] || (this.authUserRole['Staff Reliability'] && this.createdBy == this.authUser['namecode']) || this.authUserRole['Cataloguer']) {
					// filter receiver yg namecodenya ga kosong
					let dataFilter = this.allReceiver.filter(element => element.namecode != "")
					if(dataFilter.length > 0){ // kalau ada receiver yg ada namecodenya
						let staffReliability = this.allReceiver.some(element => element.role == "Staff Reliability")
						let kabagRenhar = this.allReceiver.some(element => element.role == "Kabag Renhar")
						if(staffReliability){
							if(this.allReceiver.some(element => element.role == "Staff Reliability" && element.namecode != "")){
								if(this.allReceiver.some(element => element.role == "Kabag Renhar" && element.namecode == "") && this.allReceiver.some(element => element.role == "Manager Renhar" && element.namecode == "")){
									this.noteModal.receiverCheck = true;
								} else {
									this.noteModal.receiverCheck = false;
								}
							} else {
								this.noteModal.receiverCheck = true;
							}
						} else if(kabagRenhar){
							if(this.allReceiver.some(element => element.role == "Kabag Renhar" && element.namecode == "") && this.allReceiver.some(element => element.role == "Manager Renhar" && element.namecode == "")){
								this.noteModal.receiverCheck = true;
							} else {
								this.noteModal.receiverCheck = false;
							}
						} else {
							this.noteModal.receiverCheck = false;
						}
					} else if(dataFilter.length == 0){
						this.noteModal.receiverCheck = true
					}
				}
			},
			notifWarningEqpCodeWithShpForm(code, tab){
				this.alertDialog('error', this.tt('please_complete_shp_form_data', {title: 'SHP Form data for equipment code: ' + code + ' in ' + tab + ' tab' }))
			}
		}
	};	
</script>

// ========== NOTES ========== //
// MCR SINGLE APPROVAL
// 1. User & Renhar Form SHP tidak mandatory
// 2. Staff Reliability & Cataloguer Form SHP Mandatory (field yang muncul sesuai streamnya)

// MCR MULTIPLE APPROVAL
// 1. Yang bisa akses hanya Manager dan GM