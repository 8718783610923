<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<!-- EQUIPMENT -->
		<div class="row" v-if="mcrType !== 'A2' || mcrType !== 'B3'">
			<div class="col-md-12">
				<label class="form-control-label">{{ tt("equipment") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></label>
				<base-input :name="tt('equipment')">
					<el-select style="width: 100%;" size="small" v-model="input.shp_equipment" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipment" :loading="inputTo.loading" clearable @change="changeEquipment" :disabled="(formType == 'approval' || formType == 'detail' || mcrType == 'A1S' || mcrType == 'B2S') ? true : false" @focus="changeLog('equipment')">
						<el-option v-for="ec in equipments" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
					</el-select>
				</base-input>
			</div>
			<div class="col-md-12" v-if="mcrType == 'A2' || mcrType == 'B3'">
				<label class="form-control-label">{{ tt("material_number") }} <span class="text-danger">*</span></label>
				<base-input :name="tt('material_number')">
					<el-select style="width: 100%;" size="small" v-model="input.material_number" filterable reserve-keyword :placeholder="tt('material_number')" :loading="inputTo.loading" clearable @focus="changeLog('material_number')" @change="changeMaterialNumber" :disabled="(formType == 'approval' || formType == 'detail' || mcrType == 'A1S' || mcrType == 'B2S') ? true : false" >
						<el-option v-for="mn in materialNumbers" :key="mn.material_number" :label="mn.material_number + ' - ' + mn.short_description" :value="mn.material_number"></el-option>
					</el-select>
				</base-input>
			</div>
		</div>

        <!-- TAB RELIABILITY DAN TAB CATALOGUER -->
        <tabs tabNavClasses="nav-fill flex-column flex-sm-row nav-wrapper" tabContentClasses="card shadow">  
            <!-- TAB RELIABILITY -->
            <tab-pane>
                <span slot="title">Reliability</span>
                <!-- RELIABILITY DOMAIN -->
				<fieldset class="p-4 mb-4">
                    <!-- FAILURE MODE -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h4 class="mb-0">
                                                <a href="javascript:void(0)">{{ tt("failure_mode") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
													<el-tooltip content="Daftar Failure Mode kredibel untuk equipment yang sedang dianalisa" placement="top">
														<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
													</el-tooltip> 
												</a>
                                            </h4>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <base-button
                                            :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                                            size="sm"
                                            type="default"
                                            @click="addFailure">
                                            {{ tt("add_new") }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <table class="table align-items-center table-flush table-bordered">
                                        <thead class="card-header bg-primary">
                                            <tr>
                                                <th class="text-white">{{ tt('failure_mode')}}</th>
                                                <th class="text-white">#</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr v-for="(failure, key) in input.shp_failure_mode" :key="key">
                                                <td width="90%">
                                                    <input :name="tt('failure_mode')" 
                                                    class="form-control"
                                                    v-model="input.shp_failure_mode[key]" 
                                                    :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" 
                                                    @focus="changeLog('failure_mode_line_' + (key+1))">
                                                </td>
                                                <td>
                                                    <span><i class="fa fa-trash text-danger text-right" v-if="!form.disabled && (checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" @click="removeFailure(key)"></i></span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>

					<!-- QTY SKU -->
					<div class="row">
						<div class="col-md-6">
							<label class="form-control-label">{{ tt("qty") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
								<el-tooltip content="Jumlah spare part yang diperlukan dalam kegiatan maintenance terkait (dalam 1x issued)" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('qty_sku')" rules="required"
								:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_qty"
								@input="input.shp_qty = parseInt(input.shp_qty)" 
								@focus="changeLog('qty')"
								:placeholder="tt('type_the_quantity')"
								type="number"
							></base-input>
						</div>

						<div class="col-md-6">
							<label class="form-control-label">{{ tt("uom") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></label>
							<base-input :name="tt('uom')" v-model="input.shp_uom" rules="required" disabled="true"></base-input>
						</div>
					</div>
                    
                    <!-- MCE STRATEGY -->
                    <div class="row">
                        <div class="col-md-12">
                            <div class="card z-depth-0 bordered">
                                <div class="card-header bg-secondary border">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <h5 class="mb-0">
                                                <a href="javascript:void(0)">{{ tt("mce_strategy_interval") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></a>
                                            </h5>
                                        </div>
                                        <div class="col-md-6 text-right">
                                            <base-button
												:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
												size="sm"
												type="default"
												@click="addMce">
												{{ tt("add_new") }}
                                            </base-button>
                                        </div>
                                    </div>
                                </div>
                                <table class="table align-items-center table-flush table-bordered">
                                    <thead class="card-header bg-primary">
                                        <tr>
                                            <th class="text-white">{{ tt('mce_strategy')}} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
												<el-tooltip content="Pemilihan CBM/PvM/RTF" placement="top">
													<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
												</el-tooltip> 
											</th>
                                            <th class="text-white" width="250">{{ tt('mce_interval')}} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
												<el-tooltip content="Interval (sudah termasuk Net PF) yang dibutuhkan dari diketahuinya potensial failure hingga failure terjadi. Satuan waktu : Bulan, apabila aktual Hari maka direpresentasikan dalam satuan bulan (mis : 10 hari = 0,33 Bulan )." placement="top">
													<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
												</el-tooltip> 
											</th>
                                            <th class="text-white">{{ tt('mce_net_pf')}} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) && input.shp_mce_strategy.mce_strategy.includes('CBM')">* </span>
												<el-tooltip content="Diisi khusus jika strategi CBM" placement="top">
													<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
												</el-tooltip> 
											</th>
                                            <th class="text-white" width="500">{{ tt('mce_tindakan')}} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
												<el-tooltip content="Tindakan yang dilakukan sesuai dengan maintenance strategy yang dipilih (mis: PM = Penggantian terjadwal)" placement="top">
													<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
												</el-tooltip>
											</th>
                                            <th class="text-white">#</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(mce_strategy, key) in input.shp_mce_strategy.mce_strategy" :key="key">
                                            <td>
                                                <el-select v-model="input.shp_mce_strategy.mce_strategy[key]" clearable :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" @focus="changeLog('mce_strategy_line_' + (key+1))" @change="changeNetPfInput(key)">
                                                    <el-option label="CBM" value="CBM"></el-option>
                                                    <el-option label="PvM" value="PvM"></el-option>
                                                    <el-option label="RTF" value="RTF"></el-option>
                                                </el-select>
                                            </td>
                                            <td>
                                                <input v-model="input.shp_mce_strategy.mce_interval[key]" type="number" class="form-control height-43" :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"  rules="required" @focus="changeLog('mce_interval_line_'+(key+1))">
                                            </td>
											<td>
                                                <input v-model="input.shp_mce_strategy.mce_net_pf[key]" 
													type="number" 
													class="form-control height-43" 
													:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) || input.shp_mce_strategy.mce_strategy[key] != 'CBM'" 
													@focus="changeLog('mce_net_pf_line_'+(key+1))"
												>
                                            </td>
											<td>
                                                <input v-model="input.shp_mce_strategy.mce_tindakan[key]" type="text" class="form-control height-43" :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"  rules="required" @focus="changeLog('mce_tindakan_line_'+(key+1))">
                                            </td>
                                            <td>
                                                <span><i class="fa fa-trash text-danger text-right" v-if="!form.disabled && (checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" @click="removeMce(key)"></i></span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    
					<!-- MANUFATURER PART NUMBER -->
                    <div class="card z-depth-0 bordered" v-if="mcrType == 'A2' || mcrType == 'B3'">
						<div class="card-header bg-secondary border">
							<div class="row">
								<div class="col-md-6">
									<h5 class="mb-0">
									<a href="javascript:void(0)">{{ tt("manufacturer_part_number") }}</a>
									</h5>
								</div>
								<div class="col-md-6 text-right">
									<base-button
									:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
									size="sm"
									type="default"
									@click="addMpn">
									{{ tt("add_new") }}
									</base-button>
								</div>
							</div>
						</div>
						<div>
							<table class="table align-items-center table-flush table-bordered">
								<thead class="card-header bg-primary">
									<tr>
										<th width="500" class="text-white">{{ tt("manufacturer_code") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></th>
										<th class="text-white">{{ tt("manufacture_ref") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></th>
										<th class="text-white">{{ tt("source_type") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></th>
										<th class="text-white">{{tt('position')}}</th>
										<th class="text-white" width="15%">{{ tt("type") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">*</span></th>
										<th class="text-white">{{ tt("note") }}</th>
										<th class="text-white">#</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(mpn, key) in manufacturePartNumber.manufacture_code"
										v-bind:key="mpn.manufacture_code"
									>
										<td>
											<el-select style="width: 100%;" v-model="manufacturePartNumber.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">
												<el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
											</el-select>
										</td>
										<td>
											<input
											:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
											class="form-control"
											:name="tt('mpn')"
											:placeholder="tt('mpn')"
											v-model="manufacturePartNumber.mpn[key]"
											@input="manufacturePartNumber.mpn[key] = manufacturePartNumber.mpn[key].toUpperCase()"
											rules="required"/>
										</td>
										<td>
											<el-select :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" v-model="manufacturePartNumber.source_type[key]">
												<el-option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{ st.code +' - '+ st.description }}</el-option>
											</el-select>
										</td>
										<td>
											<input :disabled="(form.disabled || ((formType == 'approval' || formType == 'edit') && checkDomain == 'reliability')) &&  manufacturePartNumber.source_type[key] == 'DWG'? false : true" class="form-control" :name="tt('position')" :placeholder="tt('position')" v-model="manufacturePartNumber.position[key]" style='width: 100px;'
											/>
										</td>
										<td>
											<el-select :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" v-model="manufacturePartNumber.manufacture_type[key]">
												<el-option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{ mt.description }}</el-option>
											</el-select>
										</td>
										<td>
											<input :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" class="form-control" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumber.manufacture_note[key]" @input="manufacturePartNumber.manufacture_note[key] = manufacturePartNumber.manufacture_note[key].toUpperCase()" style='width: 100px;'
											/>
										</td>
										<td>
											<i v-if="!form.disabled || ((formType == 'approval' || formType == 'edit') && checkDomain == 'reliability') ? true : false" class="fa fa-trash text-danger" @click="removeMpn(key)"></i>
										</td>
									</tr>
								</tbody>
							</table>
						</div>
                    </div>
                    
					<!-- SOE (Stock Out Effect) / Effect Sparepart Not Available -->
                    <label class="form-control-label">{{ tt("effect_spare_part_not_available") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
						<el-tooltip content="SOE Stock Out effect, Penjelasan mengenai dampak (Konsekuensi Stock Out) dari spare part ketika tidak tersedia saat dibutuhkan dalam kegiatan maintenance" placement="top">
							<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
						</el-tooltip>
					</label>
                    <base-input :name="tt('effect_spare_part_not_available')" rules="required">
                        <textarea
                            :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                            class="form-control"
                            rows="5"
                            v-model="input.shp_effect_spare_part_not_available"
                            @input="input.shp_effect_spare_part_not_available = input.shp_effect_spare_part_not_available.toUpperCase(), fixCursor($event)"
                            @focus="changeLog('effect_spare_part_not_available')">
                        </textarea>
                    </base-input>

					<!-- DEMAND RATE -->
                    <div class="row">
                        <div class="col-9">
                            <label class="form-control-label">{{ tt("demand_rate") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) && (!['A1S','B2S'].includes(mcrType))">* </span>
								<el-tooltip placement="top">
									<div slot="content">
										<span>
											Ratio permintaan dimana jumlah permintaan dibagi tahun SAP (6 tahun / 2016 -2022)<br><br>
											Jika material baru (belum pernah diorder), maka demand rate tidak mandatory
										</span>
									</div>
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
                            <base-input :name="tt('demand_rate')" :rules="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) && (!['A1S','B2S'].includes(mcrType)) ? 'required' : ''"
                                :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                                v-model="input.shp_demand_rate"
                                @input="input.shp_demand_rate = input.shp_demand_rate.toUpperCase()"
                                @focus="changeLog('demand_rate')"
								type="number">
                            </base-input>
                        </div>
                        <div class="col-3">
                            <label class="form-control-label">{{ tt("unit") }}</label>
                            <base-input :name="tt('unit')"
                                :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                                v-model="input.shp_demand_rate_unit"
                                @input="input.shp_demand_rate_unit = input.shp_demand_rate_unit.toUpperCase()"
                                @focus="changeLog('demand_rate_unit')"
								type="number">
                            </base-input>
                        </div>
                    </div>

					<!-- ACTION TO HANDLED -->
					<fieldset class="border p-4 mb-4">
						<div class="row">
							<legend class="col-12 float-none w-auto p-2"><h4>{{ tt("action_to_handle") }}</h4></legend>
							<div class="col-12">
								<label class="form-control-label">{{ tt("action") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
									<el-tooltip content="Tindakan yang bisa dilakukan selain pembelian (mis : repair)" placement="top">
										<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
									</el-tooltip>
								</label>
								<base-input :name="tt('action')" rules="required">
									<textarea
									:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
									class="form-control"
									rows="5"
									v-model="input.shp_action_to_handle"
									@input="input.shp_action_to_handle = input.shp_action_to_handle.toUpperCase(), fixCursor($event)"
									@focus="changeLog('action_to_handle')">
									</textarea>
								</base-input>
							</div>
							<div class="col-12">
								<label class="form-control-label">{{ tt("repair_time") }}
									<el-tooltip content="Waktu diperlukan dari item issued untuk repair hingga kembali ke inventory (satuan Bulan)" placement="top">
										<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
									</el-tooltip>
								</label>
								<base-input :name="tt('repair_time')"
									:disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
									v-model="input.shp_lead_time_action_to_handle"
									@input="input.shp_lead_time_action_to_handle = input.shp_lead_time_action_to_handle.toUpperCase()"
									@focus="changeLog('lead_time_to_handle')"
									type="number"
								></base-input>
							</div>
						</div>
					</fieldset>

					<!-- CONSEQUENCE AND CRITICALLITY -->
                    <label class="form-control-label">{{ tt("consequences_criticality") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
						<el-tooltip placement="top">
							<div slot="content">
								<span>Pilihan kategori dampak dari stock out:</span>
								<ul class="pt-2 pl-4">
									<li><b>Direct Effect &ndash; Critical</b> : dampak langsung menyebabkan shutdown</li>
									<li><b>In-Direct Effect &ndash; Critical</b> : Tidak membuat shutdown tapi harus menurunkan rate selama menunggu spare datang</li>
									<li><b>Increased Risk</b> : Tidak membuat shutdown tapi mempunyai resiko dengan konsekuensi besar (safety/ops) ketika dilanjutkan</li>
									<li><b>Non Operational</b> : Tidak membuat shutdown dan menunggu Spare Part datang (SOE) cenderung tidak berdampak/berisiko</li>
								</ul>
							</div>
							<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
						</el-tooltip>
					</label>
                    <base-input :name="tt('consequences_criticality')" rules="required">
                        <el-select :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" :placeholder="tt('choose_consequences_criticality')" v-model="input.shp_consequences_criticality" clearable @focus="changeLog('consequences_criticality')">
                            <el-option value="Direct Effect-Critical">Direct Effect-Critical</el-option>
                            <el-option value="In-direct Effect-Critical">In-direct Effect-Critical</el-option>
                            <el-option value="Increased Risk">Increased Risk</el-option>
                            <el-option value="Non Operational">Non Operational</el-option>
                        </el-select>
                    </base-input>

					<!-- OWNER ESTIMATE -->
                    <label class="form-control-label">{{ tt("owner_estimate") }}</label>
					<base-input :name="tt('owner_estimate')"
						disabled="true"
						v-model="input.shp_owner_estimate"
						@focus="changeLog('owner_estimate')"
						type="number">
					</base-input>

					<!-- ESTIMATED LOSS -->
					<div class="row">
						<div class="col-md-6">
							<label class="form-control-label">{{ tt("estimated_loss_idr") }} 
								<el-tooltip content="Kerugian yang timbul akibat Stock Out Effect dalam satuan nilai rupiah" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('estimated_loss_idr')" :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_estimated_loss_idr"
								@input="input.shp_estimated_loss_idr = input.shp_estimated_loss_idr.toUpperCase()"
								@focus="changeLog('estimated_loss_idr')"
								type="number">     
							</base-input>
						</div>

						<div class="col-md-6">
							<label class="form-control-label">{{ tt("estimated_loss_day") }} 
								<el-tooltip content="Nilai yang timbul di Estimated Loss IDR per satuan waktu (hari)" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('estimated_loss_day')" :disabled="form.disabled || (checkDomain !== 'reliability' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_estimated_loss_day"
								@input="input.shp_estimated_loss_day = input.shp_estimated_loss_day.toUpperCase()"
								@focus="changeLog('estimated_loss_day')"
								type="number">     
							</base-input>
						</div>
					</div>

					<!-- TOTAL ESTIMATED LOSS -->
                    <label class="form-control-label">{{ tt("total_estimated_loss") }} <span class="text-danger" v-if="(checkDomain === 'reliability' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))">* </span>
						<el-tooltip content="Estimasi kerugian yang timbul apabila SOE terjadi dikalikan Lead Time di sub Cataloguer" placement="top">
							<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
						</el-tooltip>
					</label>
                    <base-input rules="required"
						disabled="true"
						:name="tt('total_estimated_loss')" 
						v-model="input.shp_total_estimated_loss" 
						@input="input.shp_total_estimated_loss = input.shp_total_estimated_loss.toUpperCase()"
						@focus="changeLog('total_estimated_loss')"
						type="number">
                    </base-input>
                    
				</fieldset>
            </tab-pane>

            <!-- TAB CATALOGUER -->
            <tab-pane>
                <span slot="title">Cataloguer</span>
                <!-- CATALOGUER DOMAIN -->
				<fieldset class="p-4 mb-4">

					<!-- UNIT PRICE AND QUANTITY ON HAND -->
					<div class="row">
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("unit_price") }} 
								<el-tooltip content="Harga parts (satuan/ unit dalam rupiah)" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('unit_price')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_unit_price"
                                type="number"
								@input="unitPriceAndQoHCalculation"
								@focus="changeLog('unit_price')">
							</base-input>
						</div>
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("quantity_on_hand") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
								<el-tooltip content="Jumlah spare part yang tercatat ada di dalam gudang per tanggal dilakukan analisa" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('quantity_on_hand')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_quantity_on_hand"
                                type="number"
								@input="unitPriceAndQoHCalculation"
								@focus="changeLog('quantity_on_hand')">
							</base-input>
						</div>
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("value_unrestricted") }} 
								<el-tooltip content="Harga parts satuan / unit pembelian dikalikan Quantity on Hand" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('value_unrestricted')" 
								disabled
								v-model="input.shp_value_unrestricted"
                                type="number">
							</base-input>
						</div>
					</div>

					<!-- LEAD TIME, CATEGORY LEAD TIME, & ABC_INDICATOR -->
					<div class="row">
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("estimated_lead_time") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
								<el-tooltip content="Waktu yang diperlukan dari terbit PR hingga barang tiba di gudang, satuan Bulan" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('estimated_lead_time')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_estimated_lead_time"
								@input="input.shp_estimated_lead_time = input.shp_estimated_lead_time.toUpperCase()"
								@focus="changeLog('estimated_lead_time')"
								type="number">
							</base-input>
						</div>
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("category_lead_time") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
								<el-tooltip content="<3 Bulan, 3-6 bulan, >6 bulan" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('category_lead_time')">
								<el-select 
									:disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" 
									:placeholder="tt('choose_category_lead_time')" 
									v-model="input.shp_category_lead_time" 
									clearable 
									@focus="changeLog('category_lead_time')"
								>
									<el-option value="<3 Bulan">&lt;3 Bulan</el-option>
									<el-option value="3-6 bulan">3-6 bulan</el-option>
									<el-option value=">6 bulan">&gt;6 Bulan</el-option>
								</el-select>
							</base-input>
						</div>
						<div class="col-md-4">
							<label class="form-control-label">{{ tt("abc_indicator") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
								<el-tooltip content="Penggunaan indikator A, B, C, D, E atau X untuk material tersebut" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('abc_indicator')">
								<el-select 
									:disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" 
									:placeholder="tt('choose_abc_indicator')" 
									v-model="input.shp_abc_indicator" 
									clearable 
									@focus="changeLog('abc_indicator')"
								>
									<el-option value="A">A</el-option>
									<el-option value="B">B</el-option>
									<el-option value="C">C</el-option>
									<el-option value="D">D</el-option>
									<el-option value="E">E</el-option>
									<el-option value="X">X</el-option>
								</el-select>
							</base-input>
						</div>
					</div>
					
					<!-- MRP TYPE -->
					<fieldset class="border p-4 mb-4">
						<div class="row">
							<legend class="col-12 float-none w-auto p-2"><h4>{{ tt("mrp_type") }}</h4></legend>
							<div class="col-md-4" v-if="!['A1S','B2S'].includes(mcrType)">
								<label class="form-control-label">{{ tt("original_mrp_type") }}
									<el-tooltip content="Stock Strategy sebelum SHP" placement="top">
										<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
									</el-tooltip>
								</label>
								<base-input :name="tt('original_mrp_type')">
									<el-select disabled
										:placeholder="tt('choose_original_mrp_type')"
										v-model="input.shp_original_stock_strategy"
										@input="input.shp_original_stock_strategy = input.shp_original_stock_strategy.toUpperCase()"
										@focus="changeLog('original_stock_strategy')"
										clearable
									>
										<el-option value="Non Stock">Non Stock</el-option>
										<el-option value="Stock (V1)">Stock (V1)</el-option>
									</el-select>
								</base-input>
							</div>
							<div :class="{'col-md-4': !['A1S','B2S'].includes(mcrType), 'col-md-12': ['A1S','B2S'].includes(mcrType)}">
								<label class="form-control-label">{{ !['A1S','B2S'].includes(mcrType) ? tt("after_analysis_mrp_type") : tt("mrp_type") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
									<el-tooltip content="Stock strategy setelah SHP" placement="top" v-if="!['A1S','B2S'].includes(mcrType)">
										<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
									</el-tooltip>
								</label>
								<base-input :name="tt('after_analysis_mrp_type')">
									<el-select :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" 
										:placeholder="tt('choose_after_analysis_mrp_type')"
										v-model="input.shp_stock_strategy"
										@input="handleEmptyStockLevelType"
										@focus="changeLog('after_analysis_mrp_type')"
										clearable
									>
										<el-option value="Non Stock">Non Stock</el-option>
										<el-option value="Stock (V1)">Stock (V1)</el-option>
										<el-option value="Stock (V2)">Stock (V2)</el-option>
									</el-select>
								</base-input>
							</div>
							<div class="col-md-4" v-if="!['A1S','B2S'].includes(mcrType)">
								<label class="form-control-label">{{ tt("change_mrp_type") }}</label>
								<base-input :name="tt('change_mrp_type')">
									<el-select :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))" 
										:placeholder="tt('choose')"
										v-model="input.shp_change_mrp_type"
										@input="input.shp_change_mrp_type = input.shp_change_mrp_type.toUpperCase()"
										@focus="changeLog('change_mrp_type')"
										clearable
									>
										<el-option value="yes">Yes</el-option>
										<el-option value="no">No</el-option>
									</el-select>
								</base-input>
							</div>
						</div>
					</fieldset>

					<!-- STOCK LEVEL TYPE CALCULATION -->
					<div class="row">
						<div class="col-6" v-if="!['A1S', 'B2S'].includes(mcrType)">
							<label class="form-control-label">{{ tt("original_stock_level_type_calculation") }}
								<el-tooltip content="Sebelum Stock calculation berdasar SHP" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('original_stock_level_type_calculation')">
								<el-select disabled
								rows="3"
								:placeholder="tt('choose')"
								v-model="input.shp_original_stock_level_type_calculation"
								@input="input.shp_original_stock_level_type_calculation = input.shp_original_stock_level_type_calculation.toUpperCase()"
								@focus="changeLog('original_stock_level_type_calculation')"
								clearable>
									<el-option value="Service Level">Service Level</el-option>
									<el-option value="Value Optimization">Value Optimization</el-option>
									<el-option value="Min-Max">Min-Max</el-option>
								</el-select>
							</base-input>
                        </div>
                        <div :class="{'col-6': !['A1S', 'B2S'].includes(mcrType), 'col-12': ['A1S', 'B2S'].includes(mcrType)}">
                            <label class="form-control-label">{{ !['A1S', 'B2S'].includes(mcrType) ? tt("after_analysis_stock_level_type_calculation") : tt("stock_level_type_calculation") }} <span class="text-danger" v-if="(checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) && input.shp_stock_strategy !== 'NON STOCK'">* </span>
								<el-tooltip content="Setelah Stock calculation berdasar SHP" placement="top" v-if="!['A1S','B2S'].includes(mcrType)">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
								<el-tooltip content="Setelah Stock calculation berdasar SHP" placement="top" v-if="['A1S','B2S'].includes(mcrType)">
									<div slot="content">
										<span>Jenis formula atau kalkulasi level stok yang digunakan untuk spare part tersebut</span>
										<ul class="pt-2 pl-4">
											<li><b>Service Level</b></li>
											<li><b>Value Optimization</b></li>
											<li><b>Min-Max</b></li>
										</ul>
									</div>
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('after_analysis_stock_level_type_calculation')" :rules="(checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))) && input.shp_stock_strategy !== 'NON STOCK' ? 'required' : ''">
								<el-select :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								rows="3"
								:placeholder="tt('choose')"
								v-model="input.shp_stock_level_type_calculation"
								@input="input.shp_stock_level_type_calculation = input.shp_stock_level_type_calculation.toUpperCase()"
								@focus="changeLog('after_analysis_stock_level_type_calculation')"
								clearable>
									<el-option value="Service Level">Service Level</el-option>
									<el-option value="Value Optimization">Value Optimization</el-option>
									<el-option value="Min-Max">Min-Max</el-option>
								</el-select>
							</base-input>
                        </div>
                    </div>

					<!-- SERVICE LEVEL -->
					<div class="row">
						<div class="col-6" v-if="!['A1S', 'B2S'].includes(mcrType)">
							<label class="form-control-label">{{ tt("original_service_level") }} 
								<el-tooltip content="Target dari Service Level yang diinginkan untuk spare part tersebut sebelum analisa" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label> 
							<base-input :name="tt('original_service_level')" 
								disabled
								v-model="input.shp_original_service_level"
								@input="input.shp_original_service_level = input.shp_original_service_level.toUpperCase()"
								@focus="changeLog('original_service_level')"
								type="number">
							</base-input>
						</div>

						<div :class="{'col-6': !['A1S', 'B2S'].includes(mcrType), 'col-12': ['A1S', 'B2S'].includes(mcrType)}">
							<label class="form-control-label">{{ !['A1S', 'B2S'].includes(mcrType) ? tt("after_analysis_service_level") : tt("service_level") }} 
								<el-tooltip content="Target dari Service Level yang diinginkan untuk spare part tersebut setelah analisa" placement="top" v-if="!['A1S','B2S'].includes(mcrType)">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('after_analysis_service_level')" 
								:disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_service_level"
								@input="input.shp_service_level = input.shp_service_level.toUpperCase()"
								@focus="changeLog('after_analysis_service_level')"
								type="number">
							</base-input>
						</div>
					</div>

					<!-- STOCK LEVEL -->
					<div class="row">
						<div class="col-6" v-if="!['A1S', 'B2S'].includes(mcrType)">
							<label class="form-control-label">{{ tt("original_stock_level") }}</label>
							<base-input :name="tt('original_stock_level')"
								disabled
								v-model="input.shp_original_stock_level"
								@input="input.shp_original_stock_level = input.shp_original_stock_level.toUpperCase()"
								@focus="changeLog('original_stock_level')"
								type="number">
							</base-input>
						</div>

						<div :class="{'col-6': !['A1S', 'B2S'].includes(mcrType), 'col-12': ['A1S', 'B2S'].includes(mcrType)}">
							<label class="form-control-label">{{ !['A1S', 'B2S'].includes(mcrType) ? tt("after_analysis_stock_level") : tt("stock_level") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
								<el-tooltip content="Stock level yang perlu ada di gudang, sebagai hasil perhitungan" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
							<base-input :name="tt('after_analysis_stock_level')"
								:disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
								v-model="input.shp_stock_level"
								@input="input.shp_stock_level = input.shp_stock_level.toUpperCase()"
								@focus="changeLog('after_analysis_stock_level')"
								type="number">
							</base-input>
						</div>
					</div>

					<!-- MIN -->
                    <div class="row">
                        <div class="col-6" v-if="!['A1S','B2S'].includes(mcrType)">
                            <label class="form-control-label">{{ tt("original_min") }}</label>
                            <base-input :name="tt('min')" disabled
                                v-model="input.shp_original_min"
                                type="number"
                                @input="input.shp_original_min = input.shp_original_min.toUpperCase()"
								@focus="changeLog('original_min')">
                            </base-input>
                        </div>
						
                        <div :class="{'col-6': !['A1S','B2S'].includes(mcrType), 'col-12': ['A1S','B2S'].includes(mcrType)}">
                            <label class="form-control-label">{{ !['A1S','B2S'].includes(mcrType) ? tt("after_analysis_min") : tt("shp_min") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')) || input.shp_stock_strategy == 'STOCK (V1)'">* </span>
								<el-tooltip content="Nilai minimum yang tersimpan di gudang" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
                            <base-input :name="tt('after_analysis_min')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                                v-model="input.shp_min"
                                type="number"
                                @input="input.shp_min = input.shp_min.toUpperCase()"
                                @focus="changeLog('after_analysis_min')">
                            </base-input>
                        </div>
                    </div>

					<!-- MAX -->
                    <div class="row">
                        <div class="col-6" v-if="!['A1S', 'B2S'].includes(mcrType)">
                            <label class="form-control-label">{{ tt("original_max") }}</label>
                            <base-input :name="tt('min')" disabled
                                v-model="input.shp_original_max"
                                type="number"
                                @input="input.shp_original_max = input.shp_original_max.toUpperCase()"
								@focus="changeLog('original_max')">
                            </base-input>
                        </div>
						
                        <div :class="{'col-6': !['A1S', 'B2S'].includes(mcrType), 'col-12': ['A1S', 'B2S'].includes(mcrType)}">
                            <label class="form-control-label">{{ !['A1S', 'B2S'].includes(mcrType) ? tt("after_analysis_max") : tt("shp_max") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer')) || input.shp_stock_strategy == 'STOCK (V1)'">* </span>
								<el-tooltip content="Nilai maximum yang tersimpan di gudang" placement="top">
									<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
								</el-tooltip>
							</label>
                            <base-input :name="tt('after_analysis_max')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                                v-model="input.shp_max"
                                type="number"
                                @input="input.shp_max = input.shp_max.toUpperCase()"
                                @focus="changeLog('after_analysis_max')">
                            </base-input>
                        </div>
                    </div>

					<!-- DATE DURING ANALYSIS -->
					<label class="form-control-label">{{ tt("date_during_analysis") }} 
						<el-tooltip content="Tanggal dilakukan analisa material tersebut" placement="top">
							<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
						</el-tooltip>
					</label>
					<base-input :name="tt('date_during_analysis')" :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
						v-model="input.shp_date_during_analysis"
						type="date"
						:placeholder="tt('date_during_analysis')"
						@focus="changeLog('date_during_analysis')">
					</base-input>

					<!-- RESUME JUSTIFICATION STOCK STRATEGY -->
                    <label class="form-control-label">{{ tt("resume_justification_stock_strategy") }} <span class="text-danger" v-if="checkDomain === 'cataloguer' || (checkDomain.includes('reliability') && checkDomain.includes('cataloguer'))">* </span>
						<el-tooltip content="Kesimpulan singkat mengenai alasan pemilihan strategi stock, untuk didiskusikan oleh Perencana Material bersama Keandalan" placement="top">
							<sup><span style="font-size:10px"><i class="fa fa-exclamation-circle"></i></span></sup>
						</el-tooltip>
					</label>
                    <base-input :name="tt('resume_justification_stock_strategy')">
                        <textarea
                        :disabled="form.disabled || (checkDomain !== 'cataloguer' && !(checkDomain.includes('reliability') && checkDomain.includes('cataloguer')))"
                        class="form-control"
                        rows="3"
                        v-model="input.shp_resume_justification_stock_strategy"
                        @input="input.shp_resume_justification_stock_strategy = input.shp_resume_justification_stock_strategy.toUpperCase(), fixCursor($event)"
                        @focus="changeLog('resume_justification_stock_strategy')">
                        </textarea>
                    </base-input>
				</fieldset>
            </tab-pane>
        </tabs>

		<div class="text-right">

			<base-button type="default" @click="checkChangeLog">Change Log</base-button>

			<base-button
				type="primary"
				@click="checkOwnerEstimate()"
				:disabled="form.disabled || form.btnSave.onLoading">
				<span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
				<span v-else>{{ tt("save") }}</span>
			</base-button>
		</div>

		<!-- CHANGE LOG -->
		<modal :show.sync="modalChangeLog" size="xl">
			<template slot="header">
					<h5 class="modal-title">{{ tt('change_log') }}</h5>
			</template>
			<el-table height="500px" class="table-responsive" header-row-class-name="thead-light" :data="dataChangeLog">
				<el-table-column :label="tt('date')" :prop="tt('date')" min-width="120px" sortable>
					<template v-slot="{row}">
						{{ row.created_at }}
					</template>
				</el-table-column>
				<el-table-column :label="tt('column')" :prop="tt('column')" min-width="120px" sortable>
					<template v-slot="{row}">
					<span v-if="row.message.indexOf('line') != -1">
						{{ row.message.substring(0, row.message.indexOf('line') - 1)}}
					</span>
					<span v-else>
						{{ row.message.substring( row.message.indexOf('line'), row.message.indexOf('changed')) }}
					</span>
					</template>
				</el-table-column>
				<el-table-column :label="tt('line')" :prop="tt('line')" min-width="100px" sortable>
					<template v-slot="{row}">
					<span v-if="row.message.indexOf('line') != -1">
						{{ row.message.substring( row.message.indexOf('line'), row.message.indexOf('changed')) }}
					</span>
					<span v-else> {{ '-' }}</span>
					</template>
				</el-table-column>
				<el-table-column :label="tt('old_values')" :prop="tt('old_values')" min-width="150px" sortable>
					<template v-slot="{row}">
						{{ row.message.substring(row.message.indexOf('from') + 6, row.message.indexOf('" to')) }}
					</template>
				</el-table-column>
				<el-table-column :label="tt('new_values')" :prop="tt('new_values')" min-width="150px" sortable>
					<template v-slot="{row}">
						{{ row.message.substring(row.message.indexOf('to "') + 4, row.message.length - 1) }}
					</template>
				</el-table-column>
				<el-table-column :label="tt('changed_by')" :prop="tt('changed_by')" min-width="120px" sortable>
					<template v-slot="{row}">
						{{ row.executed_by }}
					</template>
				</el-table-column>
			</el-table>
		</modal>
	</div>
</template>

<script>
	import {mapState} from 'vuex'
	import Api from "@/helpers/api";
	import draftList from "@/services/material/draftList.service";
	import manufacture from "@/services/master/manufacture.service";
	import equipmentCode from "@/services/master/equipmentCode.service";
    import unitOfMeasure from '@/services/dictionary/unitOfMeasure.service';
	import baseApiUrl from '@/configs/config';
	import materialNumber from '@/services/master/materialNumber.service';
	import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
	import transactionLog from '@/services/report/transactionLog.service';
	import sourceType from "@/services/master/sourceType.service";
	import manrefType from "@/services/master/manrefType.service";

	export default {
		props: ['clientPlantLevel', 'input.price_reference'],
		data() {
			return {
				requestCalls: 0,
		      	form: {
					disabled: false,
					btnSave: {
						onLoading: false
					}
			    },
			    inputTo: {
                    loading: false,
                    loadTimeout: null
                },
				manufacture: [],
				equipmentCode: [],
				equipments: [],
				unitOfMeasure: [],
				input: {
					id:'',
			        shp_failure_mode: [],
			        shp_mce_strategy: {
						mce_strategy: [],
						mce_interval:[],
						mce_net_pf:[],
						mce_tindakan:[],
					},
			        shp_effect_spare_part_not_available: '',
			        shp_action_to_handle: '',
			        shp_lead_time_action_to_handle: '',
			        shp_consequences_criticality: '',
			        shp_estimated_loss_idr: '',
					shp_estimated_loss_day: '',
					shp_total_estimated_loss: '',
			        shp_estimated_lead_time: '',
					shp_category_lead_time: '',
					shp_abc_indicator: '',
			        shp_original_stock_strategy: '',
					shp_stock_strategy: '',
					shp_change_mrp_type: '',
			        shp_resume_justification_stock_strategy: '',
					shp_equipment:'',
					material_number:'',
					shp_unit_price:'',
					shp_quantity_on_hand: '0',
					shp_value_unrestricted:'',
					shp_demand_rate:'',
					shp_demand_rate_unit:'',
					code:'',
					shp_original_stock_level_type_calculation:'',
					shp_stock_level_type_calculation: '',
					shp_original_service_level:'',
					shp_service_level:'',
					shp_original_stock_level:'',
					shp_stock_level:'',
					shp_qty:'',
					shp_uom: '',
					shp_uom_mirroring: '',
					shp_original_min:'',
					shp_min: '',
					shp_original_max:'',
					shp_max: '',
					shp_date_during_analysis: '',
					shp_owner_estimate: 0,
			    },
			    manufacturePartNumber: {
			        manufacture_code: [],
			        manufacture_code_view: [],
			        mpn: [],
			        source_type: [],
			        position: [],
			        manufacture_type: [],
			        manufacture_note: []
			    },
			    apiUrl: baseApiUrl.storageUrl,

			    mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type,
			    mcrType: this.$route.params.mcr_type,
			    token: this.$route.params.token,
				maxValidation:{
					size:null,
					text:null
				},
				materialNumbers:[],
				parameter: '',
                oldValue: {},
                newValue: {},
				modalChangeLog:false,
				dataChangeLog:[],
				sourceType: {},
				manrefType:{},
				equipmentQuantityInstall: {
					plant_code:[],
					equipment_code:[],
					qty_installed:[],
					drawing_number:[],
					del_flag:[],
					status:[],
					show:[],
					attachment:[]
			    },
				partShortDesc:'',
				prcInc:'',
				selectSearch: {
                    loadTimeout: null,
					loading:false,
                },
				containedShpValues:{},
				recordChangeLog: {},
			}
		},
		mounted() {
			this.getMcrId()
			// this.getDetailMcrItem()
			this.maximumValidation()
			this.checkFormType();
			this.getSourceType();
			this.getManrefType();
		},
		computed:{
			total_qty_installed() {
				let total = this.equipmentSHP.qty_installed.reduce((a,b) => parseInt(a) + parseInt(b), 0)
				if(Number.isInteger(total)) {
					return total;
				} else {
					return 0;
				}
			},
			checkDomain() {
				if ((this.authUserRole['Staff Reliability']) && this.authUserRole['Cataloguer']){
					return ['reliability', 'cataloguer']

				} else if (
					// this.authUserRole['User Pemeliharaan'] || 
					// this.authUserRole['User Non Pemeliharaan'] ||
					// this.authUserRole['Kabag User'] ||
					// this.authUserRole['Manager User'] ||
					// this.authUserRole['Staff Renhar'] || 
					// this.authUserRole['Kabag Renhar'] || 
					// this.authUserRole['Manager Renhar'] || 
					this.authUserRole['Staff Reliability']
					// this.authUserRole['Kabag Reliability'] ||
					// this.authUserRole['Manager Reliability'] ||
					// this.authUserRole['GM Pemeliharaan']
				) {
					return 'reliability';
				} else if (this.authUserRole['Cataloguer']) {
					return 'cataloguer';
				} else if (this.authUserRole['Master Data PI']) {
					return 'all';
				} else {
					return [];
				}
			},
			...mapState({
                authUserRole :state => state.auth.userRole,
                authUserPermission :state => state.auth.userPermission,
            }),
			insertChangeLog() {
        		var shp = {
                    effect_spare_part_not_available: this.input.shp_effect_spare_part_not_available,
                    action_to_handle: this.input.shp_action_to_handle,
                    lead_time_action_to_handle: this.input.shp_lead_time_action_to_handle,
                    consequences_criticality: this.input.shp_consequences_criticality,
                    estimated_loss_idr: this.input.shp_estimated_loss_idr,
                    estimated_loss_day: this.input.shp_estimated_loss_day,
                    total_estimated_loss: this.input.shp_total_estimated_loss,
                    estimated_lead_time: this.input.shp_estimated_lead_time,
                    category_lead_time: this.input.shp_category_lead_time,
                    abc_indicator: this.input.shp_abc_indicator,
                    unit_price: this.input.shp_unit_price,
                    quantity_on_hand: this.input.shp_quantity_on_hand,
                    value_unrestricted: this.input.shp_value_unrestricted,
                    original_stock_strategy: this.input.shp_original_stock_strategy,
                    stock_strategy: this.input.shp_stock_strategy,
                    change_mrp_type: this.input.shp_change_mrp_type,
                    resume_justification_stock_strategy: this.input.shp_resume_justification_stock_strategy,
                    equipment: this.input.shp_equipment,
                    material_number: this.input.material_number,
                    demand_rate: this.input.shp_demand_rate, 
                    demand_rate_unit: this.input.shp_demand_rate_unit, 
					original_stock_level_type_calculation:this.input.shp_original_stock_level_type_calculation,
					stock_level_type_calculation:this.input.shp_stock_level_type_calculation,
					original_service_level:this.input.shp_original_service_level,
					service_level:this.input.shp_service_level,
					original_stock_level:this.input.shp_original_stock_level,
					stock_level:this.input.shp_stock_level,
					service_level:this.input.shp_service_level,
					material_number: this.input.material_number,
					qty: this.input.shp_qty,
					uom: this.input.shp_uom,
					original_min: this.input.shp_original_min,
					min: this.input.shp_min,
					original_max: this.input.shp_original_max,
					max: this.input.shp_max,
					date_during_analysis: this.input.shp_date_during_analysis,
					owner_estimate: this.input.shp_owner_estimate,
        		};

                var shp_manufacture = {};
				if((this.mcrType == 'A2' || this.mcrType == 'B3') && this.manufacturePartNumber.manufacture_code.length){
					for (var i=0; i<this.manufacturePartNumber.manufacture_code.length; i++) {
						shp_manufacture['manufacture_code_line_'+(i+1)] = this.manufacturePartNumber.manufacture_code[i];
						shp_manufacture['mpn_line_'+(i+1)] = this.manufacturePartNumber.mpn[i];
						shp_manufacture['source_type_line_'+(i+1)] = this.manufacturePartNumber.source_type[i];
						shp_manufacture['position_line_'+(i+1)] = this.manufacturePartNumber.position[i];
						shp_manufacture['manufacture_type_line_'+(i+1)] = this.manufacturePartNumber.manufacture_type[i];
						shp_manufacture['manufacture_note_line_'+(i+1)] = this.manufacturePartNumber.manufacture_note[i];
					}
				}

				var shp_failure_mode = {};
				if (this.input.shp_failure_mode.length) {
					for(var i = 0; i < this.input.shp_failure_mode.length; i++){
						shp_failure_mode['failure_mode_line_' + (i+1)] = this.input.shp_failure_mode[i]
					}	
				}

				var shp_mce_strategy = {};
				if (this.input.shp_mce_strategy.mce_strategy.length) {
					for(var i = 0; i < this.input.shp_mce_strategy.mce_strategy.length; i++){
						shp_mce_strategy['mce_strategy_line_' + (i+1)] = this.input.shp_mce_strategy.mce_strategy[i]
						shp_mce_strategy['mce_interval_line_' + (i+1)] = this.input.shp_mce_strategy.mce_interval[i]
						shp_mce_strategy['mce_net_pf_line_' + (i+1)] = this.input.shp_mce_strategy.mce_net_pf[i]
						shp_mce_strategy['mce_tindakan_line_' + (i+1)] = this.input.shp_mce_strategy.mce_tindakan[i]
					}
				}

        		var data = Object.assign(shp, shp_mce_strategy, shp_failure_mode, shp_manufacture);

        		return data;
			},
		},
		watch: {
			insertChangeLog(newValue, oldValue) {
				var parameter = this.parameter;

				if (parameter != '') {
					if (!Object.keys(this.oldValue).includes(parameter)) { // kalau sudah ada oldValue dengan parameter ini maka jangan ditimpa (insert lagi)
						this.oldValue[parameter] = `${oldValue[parameter]}`;
					}

					this.newValue[parameter] = `${newValue[parameter]}`;
				}
			},
			'input.shp_estimated_loss_idr': function() {
				var a = this.input.shp_estimated_loss_idr;
				var b = this.input.shp_estimated_loss_day

				this.input.shp_total_estimated_loss = a * b
			},
			'input.shp_estimated_loss_day': function() {
				var a = this.input.shp_estimated_loss_idr;
				var b = this.input.shp_estimated_loss_day

				this.input.shp_total_estimated_loss = a * b
			}
		},
		methods: {
			maximumValidation(){
				this.maximumSizeAlert().then((res) =>{
					this.maxValidation.size = res.size
					this.maxValidation.text = res.text
				})
			},
			// CHECK FORM TYPE
			checkFormType() {
				if(this.formType == 'edit' && this.authUserPermission['mcr-approval-shp-form-edit']){
					this.form.disabled = false;
				} else if (this.formType == 'detail'){
					this.form.disabled = true;
				} else if (this.formType == 'approval' && this.authUserPermission['mcr-approval-shp-form-edit']){
					this.form.disabled = false;
				} else if (this.formType == 'sapSyncTaskEdit'){
					this.form.disabled = false;
				} else {
					this.form.disabled = true;
				}
			},
			getDetailMcrItem() {
				let context = this;
				if(this.input.code || (this.mcrType == 'A2' || this.mcrType == 'B3')){
					this.requestCalls++;
					let query = !!this.input.code ? { code : this.input.code} : ((this.mcrType == 'A2' || this.mcrType == 'B3' ) ? {mcr_item_code: this.mcrItemCode} : '')
					Api(context, draftList.shp_mcr_item(query))
					.onSuccess(function(response) {
						context.input.id     									= response.data.data.mcr_item_id != null ? response.data.data.mcr_item_id : ''
						context.input.material_number   						= response.data.data.material_number != null ? response.data.data.material_number : ''
						context.input.shp_effect_spare_part_not_available  		= response.data.data.shp_effect_spare_part_not_available != null ? response.data.data.shp_effect_spare_part_not_available : ''
						context.input.shp_action_to_handle  					= response.data.data.shp_action_to_handle != null ? response.data.data.shp_action_to_handle : ''
						context.input.shp_lead_time_action_to_handle  			= response.data.data.shp_lead_time_action_to_handle != null ? response.data.data.shp_lead_time_action_to_handle : ''
						context.input.shp_consequences_criticality  			= response.data.data.shp_consequences_criticality != null ? response.data.data.shp_consequences_criticality : ''
						context.input.shp_estimated_loss_idr  					= response.data.data.shp_estimated_loss_idr != null ? response.data.data.shp_estimated_loss_idr : ''
						context.input.shp_estimated_loss_day  					= response.data.data.shp_estimated_loss_day != null ? response.data.data.shp_estimated_loss_day : ''
						context.input.shp_total_estimated_loss  				= response.data.data.shp_total_estimated_loss != null ? response.data.data.shp_total_estimated_loss : ''
						context.input.shp_estimated_lead_time  					= response.data.data.shp_estimated_lead_time != null ? response.data.data.shp_estimated_lead_time : ''
						context.input.shp_category_lead_time  					= response.data.data.shp_category_lead_time != null ? response.data.data.shp_category_lead_time : ''
						context.input.shp_abc_indicator  						= response.data.data.shp_abc_indicator != null ? response.data.data.shp_abc_indicator : ''
						context.input.shp_unit_price  							= response.data.data.shp_unit_price != null ? response.data.data.shp_unit_price : ''
						context.input.shp_quantity_on_hand  					= response.data.data.shp_quantity_on_hand != null ? response.data.data.shp_quantity_on_hand : '0'
						context.input.shp_value_unrestricted  					= response.data.data.shp_value_unrestricted != null ? response.data.data.shp_value_unrestricted : ''
						context.input.shp_change_mrp_type  						= response.data.data.shp_change_mrp_type != null ? response.data.data.shp_change_mrp_type : ''
						context.input.shp_resume_justification_stock_strategy  	= response.data.data.shp_resume_justification_stock_strategy != null ? response.data.data.shp_resume_justification_stock_strategy : ''
						context.input.shp_demand_rate 							= response.data.data.shp_demand_rate != null ? response.data.data.shp_demand_rate : ''
						context.input.shp_demand_rate_unit 						= response.data.data.shp_demand_rate_unit != null ? response.data.data.shp_demand_rate_unit : ''
						context.input.shp_equipment  							= response.data.data.equipment_code != null ? response.data.data.equipment_code : ''
						context.input.shp_qty  									= response.data.data.shp_qty != null ? response.data.data.shp_qty : ''
						context.input.shp_uom  									= response.data.data.shp_uom != null ? response.data.data.shp_uom : context.input.shp_uom
						context.input.shp_original_stock_strategy  				= response.data.data.shp_original_stock_strategy  != null ? response.data.data.shp_original_stock_strategy : ''
						context.input.shp_stock_strategy  						= response.data.data.shp_stock_strategy  != null ? response.data.data.shp_stock_strategy : ''
						context.input.shp_original_stock_level_type_calculation = response.data.data.shp_original_stock_level_type_calculation != null ? response.data.data.shp_original_stock_level_type_calculation : ''
						context.input.shp_stock_level_type_calculation 			= response.data.data.shp_stock_level_type_calculation != null ? response.data.data.shp_stock_level_type_calculation : ''
						context.input.shp_original_service_level  				= response.data.data.shp_original_service_level != null ? response.data.data.shp_original_service_level : ''
						context.input.shp_service_level  						= response.data.data.shp_service_level != null ? response.data.data.shp_service_level : ''
						context.input.shp_original_stock_level  				= response.data.data.shp_original_stock_level != null ? response.data.data.shp_original_stock_level : ''
						context.input.shp_stock_level  							= response.data.data.shp_stock_level != null ? response.data.data.shp_stock_level : ''
						context.input.shp_original_min  						= response.data.data.shp_original_min != null ? response.data.data.shp_original_min : ''
						context.input.shp_min  									= response.data.data.shp_min != null ? response.data.data.shp_min : ''
						context.input.shp_original_max  						= response.data.data.shp_original_max != null ? response.data.data.shp_original_max : ''
						context.input.shp_max  									= response.data.data.shp_max != null ? response.data.data.shp_max : ''
						context.input.shp_date_during_analysis  				= response.data.data.shp_date_during_analysis != null ? response.data.data.shp_date_during_analysis : ''
						context.input.shp_owner_estimate						= response.data.data.shp_owner_estimate != null ? parseInt(response.data.data.shp_owner_estimate) : context.input.shp_owner_estimate

						context.equipments = [];
						context.equipments.push({
							equipment_code: response.data.data.equipment_code,
							equipment_name: response.data.data.equipment_name,
						})	
						
						if(!!context.input.shp_equipment){
							context.dependEquipment(context.input.shp_equipment)
						}

						context.input.shp_mce_strategy.mce_strategy = []
						context.input.shp_mce_strategy.mce_interval = []
						context.input.shp_mce_strategy.mce_net_pf = []
						context.input.shp_mce_strategy.mce_tindakan = []
						context.input.shp_failure_mode  = []
						
						if(response.data.data.shp_mce_strategy != ''){
							let mceStrategies = JSON.parse(response.data.data.shp_mce_strategy);
							let mceIntervals = JSON.parse(response.data.data.shp_interval);
							let mceNetPf = JSON.parse(response.data.data.shp_net_pf);
							let mceTindakan = JSON.parse(response.data.data.shp_tindakan);
							for(var i = 0; i < mceStrategies.length;i++){
								context.input.shp_mce_strategy.mce_strategy.push(mceStrategies[i])
								context.input.shp_mce_strategy.mce_interval.push(mceIntervals[i])
								context.input.shp_mce_strategy.mce_net_pf.push(mceNetPf[i])
								context.input.shp_mce_strategy.mce_tindakan.push(mceTindakan[i])
							}
						}

						if(response.data.data.shp_failure_mode != ''){
							let failureModes = JSON.parse(response.data.data.shp_failure_mode);

							for(var i = 0; i < failureModes.length;i++){
								context.input.shp_failure_mode.push(failureModes[i])
							}
						}

					})
					.onError(function(error) {
						if (error.response.status == 404) {
							context.input.material_number = ''
							context.input.shp_failure_mode = []
							context.input.shp_mce_strategy.mce_strategy = []
							context.input.shp_mce_strategy.mce_interval = []
							context.input.shp_mce_strategy.mce_net_pf = []
							context.input.shp_mce_strategy.mce_tindakan = []
							context.input.shp_effect_spare_part_not_available = ''
							context.input.shp_action_to_handle = ''
							context.input.shp_lead_time_action_to_handle = ''
							context.input.shp_consequences_criticality = ''
							context.input.shp_estimated_loss_idr = ''
							context.input.shp_estimated_loss_day = ''
							context.input.shp_total_estimated_loss = ''
							context.input.shp_estimated_lead_time = ''
							context.input.shp_category_lead_time = ''
							context.input.shp_abc_indicator = ''
							context.input.shp_unit_price = ''
							context.input.shp_quantity_on_hand = '0'
							context.input.shp_value_unrestricted = ''
							context.input.shp_original_stock_strategy = ''
							context.input.shp_stock_strategy = ''
							context.input.shp_change_mrp_type = ''
							context.input.shp_resume_justification_stock_strategy = ''
							context.input.shp_demand_rate = ''
							context.input.shp_demand_rate_unit = ''
							context.input.shp_original_stock_level_type_calculation = ''
							context.input.shp_stock_level_type_calculation = ''
							context.input.shp_original_service_level = ''
							context.input.shp_service_level = ''
							context.input.shp_original_stock_level = ''
							context.input.shp_stock_level = ''
							context.input.shp_qty = ''
							context.input.shp_original_min = ''
							context.input.shp_min = ''
							context.input.shp_original_max = ''
							context.input.shp_max = ''
							context.input.shp_date_during_analysis = ''
							context.input.shp_owner_estimate = 0
						}
					}).onFinish(() =>{
						context.requestCalls--;
					}).call();

					if(context.mcrType == 'A2' || context.mcrType == 'B3'){
						Api(context, draftList.getMcrItemMpn(this.mcrItemCode))
						.onSuccess(function(response) {
						context.manufacturePartNumber.manufacture_code = [];
						context.manufacturePartNumber.manufacture_code_view = [];
						context.manufacturePartNumber.mpn = [];
						context.manufacturePartNumber.source_type = [];
						context.manufacturePartNumber.position = [];
						context.manufacturePartNumber.manufacture_type = [];
						context.manufacturePartNumber.manufacture_note = [];

						for (var i = 0; i < response.data.data.length; i++) {
							context.manufacture[i] = response.data.data[i];
							context.manufacturePartNumber.manufacture_code[i] = response.data.data[i].manufacture_code;
							context.manufacturePartNumber.manufacture_code_view[i] = response.data.data[i].manufacture_code +' - '+ response.data.data[i].manufacture_name;
							context.manufacturePartNumber.mpn[i] = response.data.data[i].mpn;
							context.manufacturePartNumber.source_type[i] = response.data.data[i].source_type;
							context.manufacturePartNumber.position[i] = response.data.data[i].position;
							context.manufacturePartNumber.manufacture_type[i] = response.data.data[i].manufacture_type;
							context.manufacturePartNumber.manufacture_note[i] = response.data.data[i].manufacture_note;
						}
						})
						.onError(function(error) {
						if (error.response.status == 404) {
							context.manufacturePartNumber.manufacture_code = [];
							context.manufacturePartNumber.mpn = [];
							context.manufacturePartNumber.source_type = [];
							context.manufacturePartNumber.position = [];
							context.manufacturePartNumber.manufacture_type = [];
							context.manufacturePartNumber.manufacture_note = [];
						}
						}).onFinish(() =>{
							context.requestCalls--;
						})
						.call();
					}
				}else{
						this.input.material_number = ''
						this.input.shp_failure_mode = [] 
						this.input.shp_mce_strategy.mce_strategy = []
						this.input.shp_mce_strategy.mce_interval = []
						this.input.shp_mce_strategy.mce_net_pf = []
						this.input.shp_mce_strategy.mce_tindakan = []
						this.input.shp_effect_spare_part_not_available = ''
						this.input.shp_action_to_handle = ''
						this.input.shp_lead_time_action_to_handle = ''
						this.input.shp_consequences_criticality = ''
						this.input.shp_estimated_loss_idr = ''
						this.input.shp_estimated_loss_day = ''
						this.input.shp_total_estimated_loss = ''
						this.input.shp_estimated_lead_time = ''
						this.input.shp_category_lead_time = ''
						this.input.shp_abc_indicator = ''
						this.input.shp_unit_price = ''
						this.input.shp_quantity_on_hand = '0'
						this.input.shp_value_unrestricted = ''
						this.input.shp_original_stock_strategy = ''
						this.input.shp_stock_strategy = ''
						this.input.shp_change_mrp_type = ''
						this.input.shp_resume_justification_stock_strategy = ''
						this.input.shp_demand_rate = ''
						this.input.shp_demand_rate_unit = ''
						this.input.shp_original_stock_level_type_calculation = ''
						this.input.shp_stock_level_type_calculation = ''
						this.input.shp_original_service_level = ''
						this.input.shp_service_level = ''
						this.input.shp_original_stock_level = ''
						this.input.shp_stock_level = ''
						this.input.shp_uom = this.input.shp_uom
						this.input.shp_qty = ''
						this.input.shp_original_min = ''
						this.input.shp_min = ''
						this.input.shp_original_max = ''
						this.input.shp_max = ''
						this.input.shp_date_during_analysis = ''
						this.input.shp_owner_estimate = this.input.shp_owner_estimate;
				}
		    },

		    getManufacture(query) {
		    	if (query.length > 1) {
					clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, manufacture.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
	                        context.manufacture =  response.data.data.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.manufacture = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
            	}
		    },

			getEquipment(query) {
		    	if (query.length > 3) {
			  		clearTimeout(this.inputTo.loadTimeout);             
	                let context = this;   
	                this.inputTo.loadTimeout = setTimeout(() => {
	                    this.inputTo.loading = true;
	                    Api(context, equipmentCode.getByCompany(null, {search: query})).onSuccess(function(response) {
	                        context.equipments =  response.data.data;                                    
	                        context.inputTo.loading = false;   
	                    }).onError(function(error) {
	                        if (error.response.status == 404) {
	                            context.equipments = [];
	                            context.inputTo.loading = false;
	                        }
	                    }).call();             
	                })
	            }
		    },

			changeEquipment(value){
				this.input.material_number = '';
				this.dependEquipment(value)
			},
			dependEquipment(eqp) {                                   
				let context = this;   
				Api(context,  materialNumber.get({search: '',equipment:eqp})).onSuccess(function(response) {
					context.materialNumbers = response.data.data;                        
					context.inputTo.loading = false;                     
				}).onError(function(error) {
					context.materialNumbers = [];
					context.inputTo.loading = false;
				}).call();                        
            },
			addMpn() {
		      if (this.manufacturePartNumber.manufacture_code.length == 0) {
		        this.manufacturePartNumber.manufacture_code.push("");
		        this.manufacturePartNumber.mpn.push("");
		        this.manufacturePartNumber.source_type.push("");
		        this.manufacturePartNumber.position.push("");
		        this.manufacturePartNumber.manufacture_type.push("");
		        this.manufacturePartNumber.manufacture_note.push("");
		      } else if (this.manufacturePartNumber.manufacture_code.length > 0) {
		        if (
		          this.manufacturePartNumber.manufacture_code[this.manufacturePartNumber.manufacture_code.length - 1] != "" &&
		          this.manufacturePartNumber.mpn[this.manufacturePartNumber.mpn.length - 1] != "" &&
		          this.manufacturePartNumber.source_type[this.manufacturePartNumber.source_type.length - 1] != "" &&
		          this.manufacturePartNumber.manufacture_type[this.manufacturePartNumber.manufacture_type.length - 1] != ""
		        ) {
					if (this.manufacturePartNumber.source_type[this.manufacturePartNumber.source_type.length - 1] == "DWG" && this.manufacturePartNumber.position[this.manufacturePartNumber.position.length - 1] == "") {
						this.$notify({
							message: this.tt("please_complete_data", { title: this.tt("mpn") }),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});

						return false;
					}

					this.manufacturePartNumber.manufacture_code.push("");
					this.manufacturePartNumber.mpn.push("");
					this.manufacturePartNumber.source_type.push("");
					this.manufacturePartNumber.position.push("");
					this.manufacturePartNumber.manufacture_type.push("");
					this.manufacturePartNumber.manufacture_note.push("");
		        } else {
		          this.$notify({
		            message: this.tt("please_complete_data", { title: this.tt("mpn") }),
		            type: "danger",
					verticalAlign: "bottom",
					horizontalAlign: "left"
		          });
		        }
		      }
		    },
		    removeMpn(key) {
		    	this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    if (result.value) {
                        this.manufacturePartNumber.manufacture_code.splice(key, 1);
						this.manufacturePartNumber.mpn.splice(key, 1);
						this.manufacturePartNumber.source_type.splice(key, 1);
						this.manufacturePartNumber.position.splice(key, 1);
						this.manufacturePartNumber.manufacture_type.splice(key, 1);
						this.manufacturePartNumber.manufacture_note.splice(key, 1);
                    }
                })
		    },
			addFailure(){
				if(this.input.shp_failure_mode.length == 0){
					this.input.shp_failure_mode.push("")
				}else{
					if(this.input.shp_failure_mode[this.input.shp_failure_mode.length - 1] != ""){
						this.input.shp_failure_mode.push("")
					}else{
						this.$notify({
							message: this.tt("please_complete_data", {title: this.tt("failure_mode")}),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});
						return false;
					}
				}
			},
			removeFailure(key){
				this.confirmDialog(this.tt('confirm_delete')).then((result) =>{
					if(result.value){
						this.input.shp_failure_mode.splice(key, 1)
					}
				})
			},
			addMce(){
				if(this.input.shp_mce_strategy.mce_strategy.length == 0){
					this.input.shp_mce_strategy.mce_strategy.push("")
					this.input.shp_mce_strategy.mce_interval.push("")
					this.input.shp_mce_strategy.mce_net_pf.push("")
					this.input.shp_mce_strategy.mce_tindakan.push("")
				}else{
					if(this.input.shp_mce_strategy.mce_strategy[this.input.shp_mce_strategy.mce_strategy.length - 1] != "" && this.input.shp_mce_strategy.mce_interval[this.input.shp_mce_strategy.mce_interval.length - 1] != "" && this.input.shp_mce_strategy.mce_tindakan[this.input.shp_mce_strategy.mce_tindakan.length - 1] != ""){
						this.input.shp_mce_strategy.mce_strategy.push("")
						this.input.shp_mce_strategy.mce_interval.push("")
						this.input.shp_mce_strategy.mce_net_pf.push("")
						this.input.shp_mce_strategy.mce_tindakan.push("")
					}else{
						this.$notify({
							message: this.tt("please_complete_data", {title: this.tt("mce_strategy_interval")}),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});
						return false;
					}
				}
			},
			removeMce(key){
				this.confirmDialog(this.tt('confirm_delete')).then((result) =>{
					if(result.value){
						this.input.shp_mce_strategy.mce_strategy.splice(key, 1)
						this.input.shp_mce_strategy.mce_interval.splice(key, 1)
						this.input.shp_mce_strategy.mce_net_pf.splice(key, 1)
						this.input.shp_mce_strategy.mce_tindakan.splice(key, 1)
					}
				})
			},
			checkOwnerEstimate() {
		      	let context = this;
				if(context.checkDomain == 'reliability' || (context.checkDomain.includes('reliability') && context.checkDomain.includes('cataloguer')) || context.mcrType == 'A2' || context.mcrType == 'B3') {
				// JIKA USER TERSEBUT MEMILIKI ROLE RELIABILITY SAJA ATAU KEDUANYA SEKALIPUN MCR TYPENYA CHANGE
					if (context.authUserRole['Staff Reliability'] || context.authUserRole['Staff Renhar'] || context.authUserRole['User Pemeliharaan']) {
						//TANYAKAN JIKA OWNER ESTIMATE INGIN TETAP 0 ATAU TIDAK
						if (parseInt(context.input.shp_owner_estimate) === 0) {
							context.confirmDialog(context.tt('confirm_owner_estimate')).then((result) => {
								if (result.value) {
									context.save()
								}
								return false
							})
						} else {
							context.save()
						}
					}
				} else {
				// JIKA USERT TERSEBUT HANYA MEMILIKI ROLE CATALOGUER
					context.save()
				}
			},
		    save() {
		      	let context = this;
		      	let formData = new FormData();
				// formData.append("client_plant_level", 'SHP');

				// --------------------  RELIABILITY DOMAIN ---------------------------- //
				if(this.checkDomain == 'reliability' || (this.checkDomain.includes('reliability') && this.checkDomain.includes('cataloguer')) || this.mcrType == 'A2' || this.mcrType == 'B3') {
					// kalau Check Domain reliability atau Check Domain keduanya (reliability dan cataloguer) maka field domain reliability mandatory
					if (this.authUserRole['Staff Reliability'] || this.authUserRole['Staff Renhar'] || this.authUserRole['User Pemeliharaan']) {

						if (!context.input.shp_failure_mode.length || !context.input.shp_mce_strategy.mce_strategy.length || !context.input.shp_mce_strategy.mce_interval.length || 
						!context.input.shp_mce_strategy.mce_tindakan.length || context.input.shp_qty === '' || context.input.shp_uom === '' || context.input.shp_effect_spare_part_not_available === '' || 
						context.input.shp_action_to_handle === '' || context.input.shp_consequences_criticality === '' || context.input.shp_total_estimated_loss === '' || 
						(!['A1S','B2S'].includes(this.mcrType) && context.input.shp_demand_rate === '') 
						){
							context.$notify({
								message: this.tt("please_complete_data", {title: this.tt("mandatory_field_*")}),
								type: "danger",
								verticalAlign: "bottom",
								horizontalAlign: "left"
							});
							return false;
						}

						// JIKA FAILURE MODE MULTIPLE TAPI ADA SALAH SATUNYA YANG KOSONG
						if(context.input.shp_failure_mode.length > 0){
							for (let fMode = 0; fMode < context.input.shp_failure_mode.length; fMode++) {
								if (context.input.shp_failure_mode[fMode] == "") {
									context.$notify({
										message: context.tt("please_complete_data", {title: context.tt("failure_mode")}),
										type: "danger",
										verticalAlign: "bottom",
										horizontalAlign: "left"
									});
									return false;
								}
								
							}
						}

						// CEK JIKA SALAH SATU MCE STRATEGYNYA ADA YANG KOSONG ATAU MCE STRATEGYNYA CBM DAN MCE NET PF KOSONG, MAKA ERROR 
						if (context.input.shp_mce_strategy.mce_strategy.length > 0) {
							for (var i = 0; i < context.input.shp_mce_strategy.mce_strategy.length; i++) {
								if (context.input.shp_mce_strategy.mce_strategy[i] == "" || context.input.shp_mce_strategy.mce_interval[i] == "" || context.input.shp_mce_strategy.mce_tindakan[i] == "") {
									context.$notify({
										message: context.tt("please_complete_data", {title: context.tt("mce_strategy_interval")}),
										type: "danger",
										verticalAlign: "bottom",
										horizontalAlign: "left"
									});
									return false;
								}

								if (context.input.shp_mce_strategy.mce_strategy[i] === "CBM" && context.input.shp_mce_strategy.mce_net_pf[i] === "" ) {
									context.$notify({
										message: this.tt("please_complete_data", {title: this.tt("cbm_and_net_pf")}),
										type: "danger",
										verticalAlign: "bottom",
										horizontalAlign: "left"
									});
									return false;
								}
							}
						}
						
						//  FORM DATA MANUFACTURER
						// --------------------  FORM DATA MPN ----------------------------
						if(context.mcrType == 'A2' || context.mcrType == 'B3'){
							if (!context.manufacturePartNumber.manufacture_code.length) { 
								context.$notify({
									message: this.tt("please_complete_data", {title: this.tt("mpn")}),
									type: "danger",
									verticalAlign: "bottom",
									horizontalAlign: "left"
								});
								return false;
							}
						}
					}
					// STATUS PROCESS
					if ((this.mcrType == 'A1S' && this.authUserRole['Staff Reliability']) || (this.mcrType == 'A2' && this.authUserRole['Staff Reliability'])) {
						// formData.append('status_process', 'shp_rel_process')
					} else if((this.mcrType == 'A2' && this.authUserRole['User Pemeliharaan']) || (this.mcrType == 'A2' && this.authUserRole['Staff Renhar'])) {
						// formData.append('status_process', 'user_process')
					}
				}

				// --------------------  CATALOGUER DOMAIN ---------------------------- //
				if (this.checkDomain == 'cataloguer' || (this.checkDomain.includes('reliability') && this.checkDomain.includes('cataloguer')) || this.mcrType == 'A2' || this.mcrType == 'B3') {

					if (this.authUserRole['Cataloguer']) {
						// KALAU ROLE CATALOGUER ATAU CHECK DOMAIN KEDUANYA (RELIABILITY DAN CATALOGUER) MAKA FIELD DOMAIN CATALOGUER MANDATORY
						if (context.input.shp_quantity_on_hand === '' || context.input.shp_estimated_lead_time === '' || 
						context.input.shp_category_lead_time === '' || context.input.shp_abc_indicator === '' || context.input.shp_stock_strategy === '' || 
						context.input.shp_stock_level === '' || context.input.shp_min === '' || !context.input.shp_max === '' || 
						context.input.shp_resume_justification_stock_strategy === '' || (context.input.shp_stock_strategy !== 'NON STOCK' && context.input.shp_stock_level_type_calculation === '')
						) {
							// if (context.input.shp_stock_strategy !== 'NON STOCK' && !context.input.shp_stock_level_type_calculation) {
								context.$notify({
									message: this.tt("please_complete_data", {title: this.tt("mandatory_field_*")}),
									type: "danger",
									verticalAlign: "bottom",
									horizontalAlign: "left"
								});
								return false;	
							// }
						}

						// // STATUS PROCESS
						// formData.append('status_process', 'shp_cat_process')
					}					
				}

				// -------------------- SHP Form A2 || B3  ---------------------------- //
				if(this.mcrType == 'A2' || this.mcrType == 'B3'){
					if(!this.input.shp_equipment && !this.input.material_number){
						// formData.append('shp_equipment', this.input.shp_equipment)
						// formData.append('material_number', this.input.material_number)
					}else{
						context.$notify({
							message: this.tt("please_complete_data", {title: this.tt("equipment_&_material_number")}),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});
					}
				}else{
					// formData.append('shp_equipment', this.input.shp_equipment)
				}

				if(context.input.shp_stock_strategy == "STOCK (V1)" && context.checkDomain == 'cataloguer' || (context.checkDomain.includes('reliability') && context.checkDomain.includes('cataloguer'))){
					if((context.input.shp_min == '' || context.input.shp_min == null) || (context.input.shp_max == '' || context.input.shp_max == null)){
						context.$notify({
							message: this.tt("please_complete_data", {title: 'Min & Max'}),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
						});
						return;
					}
				}

				// formData.append("shp_effect_spare_part_not_available", context.input.shp_effect_spare_part_not_available);
				// formData.append("shp_action_to_handle", context.input.shp_action_to_handle);
				// formData.append("shp_lead_time_action_to_handle", context.input.shp_lead_time_action_to_handle);
				// formData.append("shp_consequences_criticality", context.input.shp_consequences_criticality);
				// formData.append("shp_estimated_loss_idr", context.input.shp_estimated_loss_idr);
				// formData.append("shp_estimated_loss_day", context.input.shp_estimated_loss_day);
				// formData.append("shp_total_estimated_loss", context.input.shp_total_estimated_loss);
				// formData.append("shp_estimated_lead_time", context.input.shp_estimated_lead_time);
				// formData.append("shp_category_lead_time", context.input.shp_category_lead_time);
				// formData.append("shp_abc_indicator", context.input.shp_abc_indicator);
				// formData.append("shp_unit_price", context.input.shp_unit_price);
				// formData.append("shp_quantity_on_hand", context.input.shp_quantity_on_hand);
				// formData.append("shp_value_unrestricted", context.input.shp_value_unrestricted);
				// formData.append("shp_original_stock_strategy", context.input.shp_original_stock_strategy);
				// formData.append("shp_stock_strategy", context.input.shp_stock_strategy);
				// formData.append("shp_change_mrp_type", context.input.shp_change_mrp_type);
				// formData.append("shp_resume_justification_stock_strategy", context.input.shp_resume_justification_stock_strategy);
				// formData.append("shp_original_stock_level_type_calculation", context.input.shp_original_stock_level_type_calculation);
				// formData.append("shp_stock_level_type_calculation", context.input.shp_stock_level_type_calculation);
				// formData.append("shp_original_service_level", context.input.shp_original_service_level);
				// formData.append("shp_service_level", context.input.shp_service_level);
				// formData.append("shp_original_stock_level", context.input.shp_original_stock_level);
				// formData.append("shp_stock_level", context.input.shp_stock_level);
				// formData.append("shp_demand_rate", context.input.shp_demand_rate);
				// formData.append("shp_demand_rate_unit", context.input.shp_demand_rate_unit);
				// formData.append("shp_qty", context.input.shp_qty);
				// formData.append("shp_uom", context.input.shp_uom);
				// formData.append("shp_original_min", context.input.shp_original_min);
				// formData.append("shp_min", context.input.shp_min);
				// formData.append("shp_original_max", context.input.shp_original_max);
				// formData.append("shp_max", context.input.shp_max);
				// formData.append("shp_date_during_analysis", context.input.shp_date_during_analysis);
				// formData.append("shp_owner_estimate", parseInt(context.input.shp_owner_estimate));

				// if (context.equipmentQuantityInstall.equipment_code.length) {
			    //     for (var i = 0; i < context.equipmentQuantityInstall.equipment_code.length; i++) {
			    //       	formData.append('prc_plant_equipment['+i+']', context.equipmentQuantityInstall.plant_code[i])
			    //         formData.append("prc_equipment_code[" + i + "]",context.equipmentQuantityInstall.equipment_code[i]);
			    //         formData.append("prc_qty_installed[" + i + "]",context.equipmentQuantityInstall.qty_installed[i]);
			    //         formData.append("prc_drawing_number[" + i + "]",context.equipmentQuantityInstall.drawing_number[i]);
				// 		formData.append('prc_equipment_status['+i+']', context.equipmentQuantityInstall.status[i])
				// 		formData.append('prc_equipment_show['+i+']', context.equipmentQuantityInstall.show[i])
				// 		formData.append('prc_equipment_attachment['+i+']', context.equipmentQuantityInstall.attachment[i])
			    //     }
			    // }

				// if(context.partShortDesc != ''){
				// 	formData.append("prc_short_desc", context.partShortDesc);
				// }

				// if(context.prcInc != ''){
				// 	formData.append("prc_inc", context.prcInc);
				// }

				if(context.manufacturePartNumber.manufacture_code.length){
					for (var i = 0; i < context.manufacturePartNumber.manufacture_code.length; i++) {
						if (
							context.manufacturePartNumber.manufacture_code[i] != "" &&
							context.manufacturePartNumber.mpn[i] != "" &&
							context.manufacturePartNumber.source_type[i] != "" &&
							context.manufacturePartNumber.manufacture_type[i] != ""
						) {
							if (context.manufacturePartNumber.source_type[i] == "DWG" && context.manufacturePartNumber.position[i] == "") {
								this.$notify({
									message: this.tt("please_complete_data", { title: this.tt("mpn") }),
									type: "danger",
									verticalAlign: "bottom",
									horizontalAlign: "left"
								});

								return false;
							}

							// formData.append("manufacture_code[" + i + "]", context.manufacturePartNumber.manufacture_code[i]);
							// formData.append("mpn[" + i + "]", context.manufacturePartNumber.mpn[i]);
							// formData.append("source_type[" + i + "]", context.manufacturePartNumber.source_type[i]);
							// formData.append("position[" + i + "]", context.manufacturePartNumber.position[i]);
							// formData.append("manufacture_type[" + i + "]", context.manufacturePartNumber.manufacture_type[i]);
							// formData.append("manufacture_note[" + i + "]", context.manufacturePartNumber.manufacture_note[i]);

							// formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumber.manufacture_code[i])
							// formData.append('prc_mpn['+i+']', context.manufacturePartNumber.mpn[i])
							// formData.append('prc_source_type['+i+']', context.manufacturePartNumber.source_type[i])
							// formData.append('prc_mpn_position['+i+']', context.manufacturePartNumber.position[i])
							// formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumber.manufacture_type[i])
							// formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumber.manufacture_note[i])
							// formData.append('prc_manufacture_status['+i+']', 'new')
							// formData.append('prc_manufacture_shortdes['+i+']', false)
						} else {
							context.$notify({
							message: this.tt("please_complete_data", {title: this.tt("mpn")}),
							type: "danger",
							verticalAlign: "bottom",
							horizontalAlign: "left"
							});
							return false;
						}
					}
				}

				// CHANGE LOG
            	var logCount 		= 0;
            	for(const key in context.oldValue) {
            		var paramReplaced = key.replace(/_/g, ' '); 
            		// formData.append('change_log['+logCount+++']', `${paramReplaced} changed from "${context.oldValue[key]}" to "${context.newValue[key]}"`)
					
					// if (!context.recordChangeLog[logCount]) {
					// 	context.recordChangeLog[logCount] = [];
					// }

					context.recordChangeLog[logCount] = []
            		context.recordChangeLog[logCount].push(`${paramReplaced} changed from "${context.oldValue[key]}" to "${context.newValue[key]}"`)
					logCount++
					
            	}

				context.containedShpValues = { 
					input: context.input, 
					equipments: context.equipments,
					materialNumbers: context.materialNumbers,
					manufacturePartNumber: context.manufacturePartNumber,
					changeLog: context.recordChangeLog,
				}

				context.equipmentQuantityInstall.plant_code = [];
				context.equipmentQuantityInstall.equipment_code = [];
				context.equipmentQuantityInstall.qty_installed = [];
				context.equipmentQuantityInstall.drawing_number = [];
				context.equipmentQuantityInstall.del_flag = [];
				context.equipmentQuantityInstall.status = [];
				context.equipmentQuantityInstall.show = [];
				context.equipmentQuantityInstall.attachment = [];
				context.partShortDesc = ''
				context.prcInc = ''

				context.$emit('containedValuesShp', context.containedShpValues, context.input.shp_equipment)
				context.$emit('closeShp')
				context.$emit('saveShp','shp')
					
				context.$nextTick(() => {
					context.$notify({
						message: 'Your SHP Form is success',
						type: "success",
						verticalAlign: 'bottom', 
						horizontalAlign: 'left'
					});
				});

		    },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
			changeMaterialNumber(event){
				this.requestCalls++;
				let context = this;
				context.getSourceType()
				context.getManrefType()
				Api(context, endUserSearchMaterial.getMaterialShp({material_number: event, equipment_code: this.input.shp_equipment})).onSuccess(function(response) {

					context.input.shp_effect_spare_part_not_available  = ""
					context.input.shp_action_to_handle  = ""
					context.input.shp_lead_time_action_to_handle  = ""
					context.input.shp_consequences_criticality  = ""
					context.input.shp_estimated_loss_idr  = ""
					context.input.shp_estimated_loss_day  = ""
					context.input.shp_total_estimated_loss  = ""
					context.input.shp_estimated_lead_time  = ""
					context.input.shp_category_lead_time  = ""
					context.input.shp_abc_indicator  = ""
					context.input.shp_unit_price  = ""
					context.input.shp_quantity_on_hand  = '0'
					context.input.shp_value_unrestricted  = ""
					context.input.shp_original_stock_strategy  = ""
					context.input.shp_stock_strategy  = ""
					context.input.shp_change_mrp_type  = ""
					context.input.shp_resume_justification_stock_strategy  = ""
					context.input.shp_demand_rate  = ""
					context.input.shp_original_stock_level_type_calculation  = ""
					context.input.shp_stock_level_type_calculation  = ""
					context.input.shp_original_service_level  = ""
					context.input.shp_service_level  = ""
					context.input.shp_original_stock_level  = ""
					context.input.shp_stock_level  = ""

					if(!!response.data.data.shp){
						context.input.shp_effect_spare_part_not_available  = response.data.data.shp.shp_effect_spare_part_not_available != null ? response.data.data.shp.shp_effect_spare_part_not_available : ''
						context.input.shp_action_to_handle  = response.data.data.shp.shp_action_to_handle != null ? response.data.data.shp.shp_action_to_handle : ''
						context.input.shp_lead_time_action_to_handle  = response.data.data.shp.shp_lead_time_action_to_handle != null ? response.data.data.shp.shp_lead_time_action_to_handle : ''
						context.input.shp_consequences_criticality  = response.data.data.shp.shp_consequences_criticality != null ? response.data.data.shp.shp_consequences_criticality : ''
						context.input.shp_estimated_loss_idr  = response.data.data.shp.shp_estimated_loss_idr != null ? response.data.data.shp.shp_estimated_loss_idr : ''
						context.input.shp_estimated_loss_day  = response.data.data.shp.shp_estimated_loss_day != null ? response.data.data.shp.shp_estimated_loss_day : ''
						context.input.shp_total_estimated_loss  = response.data.data.shp.shp_total_estimated_loss != null ? response.data.data.shp.shp_total_estimated_loss : ''
						context.input.shp_estimated_lead_time  = response.data.data.shp.shp_estimated_lead_time != null ? response.data.data.shp.shp_estimated_lead_time : ''
						context.input.shp_category_lead_time  = response.data.data.shp.shp_category_lead_time != null ? response.data.data.shp.shp_category_lead_time : ''
						context.input.shp_abc_indicator  = response.data.data.shp.shp_abc_indicator != null ? response.data.data.shp.shp_abc_indicator : ''
						context.input.shp_unit_price  = response.data.data.shp.shp_unit_price != null ? response.data.data.shp.shp_unit_price : ''
						context.input.shp_quantity_on_hand  = response.data.data.shp.shp_quantity_on_hand != null ? response.data.data.shp.shp_quantity_on_hand : '0'
						context.input.shp_value_unrestricted  = response.data.data.shp.shp_value_unrestricted != null ? response.data.data.shp.shp_value_unrestricted : ''
						context.input.shp_original_stock_strategy  = response.data.data.shp.shp_stock_strategy != null ? response.data.data.shp.shp_stock_strategy : ''
						context.input.shp_stock_strategy  = ''
						context.input.shp_change_mrp_type  = response.data.data.shp.shp_change_mrp_type != null ? response.data.data.shp.shp_change_mrp_type : ''
						context.input.shp_resume_justification_stock_strategy  = response.data.data.shp.shp_resume_justification_stock_strategy != null ? response.data.data.shp.shp_resume_justification_stock_strategy : ''
						context.input.shp_demand_rate  = response.data.data.shp.shp_demand_rate != null ? response.data.data.shp.shp_demand_rate : ''
						context.input.shp_original_stock_level_type_calculation  = response.data.data.shp.shp_stock_level_type_calculation != null ? response.data.data.shp.shp_stock_level_type_calculation : ''
						context.input.shp_stock_level_type_calculation  = ''
						context.input.shp_original_service_level  = response.data.data.shp.shp_service_level != null ? response.data.data.shp.shp_service_level : ''
						context.input.shp_service_level  = ''
						context.input.shp_original_stock_level  = response.data.data.shp.shp_stock_level != null ? response.data.data.shp.shp_stock_level : ''
						context.input.shp_stock_level  = ''
						context.input.shp_original_min  = response.data.data.shp.shp_min != null ? response.data.data.shp.shp_min : ''
						context.input.shp_min  = ''
						context.input.shp_original_max  = response.data.data.shp.shp_max != null ? response.data.data.shp.shp_max : ''
						context.input.shp_max  = ''
						context.input.shp_date_during_analysis  = response.data.data.shp.shp_date_during_analysis != null ? response.data.data.shp.shp_date_during_analysis : ''
						context.input.shp_owner_estimate  = response.data.data.shp.shp_owner_estimate != null ? parseInt(response.data.data.shp.shp_owner_estimate) : 0
					}

					context.input.shp_mce_strategy.mce_strategy = []
					context.input.shp_mce_strategy.mce_interval = []
					context.input.shp_mce_strategy.mce_net_pf = []
					context.input.shp_mce_strategy.mce_tindakan = []
					context.input.shp_failure_mode  = []

					if(!!response.data.data.shp && response.data.data.shp.shp_mce_strategy != '' ){
						let mceStrategies = JSON.parse(response.data.data.shp.shp_mce_strategy);
						let mceIntervals = JSON.parse(response.data.data.shp.shp_interval);
						let mceNetPf = JSON.parse(response.data.data.shp.shp_net_pf);
						let mceTindakan = JSON.parse(response.data.data.shp.shp_tindakan);
						for(var i = 0; i < mceStrategies.length;i++){
							context.input.shp_mce_strategy.mce_strategy.push(mceStrategies[i])
							context.input.shp_mce_strategy.mce_interval.push(mceIntervals[i])
							context.input.shp_mce_strategy.mce_net_pf.push(mceNetPf[i])
							context.input.shp_mce_strategy.mce_tindakan.push(mceTindakan[i])
						}
					}

					if(!!response.data.data.shp && response.data.data.shp.shp_failure_mode != ''){
						let failureModes = JSON.parse(response.data.data.shp.shp_failure_mode);

						for(var i = 0; i < failureModes.length;i++){
							context.input.shp_failure_mode.push(failureModes[i])
						}
					}

					context.manufacturePartNumber.manufacture_code = [];
			        context.manufacturePartNumber.manufacture_code_view = [];
			        context.manufacturePartNumber.mpn = [];
			        context.manufacturePartNumber.source_type = [];
			        context.manufacturePartNumber.position = [];
			        context.manufacturePartNumber.manufacture_type = [];
			        context.manufacturePartNumber.manufacture_note = [];

					for (var i = 0; i < response.data.data.manufacture.length; i++) {
						context.manufacture[i] = response.data.data.manufacture[i];
						context.manufacturePartNumber.manufacture_code[i] = response.data.data.manufacture[i].manufacture_code;
						context.manufacturePartNumber.manufacture_code_view[i] = response.data.data.manufacture[i].manufacture_code +' - '+ response.data.data.manufacture[i].manufacture_name;
						context.manufacturePartNumber.mpn[i] = response.data.data.manufacture[i].manufacture_ref;
						context.manufacturePartNumber.source_type[i] = response.data.data.manufacture[i].source_type_code;
						context.manufacturePartNumber.position[i] = response.data.data.manufacture[i].position;
						context.manufacturePartNumber.manufacture_type[i] = response.data.data.manufacture[i].type;
						context.manufacturePartNumber.manufacture_note[i] = response.data.data.manufacture[i].note;
					}

					
					context.equipmentQuantityInstall.plant_code = [];
			        context.equipmentQuantityInstall.equipment_code = [];
			        context.equipmentQuantityInstall.qty_installed = [];
			        context.equipmentQuantityInstall.drawing_number = [];
			        context.equipmentQuantityInstall.del_flag = [];
			        context.equipmentQuantityInstall.status = [];
			        context.equipmentQuantityInstall.show = [];
			        context.equipmentQuantityInstall.attachment = [];

					for (var i = 0; i < response.data.data.equipment.length; i++) {
						context.equipmentQuantityInstall.plant_code[i] = response.data.data.equipment[i].plant_code;
						context.equipmentQuantityInstall.equipment_code[i] = response.data.data.equipment[i].equipment_code;
						context.equipmentQuantityInstall.qty_installed[i] = response.data.data.equipment[i].qty_installed;
						context.equipmentQuantityInstall.drawing_number[i] = response.data.data.equipment[i].drawing_number;
						context.equipmentQuantityInstall.del_flag[i] = response.data.data.equipment[i].del_flag;
						context.equipmentQuantityInstall.status[i] = response.data.data.equipment[i].status;
						context.equipmentQuantityInstall.show[i] = response.data.data.equipment[i].show;
						context.equipmentQuantityInstall.attachment[i] = response.data.data.equipment[i].attachment;
					}


					context.partShortDesc = '';
					context.partShortDesc =response.data.data.short_desc ? response.data.data.short_desc.short_description : '';

					context.prcInc = '';
					context.prcInc = response.data.data.inc ? response.data.data.inc : '';

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
						context.equipmentQuantityInstall.plant_code = [];
						context.equipmentQuantityInstall.equipment_code = [];
						context.equipmentQuantityInstall.qty_installed = [];
						context.equipmentQuantityInstall.drawing_number = [];
						context.equipmentQuantityInstall.del_flag = [];
						context.equipmentQuantityInstall.status = [];
						context.equipmentQuantityInstall.show = [];
						context.equipmentQuantityInstall.attachment = [];

						context.manufacturePartNumber.manufacture_code = [];
						context.manufacturePartNumber.manufacture_code_view = [];
						context.manufacturePartNumber.mpn = [];
						context.manufacturePartNumber.source_type = [];
						context.manufacturePartNumber.position = [];
						context.manufacturePartNumber.manufacture_type = [];
						context.manufacturePartNumber.manufacture_note = [];

						context.partShortDesc = '';
						context.prcInc = '';

						context.input.shp_failure_mode = [] 
						context.input.shp_mce_strategy.mce_strategy = []
						context.input.shp_mce_strategy.mce_interval  = []
						context.input.shp_mce_strategy.mce_net_pf  = []
						context.input.shp_mce_strategy.mce_tindakan  = []
						context.input.shp_effect_spare_part_not_available = ''
						context.input.shp_action_to_handle = ''
						context.input.shp_lead_time_action_to_handle = ''
						context.input.shp_consequences_criticality = ''
						context.input.shp_estimated_loss_idr = ''
						context.input.shp_estimated_loss_day = ''
						context.input.shp_total_estimated_loss = ''
						context.input.shp_estimated_lead_time = ''
						context.input.shp_category_lead_time = ''
						context.input.shp_abc_indicator = ''
						context.input.shp_unit_price = ''
						context.input.shp_quantity_on_hand = '0'
						context.input.shp_value_unrestricted = ''
						context.input.shp_original_stock_strategy = ''
						context.input.shp_stock_strategy = ''
						context.input.shp_change_mrp_type = ''
						context.input.shp_resume_justification_stock_strategy = ''
						context.input.shp_demand_rate = ''
                    }
                }).onFinish(function() {
                	context.requestCalls--;
                }).call()
			},
			changeLog(parameter) {
            	this.parameter = parameter;
            },
            checkChangeLog() {
            	this.getChangeLog();
            	this.modalChangeLog = true;
            },
			getChangeLog() {
            	let context = this;

            	Api(context, transactionLog.McrChangeLog(context.mcrItemCode)).onSuccess(function(response) {  
                    context.dataChangeLog = response.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.dataChangeLog = [];
                    }
                }).call()
            },
			getSourceType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, sourceType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.sourceType = response.data.data.data.data;
				  context.requestCalls--;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.sourceType = [];
				  }
				}).call();
		    },
			getManrefType() {
		    	this.requestCalls++;
				let context = this;

				Api(context, manrefType.get(null, { per_page: "none" }))
				.onSuccess(function(response) {
				  context.manrefType = response.data.data.data.data;
				  context.requestCalls--;
				})
				.onError(function(error) {
				  if (error.response.status == 404) {
				    context.manrefType = [];
				  }
				}).call();
		    },
			getMcrId(){
				let context = this;
				Api(context, draftList.shp_mcr_item({mcr_item_code:this.mcrItemCode,status:'new'})).onSuccess((response) => {
					context.input.id = response.data.data.mcr_item_id;
				}).call()
			},
			unitPriceAndQoHCalculation(){
				if (this.input.shp_unit_price !== '' && this.input.shp_quantity_on_hand !== '') {
					this.input.shp_value_unrestricted = this.input.shp_unit_price * this.input.shp_quantity_on_hand
				} else {
					this.input.shp_value_unrestricted = ''
				}
			},
			changeNetPfInput(key) {
				if (this.input.shp_mce_strategy.mce_strategy[key] !== 'CBM') {
					this.input.shp_mce_strategy.mce_net_pf[key] = '';
				}
			},
			querySearchUnitOfMeasure(query) {
                    clearTimeout(this.selectSearch.loadTimeout);             
                    let context = this;   

                    this.selectSearch.loading = true;
                    
                    this.selectSearch.loadTimeout = setTimeout(() => {
                        Api(context, unitOfMeasure.get(null, {per_page: 'none',search: query})).onSuccess(function(response) {
                            context.unitOfMeasure = response.data.data.data.data;    
                            context.selectSearch.loading = false;                                           
                        }).onError(function(error) {
                            context.unitOfMeasure = [];
                            context.selectSearch.loading = false;
                        }).call();             
                    }, 200)
            },
			handleEmptyStockLevelType() {
				this.input.shp_stock_strategy = this.input.shp_stock_strategy.toUpperCase()
				if (this.input.shp_stock_strategy === 'NON STOCK') {
					this.input.shp_stock_level_type_calculation = ''
				}
			}
		}
	};
</script>

<style>
	.height-43.el-input .el-input__inner{
		height:43px;
	}
	.el-tooltip__popper {
		max-width: 320px !important;
		word-break: break-word;
	}
</style>