<template>
    <div v-if="requestCalls > 0">
        <skeleton-loading></skeleton-loading>
    </div>
    <div v-else>
        <div class="row">
            <div class="col-md-12">
                <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
                <base-input :name="tt('material_number')" rules="required">
                    <el-select v-model="inputDraftListA2.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" @change="changeMaterialNumberA2" :disabled="form.a2.disabled">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                    </el-select>  
                </base-input>
            </div>
        </div>

        <div id="accordionClA2" v-if="level == 'Specification'">
            <div class="card z-depth-0 bordered">
                <div class="card-header bg-secondary border" id="headingOneClA2">
                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                <span @click="toggleAccordion(accordion.a2.cl.show, 'clA2')">{{tt('client_level')}}</span>
                            </div>
                        </div>
                    </h4>
                </div>
                <div id="collapseClA2" :class="'collapse '+accordion.a2.cl.show">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('item_type') }}</label><span class="text-danger">*</span></label>
                                <base-input :name="tt('item_type')" rules="required">
                                    <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.item_type_code" :placeholder="tt('choose_item_type')">
                                        <el-option class="select-danger" :value="it" :label="it" :key="it" v-for="it in  itemType"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('critical_non_critical') }}</label><span class="text-danger">*</span></label>
                                <base-input :name="tt('critical_non_critical')" rules="required">
                                    <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.critical_non_critical" :placeholder="tt('choose_critical_non_critical')">
                                        <el-option class="select-danger" :value="cnc" :label="cnc" :key="cnc" v-for="cnc in  criticalNonCritical"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('fabric_or_non_fabrication') }}</label><span class="text-danger">*</span></label>
                                <base-input :name="tt('fabric_or_non_fabrication')" rules="required">
                                    <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.fab_non_fab" :placeholder="tt('choose_fabric_or_non_fabrication')">
                                        <el-option class="select-danger" :value="fnf" :label="fnf" :key="fnf" v-for="fnf in  fabNonFab"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                            <div class="col-md-6">
                                <label class="form-control-label">{{ tt('bom_or_non_bom') }}</label><span class="text-danger">*</span></label>
                                <base-input :name="tt('bom_or_non_bom')" rules="required">
                                    <el-select :disabled="form.a2.disabled" class="select-danger" v-model="inputDraftListA2.bom_non_bom" :placeholder="tt('choose_bom_or_non_bom')">
                                        <el-option class="select-danger" :value="bnb" :label="bnb" :key="bnb" v-for="bnb in  bomNonBom"></el-option>
                                    </el-select>
                                </base-input>
                            </div>
                        </div>
                        <label class="form-control-label">{{ tt('item_name') }}</label><span class="text-danger">*</span></label>
                        <base-input :disabled="form.a2.disabled" :name="tt('item_name')" :placeholder="tt('item_name')" v-model="inputDraftListA2.item_name" @input="inputDraftListA2.item_name = inputDraftListA2.item_name.toUpperCase()" rules="required"></base-input>

                        <label class="form-control-label">{{ tt('specification') }}</label><span class="text-danger">*</span></label>
                        <base-input :name="tt('specification')" rules="required">
                            <textarea :disabled="form.a2.disabled" class="form-control" rows="10" v-model="inputDraftListA2.specification" @input="inputDraftListA2.specification = inputDraftListA2.specification.toUpperCase()"></textarea>
                        </base-input>
                    </div>
                </div>
            </div>
        </div>

        <!-- MPN -->

        <div id="accordionMpnA2" v-if="level == 'Specification'">
            <div class="card z-depth-0 bordered">
                <div class="card-header bg-secondary border" id="headingOneMpnA2">
                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                <h5>
                                    <a
                                        href="javascript:void(0)"
                                        @click="toggleAccordion(accordion.a2.mpn.show, 'mpnA2')"
                                        >{{ tt("manufacturer_part_number") }}
                                    </a>
                                </h5>
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addMpnA2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                </div>
                <div id="collapseMpnA2" :class="'collapse '+accordion.a2.mpn.show">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th min-width="500" class="text-white">{{tt('manufacturer_code')}} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{ tt("manufacture_ref") }} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{ tt("source_type") }} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{ tt("position") }}</th>
                                    <th class="text-white">{{tt('type')}} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{tt('note')}}</th>
                                    <th class="text-white">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(mpn, key) in manufacturePartNumberA2.manufacture_code" v-bind:key="mpn.manufacture_code">
                                    <td>
                                        <el-select v-model="manufacturePartNumberA2.manufacture_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getManufacture" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                            <el-option label="" value=""></el-option>
                                            <el-option v-for="mn in manufacture" :key="mn.manufacture_code" :label="mn.manufacture_code +' - '+ mn.manufacture_name" :value="mn.manufacture_code"></el-option>
                                        </el-select>
                                    </td>
                                    <td>
                                        <input :disabled="form.a2.disabled" class="form-control" :name="tt('mpn')" :placeholder="tt('mpn')" v-model="manufacturePartNumberA2.mpn[key]" @input="manufacturePartNumberA2.mpn[key] = manufacturePartNumberA2.mpn[key].toUpperCase()" rules="required">
                                    </td>
                                    <td>
                                        <select :disabled="form.a2.disabled" class="form-control" v-model="manufacturePartNumberA2.source_type[key]">
                                            <option value="">Choose Type</option>
                                            <option :value="st.code" v-for="st in sourceType" v-bind:key="st.id">{{ st.code +' - '+ st.description }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input :disabled="form.a2.disabled || manufacturePartNumberA2.source_type[key] != 'DWG'" class="form-control" :name="tt('position')" :placeholder="tt('position')" v-model="manufacturePartNumberA2.position[key]" style='width: 100px;'
                                        />
                                    </td>
                                    <td>
                                        <select :disabled="form.a2.disabled" class="form-control" v-model="manufacturePartNumberA2.manufacture_type[key]">
                                                <option value="">Choose Type</option>
                                            <option :value="mt.code" v-for="mt in manrefType" v-bind:key="mt.id">{{mt.description}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <input :disabled="form.a2.disabled" class="form-control" :name="tt('note')" :placeholder="tt('note')" v-model="manufacturePartNumberA2.manufacture_note[key]" @input="manufacturePartNumberA2.manufacture_note[key] = manufacturePartNumberA2.manufacture_note[key].toUpperCase()" rules="required">
                                    </td>
                                    <td>
                                        <i v-if="!form.a2.disabled" class="fa fa-trash text-danger" @click="removeMpnA2(key)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- EQUIPMENT & QUANTITY INSTALL -->

        <div id="accordionEqiA2">
            <div class="card z-depth-0 bordered">
                <div class="card-header bg-secondary border" id="headingOneEqiA2">
                    <h4 class="pt-2 pl-2 pb-2 pr-2 mb--1">
                        <div class="row">
                            <div class="col-md-6">
                                <h5>
                                    <a
                                        href="javascript:void(0)"
                                        @click="toggleAccordion(accordion.a2.eqi.show, 'eqiA2')"
                                        >{{ tt("equipment_&_quantity_install") }}
                                        <span class="text-danger" v-if= "this.inputDraftListA2.bom_non_bom == 'BOM' || this.inputDraftListA2.item_type_code == 'OEM'"> *</span>
                                    </a>
                                </h5>
                            </div>
                            <div class="col-md-6 text-right">
                                <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addEqiA2">{{ tt('add_new') }}</base-button>
                            </div>
                        </div>
                    </h4>
                </div>
                <div id="collapseEqiA2" :class="'collapse '+accordion.a2.eqi.show">
                    <div class="table-responsive">
                        <table class="table align-items-center table-flush table-bordered">
                            <thead class="card-header bg-primary">
                                <tr>
                                    <th class="text-white">{{tt('plant')}} <span class="text-danger">*</span></th>
                                    <th min-width="500" class="text-white">{{tt('equipment_code')}} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{tt('quantity_install')}} <span class="text-danger">*</span></th>
                                    <th class="text-white">{{tt('drawing_number')}} <span class="text-danger">*</span></th>
                                    <th width="200" class="text-white">{{ tt("attachment") }} </th>
                                    <th class="text-white">{{tt('del_flag')}}</th>
                                    <th class="text-white">#</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(eqi, key) in equipmentQuantityInstallA2.equipment_code" v-bind:key="eqi.equipment_code">
                                    <td>
                                        <el-select v-model="equipmentQuantityInstallA2.plant[key]" filterable remote reserve-keyword :placeholder="tt('choose_plant')" :loading="inputTo.loading" :disabled="form.a2.disabled">
                                            <el-option label="" value=""></el-option>
                                            <el-option v-for="pc in plantCode" :key="pc.plant_code" :label="pc.plant_code +' - '+ pc.description" :value="pc.plant_code"></el-option>
                                        </el-select>
                                    </td>
                                    <td>
                                        <el-select v-model="equipmentQuantityInstallA2.equipment_code[key]" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 4})" :remote-method="getEquipmentCode" :loading="inputTo.loading" :disabled="form.a2.disabled" @change="storeFocusedEquipment(equipmentQuantityInstallA2.equipment_code[key]), clearDrawing(key)">
                                            <el-option label="" value=""></el-option>
                                            <el-option v-for="ec in equipmentCode" :key="ec.equipment_code" :label="ec.equipment_code +' - '+ ec.equipment_name" :value="ec.equipment_code"></el-option>
                                        </el-select>
                                    </td>
                                    <td><input :disabled="form.a2.disabled" class="form-control" :name="tt('qty_install')" :placeholder="tt('qty_install')" v-model="equipmentQuantityInstallA2.qty_installed[key]" rules="required"></td>
                                    <td>
                                        <el-autocomplete 
                                            :disabled="form.a2.disabled" 
                                            class="form-control-sm col-md-12 mb-4" 
                                            v-model="equipmentQuantityInstallA2.drawing_number[key]" 
                                            :fetch-suggestions="querySearchDrawingMaster" 
                                            :placeholder="tt('drawing_number')" 
                                            :trigger-on-focus="false" 
                                            @select="selectDrawingNumber" 
                                            size="big" 
                                            @input="equipmentQuantityInstallA2.drawing_number[key] = equipmentQuantityInstallA2.drawing_number[key].toUpperCase()"
                                            @focus="selectedDrawingMasterKey(key)"
                                            @change="dmAttachment(key)">
                                        </el-autocomplete>
                                    </td>
                                    <td>
                                        <span style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'drawing_master/'+dm_attachment[key])">
                                            {{dm_attachment[key]}}
                                        </span>
                                    </td>
                                    <td class="text-center">
                                        <el-checkbox v-model="equipmentQuantityInstallA2.del_flag[key]"></el-checkbox>
                                    </td>
                                    <td>
                                        <i v-if="!form.a2.disabled" class="fa fa-trash text-danger" @click="removeEqiA2(key)"></i>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <!-- ATTACHMENT -->

        <div id="accordionAd" v-if="level == 'Specification'">
            <div class="card z-depth-0 bordered">
              <div class="card-header bg-secondary border" id="headingOneAd">
                <div class="row">
                    <div class="col-md-6">
                        <h5>
                            <a
                                href="javascript:void(0)"
                                @click="toggleAccordion(accordion.a2.ad.show, 'ad')"
                                >{{ tt("attachment_document") }}
                                <span class="text-danger">*</span>
                            </a>
                        </h5>
                    </div>
                    <div class="col-md-6 text-right">
                        <base-button v-if="!form.a2.disabled" size="sm" type="default" @click="addAd">{{ tt("add_new") }}</base-button>
                    </div>
                </div>
              </div>
              <div id="collapseAd" :class="'collapse ' + accordion.a2.ad.show">
                <div class="table-responsive">
                    <table class="table align-items-center table-flush table-bordered">
                      <thead class="card-header bg-primary">
                        <tr>
                          <th class="text-white">{{ tt("file_name") }} <span class="text-danger">*</span></th>
                          <th class="text-white">{{ tt("description") }} <span class="text-danger">*</span></th>
                          <th class="text-white">#</th>
                        </tr>
                      </thead>
                      <tbody>

                        <!-- PREVIEW KETIKA EDIT -->
                        <tr v-for="(att, index) in prevAttachmentDocument" v-bind:key="index">
                            <td>
                                <span v-if="att.file_attachment_name.substring(0, 4) == 'http'" style="color: #5e72e4; cursor: pointer" @click="previewAttachment(att.file_attachment_name)">
                                    {{att.file_attachment_name}}
                                </span>
                                <span v-else style="color: #5e72e4; cursor: pointer" @click="previewAttachment(apiUrl+'smartcat_attachment/'+att.file_attachment_name)">
                                    {{att.file_attachment_name}}
                                </span>
                            </td>
                            <td>
                                <span>{{att.description_attachment}}</span>
                            </td>
                            <td>
                                <span v-if="att.file_attachment_name.substring(0, 4) == 'http' && attachmentChange == true">
                                    <a href="javascript:void(0)" v-if="!form.a2.disabled" @click="removePrevAdOnChange(index)"><i class="fa fa-trash text-danger"></i></a> 
                                </span>
                                <span v-else>
                                    <a href="javascript:void(0)" v-if="!form.a2.disabled" @click="removePrevAd(att.id)"><i class="fa fa-trash text-danger"></i></a>
                                </span>
                            </td>
                        </tr>

                        <!-- INPUTAN ATTACHMENT -->
                        <tr v-for="(ad, key) in attachmentDocument.file_attachment_name" v-bind:key="ad.file_name">
                          <td>
                            <file-input
                              :disabled="form.disabled"
                              @change="changeFileAttachmentName"
                              :initialLabel="(ad.file_attachment_name) ? ad.file_attachment_name : maxValidation.text"
                              :ref="'file' + key"
                              accept="application/pdf"
                             :max-file="maxValidation.size"
                            ></file-input>
                          </td>
                          <td>
                            <input :disabled="form.disabled" class="form-control" :name="tt('description')" :placeholder="tt('description')" v-model="attachmentDocument.description_attachment[key]" @input="attachmentDocument.description_attachment[key] = attachmentDocument.description_attachment[key].toUpperCase()" rules="required">
                          </td>
                          <td>
                            <a href="javascript:void(0)" v-if="!form.a2.disabled" @click="removeAd(key)"><i class="fa fa-trash text-danger"></i></a>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                </div>
              </div>
            </div>
            </div>
            
            <label class="form-control-label">{{ tt('reason') }} <span class="text-danger">*</span></label>
            <base-input :disabled="form.a2.disabled" :name="tt('reason')" :placeholder="tt('reason')" v-model="inputDraftListA2.reason" @input="inputDraftListA2.reason = inputDraftListA2.reason.toUpperCase()" rules="required"></base-input>

            <label class="form-control-label">{{ tt('note') }}</label>
            <base-input :name="tt('note')">
                <textarea :disabled="form.a2.disabled" class="form-control" rows="5" v-model="inputDraftListA2.note" @input="inputDraftListA2.note = inputDraftListA2.note.toUpperCase()"></textarea>
            </base-input>

            <div class="text-right" v-if="!form.a2.disabled">
                <base-button
                    type="primary"
                    @click="save()"
                    :disabled="form.btnSave.onLoading">
                    <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
                    <span v-else>{{ tt("save") }}</span>
                </base-button>
            </div>

            <!-- PREVIEW ATTACHMENT -->
            <preview-attachment :previewAttachmentModalProp='previewAttachmentModal' :attachmentFileProp='attachmentFile' :attachmentTypeProp='attachmentFileType' @changeModal="changeModalPrev"/>
        </div>
</template>
<script>
    import {mapState} from 'vuex'
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import materialNumber from '@/services/master/materialNumber.service';
    import manufacture from '@/services/master/manufacture.service';
    import manrefType from '@/services/master/manrefType.service';
    import equipmentCode from '@/services/master/equipmentCode.service';
    import abcIndicator from '@/services/master/abcIndicator.service';
    import mrpTypes from '@/services/master/mrpTypes.service';
    import mrpController from '@/services/master/mrpController.service';
    import drawingMaster from '@/services/dictionary/drawingMaster.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import sourceType from "@/services/master/sourceType.service";
    import baseApiUrl from '@/configs/config';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';

    export default {       
        props: ['test'], 
        data() {
            return {  
                requestCalls: 0,
                form: {
                    a2: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
                        onLoading: false
                    }
                },
                focusedEquipment: '',
                inputDraftListA2: {
                    material_number: '',
                    client_plant_level: '',
                    item_type_code: '',
                    critical_non_critical: '',
                    bom_non_bom: '',
                    fab_non_fab: '',
                    item_name: '',
                    specification: '',
                    plant_code: '',
                    abc_indicator_code: '',
                    mrp_type_code: '',
                    mrp_type_code_view: '',
                    mrp_controller_code: '',
                    mrp_controller_code_view: '',
                    min_stock: '',
                    max_stock: '',
                    old_min_stock: '',
                    old_max_stock: '',
                    reason: '',
                    note: ''
                },
                materialNumber: [],
                manufacture: [],
                manrefType: {},
                sourceType: {},
                equipmentCode: [],
                abcIndicator: {},
                mrpTypes: [],
                mrpController: [],
                drawingMaster: [],
                itemType: {},
                fabNonFab: {},
                bomNonBom: {},
                criticalNonCritical: {},
                plantLevel: [],
                plantCode: [],
                equipmentCode: {},
                manufacture: {},
                mrpTypes: {},
                mrpController: {},
                accordion: {
                    a2: {
                        cl: {
                            show: 'show'
                        },
                        pl: {
                            show: 'show'
                        },
                        mpn: {
                            show: 'show'
                        },
                        eqi: {
                            show: 'show'
                        },
                        ad: {
                            show: "show"
                        }
                    },  
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                manufacturePartNumberA2: {
                    manufacture_code: [],
                    manufacture_code_view: [],
                    mpn: [],
                    source_type: [],
                    position: [],
                    manufacture_type: [],
                    manufacture_note: []
                },
                equipmentQuantityInstallA2: {
                    plant: [],
                    plant_view: [],
                    equipment_code: [],
                    equipment_code_view: [],
                    qty_installed: [],
                    drawing_number: [],
                    del_flag: [],
                },
                specificationChar: {
                    characteristic: [],
                    characteristic_value: []
                },
                attachmentDocument: {
                    file_attachment_name: [],
                    description_attachment: []
                },
                prevAttachmentDocument: {},
                attachmentChange: false,
                apiUrl: baseApiUrl.storageUrl,
                dm_attachment: [],
                previewAttachmentModal: false,
                attachmentFile: '',
                attachmentFileType: '',
                drawingMasterKey: '',

                mcr_code: this.$route.params.mcr_code,
                mcrItemCode: this.$route.params.mcr_item_code,
                formType: this.$route.params.type,
                maxValidation:{
                    size:null,
                    text:null
                },
                level: this.test
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.maximumValidation()
            this.getDetailMcrItem();
            this.getParameterMcr();
            this.getSourceType();
            this.getManrefType();
            this.checkFormType()
        },
        methods: {
            maximumValidation(){
                this.maximumSizeAlert().then((res) =>{
                    this.maxValidation.size = res.size
                    this.maxValidation.text = res.text
                })
            },
            checkFormType() {
                    if(this.formType == 'edit'){
                        this.form.a2.disabled = false;
                    } else if(this.formType == 'detail'){
                        this.form.a2.disabled = true;
                    } else if(this.formType == 'approval' && this.authUserPermission['mcr-approval-user-form-edit']){
                        this.form.a2.disabled = false;
                    } else if(this.formType == 'sapSyncTaskEdit'){
                        this.form.a2.disabled = false;
                    } else {
                        this.form.a2.disabled = true;
                    }
            },
            getDetailMcrItem() {
                this.requestCalls++;
                let context = this; 
                              
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {    
                        context.inputDraftListA2 = response.data.data[0];
                        context.mrpTypes[0]      = response.data.data[0];
                        context.mrpController[0] = response.data.data[0];

                        if (response.data.data[0].material_number != null) {
                            context.materialNumber[0] = {
                                material_number: response.data.data[0].material_number,
                                short_description: response.data.data[0].short_description,
                            };
                        }
                        
                        if (response.data.data[0].plant_code != null) {
                            context.plantLevel[0] = {
                                plant_code: response.data.data[0].plant_code,
                                description: response.data.data[0].description,
                            };
                        }
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.inputDraftListA2 = [];
                        }
                    }).call()

                    // MPN
                    Api(context, draftList.getMcrItemMpn(this.mcrItemCode)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.manufacture[i]                              = response.data.data[i];
                            context.manufacturePartNumberA2.manufacture_code[i] = response.data.data[i].manufacture_code;
                            context.manufacturePartNumberA2.mpn[i]              = response.data.data[i].mpn;
                            context.manufacturePartNumberA2.source_type[i]      = response.data.data[i].source_type;
                            context.manufacturePartNumberA2.position[i]         = response.data.data[i].position;
                            context.manufacturePartNumberA2.manufacture_type[i] = response.data.data[i].manufacture_type;
                            context.manufacturePartNumberA2.manufacture_note[i] = response.data.data[i].manufacture_note;
                        }                        
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.manufacturePartNumberA2.manufacture_code = [];
                            context.manufacturePartNumberA2.mpn              = [];
                            context.manufacturePartNumberA2.source_type      = [];
                            context.manufacturePartNumberA2.position         = [];
                            context.manufacturePartNumberA2.manufacture_type = [];
                            context.manufacturePartNumberA2.manufacture_note = [];
                        }
                    }).call()

                    // EQUIPMENT & QUANITY INSTALL
                    Api(context, draftList.getMcrItemEquipment(this.mcrItemCode)).onSuccess(function(response) {
                        for (var i=0; i<response.data.data.length; i++) {
                            context.equipmentCode[i]                             = response.data.data[i];
                            context.equipmentQuantityInstallA2.plant[i]          = response.data.data[i].plant_code;
                            context.equipmentQuantityInstallA2.plant_view[i]     = response.data.data[i].plant_code +' - '+ response.data.data[i].description;
                            context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data[i].equipment_code;
                            context.equipmentQuantityInstallA2.qty_installed[i]  = response.data.data[i].qty_installed;
                            context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data[i].drawing_number;
                            context.drawingMaster[i] = {
                                drawing_number:response.data.data[i].drawing_number,
                                drawing_name:response.data.data[i].drawing_name,
                            };
                            // FOR DM ATTACHMENT
                            context.dmAttachment(i);
                            context.drawingMaster = []
                        }                        
                        //FOR PLANT Equipment & Quantity Install
                        let plantRow = { 
                            plant_code : response.data.data[0].plant_code,
                            description: response.data.data[0].description
                        };
                        context.plantCode = [plantRow]
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.equipmentQuantityInstallA2.plant = [];
                            context.equipmentQuantityInstallA2.equipment_code = [];
                            context.equipmentQuantityInstallA2.qty_installed = [];
                            context.equipmentQuantityInstallA2.drawing_number = [];
                        }
                    }).call()

                    // ATTACHMENT
                    Api(context, draftList.getMcrItemAttachment(this.mcrItemCode))
                    .onSuccess(function(response) {
                        context.prevAttachmentDocument = response.data.data;
                    })
                    .onError(function(error) {
                      if (error.response.status == 404) {
                        context.prevAttachmentDocument = [];
                      }
                    }).onFinish(function(){
                        context.requestCalls--;
                    }).call()
            },
            changeMaterialNumberA2() {         
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA2.material_number})).onSuccess(function(response) {
                    context.inputDraftListA2.item_type_code        = response.data.data.part_master[0].item_type_code;
                    context.inputDraftListA2.critical_non_critical = response.data.data.part_master[0].critical_non_critical;
                    context.inputDraftListA2.bom_non_bom           = response.data.data.part_master[0].bom_non_bom;
                    context.inputDraftListA2.fab_non_fab           = response.data.data.part_master[0].fab_non_fab;
                    context.inputDraftListA2.item_name             = response.data.data.part_master[0].item_name;

                    // CHARACTERISTIC VALUE FOR SPECIFICATION
                    for (var i=0; i<response.data.data.po_text.length; i++) {
                        context.specificationChar[i] = {
                            characteristic       : response.data.data.po_text[i].characteristic,
                            characteristic_value : response.data.data.po_text[i].characteristic_value,
                        }
                    }

                    // CONVERT CHARACTERISTIC VALUE TO STRING
                    let objToArray       = Object.values(context.specificationChar)
                    let arrayToString    = JSON.stringify(objToArray)
                    let remove           = arrayToString.replace(/{"characteristic":"|"characteristic_value":|null|[\[\]']/g, " ");
                    let removeReplace    = remove.replace(/",/g, " :");
                    let replaceNewLine   = removeReplace.split('},').map(item => item.trim()).join("\n");
                    let charValFix       = replaceNewLine.replace(/,|"/g, " ");
                    let specificationFix = "=== CLASSIFICATION ===" +"\n" + charValFix +"\n" + "=== PO TEXT MEMO ===" + "\n" + (response.data.data.part_master[0].po_text_memo) ? response.data.data.part_master[0].po_text_memo : '';
                    context.inputDraftListA2.specification = specificationFix;
                        

                    // MPN
                    for (var i=0; i<response.data.data.part_manufacture_ref.length; i++) {
                        context.manufacture[i]                              = response.data.data.part_manufacture_ref[i];
                        context.manufacturePartNumberA2.manufacture_code[i] = response.data.data.part_manufacture_ref[i].manufacture_code;
                        context.manufacturePartNumberA2.mpn[i]              = response.data.data.part_manufacture_ref[i].manufacture_ref;
                        context.manufacturePartNumberA2.source_type[i]      = response.data.data.part_manufacture_ref[i].source_type_code;
                        context.manufacturePartNumberA2.position[i]         = response.data.data.part_manufacture_ref[i].position;
                        context.manufacturePartNumberA2.manufacture_type[i] = response.data.data.part_manufacture_ref[i].type;
                        context.manufacturePartNumberA2.manufacture_note[i] = response.data.data.part_manufacture_ref[i].note;
                    }

                    // EQUIPMENT & QUANTITY INSTALL
                    for (var i=0; i<response.data.data.part_equipment_code.length; i++) {
                        context.equipmentCode[i]                             = response.data.data.part_equipment_code[i];
                        context.equipmentQuantityInstallA2.plant[i]          = response.data.data.part_equipment_code[i].plant_code;
                        context.equipmentQuantityInstallA2.plant_view[i]     = response.data.data.part_equipment_code[i].plant_code +' - '+ response.data.data.part_equipment_code[i].description;
                        context.equipmentQuantityInstallA2.equipment_code[i] = response.data.data.part_equipment_code[i].equipment_code;
                        context.equipmentQuantityInstallA2.qty_installed[i]  = response.data.data.part_equipment_code[i].qty_installed;
                        context.equipmentQuantityInstallA2.drawing_number[i] = response.data.data.part_equipment_code[i].drawing_number;
                        context.drawingMaster[i] = {
                            drawing_number:response.data.data.part_equipment_code[i].drawing_number,
                            drawing_name:response.data.data.part_equipment_code[i].drawing_name,
                        };

                        context.dmAttachment(i);
                    }

                    // ATTACHMENT
                    context.prevAttachmentDocument = []
                    context.attachmentChange = true;

                    if (context.formType == 'edit') {
                        context.removePrevAdChange();
                    }
                    
                    for (var i=0; i<response.data.data.part_attachment.length; i++) {
                        context.prevAttachmentDocument[i]   = {
                            file_attachment_name   : response.data.data.part_attachment[i].file_attachment_code,
                            description_attachment : response.data.data.part_attachment[i].attachment_description,
                        }
                    }

                    context.plantLevel = response.data.data.part_plant;

                    //FOR PLANT Equipment & Quantity Install
                    context.plantCode = response.data.data.plant_company_where_in;

                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manufacturePartNumberA2.manufacture_code = [];
                        context.manufacturePartNumberA2.mpn              = [];
                        context.manufacturePartNumberA2.source_type      = [];
                        context.manufacturePartNumberA2.position         = [];
                        context.manufacturePartNumberA2.manufacture_type = [];
                        context.manufacturePartNumberA2.manufacture_note = [];

                        context.equipmentQuantityInstallA2.plant          = [];
                        context.equipmentQuantityInstallA2.equipment_code = [];
                        context.equipmentQuantityInstallA2.qty_installed  = [];
                        context.equipmentQuantityInstallA2.drawing_number = [];
                    }
                })
                .call()
            },
            getMaterialPlant() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA2.material_number, plant_code: context.inputDraftListA2.plant_code})).onSuccess(function(response) {  
                        context.mrpTypes[0]                          = response.data.data.part_mrp[0];
                        context.mrpController[0]                     = response.data.data.part_mrp[0];
                        context.inputDraftListA2.abc_indicator_code  = response.data.data.part_mrp[0].abc_indicator_code;
                        context.inputDraftListA2.mrp_type_code       = response.data.data.part_mrp[0].mrp_type_code;
                        context.inputDraftListA2.mrp_controller_code = response.data.data.part_mrp[0].mrp_controller_code;
                        context.inputDraftListA2.old_max_stock       = response.data.data.part_mrp[0].max;
                        context.inputDraftListA2.old_min_stock       = response.data.data.part_mrp[0].min;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.plantCode = [];
                    }
                })
                .call()
            },
            getParameterMcr() {
                this.requestCalls++;
                let context = this;

                Api(context, draftList.getParameterMcr(this.mcrItemCode))
                .onSuccess(function(response) {
                  context.itemType            = response.data.data.item_type;
                  context.fabNonFab           = response.data.data.fab_non_fab;
                  context.bomNonBom           = response.data.data.bom_non_bom;
                  context.criticalNonCritical = response.data.data.critical_non_critical;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.input = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getMaterialNumber(query) {                        
                if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }           
            },
            getManufacture(query) {
                if (query.length > 1) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context, manufacture.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                            context.manufacture =  response.data.data.data.data;                                    
                            context.inputTo.loading = false;   
                        }).onError(function(error) {
                            if (error.response.status == 404) {
                                context.manufacture = [];
                                context.inputTo.loading = false;
                            }
                        }).call();             
                    }) 
                }
            },
            getManrefType() {
                this.requestCalls++;
                let context = this;  

                Api(context, manrefType.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.manrefType = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.manrefType = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getSourceType() {
                this.requestCalls++;
                let context = this;

                Api(context, sourceType.get(null, { per_page: "none" }))
                .onSuccess(function(response) {
                  context.sourceType = response.data.data.data.data;
                })
                .onError(function(error) {
                  if (error.response.status == 404) {
                    context.sourceType = [];
                  }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            getEquipmentCode(query) {
                if (query.length > 3) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context, equipmentCode.getByCompany(null, {search: query})).onSuccess(function(response) {
                            context.equipmentCode =  response.data.data;                                         
                            context.inputTo.loading = false;   
                        }).onError(function(error) {
                            if (error.response.status == 404) {
                                context.equipmentCode = [];
                                context.inputTo.loading = false;
                            }
                        }).call();             
                    }) 
                }
            },
            getAbcIndicator() {
                let context = this;               
                Api(context, abcIndicator.get(null, {per_page: 'none'})).onSuccess(function(response) {    
                    context.abcIndicator = response.data.data.data.data;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.abcIndicator = [];
                    }
                })
                .call()
            },
            getMrpTypes(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, mrpTypes.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.mrpTypes =  response.data.data.data.data;                                     
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.mrpTypes = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                }) 
            },
            getMrpController(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                this.inputTo.loadTimeout = setTimeout(() => {
                    this.inputTo.loading = true;
                    Api(context, mrpController.get(null, {per_page: 'none', search: query})).onSuccess(function(response) {
                        context.mrpController =  response.data.data.data.data;                                 
                        context.inputTo.loading = false;   
                    }).onError(function(error) {
                        if (error.response.status == 404) {
                            context.mrpController = [];
                            context.inputTo.loading = false;
                        }
                    }).call();             
                })
            },
            getDrawingMaster(query) {
                clearTimeout(this.inputTo.loadTimeout);             
                let context = this;   
                Api(context, drawingMaster.getByEquipment(null, {equipment_code: context.focusedEquipment})).onSuccess(function(response) {
                    response.data.data.forEach(function(item, index) {
                        context.drawingMaster[index] = {
                            'value': item['drawing_number'],
                        };
                    });                                
                }).onError(function(error) {
                    if (error.response.status == 404) {
                        context.drawingMaster = [];
                    }
                }).call();
            },
            dmAttachment(key) {
                let context = this;
                context.dm_attachment[key] = '';
                Api(context, drawingMaster.get(null, {per_page: 'none', search: context.equipmentQuantityInstallA2.drawing_number[key] })).onSuccess(function(response) {
                    context.dm_attachment[key] = response.data.data.data.data[0].file_name;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        // context.dm_attachment = [];
                    }
                }).onFinish(function(){
                    context.$forceUpdate()
                }).call()
            },
            storeFocusedEquipment(equipment) {
                this.focusedEquipment = equipment;
                this.getDrawingMaster()
            },
            handleTableAction(command) {
                this.formTracking.show = false;
                switch (command.action) {
                    case 'detail':
                            this.detail(command.data);
                        break;
                    case 'remove':
                            this.remove(command.data);
                        break;
                    default:
                        break;
                }
            },
            toggleAccordion(show, type) {
                if (type == 'clA2') {
                    if (show == '') {
                        this.accordion.a2.cl.show = 'show'
                    } else {
                        this.accordion.a2.cl.show = ''
                    }
                } else if (type == 'plA2') {
                    if (show == '') {
                        this.accordion.a2.pl.show = 'show'
                    } else {
                        this.accordion.a2.pl.show = ''
                    }
                } else if (type == 'mpnA2') {
                    if (show == '') {
                        this.accordion.a2.mpn.show = 'show'
                    } else {
                        this.accordion.a2.mpn.show = ''
                    }
                } else if (type == 'eqiA2') {
                    if (show == '') {
                        this.accordion.a2.eqi.show = 'show'
                    } else {
                        this.accordion.a2.eqi.show = ''
                    }
                } else if (type == "ad") {
                    if (show == "") {
                      this.accordion.ad.show = "show";
                    } else {
                      this.accordion.ad.show = "";
                    }
                }
            },
            save() {
                let context = this;    
                let formData = new FormData();
                context.form.btnSave.onLoading = true;

                if (!!context.inputDraftListA2.material_number &&
                    !!context.inputDraftListA2.reason &&
                    !!context.inputDraftListA2.item_type_code &&
                    !!context.inputDraftListA2.critical_non_critical &&
                    !!context.inputDraftListA2.fab_non_fab &&
                    !!context.inputDraftListA2.bom_non_bom &&
                    !!context.inputDraftListA2.item_name &&
                    !!context.inputDraftListA2.specification) {        
                   
                    formData.append("mcr_code", context.mcr_code);
                    formData.append("material_number", context.inputDraftListA2.material_number);
                    formData.append("client_plant_level", 'Specification');
                    formData.append("reason", context.inputDraftListA2.reason);
                    formData.append("note", (context.inputDraftListA2.note == null || context.inputDraftListA2.note.length == 0 ? '' : context.inputDraftListA2.note));
                    formData.append("item_type_code", context.inputDraftListA2.item_type_code);
                    formData.append("prc_item_type_code", context.inputDraftListA2.item_type_code);
                    formData.append("critical_non_critical", context.inputDraftListA2.critical_non_critical);
                    formData.append("prc_critical_non_critical", context.inputDraftListA2.critical_non_critical);
                    formData.append("fab_non_fab", context.inputDraftListA2.fab_non_fab);
                    formData.append("prc_fab_non_fab", context.inputDraftListA2.fab_non_fab);
                    formData.append("bom_non_bom", context.inputDraftListA2.bom_non_bom);
                    formData.append("prc_bom_non_bom", context.inputDraftListA2.bom_non_bom);
                    formData.append("item_name", context.inputDraftListA2.item_name);
                    formData.append("specification", context.inputDraftListA2.specification);
                    
                    // --------------------  FORM DATA MPN ----------------------------
                    if (context.manufacturePartNumberA2.manufacture_code.length) {
                        for (var i=0; i<context.manufacturePartNumberA2.manufacture_code.length; i++) {
                            if (context.manufacturePartNumberA2.manufacture_code[i] != '' &&
                                context.manufacturePartNumberA2.mpn[i] != '' &&
                                context.manufacturePartNumberA2.source_type[i] != '' &&
                                context.manufacturePartNumberA2.manufacture_type[i] != '') {

                                formData.append("manufacture_code[" + i + "]", context.manufacturePartNumberA2.manufacture_code[i]);
                                formData.append("mpn[" + i + "]", context.manufacturePartNumberA2.mpn[i]);
                                formData.append("source_type[" + i + "]", context.manufacturePartNumberA2.source_type[i]);
                                formData.append("position[" + i + "]", context.manufacturePartNumberA2.position[i]);
                                formData.append("manufacture_type[" + i + "]", context.manufacturePartNumberA2.manufacture_type[i]);
                                formData.append("manufacture_note[" + i + "]", context.manufacturePartNumberA2.manufacture_note[i]);

                                // insert for prc
                                formData.append('prc_manufacture_code['+i+']', context.manufacturePartNumberA2.manufacture_code[i])
                                formData.append('prc_mpn['+i+']', context.manufacturePartNumberA2.mpn[i])
                                formData.append('prc_source_type['+i+']', context.manufacturePartNumberA2.source_type[i])
                                formData.append('prc_mpn_position['+i+']', context.manufacturePartNumberA2.position[i])
                                formData.append('prc_manufacture_type['+i+']', context.manufacturePartNumberA2.manufacture_type[i])
                                formData.append('prc_manufacture_note['+i+']', context.manufacturePartNumberA2.manufacture_note[i])
                                formData.append('prc_manufacture_status['+i+']', 'new')
                                formData.append('prc_manufacture_shortdes['+i+']', false)
                            } else {
                                context.form.btnSave.onLoading = false;
                                context.$notify({
                                    message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                                    type: 'danger',
                                    verticalAlign: "bottom",
                                    horizontalAlign: "left"
                                });
                                return false;
                            }
                        }
                    } else {
                        formData.append("manufacture_code", '');
                        formData.append("mpn", '');
                        formData.append("source_type", '');
                        formData.append("position", '');
                        formData.append("manufacture_type", '');
                        formData.append("manufacture_note", '');
                    }
                    
                    // --------------------  FORM DATA EQUIPMENT & QUANTITY INSTALL ----------------------------
                    if (context.equipmentQuantityInstallA2.plant.length) {
                        for (var i=0; i<context.equipmentQuantityInstallA2.plant.length; i++) {
                            if (
                                context.equipmentQuantityInstallA2.plant[i] != '' &&
                                context.equipmentQuantityInstallA2.equipment_code[i] != '' &&
                                context.equipmentQuantityInstallA2.qty_installed[i] != '' &&
                                context.equipmentQuantityInstallA2.drawing_number[i] != '') {
                                
                                formData.append("plant_equipment_code[" + i + "]",context.equipmentQuantityInstallA2.plant[i]);
                                formData.append("equipment_code[" + i + "]",context.equipmentQuantityInstallA2.equipment_code[i]);
                                formData.append("qty_installed[" + i + "]",context.equipmentQuantityInstallA2.qty_installed[i]);
                                formData.append("drawing_number[" + i + "]",context.equipmentQuantityInstallA2.drawing_number[i]);
                                formData.append("equipment_del_flag[" + i + "]",(context.equipmentQuantityInstallA2.del_flag[i]) ? 'X' : '');

                                // insert for adopting to prc (cataloguer form)
                                formData.append('prc_plant_equipment['+i+']', context.equipmentQuantityInstallA2.plant[i])
                                formData.append("prc_equipment_code[" + i + "]", context.equipmentQuantityInstallA2.equipment_code[i]);
                                formData.append("prc_qty_installed[" + i + "]", context.equipmentQuantityInstallA2.qty_installed[i]);
                                // formData.append("prc_drawing_number[" + i + "]",context.equipmentQuantityInstallA2.drawing_number[i]);
                                formData.append('prc_equipment_del_flag['+i+']', (context.equipmentQuantityInstallA2.del_flag[i]) ? 'X' : '')
                                formData.append('prc_equipment_status['+i+']', 'new')
                                formData.append('prc_equipment_show['+i+']', true)
                            } else {
                                context.form.btnSave.onLoading = false;
                                context.$notify({
                                    message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                                    type: 'danger',
                                    verticalAlign: "bottom",
                                    horizontalAlign: "left"
                                });
                                return false;
                            }
                        }
                    } else {
                        if (context.inputDraftListA2.bom_non_bom == 'BOM' || context.inputDraftListA2.item_type_code == 'OEM') {
                            context.$notify({
                                message: this.tt("please_complete_data", { title: this.tt("equipment_&_quantity_install") }),                  
                                type: 'danger',
                                verticalAlign: "bottom",
                                horizontalAlign: "left"
                            });
                            context.form.btnSave.onLoading = false;
                            return false;
                        }else{
                            formData.append("plant_equipment_code", '');
                            formData.append("equipment_code", '');
                            formData.append("qty_installed", '');
                            formData.append("drawing_number", '');
                            formData.append("equipment_del_flag", '');
                        }
                    }
                    
                    // --------------------  FORM DATA ATTACHMENT ----------------------------
                    var prevAttachmentDocumentArray = Object.values(context.prevAttachmentDocument)
                    if (prevAttachmentDocumentArray.length || context.attachmentDocument.file_attachment_name.length) {
                        // att prev
                        if (prevAttachmentDocumentArray != "" && context.attachmentChange == true) {
                            for (var i = 0; i < prevAttachmentDocumentArray.length; i++) {
                                formData.append("file_attachment_name_prev[" + i + "]", prevAttachmentDocumentArray[i].file_attachment_name);
                                formData.append("description_attachment_prev[" + i + "]", (prevAttachmentDocumentArray[i].description_attachment == null) ? '' : prevAttachmentDocumentArray[i].description_attachment);
                            }
                        }

                        // att input
                        for (var i = 0; i < context.attachmentDocument.file_attachment_name.length; i++) {
                            if (context.attachmentDocument.file_attachment_name[i] != "" && context.attachmentDocument.description_attachment[i] != "") {
                                formData.append("file_attachment_name[" + i + "]", context.attachmentDocument.file_attachment_name[i]);
                                formData.append("description_attachment[" + i + "]", context.attachmentDocument.description_attachment[i]);
                            } else {
                                context.$notify({
                                  message: this.tt("please_complete_data", {
                                    title: this.tt("attachment_document")
                                  }),
                                  type: "danger"
                                });
                                context.form.btnSave.onLoading = false;
                                return false;
                            }
                        }
                    } else {
                        context.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("attachment_document") }),                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                        context.form.btnSave.onLoading = false;
                        return false;
                    }

                    // STATUS PROCESS
                    formData.append('status_process', 'user_process')

                    Api(context, draftList.updateMcrItemNoFormData(formData, context.inputDraftListA2.id)).onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
                            horizontalAlign: 'left',
                        });
                        context.form.btnSave.onLoading = false;
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    }).onFinish(() => {
                        context.$emit('saveA2a')
                    }).call()

                    // debug formData
                    // for (var pair of formData.entries()) {
                    //     console.log(pair[0]+ ', ' + pair[1]); 
                    // }
                } else {
                    context.form.btnSave.onLoading = false;
                    context.$notify({
                        message: this.tt("please_complete_data", { title: this.tt("change_material") }),                  
                        type: 'danger',
                        verticalAlign: "bottom",
			            horizontalAlign: "left"
                    });
                }
            },
            addMpnA2() {
                if (this.manufacturePartNumberA2.manufacture_code.length == 0) {
                    this.manufacturePartNumberA2.manufacture_code.push('')
                    this.manufacturePartNumberA2.mpn.push('')
                    this.manufacturePartNumberA2.source_type.push('')
                    this.manufacturePartNumberA2.position.push('')
                    this.manufacturePartNumberA2.manufacture_type.push('')
                    this.manufacturePartNumberA2.manufacture_note.push('')
                } else if (this.manufacturePartNumberA2.manufacture_code.length > 0) {
                    if (this.manufacturePartNumberA2.manufacture_code[this.manufacturePartNumberA2.manufacture_code.length-1] != '' &&
                        this.manufacturePartNumberA2.mpn[this.manufacturePartNumberA2.mpn.length-1] != '' &&
                        this.manufacturePartNumberA2.source_type[this.manufacturePartNumberA2.source_type.length-1] != '' &&
                        this.manufacturePartNumberA2.manufacture_type[this.manufacturePartNumberA2.manufacture_type.length-1] != '') {
                        
                        this.manufacturePartNumberA2.manufacture_code.push('')
                        this.manufacturePartNumberA2.mpn.push('')
                        this.manufacturePartNumberA2.source_type.push('')
                        this.manufacturePartNumberA2.position.push('')
                        this.manufacturePartNumberA2.manufacture_type.push('')
                        this.manufacturePartNumberA2.manufacture_note.push('')
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", { title: this.tt("mpn") }),                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                    }
                }
            },
            removeMpnA2(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.manufacturePartNumberA2.manufacture_code.splice(key, 1)
                        this.manufacturePartNumberA2.mpn.splice(key, 1)
                        this.manufacturePartNumberA2.source_type.splice(key, 1)
                        this.manufacturePartNumberA2.position.splice(key, 1)
                        this.manufacturePartNumberA2.manufacture_type.splice(key, 1)
                        this.manufacturePartNumberA2.manufacture_note.splice(key, 1)
                    }
                })
            },
            addEqiA2() {
                if (this.equipmentQuantityInstallA2.plant.length == 0) {
                    this.equipmentQuantityInstallA2.plant.push('')
                    this.equipmentQuantityInstallA2.equipment_code.push('')
                    this.equipmentQuantityInstallA2.qty_installed.push('')
                    this.equipmentQuantityInstallA2.drawing_number.push('')
                } else if (this.equipmentQuantityInstallA2.plant.length > 0) {
                    if (this.equipmentQuantityInstallA2.plant[this.equipmentQuantityInstallA2.plant.length-1] != '' &&
                        this.equipmentQuantityInstallA2.equipment_code[this.equipmentQuantityInstallA2.equipment_code.length-1] != '' &&
                        this.equipmentQuantityInstallA2.qty_installed[this.equipmentQuantityInstallA2.qty_installed.length-1] != '' &&
                        this.equipmentQuantityInstallA2.drawing_number[this.equipmentQuantityInstallA2.drawing_number.length-1] != '') {
                        
                        this.equipmentQuantityInstallA2.plant.push('')
                        this.equipmentQuantityInstallA2.equipment_code.push('')
                        this.equipmentQuantityInstallA2.qty_installed.push('')
                        this.equipmentQuantityInstallA2.drawing_number.push('')
                        this.dm_attachment.push("")
                    } else {
                        this.$notify({
                            message: this.tt("please_complete_data", {title: this.tt("equipment_&_quantity_install")}),                  
                            type: 'danger',
                            verticalAlign: "bottom",
                            horizontalAlign: "left"
                        });
                    }
                }
            },
            removeEqiA2(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.equipmentQuantityInstallA2.plant.splice(key, 1)
                        this.equipmentQuantityInstallA2.equipment_code.splice(key, 1)
                        this.equipmentQuantityInstallA2.qty_installed.splice(key, 1)
                        this.equipmentQuantityInstallA2.drawing_number.splice(key, 1) 
                        this.dm_attachment.splice(key, 1)
                    }
                })              
            },
            addAd() {

              if (this.attachmentDocument.file_attachment_name.length == 0) {
                this.attachmentDocument.file_attachment_name.push("");
                this.attachmentDocument.description_attachment.push("");
              } else if (this.attachmentDocument.file_attachment_name.length > 0) {
                if (
                  this.attachmentDocument.file_attachment_name[
                    this.attachmentDocument.file_attachment_name.length - 1
                  ] != "" &&
                  this.attachmentDocument.description_attachment[
                    this.attachmentDocument.description_attachment.length - 1
                  ] != "" 
                ) {
                    this.attachmentDocument.file_attachment_name.push("");
                    this.attachmentDocument.description_attachment.push("");
                } else {
                  this.$notify({
                    message: this.tt("please_complete_data", {title: this.tt("attachment_document")}),
                    type: "danger"
                  });
                }
              }
            },
            removeAd(key) {
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.attachmentDocument.file_attachment_name.splice(key, 1);
                        this.attachmentDocument.description_attachment.splice(key, 1);
                    }
                })
            },
            removePrevAd(id){
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemAttachment(id)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                            context.getDetailMcrItem();
                        }).call();
                    }
                })
            },
            removePrevAdOnChange(key){
                this.confirmDialog(this.tt('confirm_delete')).then((result) => {
                    let context = this;
                    if (result.value) {
                        this.prevAttachmentDocument.splice(key, 1);
                    }
                })
            },
            removePrevAdChange(){
                this.confirmDialog(this.tt('confirm_replaced_data')).then((result) => {
                    let context = this;
                    if (result.value) {
                        Api(context, draftList.deleteMcrItemAttachmentChange(context.mcrItemCode)).onSuccess(function(response) {
                            context.$notify({
                                message: response.data.message,
                                type: 'success',
                                verticalAlign: 'bottom', 
                                horizontalAlign: 'left'
                            });
                        }).call();
                    }
                })
            },
            changeFileAttachmentName(files) {
                if(files.length === 0){
                    return;
                }

                if (files[0].size > this.maxValidation.size) {
                    this.attachmentDocument == [];
                    this.$notify({
                        message :this.maxValidation.text,
                        type: 'danger',
                        verticalAlign: 'bottom', 
                        horizontalAlign: 'left'
                    });
                }else {
                    this.attachmentDocument.file_attachment_name[
                        this.attachmentDocument.file_attachment_name.length - 1
                    ] = files[0];
                }
            },
            mandatoryClient(key){
                let context = this;
                context.form.btnSave.onLoading = false;
                context.$notify({
                    message: this.tt("please_complete_data", { title: this.tt(key) }),                  
                    type: 'danger',
                    verticalAlign: "bottom",
                    horizontalAlign: "left"
                });
            },
            previewAttachment(attachment) {
                this.previewAttachmentModal = '';
                this.attachmentFile         = '';
                this.attachmentFileType     = '';
                this.previewAttachmentModal = true;
                this.attachmentFile         = attachment;
                if (attachment.substring(attachment.length-3) == 'peg' || attachment.substring(attachment.length-3) == 'PEG') {
                    this.attachmentFileType = attachment.substring(attachment.length-4);
                }else{
                    this.attachmentFileType = attachment.substring(attachment.length-3);
                }
            },
            changeModalPrev(value) {
                this.previewAttachmentModal = value;
            },
            querySearchDrawingMaster(queryString, cb) {
                if (queryString.length > 1) {
                    var links   = this.drawingMaster;
                    var results = queryString ? links.filter(this.createFilter(queryString)) : links;
                    cb(results);
                }
            },
            createFilter(queryString) {
                return (link) => {
                    return (link.value.toLowerCase().indexOf(queryString.toLowerCase()) > -1);
                };
            },
            selectDrawingNumber(item) {
                this.equipmentQuantityInstallA2.drawing_number[this.drawingMasterKey] = item.value
            },
            selectedDrawingMasterKey(key) {
              this.drawingMasterKey = key;
            },
            clearDrawing(key) {
                this.equipmentQuantityInstallA2.drawing_number[key] = ''
                this.dm_attachment[key] = ''
            },
        }   
    };
</script>