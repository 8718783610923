<template>
	<div v-if="requestCalls > 0">
		<skeleton-loading></skeleton-loading>
	</div>
	<div v-else>
		<div class="row">
	        <div class="col-md-12">
	            <label class="form-control-label">{{ tt('level') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('level')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.deletion_level" @change="emptyField()" :placeholder="tt('choose_level')">
	                    <el-option class="select-danger" :value="dl" :label="dl" :key="dl" v-for="dl in deletionLevel"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Client' || inputDraftListA4.deletion_level == 'Plant' || inputDraftListA4.deletion_level == 'Storage Location' || inputDraftListA4.deletion_level == 'Valuation' || inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('material_number') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('material_number')" rules="required">

	            	<el-select v-model="inputDraftListA4.material_number" filterable remote reserve-keyword :placeholder="tt('minimum_type_char', {total: 3})" :remote-method="getMaterialNumber" :loading="inputTo.loading" @change="changeMaterialNumberA4" :disabled="form.a4.disabled">
                        <el-option label="" value=""></el-option>
                        <el-option v-for="mn in materialNumber" :key="mn.material_number" :label="mn.material_number +' - '+ mn.short_description" :value="mn.material_number"></el-option>
                    </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Plant'">
	            <label class="form-control-label">{{ tt('plant') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('plant')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.plant_code" :placeholder="tt('choose_plant')" @change="getMaterialPlant">
	                    <el-option class="select-danger" v-for="pc in  plantCode" :value="pc.plant_code" :label="pc.plant_code +' - '+pc.description" :key="pc.plant_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    <div class="row">
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Plant'">
	            <label class="form-control-label">{{ tt('storage_location') }} </label>
	            <base-input :name="tt('storage_location')">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.location_code" :placeholder="tt('choose_storage_location')">
	                    <el-option label="" value=""></el-option>
	                    <el-option class="select-danger" v-for="lc in locationCode" :value="lc.location_code" :label="lc.location_code +' - '+ lc.location_description" :key="lc.location_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>

	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Plant'">
	            <label class="form-control-label">{{ tt('valuation_type') }}</label>
	            <base-input :name="tt('valuation_type')">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.valuation_type_code" :placeholder="tt('choose_valuation_type')">
	                    <el-option label="" value=""></el-option>
	                    <el-option class="select-danger" v-for="vt in valuationType" :value="vt.valuation_type_code" :label="vt.valuation_type_code +' - '+ vt.valuation_type_description" :key="vt.valuation_type_code"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    <div class="row">
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('sales_organization') }} <span class="text-danger">*</span></label>
	            <base-input :name="tt('sales_organization')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.sales_org_code" :placeholder="tt('choose_sales_organization')">
	                    <el-option class="select-danger" :value="so.sales_org_code" :label="so.sales_org_code +' - '+ so.sales_description" :key="so.sales_org_code" v-for="so in  salesOrganization"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	        <div class="col-md-12" v-if="inputDraftListA4.deletion_level == 'Sales'">
	            <label class="form-control-label">{{ tt('distribution_channel') }}</label>
	            <base-input :name="tt('distribution_channel')" rules="required">
	                <el-select :disabled="form.a4.disabled" class="select-danger" v-model="inputDraftListA4.distr_channel_code" :placeholder="tt('choose_distribution_channel')">
	                    <el-option class="select-danger" :value="dc.distr_channel_code" :label="dc.distr_channel_code +' - '+ dc.distr_channel_description" :key="dc.distr_channel_code" v-for="dc in salesOrganization"></el-option>
	                </el-select>
	            </base-input>
	        </div>
	    </div>

	    <label class="form-control-label">{{ tt('reason') }} <span class="text-danger">*</span></label>
	    <base-input :name="tt('reason')" rules="required">
	        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.reason" @input="inputDraftListA4.reason = inputDraftListA4.reason.toUpperCase()"></textarea>
	    </base-input>
	    <label class="form-control-label">{{ tt('note') }}</label>
	    <base-input :name="tt('note')">
	        <textarea :disabled="form.a4.disabled" class="form-control" rows="5" v-model="inputDraftListA4.note" @input="inputDraftListA4.note = inputDraftListA4.note.toUpperCase()"></textarea>
	    </base-input>

	    <div class="text-right mb-3" v-if="!form.a4.disabled">
	        <base-button
	          type="primary"
	          @click="save()"
	          :disabled="form.btnSave.onLoading">
	          <span v-if="form.btnSave.onLoading"><i class="fas fa-spinner fa-spin"></i>{{ tt("please_wait") }}</span>
	          <span v-else>{{ tt("save") }}</span>
			</base-button>
        </div>

	</div>
</template>
<script>
	import {mapState} from 'vuex';
    import Api from '@/helpers/api';
    import draftList from '@/services/material/draftList.service';
    import mcrApproval from '@/services/dashboard/mcrApproval.service';
    import endUserSearchMaterial from '@/services/endUserSearch/endUserSearchMaterial.service';
    import materialNumber from '@/services/master/materialNumber.service';

    export default {        
        data() {
            return { 
            	requestCalls: 0,
                form: {
                    a4: {
                        show: false,
                        disabled: false,
                        type: 'add',
                    },
                    btnSave: {
						onLoading: false
					}
                },
                accordion: {
                    a4: {
                        mpn: {
                            show: ''
                        },
                        eqi: {
                            show: ''
                        },
                    },
                },
                inputTo: {
                    loading: false,
                    loadTimeout: null
                },
                inputDraftListA4: {
                    id: '',
                    material_number: '',
                    plant_code: '',
                    location_code: '',
                    valuation_area_code: '',
                    valuation_type_code: '',
                    sales_org_code: '',
                    distr_channel_code: '',
                    warehouse_number: '',
                    storage_type_code: '',
                    reason: '',
                    note: '',
                    deletion_level: '',
                },
                manufacturePartNumberA4: {
                    mpn: [],
                },
                equipmentQuantityInstallA4: {
                    equipment_code: [],
                },
                deletionLevel: {},
                materialNumber: [],
                plantCode: {},
                locationCode: [],
                valuationType: {},
                salesOrganization: {},
                distributionChannel: {},
                equipmentCode: {},

                mcr_code: this.$route.params.mcr_code,
			    mcrItemCode: this.$route.params.mcr_item_code,
			    formType: this.$route.params.type, // edit, detail
			    mcrType: this.$route.params.mcr_type // B2,B3,dll
            }
        },
        computed: {
            ...mapState({
                authUserPermission :state => state.auth.userPermission
            }),
        },
        mounted() {
            this.getDetailMcrItem();
            this.checkFormType();
            this.getParameterMcr();
            this.emptyField();
        },
        methods: {
        	checkFormType() {
                if(this.formType == 'edit'){
                    this.form.a4.disabled = false;
                } else if(this.formType == 'detail'){
                    this.form.a4.disabled = true;
                } else if(this.formType == 'approval' && (this.authUserPermission['mcr-approval-user-form-edit'] || this.authUserPermission['mcr-approval-cataloguer-form-edit'])){
                    this.form.a4.disabled = false;
                } else if(this.formType == 'sapSyncTaskEdit'){
                    this.form.a4.disabled = false;
                } else {
                    this.form.a4.disabled = true
                }
			},
        	getDetailMcrItem() {
        		this.requestCalls++;
                let context = this;        
                       
                Api(context, draftList.getDetailMcrItem(this.mcrItemCode)).onSuccess(function(response) {
                    context.inputDraftListA4.id = response.data.data[0].id;
                    context.inputDraftListA4.deletion_level = response.data.data[0].deletion_level;
                    context.inputDraftListA4.material_number = response.data.data[0].material_number;
                    context.inputDraftListA4.plant_code = response.data.data[0].plant_code;
                    context.inputDraftListA4.location_code = response.data.data[0].location_code;
                    context.inputDraftListA4.valuation_area_code = response.data.data[0].plant_code;
                    context.inputDraftListA4.valuation_type_code = response.data.data[0].valuation_type_code;
                    context.inputDraftListA4.sales_org_code = response.data.data[0].sales_org_code;
                    context.inputDraftListA4.distr_channel_code = response.data.data[0].distr_channel_code;
                    context.inputDraftListA4.reason = response.data.data[0].reason;
                    context.inputDraftListA4.note = response.data.data[0].note;
                    if (response.data.data[0].material_number != null) {
                        // context.materialNumber[0] = {
                        //     material_number: response.data.data[0].material_number,
                        //     short_description: response.data.data[0].short_description,
                        // };
                        context.getMaterialNumber(response.data.data[0].material_number)
                    }
                    context.plantCode[0] = {
                        plant_code: response.data.data[0].plant_code,
                        description: response.data.data[0].description,
                    };
                    if (response.data.data[0].location_code != null) {
                        context.locationCode[0] = {
                            location_code: response.data.data[0].location_code,
                            location_description: response.data.data[0].location_description,
                        };
                    }
                    if (response.data.data[0].valuation_type_code != null) {
                        context.valuationType[0] = {
                            valuation_type_code: response.data.data[0].valuation_type_code,
                            valuation_type_description: response.data.data[0].valuation_type_description,
                        };
                    }
                    context.salesOrganization[0] = {
                        sales_org_code: response.data.data[0].sales_org_code,
                        sales_description: response.data.data[0].sales_org_description,
                        distr_channel_code: response.data.data[0].distr_channel_code,
                        distr_channel_description: response.data.data[0].distr_channel_description,
                    };

                    if (context.inputDraftListA4.deletion_level == 'Plant') {
                        context.changeMaterialNumberA4();
                        context.getMaterialPlant();
                    } else if (context.inputDraftListA4.deletion_level == 'Sales') {
                        context.changeMaterialNumberA4();
                    }
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.inputDraftListA4 = [];
                    }
                }).onFinish(function(){
                    context.requestCalls--;
                }).call()
            },
            changeMaterialNumberA4() {  
                let context = this;
                Api(context, endUserSearchMaterial.partCharValueEndUser({material_number: context.inputDraftListA4.material_number})).onSuccess(function(response) {
                	context.plantCode = response.data.data.part_plant;
                	context.salesOrganization = response.data.data.part_sales;
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                    	context.plantCode = [];
                        context.manufacturePartNumberA4.mpn = [];
                        context.equipmentQuantityInstallA4.equipment_code = [];
                    }
                })
                .call()
            },
            getMaterialPlant() {
                let context = this;               
                Api(context, endUserSearchMaterial.getMaterialPlant({material_number: context.inputDraftListA4.material_number, plant_code: context.inputDraftListA4.plant_code})).onSuccess(function(response) { 
                    context.locationCode = response.data.data.part_storage_location;

                    // dibuat seperti ini agar valuation type yang null tidak muncul
                    var valuationTypeFiltered = [];
                    response.data.data.part_accounting.forEach((v,i) => {
                        if (v.valuation_type_code != null) {
                            valuationTypeFiltered[i] = v;
                        }
                    })
                    context.valuationType = valuationTypeFiltered
                }).onError(function(error) {                    
                    if (error.response.status == 404) {
                        context.locationCode = [];
                    }
                })
                .call()
            },
        	getParameterMcr() {
        		this.requestCalls++;
		    	let context = this;

		    	Api(context, draftList.getParameterMcr(this.mcrItemCode))
		        .onSuccess(function(response) {
		          context.deletionLevel = response.data.data.deletion_level;
		        })
		        .onError(function(error) {
		          if (error.response.status == 404) {
		            context.input = [];
		          }
		        }).onFinish(function(){
                	context.requestCalls--;
                }).call()
		    },
		    getMaterialNumber(query) {
		    	if (query.length > 2) {
                    clearTimeout(this.inputTo.loadTimeout);             
                    let context = this;   
                    this.inputTo.loadTimeout = setTimeout(() => {
                        this.inputTo.loading = true;
                        Api(context,  materialNumber.get({search: query})).onSuccess(function(response) {
                            context.materialNumber = response.data.data;                        
                            context.inputTo.loading = false;                     
                        }).onError(function(error) {
                            context.materialNumber = [];
                            context.inputTo.loading = false;
                        }).call();             
                    })     
                }
            },
            save() {
                let context = this;          
                let data = {
                    mcr_code: context.mcr_code,
                    deletion_level: context.inputDraftListA4.deletion_level,
                    reason: context.inputDraftListA4.reason,
                    note: context.inputDraftListA4.note,
                    status_process: 'user_process',
                }


                if (context.inputDraftListA4.deletion_level !== '') {
                    if (context.inputDraftListA4.deletion_level == 'Client') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Plant') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.plant_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                    if (context.inputDraftListA4.deletion_level == 'Sales') {
                        if (context.inputDraftListA4.material_number != '' && context.inputDraftListA4.sales_org_code != '' && context.inputDraftListA4.reason != '') {
                            var validation = true;
                        } else {
                            var validation = false;
                        }
                    }
                } else {
                    var validation = false;
                }

                if (validation == true) {
                	if (context.inputDraftListA4.deletion_level == 'Client') {
                		data.material_number = context.inputDraftListA4.material_number;
                	}

                	if (context.inputDraftListA4.deletion_level == 'Plant') {
                		data.material_number = context.inputDraftListA4.material_number;
                		data.plant_code = context.inputDraftListA4.plant_code;
                		data.location_code = context.inputDraftListA4.location_code;
                		data.valuation_type_code = context.inputDraftListA4.valuation_type_code

                		// KETIKA UPDATE MENGGANTI DELETION LAIN VALUE YG GA PERLU DI KOSONGKAN
                		data.sales_org_code = null;
                    	data.distr_channel_code = null;
                	}

                	if (context.inputDraftListA4.deletion_level == 'Sales') {
                		data.material_number = context.inputDraftListA4.material_number;
                		data.sales_org_code = context.inputDraftListA4.sales_org_code;
                    	data.distr_channel_code = context.inputDraftListA4.distr_channel_code;

                    	// KETIKA UPDATE MENGGANTI DELETION LAIN VALUE YG GA PERLU DI KOSONGKAN
                    	data.plant_code = null;
                    	data.location_code = null;
                		data.valuation_type_code = null
                	}
                    
                    context.form.btnSave.onLoading = true;

                    Api(context, draftList.updateMcrItemNoFormData(data, context.inputDraftListA4.id)).onSuccess(function(response) {    
                        context.$notify({
                            message: response.data.message,                  
                            type: 'success',
                            verticalAlign: 'bottom', 
							horizontalAlign: 'left'
                        });
                        context.form.btnSave.onLoading = false;
                        context.getDetailMcrItem()
                        // context.emptyField();
                        context.$emit('saveA4')
                        // context.$parent.$refs.statusProcess.get()
                    }).onError(function(error) {                    
                        if (error.response.status == 404) {
                            context.draftList = [];
                            context.form.btnSave.onLoading = false;
                        }
                    })
                    .call()
                } else {
                    context.$notify({
                        message: this.tt("please_complete_mandatory_field"),                  
                        type: 'danger',
                        verticalAlign: "bottom",
                        horizontalAlign: "left"
                    });
                }
            },
            emptyField(){
        		if (this.inputDraftListA4.deletion_level == 'Client') 
        		{
        			this.inputDraftListA4.plant_code = '';
	                this.inputDraftListA4.location_code = '';
	                this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Plant') 
        		{
        			this.inputDraftListA4.location_code = '';
	                this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
	                this.inputDraftListA4.sales_org_code = '';
	                this.inputDraftListA4.distr_channel_code = '';
        		}else if (this.inputDraftListA4.deletion_level == 'Sales') 
        		{
        			this.inputDraftListA4.plant_code = '';
        			this.inputDraftListA4.location_code = '';
        			this.inputDraftListA4.valuation_type_code = '';
	                this.inputDraftListA4.valuation_area_code = '';
        		}
        	},
        }   
    };
</script>